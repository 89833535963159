import type {ReactElement} from 'react';
import List from '@material-ui/core/List';
import Button from '@material-ui/core/Button';
import {playAudioFile} from './AudioPlayer';
import type AudioExerciseModel from 'one.models/lib/models/AudioExerciseModel';
import {useEntryViewContext} from '../../journal/EntryView';
import type {ImpactStudyVisit} from '../../../model/studies/StudyHelper';
import {IMPACT_VISIT} from '../../../model/studies/StudyHelper';
import i18n from '../../../i18n';

/**
 * Used to get the right name of the audio file based on the visit.
 * @param visit
 */
function getTaskName(visit: ImpactStudyVisit): string {
    switch (visit) {
        case IMPACT_VISIT.Day_0:
            return i18n.t('studies:impact.bodyScan');
        case IMPACT_VISIT.Day_7:
            return i18n.t('studies:impact.yoga');
        case IMPACT_VISIT.Day_14:
            return i18n.t('studies:impact.meditation');
        case IMPACT_VISIT.Day_21:
            return i18n.t('studies:impact.meditative');
    }
}

/**
 * Represents the smiler study dashboard.
 * @param props
 * @param props.pastVisits - the visits that have passed for smiler impact study.
 * @param props.audioExerciseModel - audio exercise model.
 */
export default function PastAudioExercisesDashboard(props: {
    pastVisits: ImpactStudyVisit[];
    audioExerciseModel?: AudioExerciseModel;
}): ReactElement {
    // the context for the entry view that is displayed with the audio player
    const {closeEntryViewCallback, setContent, setTitle} = useEntryViewContext();

    /**
     * Trigger specific action depending on what task was clicked.
     * @param visit - specify the action that will happens.
     */
    function playAudio(visit: ImpactStudyVisit): void {
        if (props.audioExerciseModel !== undefined) {
            playAudioFile(
                closeEntryViewCallback,
                setContent,
                setTitle,
                props.audioExerciseModel,
                visit
            );
        }
    }

    return (
        <>
            {props.pastVisits.length > 0 && (
                <List className="dashboard active">
                    {props.pastVisits.map(visit => (
                        <div key={visit}>
                            <Button
                                color="default"
                                variant="contained"
                                className="optional-task-button"
                                onClick={() => playAudio(visit)}
                            >
                                {getTaskName(visit)}
                            </Button>
                        </div>
                    ))}
                </List>
            )}
        </>
    );
}
