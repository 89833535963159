import type {ReactElement} from 'react';
import type OneInstanceModel from '../../model/OneInstanceModel';
import {Button} from '@material-ui/core';
import i18n from '../../i18n';
import EraseDialog from '../eraseInstance/EraseDialog';
import type ConsentFileModel from 'one.models/lib/models/ConsentFileModel';
import type {PropertyTree} from 'one.models/lib/models/SettingsModel';
import MenuButton from '../menu/MenuButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import MarkdownPaperView from '../displayMarkdowns/MarkdownPaperView';
import {useState} from 'react';

/**
 * @param props
 * @param props.oneInstanceModel
 * @param props.setNavigationDrawer
 * @param props.consentFileModel
 * @param props.setLogoutFromErase
 * @param props.settings
 * @returns
 */
export default function Erase(props: {
    oneInstanceModel: OneInstanceModel;
    setNavigationDrawer: (value: boolean) => void;
    setLogoutFromErase: (value: boolean) => void;
    consentFileModel: ConsentFileModel;
    settings: PropertyTree;
}): ReactElement {
    const [showEraseDialog, setShowEraseDialog] = useState<boolean>(false);

    return (
        <>
            <div className="circular-progress-container">
                <CircularProgress className="circular-progress" size={35} />
            </div>
            <div className="page-container hide">
                <div className="menu-button-header">
                    <MenuButton />
                    <h2 className="headline">{i18n.t('common:eraseView.title')}</h2>
                </div>
                <MarkdownPaperView filePath={i18n.t('markDown:eraseInstance')} />
                <div className="buttons-container">
                    <Button
                        className="button"
                        variant="contained"
                        color="secondary"
                        onClick={() => setShowEraseDialog(true)}
                    >
                        {i18n.t('common:buttons.delete')}
                    </Button>
                </div>
                {showEraseDialog && (
                    <EraseDialog
                        oneInstanceModel={props.oneInstanceModel}
                        setNavigationDrawer={props.setNavigationDrawer}
                        showEraseDialog={setShowEraseDialog}
                        setLogoutFromErase={props.setLogoutFromErase}
                        consentFileModel={props.consentFileModel}
                        settings={props.settings}
                    />
                )}
            </div>
        </>
    );
}
