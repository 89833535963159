import {useEffect, useState} from 'react';
import type {ReactElement} from 'react';
import * as dateFns from 'date-fns';
import './Calendar.css';
import '../../Primary.css';
import type JournalModel from 'one.models/lib/models/JournalModel';
import type {EventListEntry} from 'one.models/lib/models/JournalModel';
import {getCurrentEventInformation, useEventList} from '../modelHelper/JournalHelper';
import type {EventTypes} from '../journal/Journal';
import JournalDialog from '../journal/JournalDialog';
import {CircularProgress} from '@material-ui/core';
import i18n from '../../i18n';
import {hideCircularProgress} from '../utils/Utils';

/**
 * This component builds and returns the calendar in month view.
 * @param props - Properties of this view.
 * @param props.journalModel
 * @param props.eventTypes
 * @returns - entire month view of the calendar.
 */
export default function MonthCalendarView(props: {
    journalModel: JournalModel;
    eventTypes: EventTypes[];
}): ReactElement {
    const [currentDate, setCurrentDate] = useState(new Date());
    const dateFormat = 'MMMM yyyy';
    const allEventsList = useEventList(props.journalModel);

    const [clickedEvent, setClickedEvent] = useState<EventListEntry>();
    const [clickedEventType, setClickedEventType] = useState('');
    const [dialogState, setDialogState] = useState(false);

    useEffect(() => {
        hideCircularProgress();
    }, [allEventsList]);

    /**
     * Build icons for a day from the calendar for each event type from the event list.
     *
     * @param {Date} date - if this date match the event date, then corresponding icon for each event type is returned.
     * @param {EventTypes[]} eventTypes - The list with all types of events
     * @returns {ReactElement} - an array containing specific icon for each event type from the event list.
     */
    function buildIconByEventType(date: Date, eventTypes: EventTypes[]): ReactElement {
        const displayedIcons = [];

        for (let i = allEventsList.length; i >= 0; i--) {
            if (
                allEventsList[i] !== undefined &&
                dateFns.isSameDay(allEventsList[i].data.creationTime, date)
            ) {
                const currentEventInfo = getCurrentEventInformation(allEventsList[i], eventTypes);

                displayedIcons.push(
                    <div
                        className="month-icon"
                        key={currentEventInfo.minutes}
                        onClick={() => {
                            setDialogState(!dialogState);
                            setClickedEventType(currentEventInfo.type);
                            setClickedEvent(allEventsList[i]);
                        }}
                    >
                        {currentEventInfo.hour}
                        {':'}
                        {currentEventInfo.minutes}
                        {currentEventInfo.icon}
                    </div>
                );
            }
        }

        return <>{displayedIcons}</>;
    }

    /**
     * Build the top of the calendar with all days in a week.
     * @returns {ReactElement} - the header of the calendar containing the days of a week.
     */
    function daysOfWeek(): ReactElement {
        return (
            <div className="days row">
                {[
                    i18n.t('common:days.Monday'),
                    i18n.t('common:days.Tuesday'),
                    i18n.t('common:days.Wednesday'),
                    i18n.t('common:days.Thursday'),
                    i18n.t('common:days.Friday'),
                    i18n.t('common:days.Saturday', i18n.t('common:days.Sunday'))
                ].map(day => {
                    return (
                        <div className="column col-center" key={day}>
                            {day}
                        </div>
                    );
                })}
            </div>
        );
    }

    /**
     * Build the body of the calendar with all the days in a month (e.g. for January 1,2,3..31).
     * @param {EventTypes[]} eventTypes - The list with all types of events
     * @returns {ReactElement} - the body of the calendar with all days in a month.
     */
    function buildDaysOfAMonth(eventTypes: EventTypes[]): ReactElement {
        const monthStart = dateFns.startOfMonth(currentDate);
        const monthEnd = dateFns.endOfMonth(monthStart);
        const startDate = dateFns.startOfWeek(monthStart, {weekStartsOn: 1});
        const endDate = dateFns.endOfWeek(monthEnd, {weekStartsOn: 1});
        const dayFormatter = 'd';
        const rows = [];

        let days = [];
        let day = startDate;
        let formattedDate = '';
        let dayOfMonth = 0;

        while (day <= endDate) {
            for (let i = 0; i < 7; i++) {
                formattedDate = dateFns.format(day, dayFormatter);
                dayOfMonth = parseInt(dateFns.format(day, dayFormatter), 10);

                days.push(
                    <div className="column cell" key={dayOfMonth}>
                        <div className="month-cell" key={i}>
                            {buildIconByEventType(day, eventTypes)}
                        </div>
                        <span className="number">{formattedDate}</span>
                    </div>
                );
                day = dateFns.addDays(day, 1);
            }
            rows.push(
                <div className="row" key={dayOfMonth}>
                    {days}
                </div>
            );
            days = [];
        }

        return <div className="body">{rows}</div>;
    }

    return (
        <>
            <div className="circular-progress-container">
                <CircularProgress className="circular-progress" size={35} />
            </div>

            <div className="page-container calendar hide">
                {dialogState && clickedEvent ? (
                    <JournalDialog
                        dialogState={dialogState}
                        setDialogState={setDialogState}
                        event={clickedEvent}
                        type={clickedEventType}
                    />
                ) : (
                    <div />
                )}
                <div className="calendarHeader row flex-middle">
                    <div
                        className="icon_calendar"
                        onClick={() => setCurrentDate(dateFns.subMonths(currentDate, 1))}
                    >
                        chevron_left
                    </div>
                    {i18n.t(
                        'common:months.' + dateFns.format(currentDate, dateFormat).split(' ')[0]
                    )}
                    {dateFns.format(currentDate, dateFormat).split(' ')[1]}
                    <div
                        className="icon_calendar"
                        onClick={() => setCurrentDate(dateFns.addMonths(currentDate, 1))}
                    >
                        chevron_right
                    </div>
                </div>
                <div>{daysOfWeek()}</div>
                <div>{buildDaysOfAMonth(props.eventTypes)}</div>
            </div>
        </>
    );
}
