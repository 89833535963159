import type ECGModel from 'one.models/lib/models/ECGModel';
import type WbcDiffModel from 'one.models/lib/models/WbcDiffModel';
import type DocumentModel from 'one.models/lib/models/DocumentModel';
import type {DocumentInfo} from 'one.models/lib/models/DocumentModel';
import type QuestionnaireModel from 'one.models/lib/models/QuestionnaireModel';
import type {QuestionnaireResponses} from 'one.models/lib/recipes/QuestionnaireRecipes/QuestionnaireResponseRecipes';
import * as dateFns from 'date-fns';
import type ChannelManager from 'one.models/lib/models/ChannelManager';
import type {ObjectData} from 'one.models/lib/models/ChannelManager';
import type {Electrocardiogram} from 'one.models/lib/recipes/ECGRecipes';
import type {OneUnversionedObjectTypes} from 'one.core/lib/recipes';
import type {WbcObservation} from 'one.models/lib/recipes/WbcDiffRecipes';
import {ReportModel} from '../../one.smiler.replicant-dependencies/Minimal-ReportModel';
import {getInstanceOwnerIdHash} from 'one.core/lib/instance';
import type AudioExerciseModel from 'one.models/lib/models/AudioExerciseModel';
import StudyWorkflow from './StudyWorkflow';
import {STUDY_DURATION} from './StudyWorkflow';
import type {SmilerStudyVisit, VisitDataCommon} from './StudyHelper';
import {SMILER_VISIT} from './StudyHelper';
import type StudyCommonInterface from './StudyCommonInterface';

/**
 * The type representing the data of a visit.
 */
export type SmilerVisitData = {
    isWbcAnswered: boolean;
    isEcgAnswered: boolean;
    isQuestionnaireAnswered: boolean;
    isPhotoAnswered: boolean;
} & VisitDataCommon;

/**
 * The visits properties.
 */
export type SmilerVisitProperties = {
    [SMILER_VISIT.Day_0]: SmilerVisitData;
    [SMILER_VISIT.Day_14]: SmilerVisitData;
    [SMILER_VISIT.Day_28]: SmilerVisitData;
};

/**
 * All available tasks of the Smiler study.
 */
export const SMILER_TASK = {
    Questionnaire: 'questionnaire',
    Photo: 'photo',
    Wbc: 'wbc',
    Ecg: 'ecg'
} as const;

/** The type definition based on the SMILER_TASK value. */
export type SmilerVisitTask = typeof SMILER_TASK[keyof typeof SMILER_TASK];

/**
 * This model represents the workflow for the Smiler study.
 */
export default class SmilerWorkflow
    extends StudyWorkflow<SmilerVisitTask, SmilerStudyVisit>
    implements StudyCommonInterface<SmilerVisitProperties, SmilerStudyVisit>
{
    private readonly VISIT_INTERVAL_DAYS = 14;
    private readonly DAYS_OFFSET_ACCEPTANCE = 1;
    private static readonly VISIT_TASKS_NUMBER = 4;
    private visitProperties: SmilerVisitProperties;

    constructor(
        ecgModel: ECGModel,
        wbcModel: WbcDiffModel,
        documentModel: DocumentModel,
        questionnaireModel: QuestionnaireModel,
        audioExerciseModel: AudioExerciseModel,
        channelManager: ChannelManager
    ) {
        super(
            ecgModel,
            wbcModel,
            documentModel,
            questionnaireModel,
            audioExerciseModel,
            channelManager
        );
        this.visitProperties = this.resetVisitProperties();
    }

    async init(): Promise<void> {
        // initialize a timer to emit an event when the current day passed,
        // in this way we can update the ui properly because some of the properties are affected when the current day passed
        if (this.timer === undefined) {
            this.timer = this.studyHelper.initTimer(
                this.updateTimeDependentProperties.bind(this),
                this.onUpdated
            );
        }

        await this.initializeStartStudyDate();
        await this.updateVisitsProperties();
        this.activeVisit = this.calculateActiveVisit();
        this.updateTimeDependentProperties();

        // trigger the report at initialization to make sure that
        // if some objects (ecg, wbc) were added while smiler was not open the report will be triggered anyway
        try {
            await this.triggerReport();
        } catch (error) {
            // @TODO need to discuss how it should be handled properly
            console.error(error);
        }

        this.questionnaireDisconnect = this.questionnaireModel.onUpdated(
            this.handleOnUpdate.bind(this)
        );
        this.wbcDisconnect = this.wbcModel.onUpdated(this.handleOnUpdate.bind(this));
        this.ecgDisconnect = this.ecgModel.onUpdated(this.handleOnUpdate.bind(this));
        this.documentDisconnect = this.documentModel.onUpdated(this.handleOnUpdate.bind(this));
        this.audioExerciseDisconnect = this.audioExerciseModel.onUpdated(
            this.handleOnStudyUpdate.bind(this)
        );
    }

    getVisitProperties(): SmilerVisitProperties {
        return this.visitProperties;
    }

    getVisitTasksNumber(): number {
        return SmilerWorkflow.VISIT_TASKS_NUMBER;
    }

    async handleOnUpdate(objectData?: ObjectData<OneUnversionedObjectTypes>): Promise<void> {
        if (!objectData) {
            return;
        }

        // if the object is from the past then update the start study date
        if (dateFns.isBefore(objectData.creationTime, this.studyStartDate)) {
            this.studyStartDate = objectData.creationTime;
        }

        const visitsFulfilled = this.checkVisitsTasksCompletion([
            objectData as ObjectData<
                QuestionnaireResponses | DocumentInfo | Electrocardiogram | WbcObservation
            >
        ]);

        switch (objectData.data.$type$) {
            case 'WbcObservation':
                // Assign the calculated value only if the task isn't already done. Let's assume that
                // the received object is an wbc observation completed in the second visit. The
                // visitsFulfilled object will have the data calculated correctly just for the
                // received object ("visitsFulfilled.day7_isTaskCompleted" will be true, the others
                // false). If the wbc observation from the first visit is done, then by assigning
                // directly the calculated value, we will loose the right data. Checking the
                // value of the object before assigning the new value ensure the data accuracy.
                this.visitProperties[SMILER_VISIT.Day_0].isWbcAnswered = this.visitProperties[
                    SMILER_VISIT.Day_0
                ].isWbcAnswered
                    ? this.visitProperties[SMILER_VISIT.Day_0].isWbcAnswered
                    : visitsFulfilled.day0_isTaskCompleted;
                this.visitProperties[SMILER_VISIT.Day_14].isWbcAnswered = this.visitProperties[
                    SMILER_VISIT.Day_14
                ].isWbcAnswered
                    ? this.visitProperties[SMILER_VISIT.Day_14].isWbcAnswered
                    : visitsFulfilled.day14_isTaskCompleted;
                this.visitProperties[SMILER_VISIT.Day_28].isWbcAnswered = this.visitProperties[
                    SMILER_VISIT.Day_28
                ].isWbcAnswered
                    ? this.visitProperties[SMILER_VISIT.Day_28].isWbcAnswered
                    : visitsFulfilled.day28_isTaskCompleted;
                break;
            case 'QuestionnaireResponses':
                this.visitProperties[SMILER_VISIT.Day_0].isQuestionnaireAnswered = this
                    .visitProperties[SMILER_VISIT.Day_0].isQuestionnaireAnswered
                    ? this.visitProperties[SMILER_VISIT.Day_0].isQuestionnaireAnswered
                    : visitsFulfilled.day0_isTaskCompleted;
                this.visitProperties[SMILER_VISIT.Day_14].isQuestionnaireAnswered = this
                    .visitProperties[SMILER_VISIT.Day_14].isQuestionnaireAnswered
                    ? this.visitProperties[SMILER_VISIT.Day_14].isQuestionnaireAnswered
                    : visitsFulfilled.day14_isTaskCompleted;
                this.visitProperties[SMILER_VISIT.Day_28].isQuestionnaireAnswered = this
                    .visitProperties[SMILER_VISIT.Day_28].isQuestionnaireAnswered
                    ? this.visitProperties[SMILER_VISIT.Day_28].isQuestionnaireAnswered
                    : visitsFulfilled.day28_isTaskCompleted;
                break;
            case 'Electrocardiogram':
                this.visitProperties[SMILER_VISIT.Day_0].isEcgAnswered = this.visitProperties[
                    SMILER_VISIT.Day_0
                ].isEcgAnswered
                    ? this.visitProperties[SMILER_VISIT.Day_0].isEcgAnswered
                    : visitsFulfilled.day0_isTaskCompleted;
                this.visitProperties[SMILER_VISIT.Day_14].isEcgAnswered = this.visitProperties[
                    SMILER_VISIT.Day_14
                ].isEcgAnswered
                    ? this.visitProperties[SMILER_VISIT.Day_14].isEcgAnswered
                    : visitsFulfilled.day14_isTaskCompleted;
                this.visitProperties[SMILER_VISIT.Day_28].isEcgAnswered = this.visitProperties[
                    SMILER_VISIT.Day_28
                ].isEcgAnswered
                    ? this.visitProperties[SMILER_VISIT.Day_28].isEcgAnswered
                    : visitsFulfilled.day28_isTaskCompleted;
                break;
            case 'DocumentInfo_1_1_0':
                this.visitProperties[SMILER_VISIT.Day_0].isPhotoAnswered = this.visitProperties[
                    SMILER_VISIT.Day_0
                ].isPhotoAnswered
                    ? this.visitProperties[SMILER_VISIT.Day_0].isPhotoAnswered
                    : visitsFulfilled.day0_isTaskCompleted;
                this.visitProperties[SMILER_VISIT.Day_14].isPhotoAnswered = this.visitProperties[
                    SMILER_VISIT.Day_14
                ].isPhotoAnswered
                    ? this.visitProperties[SMILER_VISIT.Day_14].isPhotoAnswered
                    : visitsFulfilled.day14_isTaskCompleted;
                this.visitProperties[SMILER_VISIT.Day_28].isPhotoAnswered = this.visitProperties[
                    SMILER_VISIT.Day_28
                ].isPhotoAnswered
                    ? this.visitProperties[SMILER_VISIT.Day_28].isPhotoAnswered
                    : visitsFulfilled.day28_isTaskCompleted;
                break;
            default:
                throw new Error(`Error: Received wrong object type ${objectData.data.$type$}.`);
        }

        const lastVisitCompletedTasks = this.visitProperties[SMILER_VISIT.Day_28].tasksCompleted;

        this.visitProperties[SMILER_VISIT.Day_0].tasksCompleted = [
            this.visitProperties[SMILER_VISIT.Day_0].isPhotoAnswered,
            this.visitProperties[SMILER_VISIT.Day_0].isWbcAnswered,
            this.visitProperties[SMILER_VISIT.Day_0].isQuestionnaireAnswered,
            this.visitProperties[SMILER_VISIT.Day_0].isEcgAnswered
        ].filter(Boolean).length;
        this.visitProperties[SMILER_VISIT.Day_14].tasksCompleted = [
            this.visitProperties[SMILER_VISIT.Day_14].isPhotoAnswered,
            this.visitProperties[SMILER_VISIT.Day_14].isWbcAnswered,
            this.visitProperties[SMILER_VISIT.Day_14].isQuestionnaireAnswered,
            this.visitProperties[SMILER_VISIT.Day_14].isEcgAnswered
        ].filter(Boolean).length;
        this.visitProperties[SMILER_VISIT.Day_28].tasksCompleted = [
            this.visitProperties[SMILER_VISIT.Day_28].isPhotoAnswered,
            this.visitProperties[SMILER_VISIT.Day_28].isWbcAnswered,
            this.visitProperties[SMILER_VISIT.Day_28].isQuestionnaireAnswered,
            this.visitProperties[SMILER_VISIT.Day_28].isEcgAnswered
        ].filter(Boolean).length;

        this.visitProperties[SMILER_VISIT.Day_0].visitDate = this.studyStartDate;
        this.visitProperties[SMILER_VISIT.Day_14].visitDate = dateFns.addDays(
            this.studyStartDate,
            SMILER_VISIT.Day_14
        );
        this.visitProperties[SMILER_VISIT.Day_28].visitDate = dateFns.addDays(
            this.studyStartDate,
            SMILER_VISIT.Day_28
        );

        // When the last visit tasks are completed then
        // we set the flag for displaying the end of the study message.
        if (
            this.visitProperties[SMILER_VISIT.Day_28].tasksCompleted ===
                SmilerWorkflow.VISIT_TASKS_NUMBER &&
            lastVisitCompletedTasks !== 0 &&
            lastVisitCompletedTasks !== this.visitProperties[SMILER_VISIT.Day_28].tasksCompleted
        ) {
            this.displayStudyEndedMessage = true;
        }

        try {
            await this.triggerReport();
        } catch (error) {
            // @TODO need to discuss how it should be handled properly
            console.error(error);
        }

        this.updateTimeDependentProperties();

        this.onUpdated.emit();
    }

    resetVisitProperties(): SmilerVisitProperties {
        const initialCompletedTasks = 0;

        return {
            [SMILER_VISIT.Day_0]: {
                isWbcAnswered: false,
                isEcgAnswered: false,
                isPhotoAnswered: false,
                isQuestionnaireAnswered: false,
                totalTasks: SmilerWorkflow.VISIT_TASKS_NUMBER,
                tasksCompleted: initialCompletedTasks,
                visitDate: new Date()
            },
            [SMILER_VISIT.Day_14]: {
                isEcgAnswered: false,
                isWbcAnswered: false,
                isPhotoAnswered: false,
                isQuestionnaireAnswered: false,
                totalTasks: SmilerWorkflow.VISIT_TASKS_NUMBER,
                tasksCompleted: initialCompletedTasks,
                visitDate: dateFns.addDays(new Date(), SMILER_VISIT.Day_14)
            },
            [SMILER_VISIT.Day_28]: {
                isWbcAnswered: false,
                isEcgAnswered: false,
                isPhotoAnswered: false,
                isQuestionnaireAnswered: false,
                totalTasks: SmilerWorkflow.VISIT_TASKS_NUMBER,
                tasksCompleted: initialCompletedTasks,
                visitDate: dateFns.addDays(new Date(), SMILER_VISIT.Day_28)
            }
        };
    }

    async updateVisitsProperties(): Promise<void> {
        const lastVisitCompletedTasks = this.visitProperties[SMILER_VISIT.Day_28].tasksCompleted;
        // get the questionnaire responses
        const questionnaireResponses = await this.questionnaireModel.responses();

        // check for which visit the questionnaire response exists
        const visitsWithQuestionnairesFulfilled =
            this.checkVisitsTasksCompletion(questionnaireResponses);

        // assign for each visit the results
        const day0_isQuestionnaireAnswered = visitsWithQuestionnairesFulfilled.day0_isTaskCompleted;
        const day14_isQuestionnaireAnswered =
            visitsWithQuestionnairesFulfilled.day14_isTaskCompleted;
        const day28_isQuestionnaireAnswered =
            visitsWithQuestionnairesFulfilled.day28_isTaskCompleted;

        // same approach as for the questionnaires
        const photos = await this.fetchPhotos();

        const visitsWithPhotosFulfilled = this.checkVisitsTasksCompletion(photos);

        const day0_isPhotoAdded = visitsWithPhotosFulfilled.day0_isTaskCompleted;
        const day14_isPhotoAdded = visitsWithPhotosFulfilled.day14_isTaskCompleted;
        const day28_isPhotoAdded = visitsWithPhotosFulfilled.day28_isTaskCompleted;

        const ecgs = await this.ecgModel.retrieveAllWithoutData();

        const visitsWithEcgsFulfilled = this.checkVisitsTasksCompletion(ecgs);

        const day0_isEcgAdded = visitsWithEcgsFulfilled.day0_isTaskCompleted;
        const day14_isEcgAdded = visitsWithEcgsFulfilled.day14_isTaskCompleted;
        const day28_isEcgAdded = visitsWithEcgsFulfilled.day28_isTaskCompleted;

        const wbcs = await this.wbcModel.observations();

        const visitsWithWbcsFulfilled = this.checkVisitsTasksCompletion(wbcs);

        const day0_isWbcAdded = visitsWithWbcsFulfilled.day0_isTaskCompleted;
        const day14_isWbcAdded = visitsWithWbcsFulfilled.day14_isTaskCompleted;
        const day28_isWbcAdded = visitsWithWbcsFulfilled.day28_isTaskCompleted;

        // calculating the number of completed tasks for DAY_0
        const day0_completedTasks = [
            day0_isEcgAdded,
            day0_isPhotoAdded,
            day0_isWbcAdded,
            day0_isQuestionnaireAnswered
        ].filter(Boolean).length;

        // calculating the number of completed tasks for DAY_14
        const day14_completedTasks = [
            day14_isEcgAdded,
            day14_isWbcAdded,
            day14_isPhotoAdded,
            day14_isQuestionnaireAnswered
        ].filter(Boolean).length;

        // calculating the number of completed tasks for DAY_28
        const day28_completedTasks = [
            day28_isEcgAdded,
            day28_isPhotoAdded,
            day28_isWbcAdded,
            day28_isQuestionnaireAnswered
        ].filter(Boolean).length;

        // When the last visit tasks are completed then
        // we set the flag for displaying the end of the study message.
        if (
            day28_completedTasks === SmilerWorkflow.VISIT_TASKS_NUMBER &&
            lastVisitCompletedTasks !== 0 &&
            lastVisitCompletedTasks !== day28_completedTasks
        ) {
            this.displayStudyEndedMessage = true;
        }

        this.visitProperties = {
            [SMILER_VISIT.Day_0]: {
                isWbcAnswered: visitsWithWbcsFulfilled.day0_isTaskCompleted,
                isEcgAnswered: day0_isEcgAdded,
                isPhotoAnswered: day0_isPhotoAdded,
                isQuestionnaireAnswered: day0_isQuestionnaireAnswered,
                totalTasks: SmilerWorkflow.VISIT_TASKS_NUMBER,
                tasksCompleted: day0_completedTasks,
                visitDate: this.studyStartDate
            },
            [SMILER_VISIT.Day_14]: {
                isEcgAnswered: day14_isEcgAdded,
                isWbcAnswered: day14_isWbcAdded,
                isPhotoAnswered: day14_isPhotoAdded,
                isQuestionnaireAnswered: day14_isQuestionnaireAnswered,
                totalTasks: SmilerWorkflow.VISIT_TASKS_NUMBER,
                tasksCompleted: day14_completedTasks,
                visitDate: dateFns.addDays(this.studyStartDate, SMILER_VISIT.Day_14)
            },
            [SMILER_VISIT.Day_28]: {
                isWbcAnswered: day28_isWbcAdded,
                isEcgAnswered: day28_isEcgAdded,
                isPhotoAnswered: day28_isPhotoAdded,
                isQuestionnaireAnswered: day28_isQuestionnaireAnswered,
                totalTasks: SmilerWorkflow.VISIT_TASKS_NUMBER,
                tasksCompleted: day28_completedTasks,
                visitDate: dateFns.addDays(this.studyStartDate, SMILER_VISIT.Day_28)
            }
        };
    }

    calculateActiveVisit(): SmilerStudyVisit | undefined {
        if (
            this.studyHelper.isVisitActive<SmilerStudyVisit>(
                this.currentStudyDay,
                SMILER_VISIT.Day_0,
                0,
                this.DAYS_OFFSET_ACCEPTANCE,
                STUDY_DURATION - 1
            )
        ) {
            return SMILER_VISIT.Day_0;
        }

        if (
            this.studyHelper.isVisitActive<SmilerStudyVisit>(
                this.currentStudyDay,
                SMILER_VISIT.Day_14,
                this.DAYS_OFFSET_ACCEPTANCE,
                this.DAYS_OFFSET_ACCEPTANCE,
                STUDY_DURATION - 1
            )
        ) {
            return SMILER_VISIT.Day_14;
        }

        if (
            this.studyHelper.isVisitActive<SmilerStudyVisit>(
                this.currentStudyDay,
                SMILER_VISIT.Day_28,
                this.DAYS_OFFSET_ACCEPTANCE,
                0,
                STUDY_DURATION - 1
            )
        ) {
            return SMILER_VISIT.Day_28;
        }

        return undefined;
    }

    /**
     * Is is used to update the tasks categories when a new task is
     * done or if the current day has passed.
     */
    updateTasksCategories(): void {
        this.tasks = this.studyHelper.classifyTasks<SmilerStudyVisit, SmilerVisitTask>(
            this.activeVisit,
            this.tasks,
            [SMILER_TASK.Questionnaire, SMILER_TASK.Wbc, SMILER_TASK.Ecg, SMILER_TASK.Photo],
            this.finishedTasks,
            this.activeVisit === undefined
                ? undefined
                : this.visitProperties[this.activeVisit].visitDate,
            this.DAYS_OFFSET_ACCEPTANCE
        );
    }

    calculateFinishedTasks(): void {
        if (this.activeVisit === undefined) {
            return;
        }

        const visitData = this.visitProperties[this.activeVisit];
        const doneTasks: SmilerVisitTask[] = [];

        if (visitData.isEcgAnswered) {
            doneTasks.push(SMILER_TASK.Ecg);
        }

        if (visitData.isWbcAnswered) {
            doneTasks.push(SMILER_TASK.Wbc);
        }

        if (visitData.isQuestionnaireAnswered) {
            doneTasks.push(SMILER_TASK.Questionnaire);
        }

        if (visitData.isPhotoAnswered) {
            doneTasks.push(SMILER_TASK.Photo);
        }

        this.finishedTasks = doneTasks;
    }

    updateTimeDependentProperties(): void {
        const {currentStudyDay, daysUntilNextVisit} = this.studyHelper.updateTimeProperties(
            this.studyStartDate,
            STUDY_DURATION,
            this.VISIT_INTERVAL_DAYS
        );
        this.currentStudyDay = currentStudyDay;
        this.daysUntilNextVisit = daysUntilNextVisit;
        this.activeVisit = this.calculateActiveVisit();
        this.calculateFinishedTasks();
        this.updateTasksCategories();
    }

    // ################################ private functions ####################

    /**
     * Trigger the report when the user it's in a Visit and he completed all the
     * tasks within the Visit. (e.g: Questionnaire added, Photo added, WBC added, ECG added).
     *
     * The report action object it's stored only if the user it's in a Visit and all the
     * tasks for the current Visit are completed and there is no Report Action object
     * stored within the corresponding time interval for the current Visit.
     *
     * Issue: What happens if the participant completes the wbc and the ecg
     * and open the smiler only after few days when the Visit is not anymore active?
     * (The report will not be triggered because the user it's not in an active Visit.)
     */
    private async triggerReport(): Promise<void> {
        if (this.activeVisit === undefined) {
            return;
        }

        const ownerIdHash = getInstanceOwnerIdHash();

        if (ownerIdHash === undefined) {
            throw new Error('Owner id hash is undefined!');
        }

        // get start date of the current active Visit
        const fromDate = this.studyHelper.calculateStartDateOfVisit<SmilerStudyVisit>(
            this.activeVisit,
            this.DAYS_OFFSET_ACCEPTANCE,
            this.DAYS_OFFSET_ACCEPTANCE,
            this.currentStudyDay,
            STUDY_DURATION - 1
        );

        /** should never happen **/
        if (fromDate === undefined) {
            throw new Error('Calculation of the start date of the current active visit failed.');
        }

        // get the report actions objects for the current active visit
        // will be used to know if a report was triggered for the current Visit -> avoid triggering multiple reports for a Visit
        const reportActions = await this.channelManager.getObjectsWithType('ReportAction', {
            channelId: ReportModel.actionChannelId,
            from: fromDate,
            to: new Date()
        });

        // if all the tasks are done in the current active visit
        // and there is no precedent report action stored then post to the report channel a report action
        if (
            this.visitProperties[this.activeVisit].tasksCompleted ===
                this.visitProperties[this.activeVisit].totalTasks &&
            reportActions.length === 0
        ) {
            const wbcChannelInfoHash = await this.channelManager.getLatestMergedChannelInfoHash({
                id: 'wbc',
                owner: ownerIdHash
            });
            const ecgChannelInfoHash = await this.channelManager.getLatestMergedChannelInfoHash({
                id: 'electrocardiogram',
                owner: ownerIdHash
            });

            await this.channelManager.postToChannel(ReportModel.actionChannelId, {
                $type$: 'ReportAction',
                ownerIdHash: ownerIdHash,
                wbcChannelInfoHash: wbcChannelInfoHash,
                ecgChannelInfoHash: ecgChannelInfoHash
            });
        }
    }

    /**
     * Check for each visit if a task was completed.
     * @param {ObjectData<QuestionnaireResponses | DocumentInfo_1_1_0 | Electrocardiogram | WbcObservation>[]} objectsArray -
     * the objects array with the tasks that will be checked if they were completed within a visit.
     */
    private checkVisitsTasksCompletion(
        objectsArray: ObjectData<
            QuestionnaireResponses | DocumentInfo | Electrocardiogram | WbcObservation
        >[]
    ): {
        day0_isTaskCompleted: boolean;
        day14_isTaskCompleted: boolean;
        day28_isTaskCompleted: boolean;
    } {
        let day0_isTaskCompleted = false;
        let day14_isTaskCompleted = false;
        let day28_isTaskCompleted = false;

        for (const object of objectsArray) {
            if (
                this.studyHelper.isTaskCompletedWithinVisit<SmilerStudyVisit>(
                    SMILER_VISIT.Day_0,
                    object,
                    0,
                    this.DAYS_OFFSET_ACCEPTANCE,
                    this.studyStartDate,
                    STUDY_DURATION - 1
                )
            ) {
                day0_isTaskCompleted = true;
            }

            if (
                this.studyHelper.isTaskCompletedWithinVisit<SmilerStudyVisit>(
                    SMILER_VISIT.Day_14,
                    object,
                    this.DAYS_OFFSET_ACCEPTANCE,
                    this.DAYS_OFFSET_ACCEPTANCE,
                    this.studyStartDate,
                    STUDY_DURATION - 1
                )
            ) {
                day14_isTaskCompleted = true;
            }

            if (
                this.studyHelper.isTaskCompletedWithinVisit<SmilerStudyVisit>(
                    SMILER_VISIT.Day_28,
                    object,
                    this.DAYS_OFFSET_ACCEPTANCE,
                    0,
                    this.studyStartDate,
                    STUDY_DURATION - 1
                )
            ) {
                day28_isTaskCompleted = true;
            }
        }

        return {
            day0_isTaskCompleted: day0_isTaskCompleted,
            day14_isTaskCompleted: day14_isTaskCompleted,
            day28_isTaskCompleted: day28_isTaskCompleted
        };
    }
}
