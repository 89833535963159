import i18n from '../../../i18n';
import './StudyOverview.css';
import type {SmilerVisitTask} from '../../../model/studies/SmilerWorkflow';
import type {ImpactVisitTask} from '../../../model/studies/ImpactWorkflow';

/**
 * Represents all the needed data for the audio player.
 */
export type AudioProperties = {
    fileName: string;
    audio: HTMLAudioElement | undefined;
    audioFile: string;
    title: string;
};

/**
 * Used to build the history task lists. All done tasks within a visit are added to a string and returned.
 * @param completedTasks - an array with the done tasks.
 */
export function buildTaskDoneHistory(
    completedTasks: (SmilerVisitTask | ImpactVisitTask)[]
): string {
    let doneTasks = '';

    for (let answerCount = 0; answerCount < completedTasks.length; answerCount++) {
        doneTasks += i18n.t(`studies:stepAction.${completedTasks[answerCount]}`);

        // it means that we still have to add tasks to the done tasks history then we need the comma between them
        if (answerCount + 1 < completedTasks.length) {
            doneTasks += ', ';
        }
    }

    return doneTasks;
}
