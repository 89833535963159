import {createContext, useContext} from 'react';
import type {ReactElement} from 'react';
import './MarkdownPopup.css';
import {Dialog, DialogContent, IconButton} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import MarkdownPaperView from './MarkdownPaperView';

export type MarkdownFilePath = {
    setMarkdownFilePath: (filePath: string) => void;
};

export const MarkdownContext = createContext<MarkdownFilePath>({
    setMarkdownFilePath: () => undefined
});

/**
 * Forward the markdown file path context.
 */
export function useMarkdownContext(): MarkdownFilePath {
    return useContext(MarkdownContext);
}

/**
 * The markdown dialog component.
 * @param props
 * @param props.filePath - the filePath of the markdown file that will be displayed.
 * @param props.setFilePath - a callback to reset the state that triggers the render of this component
 * => helps to prevent the bug when you can not open anymore the closed md file.
 * @returns
 */
export default function MarkdownPopup(props: {
    filePath: string;
    setFilePath: (value: string) => void;
}): ReactElement {
    /**
     * Used to close the dialog when "x" button is pressed/clicking outside of the popup.
     */
    function closeDialog(): void {
        props.setFilePath('');
    }

    return (
        <Dialog open={props.filePath !== ''} onClose={closeDialog} className="md-dialog">
            <div className="md-dialog-close">
                <IconButton className="md-dialog-close-button" onClick={closeDialog}>
                    <CloseIcon />
                </IconButton>
            </div>
            <DialogContent>
                <MarkdownPaperView filePath={props.filePath} />
            </DialogContent>
        </Dialog>
    );
}
