import {useEffect, useState} from 'react';
import type {ReactElement} from 'react';
import type {Electrocardiogram} from 'one.models/lib/recipes/ECGRecipes';
import type {SHA256Hash} from 'one.core/lib/util/type-checks';
import {CircularProgress, Table, TableBody, TableRow} from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell/TableCell';
import i18n from '../../i18n';
import * as dateFns from 'date-fns';
import './EcgDataTable.css';
import {EcgLineGraph} from './EcgLineGraph';
import type ECGModel from 'one.models/lib/models/ECGModel';

/**
 * The ecg view displayed from the journal when an ecg it's clicked.
 * @param props
 * @param props.electrocardiogramHash
 * @param props.ecgModel
 */
export default function EcgView(props: {
    electrocardiogramHash: SHA256Hash<Electrocardiogram>;
    ecgModel: ECGModel;
}): ReactElement {
    const [electrocardiogram, setElectrocardiogram] = useState<Electrocardiogram>();

    /**
     * Retrieve the ECG By hash
     */
    useEffect(() => {
        props.ecgModel
            .retrieveElectrocardiogramByHash(props.electrocardiogramHash)
            .then(ecg => {
                setElectrocardiogram(ecg);
            })
            .catch(_ => console.error('Error: could not load ECG.'));
    }, [props.ecgModel, props.electrocardiogramHash]);

    return (
        <div>
            {electrocardiogram === undefined ? (
                <CircularProgress className="circular-progress" size={35} />
            ) : (
                <div>
                    <Table size="small">
                        <TableBody className="ecg-table">
                            <TableRow>
                                <TableCell align="left" padding={'none'}>
                                    {i18n.t('ecg:acquisitionTime')}
                                </TableCell>
                                <TableCell align="right" size={'small'} padding={'none'}>
                                    {electrocardiogram.startTimestamp
                                        ? dateFns.format(
                                              electrocardiogram.startTimestamp,
                                              'dd.MM.yyyy HH:mm'
                                          )
                                        : electrocardiogram.startTimestamp}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="left" padding={'none'}>
                                    {i18n.t('ecg:heartFrequency')}
                                </TableCell>
                                <TableCell align="right" size={'small'} padding={'none'}>
                                    {electrocardiogram.averageHeartRateBPM}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    <EcgLineGraph readings={electrocardiogram.readings || []} />
                </div>
            )}
        </div>
    );
}
