import {useCallback, useEffect, useRef, useState} from 'react';
import type {MutableRefObject, ReactElement} from 'react';
import type OneInstanceModel from '../../model/OneInstanceModel';
import {LOGOUT_MODE} from '../../model/OneInstanceModel';
import {Button, Dialog} from '@material-ui/core';
import i18n from '../../i18n';
import {Alert, AlertTitle} from '@material-ui/lab';
import './AutoLogout.css';

const USER_INTERACTION_EVENTS = {
    load: 'load',
    mousemove: 'mousemove',
    mousedown: 'mousedown',
    click: 'click',
    scroll: 'scroll',
    keypress: 'keypress'
} as const;

function clearTimeout(timeoutHandler: MutableRefObject<number | null>): void {
    if (timeoutHandler.current !== null) {
        window.clearTimeout(timeoutHandler.current);
        timeoutHandler.current = null;
    }
}

/**
 * @param props
 * @param props.oneInstanceModel
 * @returns
 */
export default function AutoLogout(props: {oneInstanceModel: OneInstanceModel}): ReactElement {
    const logoutTimeout = 900000; // ms which is 15 minutes
    const warningTime = 840000;

    const logoutTimeoutHandler = useRef<number | null>(null);
    const warningTimeoutHandler = useRef<number | null>(null);
    const [warningDialog, setWarningDialog] = useState(false);

    const logout = useCallback(async () => {
        for (const key of Object.keys(USER_INTERACTION_EVENTS)) {
            window.removeEventListener(key, clearLogoutTimeout);
        }
        await props.oneInstanceModel.logout(LOGOUT_MODE.KeepData);
    }, [props.oneInstanceModel]);

    const setLogoutTimeout = useCallback(() => {
        if (logoutTimeoutHandler.current === null) {
            logoutTimeoutHandler.current = window.setTimeout(logout, logoutTimeout);
        }

        if (warningTimeoutHandler.current === null) {
            warningTimeoutHandler.current = window.setTimeout(warn, warningTime);
        }
    }, [logout]);

    function clearLogoutTimeout(): void {
        clearTimeout(logoutTimeoutHandler);
        clearTimeout(warningTimeoutHandler);
    }

    useEffect(() => {
        /**
         *  add listeners for the events that detect user activity
         */
        for (const key of Object.keys(USER_INTERACTION_EVENTS)) {
            window.addEventListener(key, clearLogoutTimeout);
            window.addEventListener(key, setLogoutTimeout);
        }

        logoutTimeoutHandler.current = window.setTimeout(logout, logoutTimeout);
        warningTimeoutHandler.current = window.setTimeout(warn, warningTime);

        // clear the listeners when the component is unmounted
        return () => {
            for (const key of Object.keys(USER_INTERACTION_EVENTS)) {
                window.removeEventListener(key, clearLogoutTimeout);
            }
        };
    }, [logout, setLogoutTimeout]);

    function warn(): void {
        setWarningDialog(true);
    }

    return warningDialog ? (
        <Dialog
            onClose={() => {
                clearLogoutTimeout();
                setWarningDialog(false);
            }}
            aria-labelledby="simple-dialog-title"
            open={warningDialog}
        >
            <Alert severity="info">
                <AlertTitle>
                    <b>{i18n.t('common:infos.sessionExpired')}</b>
                </AlertTitle>
            </Alert>
            <div className="margin_text">{i18n.t('common:infos.sessionExpiredContent')}</div>
            <Button
                className="button_style"
                variant="contained"
                color="primary"
                size="small"
                onClick={() => {
                    clearLogoutTimeout();
                    setWarningDialog(false);
                }}
            >
                {i18n.t('common:settings.cancel')}
            </Button>
        </Dialog>
    ) : (
        <div />
    );
}
