import type {ReactElement} from 'react';
import {Avatar} from '@material-ui/core';
import {
    AccessPoint,
    AccountBox,
    ArrowDown,
    ArrowUp,
    Book,
    Calendar,
    CalendarMonth,
    Certificate,
    ClipboardTextOutline,
    Delete,
    DotsVertical,
    Export,
    Fingerprint,
    Home,
    Import,
    InformationOutline,
    InformationVariant,
    MedicalBag,
    Menu,
    NewspaperVariantOutline,
    PlusBox,
    Security,
    Thermometer,
    Cloud,
    Devices,
    Logout,
    Information,
    ExitToApp,
    BackupRestore,
    Upload,
    Wrench,
    AccountGroup,
    AccountPlus,
    Help,
    AccountArrowLeft,
    AccountArrowRight,
    FileMultiple,
    Close,
    ArrowLeft,
    LockReset,
    WrenchOutline,
    PlayBoxOutline,
    ArrowRightBoldOutline,
    ArrowLeftBoldOutline,
    Account,
    AccountEdit,
    Email,
    Phone,
    MapMarker,
    Plus,
    ShareVariant,
    Circle,
    Image,
    CheckboxMarkedOutline,
    Tag,
    CellphoneLink,
    AlertCircleOutline,
    Note,
    InvertColors
} from 'mdi-material-ui';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import RefreshIcon from '@material-ui/icons/Refresh';
import AdjustIcon from '@material-ui/icons/Adjust';
import './Icon.css';
import '../../Primary.css';
import {Feedback} from '@material-ui/icons';
import TimelineIcon from '@material-ui/icons/Timeline';
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon';
import AudiotrackIcon from '@material-ui/icons/Audiotrack';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssessmentIcon from '@material-ui/icons/Assessment';
import HowToVoteIcon from '@material-ui/icons/HowToVote';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ImageIcon from '@material-ui/icons/Image';

//  TODO for now leave any
export const icons: any = {
    AccessRights: AccessPoint,
    AccountGroup: AccountGroup,
    AccountPlus: AccountPlus,
    AddDiaryEntry: Book,
    AddNews: PlusBox,
    AddWBCMeasurement: MedicalBag,
    Alert: AlertCircleOutline,
    AnswerQuestionnaire: ClipboardTextOutline,
    ArrowDown: ArrowDown,
    ArrowUp: ArrowUp,
    ArrowLeft: ArrowLeft,
    Backup: BackupRestore,
    Cloud: Cloud,
    Circle: Circle,
    Devices: Devices,
    Feedback: Feedback,
    Info: InformationOutline,
    Erase: Delete,
    PatientInformation: Information,
    ExitStudy: ExitToApp,
    Thermometer: Thermometer,
    Diary: Calendar,
    Dots: DotsVertical,
    EventsCalendar: CalendarMonth,
    EventsList: Fingerprint,
    Export: Export,
    FileCopy: FileCopyIcon,
    Help: Help,
    Home: Home,
    Import: Import,
    ImportDocument: Upload,
    Impressum: InformationVariant,
    Journal: Fingerprint,
    Logout: Logout,
    Menu: Menu,
    News: NewspaperVariantOutline,
    PrivacyPolicy: Security,
    Settings: Wrench,
    AppSettings: WrenchOutline,
    Information: Book,
    TermsAndConditions: Book,
    User: AccountBox,
    ViewLicense: Certificate,
    AccountArrowLeft: AccountArrowLeft,
    AccountArrowRight: AccountArrowRight,
    FileMultiple: FileMultiple,
    Close: Close,
    LockReset: LockReset,
    Slider: PlayBoxOutline,
    SliderLeft: ArrowLeftBoldOutline,
    SliderRight: ArrowRightBoldOutline,
    Image: Image,
    AccountEdit: AccountEdit,
    AccountName: Account,
    AccountEmail: Email,
    AccountPhone: Phone,
    AccountAddress: MapMarker,
    Plus: Plus,
    Share: ShareVariant,
    CheckboxMarkedOutline: CheckboxMarkedOutline,
    Tag: Tag,
    ChannelIcon: AdjustIcon,
    Refresh: RefreshIcon,
    Cellphone: CellphoneLink,
    Graph: TimelineIcon,
    Smiler: InsertEmoticonIcon,
    Impact: AudiotrackIcon,
    Studies: AssignmentIcon,
    BloodGlucoseTable: AssessmentIcon,
    DataCollection: HowToVoteIcon,
    EKG: FavoriteIcon,
    Photo: ImageIcon,
    Questionnaire: Note,
    WBC: InvertColors
};

/**
 * @param props -> used as the icon name to be passes ({name: string} type)
 * @param props.name
 * @returns
 */
export function Icon(props: {name: string}): ReactElement {
    const IconType = icons[props.name];

    return (
        <Avatar className={`avatar-wrapper ${props.name}`}>
            <IconType className={'icon-type'} />
        </Avatar>
    );
}
