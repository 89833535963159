import * as dateFns from 'date-fns';

/**
 * Calculates the time until tomorrow in milliseconds.
 */
export function calculateTimeUntilTomorrow(): number {
    const currentDate = Date.now();
    return (
        (24 - dateFns.getHours(currentDate)) * 3600000 -
        dateFns.getMinutes(currentDate) * 60000 -
        dateFns.getSeconds(currentDate) * 1000
    );
}
