import type {ReactElement} from 'react';
import {useParams} from 'react-router-dom';
import '../../Primary.css';
import './ChannelsView.css';
import type {ChannelManager} from 'one.models/lib/models';
import {CircularProgress, Divider, Typography} from '@material-ui/core';
import InfoMessage, {MESSAGE_TYPE} from '../errors/InfoMessage';
import MenuButton from '../menu/MenuButton';
import i18n from '../../i18n';
import Paper from '@material-ui/core/Paper';
import type {ChannelEntry, ChannelInfo} from 'one.models/lib/recipes/ChannelRecipes';
import type {SHA256Hash} from 'one.core/lib/util/type-checks';
import type {OneUnversionedObjectTypes} from 'one.core/lib/recipes';
import {getObject} from 'one.core/lib/storage';
import {calculateHashOfObj} from 'one.core/lib/util/object';
import {
    Alert,
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineOppositeContent,
    TimelineSeparator
} from '@material-ui/lab';
import type {ObjectData} from 'one.models/lib/models/ChannelManager';
import ReactJson from 'react-json-view';
import {hideCircularProgress} from '../utils/Utils';
import {useEffect, useState} from 'react';

type MinimalChannelEntryInformation = {
    date: Date;
    hash: SHA256Hash<ChannelEntry>;
};

/**
 *
 * @param props
 * @param props.channelManager
 * @returns
 */
export default function ChannelDetailedView(props: {channelManager: ChannelManager}): ReactElement {
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [channelEntries, setChannelEntries] = useState<MinimalChannelEntryInformation[]>([]);
    const [data, setData] = useState<ObjectData<OneUnversionedObjectTypes>[]>([]);
    const {channelHash} = useParams<{channelHash: SHA256Hash<ChannelInfo>}>();

    useEffect(() => {
        function fetch(): void {
            props.channelManager
                .getObjects({channelInfoHash: channelHash})
                .then(async (channelData: ObjectData<OneUnversionedObjectTypes>[]) => {
                    const channel: ChannelInfo = await getObject(channelHash);
                    await handleChannelEntries(channel);
                    setData(channelData.reverse());
                    hideCircularProgress();
                })
                .catch((ignored: Error) => {
                    setErrorMessage('errors:channels.fetchSpecificChannelData');
                    hideCircularProgress();
                });
        }
        fetch();
    }, [channelHash, props.channelManager]);

    async function handleChannelEntries(channelInfo: ChannelInfo): Promise<void> {
        if (channelInfo.head) {
            let head = channelInfo.head;
            const channelEntriesList = [];

            while (head) {
                // eslint-disable-next-line no-await-in-loop
                const explodedHead = await getObject(head);
                channelEntriesList.push(explodedHead);
                head = explodedHead.previous as SHA256Hash<ChannelEntry>;
            }

            const minimalChannelEntries: MinimalChannelEntryInformation[] = await Promise.all(
                channelEntriesList.map(async item => {
                    const creationTime = await getObject(item.data);
                    return {
                        date: new Date(creationTime.timestamp),
                        hash: await calculateHashOfObj(item)
                    };
                })
            );
            setChannelEntries(minimalChannelEntries);
        }
    }

    function findChannelEntryHashByDate(date: Date): string {
        const minimalChannelEntry = channelEntries.find(item => {
            return item.date.toString() === date.toString();
        });

        if (minimalChannelEntry) {
            return `Channel Entry Hash : ${minimalChannelEntry.hash}`;
        }

        return i18n.t('common:channels.channelEntryHashNotFound');
    }

    return (
        <>
            <div className="circular-progress-container">
                <CircularProgress className="circularProgress" size={35} />
            </div>

            <div className="page-container hide">
                <div className="menu-button-header">
                    <MenuButton />
                    <h2 className="headline"> {i18n.t('common:settings.channels')}</h2>
                </div>
                <InfoMessage
                    errorMessage={errorMessage}
                    setDisplayMessage={setErrorMessage}
                    messageType={MESSAGE_TYPE.Error}
                />

                <div className="channels-page-content">
                    {data.length === 0 ? (
                        <Alert severity="info" key="alert">
                            {i18n.t('common:channels.emptyChannel')}
                        </Alert>
                    ) : (
                        <Timeline align="right">
                            {data.map(
                                (value: ObjectData<OneUnversionedObjectTypes>, index: number) => {
                                    return (
                                        <TimelineItem key={index}>
                                            <TimelineOppositeContent>
                                                <Typography
                                                    variant="subtitle2"
                                                    color="textSecondary"
                                                >
                                                    {findChannelEntryHashByDate(value.creationTime)}
                                                </Typography>
                                                <Divider />
                                                <Typography
                                                    variant="subtitle2"
                                                    color="textSecondary"
                                                >
                                                    {`Data Hash : ${value.id}`}
                                                </Typography>
                                                <Divider />
                                                <Typography variant="caption" color="textSecondary">
                                                    {value.creationTime.toString()}
                                                </Typography>
                                            </TimelineOppositeContent>
                                            <TimelineSeparator>
                                                <TimelineDot />
                                                <TimelineConnector />
                                            </TimelineSeparator>
                                            <TimelineContent className="paper-padding">
                                                <Paper className="paper-padding" elevation={3}>
                                                    <Typography
                                                        className="paper-padding"
                                                        variant="h6"
                                                        component="h1"
                                                    >
                                                        {value.data.$type$}
                                                    </Typography>
                                                    <ReactJson
                                                        src={value.data}
                                                        theme="bright:inverted"
                                                        displayDataTypes={false}
                                                        displayObjectSize={false}
                                                    />
                                                </Paper>
                                            </TimelineContent>
                                        </TimelineItem>
                                    );
                                }
                            )}
                        </Timeline>
                    )}
                </div>
            </div>
        </>
    );
}
