import type {NavigationDrawerItemsType} from '../menu/NavigationDrawer';
import i18n from '../../i18n';
import {Icon} from '../icon/Icon';
import type {EventTypes} from '../journal/Journal';
import {EventType} from 'one.models/lib/models/JournalModel';
import {InvertColors, Note} from 'mdi-material-ui';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ImageIcon from '@material-ui/icons/Image';
import AudiotrackIcon from '@material-ui/icons/Audiotrack';

/**
 *
 * @returns
 */
export function settingsPoliciesMenuItems(): NavigationDrawerItemsType[] {
    return [
        {name: '----'},
        {
            name: i18n.t('common:settings.viewLicense'),
            icon: <Icon name="ViewLicense" />,
            functionality: {markdownFilePath: i18n.t('markDown:license')}
        },
        {
            name: i18n.t('common:settings.impressum'),
            icon: <Icon name="Impressum" />,
            functionality: {markdownFilePath: i18n.t('markDown:impressum')}
        },
        {
            name: i18n.t('common:settings.termsConditions'),
            icon: <Icon name="TermsAndConditions" />,
            functionality: {markdownFilePath: i18n.t('markDown:termsOfUse')}
        },
        {
            name: i18n.t('common:settings.privacyPolicy'),
            icon: <Icon name="PrivacyPolicy" />,
            functionality: {markdownFilePath: i18n.t('markDown:privacyPolicyPWA')}
        },
        {
            name: i18n.t('common:settings.version'),
            icon: <Icon name="Tag" />,
            functionality: {markdownFilePath: i18n.t('markDown:releaseNotes')}
        }
    ];
}

/**
 * This function returns the items that will be displayed in the left menu, for the Smiler Impact product.
 * @returns - items for the left menu
 */
export function smilerImpactNavigationDrawerItems(): NavigationDrawerItemsType[] {
    return [
        {name: '----'},
        {
            name: i18n.t('common:menu.overview'),
            functionality: {path: '/'},
            icon: <Icon name="Home" />
        },
        {
            name: i18n.t('common:menu.study'),
            subItems: smilerImpactStudies(),
            icon: <Icon name="Studies" />
        },
        {
            name: i18n.t('common:menu.journal'),
            functionality: {path: '/journal'},
            icon: <Icon name="Journal" />
        },
        {
            name: i18n.t('common:menu.wbcGraph'),
            functionality: {path: '/wbc-graph'},
            icon: <Icon name="Graph" />
        },
        {
            name: i18n.t('common:menu.dataCollection'),
            icon: <Icon name="DataCollection" />,
            functionality: {path: '/tasks'}
        },
        {name: '----l'},
        {
            name: i18n.t('common:settings.connections'),
            functionality: {path: '/connections'},
            icon: <Icon name="Devices" />
        },
        {
            name: i18n.t('common:settings.information'),
            icon: <Icon name="Information" />,
            functionality: {path: '/information'},
            subItems: settingsPoliciesMenuItems()
        },
        {
            name: i18n.t('common:menu.settings'),
            icon: <Icon name="Settings" />,
            subItems: smilerImpactSettingsMenuItems()
        },
        {
            name: i18n.t('common:menu.logout'),
            functionality: {path: '/logout?keep=true'},
            icon: <Icon name="Logout" />
        }
    ];
}

/**
 *  This function returns the list of the menu items provided to the settings page, for the Smiler Impact product.
 *  @returns - items for the settings page
 */
export function smilerImpactSettingsMenuItems(): NavigationDrawerItemsType[] {
    return [
        {name: '----'},
        {
            name: i18n.t('common:settings.backup'),
            functionality: {path: '/settings/backup'},
            icon: <Icon name="Backup" />
        },
        {
            name: i18n.t('common:settings.erase'),
            functionality: {path: '/settings/erase'},
            icon: <Icon name="Erase" />
        }
    ];
}

/**
 *  This function returns the list of event types, for the Smiler Impact product.
 *  @returns - list of event types
 */
export function smilerImpactEventTypes(): EventTypes[] {
    return [
        {
            type: EventType.QuestionnaireResponse,
            name: i18n.t('common:eventTypes.QuestionnaireResponses'),
            icon: <Note className="note" />
        },
        {
            type: EventType.WbcDiffMeasurement,
            name: i18n.t('common:eventTypes.WbcObservation'),
            icon: <InvertColors className="invert-colors" />
        },
        {
            type: EventType.DocumentInfo,
            name: i18n.t('common:documentFilter.images'),
            icon: <ImageIcon className="file-document" />
        },
        {
            type: EventType.ECGEvent,
            name: i18n.t('common:eventTypes.Electrocardiogram'),
            icon: <FavoriteIcon className="black-color" />
        },
        {
            type: EventType.AudioExercise,
            name: i18n.t('common:eventTypes.AudioExercise'),
            icon: <AudiotrackIcon className="green-color" />
        }
    ];
}

/**
 *
 */
export function smilerImpactStudies(): NavigationDrawerItemsType[] {
    return [
        {
            name: i18n.t('common:menu.smiler'),
            functionality: {path: '/smiler'},
            icon: <Icon name="Smiler" />
        },
        {
            name: i18n.t('common:menu.impact'),
            functionality: {path: '/impact'},
            icon: <Icon name="Impact" />
        }
    ];
}
