import type {ReactElement} from 'react';
import {useHistory} from 'react-router-dom';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Avatar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Divider,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Paper,
    TextField
} from '@material-ui/core';
import {Alert} from '@material-ui/lab';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import './CloudConnections.css';
import '../../Primary.css';
import i18n from '../../i18n';
import MenuButton from '../menu/MenuButton';
import type {ConnectionInfo} from 'one.models/lib/misc/CommunicationModule';
import type AccessModel from 'one.models/lib/models/AccessModel';
import type InstancesModel from 'one.models/lib/models/InstancesModel';
import type ConnectionsModel from 'one.models/lib/models/ConnectionsModel';
import type OneInstanceModel from '../../model/OneInstanceModel';
import type {Person} from 'one.core/lib/recipes';
import type {SHA256IdHash} from 'one.core/lib/util/type-checks';
import {
    downloadPairingInformationFile,
    useOtherConnections,
    usePersonalConnections
} from '../modelHelper/ConnectionsHelper';
import InfoMessage, {MESSAGE_TYPE} from '../errors/InfoMessage';
import {SmilerAccessGroups} from '../../model/SmilerAccessRightsManager';
import {useCurrentAnonInstanceId} from '../modelHelper/InstancesHelper';
import {displayCircularProgress} from '../utils/Utils';
import {useState} from 'react';

/**
 * used in ConnectionsHelper to add to panels type
 */
export const CONNECTIONS_TYPE = {
    PersonalCloud: 0,
    ClinicServer: 1,
    RaspberryPi: 2
} as const;

/** The type definition based on the CONNECTIONS_TYPE value. **/
export type Connection = typeof CONNECTIONS_TYPE[keyof typeof CONNECTIONS_TYPE];

/**
 * Builds the Connections page
 * @param props
 * @param props.connectionsModel
 * @param props.accessModel
 * @param props.instancesModel
 * @param props.oneInstanceModel
 * @param props.successfulConnection
 * @param props.setSuccessfulConnection
 * @param props.isDevelopmentMode - If true the raspberry pi connection is
 * displayed, the add invitation link is displayed and also the button for adding a new device is displayed.
 * (for the moment this is displayed only in development mode).
 * @param props.setNavigationDrawer
 * @returns
 */
export default function Connections(props: {
    connectionsModel: ConnectionsModel;
    accessModel: AccessModel;
    instancesModel: InstancesModel;
    oneInstanceModel: OneInstanceModel;
    successfulConnection: boolean;
    setSuccessfulConnection: (value: boolean) => void;
    isDevelopmentMode?: boolean;
    setNavigationDrawer: (value: boolean) => void;
}): ReactElement {
    const history = useHistory();
    const connections = props.connectionsModel.connectionsInfo();
    const [appBrokeOnInvite, setAppBrokeOnInvite] = useState(
        !!localStorage.getItem('appBrokeOnInvite')
    );
    const [openRemoveDialog, setOpenRemoveDialog] = useState(false);
    const [errorState, setErrorState] = useState<string>('');

    /** used to open the panels that contain connections */
    const [panels, setPanels] = useState<Connection[]>([CONNECTIONS_TYPE.RaspberryPi]);

    /** personal cloud connections */
    const personalConnectionsMap = usePersonalConnections(
        props.connectionsModel,
        panels,
        setPanels
    );

    /** clinic connections */
    const clinicConnectionsMap = useOtherConnections(
        props.connectionsModel,
        props.accessModel,
        SmilerAccessGroups.clinic,
        setErrorState,
        panels,
        setPanels
    );

    /** local anonymous instance id hash */
    const currentInstanceIdHash = useCurrentAnonInstanceId(props.instancesModel, setErrorState);

    const [invitationLink, setInvitationLink] = useState('');
    const [showEraseDialogForIoM, setShowEraseDialogForIoM] = useState<boolean>(false);

    /**
     * Handler for download the pairing information file
     */
    function handleDownloadPairingInformationFile(): void {
        downloadPairingInformationFile(props.connectionsModel, true).catch(err => {
            console.error(err);
            setErrorState(err);
        });
    }

    /**
     * When a new connection is added to the list open the corresponded panel.
     *
     * @param {Connection} connectionsType
     */
    function handleAccordionChange(connectionsType: Connection): void {
        if (panels.includes(connectionsType)) {
            const indexOfPanel = panels.indexOf(connectionsType);
            panels.splice(indexOfPanel, 1);
        } else {
            panels.push(connectionsType);
        }
        setPanels([...panels]);
    }

    /**
     * Handler for the case when the user wants to continue with the current instance and not with the invitation
     */
    function handleContinueWithCurrentInstance(): void {
        setShowEraseDialogForIoM(false);
        setInvitationLink('');
    }

    /**
     *  Handler for the case when the user wants to delete the current instance and to continue with the invitation
     */
    async function handleDeleteCurrentInstance(): Promise<void> {
        props.setNavigationDrawer(false);
        displayCircularProgress();
        localStorage.setItem('connectionViaLink', 'true');
        history.replace(invitationLink.replace(window.location.origin, ''));
        await props.oneInstanceModel.logout(0);
        localStorage.setItem('connectionViaLink', '');
    }

    /**
     * Display the connection with the corresponded status.
     *
     * @param {ConnectionInfo[] | undefined} connectionInfo
     * @returns {ReactElement}
     */
    function buildConnectionsList(connectionInfo: ConnectionInfo[] | undefined): ReactElement {
        const devicesList: JSX.Element[] = [];

        if (connectionInfo === undefined) {
            return <>{devicesList}</>;
        }
        connectionInfo.forEach(connection => {
            const {targetInstanceId, isConnected} = connection;
            devicesList.push(
                <ListItem button key={targetInstanceId}>
                    <ListItemAvatar
                        className={
                            isConnected
                                ? 'no-background-avatar open-connection'
                                : 'no-background-avatar close-connection'
                        }
                    >
                        <Avatar />
                    </ListItemAvatar>
                    <ListItemText className="my-info-summary">
                        <div>{connection.targetInstanceId}</div>
                    </ListItemText>
                </ListItem>
            );
        });

        return <>{devicesList}</>;
    }

    /**
     * Renders the dialog for deleting an existing connection.
     *
     * @returns {ReactElement}
     */
    function removeDialog(): ReactElement {
        return (
            <Dialog
                open={openRemoveDialog}
                onClose={() => {
                    setOpenRemoveDialog(false);
                }}
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {i18n.t('connections:deleteConnection')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setOpenRemoveDialog(false);
                        }}
                        color="primary"
                    >
                        {i18n.t('common:settings.cancel')}
                    </Button>
                    <Button
                        onClick={() => {
                            setOpenRemoveDialog(false);
                            removePersonalDevice();
                        }}
                        color="primary"
                        autoFocus
                    >
                        {i18n.t('common:buttons.okButton')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    // TODO: to be implemented
    /**
     * Removes the personal device
     */
    function removePersonalDevice(): void {
        // eslint-disable-next-line no-console
        console.log('removePersonalDevice');
    }

    /**
     * Redirect to the invitation page for personal cloud connection
     */
    function inviteNewDevice(): void {
        history.push('/invites/personalCloud');
    }

    /**
     * Build connection panel.
     *
     * @param {Map<SHA256IdHash<Person>, ConnectionInfo[]>} connectionsMap - existing connections which will be displayed in this panel
     * @param {Connection} connectionsType - type of the displayed connections
     * @param {string} listTitle - title of the panel
     * @param {string} noConnectionsMessage - the message that will be displayed when the connectionsMap is empty
     * @param {string} buttonMessage - message for the invite button
     * @param {(() => void) | undefined} buttonOnClickAction - action for the invite button
     * @returns {ReactElement}
     */
    function buildConnectionsComponent(
        connectionsMap: Map<SHA256IdHash<Person>, ConnectionInfo[]>,
        connectionsType: Connection,
        listTitle: string,
        noConnectionsMessage: string,
        buttonMessage?: string,
        buttonOnClickAction?: (() => void) | undefined
    ): ReactElement {
        return (
            <Accordion
                key={listTitle}
                expanded={panels.includes(connectionsType)}
                onChange={() => {
                    handleAccordionChange(connectionsType);
                }}
                className="connections-list"
            >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <div className="list-title">{listTitle}</div>
                </AccordionSummary>
                <AccordionDetails className="connections-list">
                    {connectionsType === CONNECTIONS_TYPE.PersonalCloud &&
                        // display the id used for personal cloud connections
                        displayListItemWithoutIcon(
                            i18n.t('connections:mainInstanceId'),
                            currentInstanceIdHash.mainInstanceId
                        )}
                    {connectionsMap.size === 0 ? (
                        // display the message for the case when no connection is available
                        displayListItemWithoutIcon(
                            i18n.t('connections:otherDevices'),
                            noConnectionsMessage
                        )
                    ) : (
                        <div className="types-list paper-font-size">
                            {connectionsType === CONNECTIONS_TYPE.PersonalCloud && (
                                <b>{i18n.t('connections:otherDevices')}</b>
                            )}
                            {Array.from(connectionsMap.keys()).map(
                                // display all existing connections with the corresponding status
                                (personId: SHA256IdHash<Person>) => (
                                    <div key={personId}>
                                        <List>
                                            {buildConnectionsList(
                                                connectionsMap.has(personId)
                                                    ? connectionsMap.get(personId)
                                                    : []
                                            )}
                                        </List>
                                    </div>
                                )
                            )}
                        </div>
                    )}
                    {buttonMessage && (
                        <div className="button-container">
                            {buttonOnClickAction === undefined && removeDialog()}
                            <Button
                                color="primary"
                                size="small"
                                variant="contained"
                                onClick={() => {
                                    buttonOnClickAction
                                        ? buttonOnClickAction()
                                        : setOpenRemoveDialog(true);
                                }}
                            >
                                {buttonMessage}
                            </Button>
                        </div>
                    )}
                </AccordionDetails>
            </Accordion>
        );
    }

    /**
     * Display received arguments as list items with invisible
     * icon in order to preserve the page layout.
     *
     * @param {string} title -> will be displayed as simple bold text (as a list title)
     * @param {string} value -> list item text value
     * @returns {ReactElement}
     */
    function displayListItemWithoutIcon(title: string, value?: string): ReactElement {
        return (
            <div className="paper-font-size">
                <b>{title}</b>
                <ListItem key={'InstanceId'}>
                    <ListItemAvatar className={'no-background-avatar my-information'}>
                        <Avatar />
                    </ListItemAvatar>
                    <ListItemText className="my-info-summary">
                        <div>{value}</div>
                    </ListItemText>
                </ListItem>
            </div>
        );
    }

    /**
     * Panel which generates the invitation file used in raspberry pi connection.
     *
     * @returns {ReactElement}
     */
    function buildRPiSection(): ReactElement {
        return (
            <Accordion
                key={'RPiConnection'}
                expanded={panels.includes(CONNECTIONS_TYPE.RaspberryPi)}
                onChange={() => {
                    handleAccordionChange(CONNECTIONS_TYPE.RaspberryPi);
                }}
                className="connections-list"
            >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <div className="list-title">{i18n.t('connections:rpiDevice')}</div>
                </AccordionSummary>
                <AccordionDetails className="connections-list">
                    <>{i18n.t('connections:rpiConnectionDetail')}</>
                    <div className="button-container">
                        <Button
                            color="primary"
                            size="small"
                            variant="contained"
                            onClick={handleDownloadPairingInformationFile}
                        >
                            {i18n.t('connections:rpiConnect')}
                        </Button>
                    </div>
                </AccordionDetails>
            </Accordion>
        );
    }

    /**
     * Handler for case when the user enters an invitation link into the text field
     */
    function connectWithInvitationLink(): void {
        if (invitationLink.includes('invites/personalCloud/?invited=true')) {
            setShowEraseDialogForIoM(true);
        } else {
            setErrorState(i18n.t('errors:connection.invitationNotValid'));
        }
    }

    return (
        <>
            <InfoMessage
                errorMessage={errorState}
                setDisplayMessage={setErrorState}
                messageType={MESSAGE_TYPE.Error}
            />
            <div className="page-container personal-devices-container">
                <div className="menu-button-header">
                    <MenuButton />
                    <h2 className="headline">{i18n.t('common:settings.connections')}</h2>
                </div>
                {props.successfulConnection ? (
                    <Paper square elevation={3} className="stick-message-top">
                        <Alert
                            onClose={() => {
                                props.setSuccessfulConnection(false);
                            }}
                            className="eventsAlert"
                            severity="success"
                            key="alert"
                        >
                            {i18n.t('connections:connectionSuccessful')}
                            <br />
                            <b>
                                {connections.length
                                    ? connections[connections.length - 1].targetInstanceId
                                    : ''}
                            </b>
                        </Alert>
                    </Paper>
                ) : (
                    appBrokeOnInvite && (
                        <Paper square elevation={3} className="stick-message-top">
                            <Alert
                                onClose={() => {
                                    setAppBrokeOnInvite(false);
                                    localStorage.removeItem('appBrokeOnInvite');
                                }}
                                className="eventsAlert"
                                severity="error"
                                key="alert"
                            >
                                {i18n.t('errors:connection.connectionFailed')}
                            </Alert>
                        </Paper>
                    )
                )}
                {showEraseDialogForIoM && (
                    <Paper square elevation={3} className="stick-message-top">
                        <Alert
                            onClose={() => {
                                setShowEraseDialogForIoM(false);
                            }}
                            className="eventsAlert"
                            severity="error"
                            key="alert"
                        >
                            {i18n.t('connections:disallowIoMInvitation')}
                            <div className="erase-dialog-buttons">
                                <Button
                                    onClick={handleDeleteCurrentInstance}
                                    color="primary"
                                    variant="contained"
                                >
                                    {i18n.t('common:buttons.deleteAvailableData')}
                                </Button>
                                <Button
                                    onClick={handleContinueWithCurrentInstance}
                                    color="primary"
                                    variant="contained"
                                >
                                    {i18n.t('connections:continueButton')}
                                </Button>
                            </div>
                        </Alert>
                    </Paper>
                )}
                <Paper square elevation={3} className="page-content-box">
                    {buildConnectionsComponent(
                        personalConnectionsMap,
                        CONNECTIONS_TYPE.PersonalCloud,
                        i18n.t('connections:personalDevices.personalDevicesTitle'),
                        i18n.t('connections:personalDevices.noConnections'),
                        props.isDevelopmentMode
                            ? i18n.t('connections:personalDevices.addNewDevice')
                            : undefined,
                        inviteNewDevice
                    )}
                    <Divider />
                    {buildConnectionsComponent(
                        clinicConnectionsMap,
                        CONNECTIONS_TYPE.ClinicServer,
                        i18n.t('connections:clinicDevices'),
                        i18n.t('connections:personalDevices.noConnections')
                    )}
                    {props.isDevelopmentMode && (
                        <>
                            <Divider />
                            {buildRPiSection()}
                        </>
                    )}
                </Paper>
                {props.isDevelopmentMode && (
                    <Paper square elevation={3} className="page-content-box connection-paper">
                        <TextField
                            fullWidth
                            label={i18n.t('connections:invitationLink')}
                            value={invitationLink}
                            onChange={e => {
                                setInvitationLink(e.target.value);
                            }}
                            onKeyPress={event => {
                                if (event.key === 'Enter') {
                                    connectWithInvitationLink();
                                }
                            }}
                            required
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            className="connect-button"
                            onClick={connectWithInvitationLink}
                        >
                            {i18n.t('common:buttons.connect')}
                        </Button>
                    </Paper>
                )}
            </div>
        </>
    );
}
