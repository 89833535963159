import {createRef, useEffect} from 'react';
import type {ReactElement} from 'react';
import {Stepper, Step, StepLabel, Paper} from '@material-ui/core';
import './registrationProcess.css';
import {
    addScrollbar,
    contentAreaLargerThanDisplayArea,
    removeScrollbar,
    hideCircularProgress,
    updateScrollbarAtWindowResize
} from '../utils/Utils';

const CURRENT_STEP = {
    First: 'first',
    Second: 'second',
    Third: 'third'
} as const;

/**
 * @param props
 * @param props.textObject
 * @returns
 */
export default function RegistrationSimpleStepper(props: {
    textObject: {[index: string]: string};
}): ReactElement {
    const ref = createRef<HTMLElement>();

    useEffect(() => {
        const element = ref.current;

        if (element) {
            const needScrollbar = contentAreaLargerThanDisplayArea(element);

            if (needScrollbar) {
                addScrollbar(element);
            } else {
                removeScrollbar(element);
            }

            element.scrollTo(0, 0);
            hideCircularProgress();
        }
    });

    useEffect(() => {
        if (ref.current) {
            updateScrollbarAtWindowResize(ref.current);
        }
    }, [ref]);

    return (
        <Paper ref={ref} square elevation={3} className="page-content-box paper-font-size">
            <div className="register-stepper-container">
                <h3>{props.textObject.title}</h3>
                <div className="simple-stepper-introduction">{props.textObject.introduction}</div>
                <Stepper activeStep={3} orientation="vertical" className="simple-stepper">
                    {Object.values(CURRENT_STEP).map((key, idx) => {
                        return (
                            <Step className="simple-stepper-icon" key={key}>
                                <StepLabel
                                    StepIconProps={{
                                        classes: {
                                            active:
                                                key === CURRENT_STEP.First
                                                    ? 'phase1-color'
                                                    : key === CURRENT_STEP.Second
                                                    ? 'phase2-color'
                                                    : 'phase3-color',
                                            completed:
                                                key === CURRENT_STEP.First
                                                    ? 'phase1-color'
                                                    : key === CURRENT_STEP.Second
                                                    ? 'phase2-color'
                                                    : 'phase3-color'
                                        }
                                    }}
                                >
                                    <div className="simple-stepper-title">
                                        <div>{props.textObject[`${key}Title`]}</div>
                                    </div>
                                    <div
                                        className={`simple-stepper-content ${
                                            idx === Object.keys(CURRENT_STEP).length - 1
                                                ? 'last-step'
                                                : ''
                                        }`}
                                        dangerouslySetInnerHTML={{
                                            __html: props.textObject[`${key}`]
                                        }}
                                    />
                                </StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
            </div>
        </Paper>
    );
}
