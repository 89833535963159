import type {ReactElement} from 'react';
import {Input} from '@material-ui/core';
import * as dateFns from 'date-fns';
import {setTimeToEndOfTheDay, setTimeToStartOfTheDay} from '../utils/Utils';
import {DATA_RANGE} from './Filters';
import type {FilterRange} from './Filters';
import './GenericGraph.css';

/**
 * This component represents the date selectors and their behaviour.
 * @param props
 * @param props.activeFilter
 * @param props.setStartDateCallback
 * @param props.setEndDateCallback
 * @param props.setWarningMessageCallback
 * @param props.intervalStartDate
 * @param props.intervalEndDate
 * @param props.customTimeInterval
 */
export default function IntervalSelector(props: {
    activeFilter?: FilterRange;
    setStartDateCallback: (startDate: number) => void;
    setEndDateCallback: (endDate: number) => void;
    setWarningMessageCallback: (warning: string) => void;
    intervalStartDate: number;
    intervalEndDate: number;
    customTimeInterval?: number;
}): ReactElement {
    const dateFormatter = 'yyyy-MM-dd';

    /**
     * This function calculates the correct interval that will be displayed on the graph
     * based on the provided date and on active filter mode.
     * @param date - the date that is provided by the user from the interval end input field.
     * @param isEndDate - flag to know which date interval was changed by the user.
     */
    function updateDateInterval(date: string, isEndDate?: boolean): void {
        const newDate = new Date(date);

        let calculatedDate: Date | undefined;

        const currentDate = new Date();

        if (props.customTimeInterval !== undefined) {
            if (isEndDate) {
                calculatedDate = dateFns.subMilliseconds(newDate, props.customTimeInterval);
                props.setWarningMessageCallback('');
            } else if (
                dateFns.isBefore(
                    dateFns.addMilliseconds(newDate, props.customTimeInterval),
                    currentDate
                )
            ) {
                calculatedDate = dateFns.addMilliseconds(newDate, props.customTimeInterval);
                props.setWarningMessageCallback('');
            } else {
                props.setWarningMessageCallback('genericGraph:warnings.futureDate');
            }
        }

        switch (props.activeFilter) {
            case DATA_RANGE.Week: {
                if (isEndDate) {
                    calculatedDate = dateFns.subWeeks(newDate, 1);
                    props.setWarningMessageCallback('');
                } else if (dateFns.isBefore(dateFns.addWeeks(newDate, 1), currentDate)) {
                    calculatedDate = dateFns.addWeeks(newDate, 1);
                    props.setWarningMessageCallback('');
                } else {
                    props.setWarningMessageCallback(`genericGraph:warnings.${DATA_RANGE.Week}`);
                }
                break;
            }
            case DATA_RANGE.Month: {
                if (isEndDate) {
                    calculatedDate = dateFns.subMonths(newDate, 1);
                    props.setWarningMessageCallback('');
                } else if (dateFns.isBefore(dateFns.addMonths(newDate, 1), currentDate)) {
                    calculatedDate = dateFns.addMonths(newDate, 1);
                    props.setWarningMessageCallback('');
                } else {
                    props.setWarningMessageCallback(`genericGraph:warnings.${DATA_RANGE.Month}`);
                }
                break;
            }
            case DATA_RANGE.Year: {
                if (isEndDate) {
                    calculatedDate = dateFns.subYears(newDate, 1);
                    props.setWarningMessageCallback('');
                } else if (dateFns.isBefore(dateFns.addYears(newDate, 1), currentDate)) {
                    calculatedDate = dateFns.addYears(newDate, 1);
                    props.setWarningMessageCallback('');
                } else {
                    props.setWarningMessageCallback(`genericGraph:warnings.${DATA_RANGE.Year}`);
                }
                break;
            }
        }

        if (calculatedDate) {
            if (isEndDate) {
                props.setStartDateCallback(setTimeToStartOfTheDay(calculatedDate).getTime());
                props.setEndDateCallback(setTimeToEndOfTheDay(newDate).getTime());
            } else {
                props.setStartDateCallback(setTimeToStartOfTheDay(newDate).getTime());
                props.setEndDateCallback(setTimeToEndOfTheDay(calculatedDate).getTime());
            }
        }
    }

    return (
        <div className="time-selector">
            <div className="date-selector">
                <Input
                    className="date-input"
                    type="date"
                    required
                    value={dateFns.format(props.intervalStartDate, dateFormatter)}
                    onChange={event => {
                        updateDateInterval(event.target.value);
                    }}
                    disableUnderline={true}
                />
            </div>
            <div className="separator">-</div>
            <div className="date-selector">
                <Input
                    className="date-input"
                    type="date"
                    required
                    value={dateFns.format(props.intervalEndDate, dateFormatter)}
                    onChange={event => {
                        if (event.target.value !== '') {
                            if (dateFns.isAfter(new Date(event.target.value), new Date())) {
                                props.setWarningMessageCallback('genericGraph:warnings.futureDate');
                            } else {
                                updateDateInterval(event.target.value, true);
                            }
                        }
                    }}
                    disableUnderline={true}
                />
            </div>
        </div>
    );
}
