import {Fragment} from 'react';
import type {ReactElement} from 'react';
import i18n from '../../i18n';
import {Table, TableBody, TableCell, TableContainer, TableRow, Paper} from '@material-ui/core';
import type {Electrocardiogram} from 'one.models/lib/recipes/ECGRecipes';
import {formatDate} from '../utils/Utils';

/**
 * renders the table with the ECG additional data
 * @param props
 * @param props.ecgDataMeasurement
 */
export function EcgDataTable(props: {ecgDataMeasurement: Electrocardiogram}): ReactElement {
    function renderRowTable(type: string, value?: string): ReactElement {
        return (
            <Fragment>
                <TableRow>
                    <TableCell align="left">
                        <b> {i18n.t(`common:ecg.${type}`)}</b>
                    </TableCell>
                    <TableCell align="center"> {value}</TableCell>
                </TableRow>
            </Fragment>
        );
    }

    function renderECGTable(): ReactElement {
        const {
            voltageMeasurements,
            startTimestamp,
            samplingFrequencyHz,
            endTimestamp,
            classification,
            averageHeartRateBPM,
            symptoms
        } = props.ecgDataMeasurement;
        return (
            <Paper square elevation={3} className="page-content-box">
                <TableContainer>
                    <Table aria-label="simple table">
                        <TableBody>
                            {renderRowTable('classification', classification)}
                            {renderRowTable('voltageMeasurements', voltageMeasurements.toString())}
                            {renderRowTable('startTimestamp', formatDate(startTimestamp, true))}
                            {renderRowTable('endTimestamp', formatDate(endTimestamp, true))}
                            {renderRowTable('symptoms', symptoms)}
                            {renderRowTable('averageHeartRateBPM', averageHeartRateBPM?.toString())}
                            {renderRowTable('samplingFreq', samplingFrequencyHz?.toString())}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        );
    }

    return renderECGTable();
}
