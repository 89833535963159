import type {ReactElement} from 'react';
import type {TooltipProps} from 'recharts';
import i18n from '../../i18n';
import './EcgChart.css';

/**
 * This is a custom Tooltip for the body temperature graph.
 * @param {TooltipProps} props - properties of the view.
 * @returns {ReactElement} the information window for each point from the graph.
 */
export default function EcgTooltip(props: TooltipProps): ReactElement {
    if (props && props.payload && props.payload[0]) {
        return (
            <div className="custom-tooltip">
                <div className="first-tooltip-info">
                    {`${i18n.t('common:ecg.timeMeasurement')} : ${
                        props.payload[0].payload.timeSinceSampleStart
                    }`}
                </div>
                <div className="second-tooltip-info">
                    {`${i18n.t('common:ecg.leadVoltage')} : ${
                        props.payload[0].payload.leadVoltage
                    }`}
                </div>
            </div>
        );
    }

    return <></>;
}
