import {useEffect} from 'react';
import type {ReactElement} from 'react';
import {useHistory} from 'react-router-dom';
import type OneInstanceModel from '../../model/OneInstanceModel';
import {CircularProgress} from '@material-ui/core';

/**
 * logs out the user
 * @param props
 * @param props.oneInstanceModel
 * @param props.setNavigationDrawer
 * @returns
 */
export default function Reset(props: {
    oneInstanceModel: OneInstanceModel;
    setNavigationDrawer: (value: boolean) => void;
}): ReactElement {
    const history = useHistory();

    useEffect(() => {
        props.setNavigationDrawer(false);
        props.oneInstanceModel
            .deleteUnopenedInstance()
            .then(() => {
                history.push('/');
                window.location.reload();
            })
            .catch(err => {
                console.error(err);
                history.push('/');
            });
    }, [history, props]);

    return (
        <div className="circularProgressContainer">
            <CircularProgress className="circularProgress" size={35} />
        </div>
    );
}
