import {useState} from 'react';
import type {ReactElement} from 'react';
import {Divider, Drawer, List, ListItem, ListItemIcon, ListItemText, Fab} from '@material-ui/core';
import './NavigationDrawer.css';
import {Link} from 'react-router-dom';
import '../../Primary.css';
import type {PropertyTree} from 'one.models/lib/models/SettingsModel';
import {useSettings} from '../modelHelper/SettingsHelper';
import i18n from '../../i18n';
import {Icon} from '../icon/Icon';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MarkdownPopup, {
    MarkdownContext,
    useMarkdownContext
} from '../displayMarkdowns/MarkdownPopup';
import type {AcceptedFile} from '../importDocument/ImportFile';
import ImportFile, {ACCEPTED_FILE_TYPES, IMPORT_FILE_CLASSNAME} from '../importDocument/ImportFile';
import type DocumentModel from 'one.models/lib/models/DocumentModel';

/*
 type definition of the layout of elements properties menu
 */
export type NavigationDrawerItemsType = {
    name: string;
    functionality?: EntryFunctionality;
    icon?: ReactElement;
    subItems?: NavigationDrawerItemsType[];
    disabled?: boolean;
};

/**
 * Since we can have multiple functionalities for the menu entries not just redirecting the a
 * specific route, we need an object which wraps all the functionalities. Each menu entry which
 * is declared should have specified just one functionality.
 */
export type EntryFunctionality =
    | {path: string} // used to redirect the user to a specific route.
    | {acceptedFileType: AcceptedFile} // used to load a file from the device.
    | {markdownFilePath: string}; // used to open a popup which displays the content of a markdown file.

/**
 * Used to check if the entry functionality wraps a redirect.
 * @param functionality
 */
export function isPath(functionality: EntryFunctionality): functionality is {path: string} {
    return Object.prototype.hasOwnProperty.call(functionality, 'path');
}

/**
 * Used to check if the entry functionality wraps file upload.
 * @param functionality
 */
export function isAcceptedFileType(
    functionality: EntryFunctionality
): functionality is {acceptedFileType: AcceptedFile} {
    return Object.prototype.hasOwnProperty.call(functionality, 'acceptedFileType');
}

/**
 * Used to check if the entry functionality wraps markdown popup.
 * @param functionality
 */
export function isMarkdownFilePath(
    functionality: EntryFunctionality
): functionality is {markdownFilePath: string} {
    return Object.prototype.hasOwnProperty.call(functionality, 'markdownFilePath');
}

/**
 * This function build the items from the menu.
 * @param props - Properties of this view.
 * @param props.itemsArray
 * @param props.setNavigationDrawerState
 * @param props.settings
 * @param props.bottomElements - specify if the given elements
 * should be displayed at the bottom of the menu or not
 * @param props.documentModel - the document model used to open the browse file depending on which menu entry is clicked.
 * @returns - a list containing the items of the menu.
 */
export function MenuItems(props: {
    itemsArray: NavigationDrawerItemsType[];
    setNavigationDrawerState: (value: boolean) => void;
    settings: PropertyTree;
    bottomElements: boolean;
    documentModel: DocumentModel;
}): ReactElement {
    const [openState, setOpenState] = useState<{[key: string]: boolean}>({});
    const [closeMenuOnItemSelect] = useSettings(props.settings, 'closeMenuOnItemSelect', 'false');

    // markdown context for displaying the markdown popup when an menu entry which leads to
    // a markdown is clicked
    const {setMarkdownFilePath} = useMarkdownContext();

    /**
     *
     */
    function menuClickHandler(): void {
        if (
            window.innerWidth < 770 ||
            (window.innerWidth >= 770 && closeMenuOnItemSelect === 'true')
        ) {
            props.setNavigationDrawerState(false);
        }
    }

    /**
     * This function extends or collapses the sub-items list.
     * @param path
     */
    function handleClick(path: string): void {
        setOpenState({...openState, [path]: !openState[path]});
    }

    /**
     * This function builds the items of the menu navigation
     * @param name - the name of the item
     * @param functionality - the path of the item
     * @param icon - the icon of the item
     * @param disabled - if the menu item should be disabled or not
     * @returns - the item body
     */
    function buildNavigationElements(
        name: string,
        functionality?: EntryFunctionality,
        icon?: ReactElement,
        disabled?: boolean
    ): ReactElement {
        let menuItem = <></>;

        if (!(icon || functionality)) {
            return <Divider />;
        }

        if (
            functionality !== undefined &&
            isAcceptedFileType(functionality) &&
            (functionality.acceptedFileType === ACCEPTED_FILE_TYPES.Pdf ||
                functionality.acceptedFileType === ACCEPTED_FILE_TYPES.Pic)
        ) {
            menuItem = (
                <ListItem button disabled={!!disabled} className="menu-option">
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText>
                        <ImportFile
                            documentModel={props.documentModel}
                            type={functionality.acceptedFileType}
                            text={name}
                            className={IMPORT_FILE_CLASSNAME.MenuButton}
                        />
                    </ListItemText>
                </ListItem>
            );
        } else {
            menuItem = (
                <ListItem
                    button
                    disabled={!!disabled}
                    onClick={() => {
                        if (functionality === undefined) {
                            return;
                        }

                        if (isMarkdownFilePath(functionality)) {
                            setMarkdownFilePath(functionality.markdownFilePath);
                        }
                    }}
                    className="menu-option"
                >
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText>{name}</ListItemText>
                </ListItem>
            );
        }

        if (functionality !== undefined && isPath(functionality)) {
            return disabled ? (
                <div className="menu-option">{menuItem}</div>
            ) : (
                <Link
                    className="menu-option app-menu-entry"
                    to={functionality.path}
                    onClick={menuClickHandler}
                >
                    {menuItem}
                </Link>
            );
        }

        return <>{menuItem}</>;
    }

    /**
     * @param name
     * @param subItems
     * @param functionality
     * @param disabled
     */
    function buildSubItems(
        name: string,
        subItems: NavigationDrawerItemsType[],
        functionality?: EntryFunctionality,
        disabled?: boolean
    ): ReactElement {
        const path =
            functionality !== undefined && isPath(functionality) ? functionality.path : name;

        return (
            <div className={openState[path] ? '' : 'hide'}>
                <Drawer
                    variant="permanent"
                    classes={{
                        paper: 'navigation-drawer'
                    }}
                    open={openState[path]}
                >
                    <List>
                        <ListItem
                            button
                            onClick={() => {
                                handleClick(path);
                            }}
                            className="menu-option"
                        >
                            <ListItemIcon className="menu-button">
                                <Fab size="small" color="primary" className="fab-wrapper">
                                    <ArrowBackIcon />
                                </Fab>
                            </ListItemIcon>
                            <ListItemText>{name}</ListItemText>
                        </ListItem>
                    </List>

                    {subItems.map(
                        (
                            {
                                name: subName,
                                icon: subIcon,
                                subItems: subSubItems,
                                functionality: itemFunctionality
                            },
                            idx
                        ) => (
                            <div key={idx}>
                                {subSubItems
                                    ? buildSubItems(
                                          subName,
                                          subSubItems,
                                          itemFunctionality,
                                          disabled
                                      )
                                    : buildNavigationElements(
                                          subName,
                                          itemFunctionality,
                                          subIcon,
                                          disabled
                                      )}
                            </div>
                        )
                    )}
                </Drawer>
            </div>
        );
    }

    return (
        <List
            className={
                props.bottomElements
                    ? 'navigation-drawer-items bottom-items'
                    : 'navigation-drawer-items'
            }
        >
            {props.itemsArray.map(({name, icon, subItems, disabled, functionality}, index) => (
                <div key={index}>
                    {subItems ? (
                        <>
                            <ListItem
                                button
                                disabled={!!disabled}
                                className="menu-option"
                                onClick={() =>
                                    handleClick(
                                        functionality !== undefined && isPath(functionality)
                                            ? functionality.path
                                            : name
                                    )
                                }
                            >
                                <ListItemIcon>{icon}</ListItemIcon>
                                <ListItemText>{name}</ListItemText>
                            </ListItem>
                            {functionality !== undefined &&
                            openState[isPath(functionality) ? functionality.path : name] ===
                                undefined &&
                            isPath(functionality) &&
                            '/' + window.location.pathname.split('/')[1] === functionality.path
                                ? handleClick(isPath(functionality) ? functionality.path : name)
                                : ''}
                            {buildSubItems(name, subItems, functionality, disabled)}
                        </>
                    ) : (
                        <>{buildNavigationElements(name, functionality, icon, disabled)}</>
                    )}
                </div>
            ))}
        </List>
    );
}

/**
 * Renders the drawer of the menu navigation
 * @param props
 * @param props.navigationDrawerItems
 * @param props.setNavigationDrawerState
 * @param props.settings
 * @param props.shiftClickCallback
 * @param props.documentModel
 * @returns {ReactElement}
 */
export default function NavigationDrawer(props: {
    navigationDrawerItems: NavigationDrawerItemsType[];
    setNavigationDrawerState: (value: boolean) => void;
    settings: PropertyTree;
    shiftClickCallback: () => void;
    documentModel: DocumentModel;
}): ReactElement {
    const topItems: NavigationDrawerItemsType[] = [];
    const bottomItems: NavigationDrawerItemsType[] = [];
    let order = -1;

    // used to handle the markdown files displayed directly from the menu
    const [markdownFilePath, setMarkdownFilePath] = useState<string>('');

    /**
     * This is used to creates two arrays of items, one array for the top items and one for the bottom items.
     */
    for (let i = 0; i < props.navigationDrawerItems.length; i++) {
        if (props.navigationDrawerItems[i].name === '----l') {
            order = 1;
        }

        if (order === 1) {
            bottomItems.push(props.navigationDrawerItems[i]);
        } else {
            topItems.push(props.navigationDrawerItems[i]);
        }
    }

    return (
        <div>
            <Drawer
                variant="permanent"
                classes={{
                    paper: 'navigation-drawer'
                }}
            >
                <List className="navigation-drawer-items">
                    <ListItem
                        button
                        onClick={event => {
                            if (event.shiftKey) {
                                props.shiftClickCallback();
                            } else {
                                props.setNavigationDrawerState(false);
                            }
                        }}
                        className="menu-option"
                    >
                        <ListItemIcon className="menu-button">
                            <Icon name="Menu" />
                        </ListItemIcon>
                        <ListItemText>{i18n.t('common:menu.name')}</ListItemText>
                    </ListItem>
                </List>
                <MarkdownContext.Provider value={{setMarkdownFilePath: setMarkdownFilePath}}>
                    <MenuItems
                        setNavigationDrawerState={props.setNavigationDrawerState}
                        itemsArray={topItems}
                        settings={props.settings.getChild('menuItems')}
                        bottomElements={false}
                        documentModel={props.documentModel}
                    />

                    <MenuItems
                        setNavigationDrawerState={props.setNavigationDrawerState}
                        itemsArray={bottomItems}
                        settings={props.settings.getChild('menuItems')}
                        bottomElements={true}
                        documentModel={props.documentModel}
                    />
                </MarkdownContext.Provider>
                <MarkdownPopup filePath={markdownFilePath} setFilePath={setMarkdownFilePath} />
            </Drawer>
        </div>
    );
}
