import type {SHA256IdHash} from 'one.core/lib/util/type-checks';
import type {Person} from 'one.core/lib/recipes';
import {getAllValuesLinkingMostCurrent} from 'one.core/lib/reverse-map-query';
import {getObjectByIdHash} from 'one.core/lib/storage';
import {convertObjToMicrodata} from 'one.core/lib/object-to-microdata';

/**
 * Given the idHash of a versioned object the function searches
 * for all access objects that point to this object and computes
 * the array with the persons with who this objects is shared.
 *
 * @param {SHA256IdHash} idHash
 * @returns {SHA256IdHash<Person>[]}
 */
export async function getPersonsWithWhoThisObjectIsSharedWIth(
    idHash: SHA256IdHash
): Promise<SHA256IdHash<Person>[]> {
    const sharedWith: SHA256IdHash<Person>[] = [];
    const accessObjects = await getAllValuesLinkingMostCurrent(idHash, true, 'Access');

    for await (const hashAndIdHashAndTimestamp of accessObjects) {
        const accessObj = await getObjectByIdHash(hashAndIdHashAndTimestamp.idHash);
        sharedWith.push(...accessObj.obj.person);
    }

    const accessMutableObjects = await getAllValuesLinkingMostCurrent(
        idHash,
        true,
        'AccessMutable'
    );

    for await (const hashAndIdHashAndTimestamp of accessMutableObjects) {
        const accessObj = await getObjectByIdHash(hashAndIdHashAndTimestamp.idHash);
        sharedWith.push(...accessObj.obj.person);
    }

    const idAccessObjects = await getAllValuesLinkingMostCurrent(idHash, true, 'IdAccess');

    for await (const hashAndIdHashAndTimestamp of idAccessObjects) {
        const accessObj = await getObjectByIdHash(hashAndIdHashAndTimestamp.idHash);
        sharedWith.push(...accessObj.obj.person);
    }

    return sharedWith;
}

/**
 * Given the idHash of a versioned object the function computes the microdata string length.
 *
 * @param {SHA256IdHash} idHash
 * @returns {number}
 */
export async function computeSizeOfObject(idHash: SHA256IdHash): Promise<number> {
    const object = await getObjectByIdHash(idHash);
    const microdata = convertObjToMicrodata(object.obj);
    return Buffer.byteLength(microdata, 'utf8');
}
