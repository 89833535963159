import {useEffect, useState} from 'react';
import type {ReactElement} from 'react';
import i18n from '../../../i18n';
import * as dateFns from 'date-fns';
import './CurrentDate.css';
import {calculateTimeUntilTomorrow} from '../../../model/Utils';

/**
 * Component that returns the current date in German format. (e.g. Montag, 15. Februar 2021)
 */
export default function CurrentDate(): ReactElement {
    const [currentDate, setCurrentDate] = useState(Date.now());

    /** Update the date at each 10 seconds, in this way the user will see the right date **/
    useEffect(() => {
        const timeout = setTimeout(() => setCurrentDate(Date.now()), calculateTimeUntilTomorrow());

        return () => {
            clearTimeout(timeout);
        };
    }, []);

    return (
        <div className="current-date">
            <i>
                {i18n.t(`common:days:${dateFns.format(currentDate, 'EEEE')}`)},{' '}
                {dateFns.format(currentDate, 'd')}.{' '}
                {i18n.t(`common:months:${dateFns.format(currentDate, 'MMMM')}`)}{' '}
                {dateFns.format(currentDate, 'yyyy')}
            </i>
        </div>
    );
}
