import type {ReactElement} from 'react';
import type {ChangeEvent} from 'react';
import {Button, CircularProgress, TextField} from '@material-ui/core';
import type DiaryModel from 'one.models/lib/models/DiaryModel';
import {useHistory} from 'react-router-dom';
import i18n from '../../i18n';
import '../../Primary.css';
import Paper from '@material-ui/core/Paper';
import './DiaryEntry.css';
import InfoMessage, {MESSAGE_TYPE} from '../errors/InfoMessage';
import MenuButton from '../menu/MenuButton';
import {displayCircularProgress, formatDate, hideCircularProgress} from '../utils/Utils';
import {useEffect, useState} from 'react';

/**
 * DiaryEntryForm
 * @param props
 * @param props.diaryModel
 * @returns
 */
export default function DiaryEntryPage(props: {diaryModel: DiaryModel}): ReactElement {
    const history = useHistory();

    const [diaryEntryDescription, setDiaryEntryDescription] = useState('');
    const [diaryEntryDate, setDiaryEntryDate] = useState<Date>();
    const [errorState, setErrorState] = useState('');
    const urlSplit = history.location.pathname.split('/');
    const isViewMode = urlSplit.includes('view');
    const diaryId = urlSplit[urlSplit.length - 1];
    const hasDiaryId = /\d/.test(diaryId);

    useEffect(() => {
        function fetchDescription(): void {
            if (!diaryEntryDate) {
                props.diaryModel
                    .getEntryById(diaryId)
                    .then(entry => {
                        if (entry) {
                            setDiaryEntryDescription(entry.data);
                            setDiaryEntryDate(entry.creationTime);
                        }
                        hideCircularProgress();
                    })
                    .catch(err => {
                        hideCircularProgress();
                        console.error('Error: ', err);
                    });
                return;
            }

            if (!hasDiaryId) {
                setDiaryEntryDescription('');
            }
            hideCircularProgress();
        }
        fetchDescription();
    }, [diaryEntryDate, diaryId, hasDiaryId, isViewMode, props.diaryModel]);

    async function sendDiaryEntry(): Promise<void> {
        displayCircularProgress();
        await props.diaryModel
            .addEntry(diaryEntryDescription)
            .then(() => {
                hideCircularProgress();
            })
            .catch(err => {
                hideCircularProgress();
                setErrorState(err.message);
            });

        if (diaryEntryDescription) {
            history.push('/journal');
        }
    }

    function createDiaryEntryCopy(): void {
        history.push('/diary/' + diaryId);
    }

    return (
        <>
            <div className="circular-progress-container">
                <CircularProgress className="circular-progress" size={35} />
            </div>
            <div
                className={`page-container hide ${
                    isViewMode ? 'diary-entry-paper-view-mode' : 'diary-entry-paper'
                }`}
            >
                <InfoMessage
                    errorMessage={errorState}
                    setDisplayMessage={setErrorState}
                    messageType={MESSAGE_TYPE.Error}
                />
                <div className="menu-button-header">
                    <MenuButton />
                    <h2 className="headline"> {i18n.t('common:eventTypes.DiaryEntry')}</h2>
                </div>
                {isViewMode ? (
                    <div className="title-element">
                        {diaryEntryDate ? formatDate(diaryEntryDate) : ''}
                    </div>
                ) : (
                    <></>
                )}
                <Paper square elevation={3} className="page-content-box">
                    {isViewMode ? (
                        <div className="diary-entry-view-mode">
                            <div className="diary-entry-description">{diaryEntryDescription}</div>
                        </div>
                    ) : (
                        <>
                            <div className="text-box">
                                <TextField
                                    fullWidth={true}
                                    value={diaryEntryDescription}
                                    id="outlined-basic"
                                    variant="outlined"
                                    multiline
                                    rows={10}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                        setDiaryEntryDescription(e.target.value);
                                    }}
                                />
                            </div>
                        </>
                    )}
                </Paper>
                <div className="buttons-container">
                    {isViewMode ? (
                        <Button
                            className="button"
                            variant="contained"
                            color="primary"
                            onClick={createDiaryEntryCopy}
                        >
                            {i18n.t('common:buttons.copy')}
                        </Button>
                    ) : (
                        <Button
                            onClick={sendDiaryEntry}
                            className="button"
                            variant="contained"
                            color="primary"
                        >
                            {i18n.t('common:buttons.submit')}
                        </Button>
                    )}
                </div>
            </div>
        </>
    );
}
