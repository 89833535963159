import {useEffect} from 'react';
import type {ReactElement} from 'react';
import {useHistory} from 'react-router-dom';
import type OneInstanceModel from '../../model/OneInstanceModel';
import {LOGOUT_MODE} from '../../model/OneInstanceModel';
import Loading from '../loading/Loading';

/**
 * logs out the user
 * @param props
 * @param props.oneInstanceModel
 * @param props.setNavigationDrawer
 * @param props.setLoggedIn
 * @returns
 */
export default function Logout(props: {
    oneInstanceModel: OneInstanceModel;
    setNavigationDrawer: (value: boolean) => void;
    setLoggedIn: (value: boolean) => void;
}): ReactElement {
    const history = useHistory();

    const {oneInstanceModel, setNavigationDrawer, setLoggedIn} = props;

    useEffect(() => {
        oneInstanceModel
            .logout(LOGOUT_MODE.KeepData)
            .then(() => {
                setNavigationDrawer(false);
                history.push('/');
                setLoggedIn(false);
            })
            .catch(err => console.error(err));
    }, [history, oneInstanceModel, setLoggedIn, setNavigationDrawer]);

    return <Loading />;
}
