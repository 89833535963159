import type {ReactElement} from 'react';
import {Button, TextField, CircularProgress} from '@material-ui/core';
import {Alert} from '@material-ui/lab';
import {useHistory} from 'react-router-dom';
import i18n from '../../i18n';
import '../../Primary.css';
import Paper from '@material-ui/core/Paper';
import '../diary/DiaryEntry.css';
import type BodyTemperatureModel from 'one.models/lib/models/BodyTemperatureModel';
import MenuButton from '../menu/MenuButton';
import {displayCircularProgress, hideCircularProgress} from '../utils/Utils';
import {useState} from 'react';

const NOT_ALLOWED_CHARS = ['e', 'E', '+', '-'];

/**
 * Body Temperature form.
 * @param {{}} props - properties of the view.
 * @param {BodyTemperatureModel} props.bodyTemperatureModel
 * @returns {ReactElement} body temperature form.
 */
export default function AddBodyTemperature(props: {
    bodyTemperatureModel: BodyTemperatureModel;
}): ReactElement {
    const history = useHistory();

    const [bodyTemperature, setBodyTemperature] = useState('');
    const [errorState, setErrorState] = useState('');

    const minimumTemperatureValue = 35;
    const maximumTemperatureValue = 45;

    async function sendBodyTemperature(): Promise<void> {
        displayCircularProgress();
        await props.bodyTemperatureModel
            .addBodyTemperature(parseFloat(bodyTemperature), Date.now())
            .then(() => {
                hideCircularProgress();
                history.push('journal');
            })
            .catch(err => {
                hideCircularProgress();
                setErrorState(err.message);
            });
    }

    return (
        <>
            <div className="circular-progress-container hide">
                <CircularProgress className="circular-progress" size={35} />
            </div>
            <div className="page-container diary-entry-paper">
                {errorState ? <Alert severity="error">{errorState}</Alert> : <></>}
                <div className="menu-button-header">
                    <MenuButton />
                    <h2 className="headline"> {i18n.t('common:menu.bodyTemperature')}</h2>
                </div>
                <Paper square elevation={3} className="page-content-box">
                    <div className="text-box">
                        <TextField
                            id="standard-number"
                            label={i18n.t('common:menu.bodyTemperature')}
                            type="number"
                            fullWidth
                            InputLabelProps={{
                                shrink: true
                            }}
                            inputProps={{
                                min: minimumTemperatureValue,
                                max: maximumTemperatureValue
                            }}
                            helperText={
                                parseFloat(bodyTemperature) < minimumTemperatureValue ||
                                parseFloat(bodyTemperature) > maximumTemperatureValue
                                    ? i18n.t('errors:bodyTemperatureModel.rangeError')
                                    : ''
                            }
                            onChange={event => {
                                setBodyTemperature(event.target.value);
                            }}
                            onKeyDown={evt =>
                                NOT_ALLOWED_CHARS.includes(evt.key) && evt.preventDefault()
                            }
                            error={
                                parseFloat(bodyTemperature) < minimumTemperatureValue ||
                                parseFloat(bodyTemperature) > maximumTemperatureValue
                            }
                        />
                    </div>
                </Paper>
                <div className="buttons-container">
                    <Button
                        onClick={() => sendBodyTemperature()}
                        variant="contained"
                        color="primary"
                        className="button"
                        disabled={
                            parseFloat(bodyTemperature) < minimumTemperatureValue ||
                            parseFloat(bodyTemperature) > maximumTemperatureValue ||
                            bodyTemperature === ''
                        }
                    >
                        {i18n.t('common:buttons.submit')}
                    </Button>
                </div>
            </div>
        </>
    );
}
