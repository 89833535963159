import type {ReactElement} from 'react';
import {Button} from '@material-ui/core';
import i18n from '../../i18n';
import {downloadRecoveryFile} from './RecoveryHelper';
import type ConsentFileModel from 'one.models/lib/models/ConsentFileModel';
import RegistrationSimpleStepper from '../registration/registrationSimpleStepper';
import {getRecoveryText} from './recoveryText';
import MenuButton from '../menu/MenuButton';
import InfoMessage, {MESSAGE_TYPE} from '../errors/InfoMessage';
import type RecoveryModel from 'one.models/lib/models/RecoveryModel';
import {useState} from 'react';

/**
 * In settings menu the recovery page is displayed so the user can
 * download the recovery file and if the user forgets it's password
 * he can recreate the old instance with a new password using the
 * recovery link.
 *
 * @param props
 * @param props.consentFileModel
 * @param props.recoveryModel
 * @returns
 */
export default function RecoveryPage(props: {
    consentFileModel: ConsentFileModel;
    recoveryModel: RecoveryModel;
}): ReactElement {
    const [errorState, setErrorState] = useState('');

    /**
     * Handler for download the recovery file
     */
    function handleDownloadFile(): void {
        downloadRecoveryFile(props.consentFileModel, props.recoveryModel).catch(err => {
            console.error(err);
            setErrorState(err);
        });
    }

    return (
        <div className="page-container recovery-container">
            <InfoMessage
                errorMessage={errorState}
                setDisplayMessage={setErrorState}
                messageType={MESSAGE_TYPE.Error}
            />
            <div className="menu-button-header">
                <MenuButton />
                <h2 className="headline">{i18n.t('common:settings.recoveryPage')}</h2>
            </div>
            <div className="privacy-policy-wrapper">
                <RegistrationSimpleStepper textObject={getRecoveryText()} />
            </div>
            <div className="buttons-container">
                <Button
                    color="primary"
                    variant="contained"
                    className="button"
                    onClick={handleDownloadFile}
                >
                    {i18n.t('common:settings.download')}
                </Button>
            </div>
        </div>
    );
}
