import type {ReactElement} from 'react';
import {XAxis, YAxis, CartesianGrid, Line, Tooltip, ResponsiveContainer, LineChart} from 'recharts';
import {Paper} from '@material-ui/core';
import '../displayMarkdowns/MarkdownPopup.css';
import type {ElectrocardiogramReadings} from 'one.models/lib/recipes/ECGRecipes';
import './EcgChart.css';
import EcgTooltip from './EcgTooltip';

/**
 * renders individual readings of ECG for the rendering the spline chart
 * @param {{}} props
 * @param {{timeSinceSampleStart: string,leadVoltage: string}[]} props.readings
 * @returns {ReactElement}
 */
export function EcgChart(props: {readings: ElectrocardiogramReadings[]}): ReactElement {
    /** construct array of ticks based on the maximum time sample of the readings **/
    const ticks =
        props.readings.length > 0
            ? Array.from(
                  Array(
                      Math.round(props.readings[props.readings.length - 1].timeSinceSampleStart) + 1
                  ).keys()
              )
            : [];
    return (
        <>
            {props.readings.length > 0 && (
                <Paper square elevation={3} className="page-content-box ecg-chart">
                    <ResponsiveContainer className="container-margin" width={'1500%'} height={400}>
                        <LineChart data={props.readings}>
                            <CartesianGrid />
                            <XAxis
                                scale="time"
                                type="number"
                                domain={['auto', 'auto']}
                                ticks={ticks}
                                tickFormatter={(label: string) => `${label}s`}
                                dataKey="timeSinceSampleStart"
                            />
                            <YAxis dataKey="leadVoltage" tick={false} />
                            <Tooltip content={<EcgTooltip />} />
                            <Line
                                type="natural"
                                dataKey="leadVoltage"
                                stroke="#CC084F"
                                strokeWidth={1}
                                dot={false}
                            />
                        </LineChart>
                    </ResponsiveContainer>
                </Paper>
            )}
        </>
    );
}
