import type {FormEvent, ReactElement} from 'react';
import i18n from '../../i18n';
import {Icon} from '../icon/Icon';
import {CircularProgress, ListItem, ListItemIcon, ListItemText, TextField} from '@material-ui/core';
import type OneInstanceModel from '../../model/OneInstanceModel';
import MenuButton from '../menu/MenuButton';
import {displayCircularProgress, hideCircularProgress} from '../utils/Utils';
import InfoMessage, {MESSAGE_TYPE} from '../errors/InfoMessage';
import type {InformationType} from '../errors/InfoMessage';
import {downloadFile} from '../modelHelper/DownloadHelper';
import {createRef, useState} from 'react';

/**
 * @param props
 * @param props.oneInstanceModel
 * @returns
 */
export default function Backup(props: {oneInstanceModel: OneInstanceModel}): ReactElement {
    const fileInput = createRef<HTMLDivElement>();

    const [informationMessage, setInformationMessage] = useState('');
    const [infoType, setInfoType] = useState<InformationType>(MESSAGE_TYPE.Info);

    /**
     * Handler for export data
     */
    async function handleExportData(): Promise<void> {
        try {
            const file = await props.oneInstanceModel.backupInstance();
            await downloadFile(file, 'one.backup.html', 'text/html');
        } catch (err) {
            console.error('Error: ', err);
        }
    }

    /**
     * Trigger to open the 'Open file' dialog
     */
    function triggerInputFile(): void {
        if (fileInput.current !== undefined && fileInput.current !== null) {
            if (fileInput.current.click !== undefined) {
                fileInput.current.click();
            }
        }
    }

    /**
     *  Handler for import data
     * @param event
     */
    async function importData(event: FormEvent<EventTarget>): Promise<void> {
        let file: File | undefined;

        if (event.target instanceof HTMLInputElement) {
            if (event.target.files !== null) {
                file = event.target.files[0];
            }
        }

        if (!file) {
            return;
        }

        // extracting the file extension from the file name
        const fileExtension = file.name
            .substring(file.name.length - 5, file.name.length)
            .toLowerCase();

        if (fileExtension === '.html') {
            await props.oneInstanceModel
                .restoreInstance(file)
                .then(() => {
                    hideCircularProgress();
                    setInformationMessage('common:backup.successImport');
                    setInfoType(MESSAGE_TYPE.Success);
                })
                .catch(() => {
                    hideCircularProgress();
                    setInformationMessage('errors:importDocument.corruptedFile');
                    setInfoType(MESSAGE_TYPE.Error);
                });
        } else {
            setInformationMessage('errors:backup.formatNotAccepted');
            setInfoType(MESSAGE_TYPE.Error);
        }
    }

    return (
        <>
            <div className="circular-progress-container hide">
                <CircularProgress className="circular-progress" size={35} />
            </div>
            <div className="page-container">
                <InfoMessage
                    errorMessage={informationMessage}
                    setDisplayMessage={setInformationMessage}
                    messageType={infoType}
                />
                <div className="menu-button-header">
                    <MenuButton />
                    <h2 className="headline">{i18n.t('common:settings.backup')}</h2>
                </div>
                <ListItem onClick={handleExportData} button>
                    <ListItemIcon>
                        <Icon name="Export" />
                    </ListItemIcon>
                    <ListItemText>{i18n.t('common:settings.export')}</ListItemText>
                </ListItem>
                <label onClick={triggerInputFile} style={{cursor: 'pointer'}}>
                    <ListItem>
                        <ListItemIcon>
                            <Icon name="Import" />
                        </ListItemIcon>
                        <ListItemText>{i18n.t('common:settings.import')}</ListItemText>
                    </ListItem>
                </label>
                <label>
                    <TextField
                        ref={fileInput}
                        type="file"
                        id="file-input"
                        onChange={async e => {
                            displayCircularProgress();
                            await importData(e).then(() => hideCircularProgress());
                        }}
                        style={{display: 'none'}}
                    />
                </label>
            </div>
        </>
    );
}
