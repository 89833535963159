import type {ReactElement} from 'react';
import '../studyCommon/StudyOverview.css';
import '../../../Primary.css';
import i18n from '../../../i18n';
import MenuButton from '../../menu/MenuButton';
import {
    changeBackgroundColorToWhite,
    hideCircularProgress,
    removeWhiteBackgroundColor
} from '../../utils/Utils';
import CurrentDate from '../../studies/studyCommon/CurrentDate';
import {CircularProgress} from '@material-ui/core';
import {useSmilerImpactStudyData} from '../../modelHelper/StudyWorkflowHelper';
import type AudioExerciseModel from 'one.models/lib/models/AudioExerciseModel';
import SmilerImpactTimeline from './SmilerImpactTimeline';
import type SmilerImpactWorkflow from '../../../model/studies/SmilerImpactWorkflow';
import type SmilerWorkflow from '../../../model/studies/SmilerWorkflow';
import type ImpactWorkflow from '../../../model/studies/ImpactWorkflow';
import type DocumentModel from 'one.models/lib/models/DocumentModel';
import * as dateFns from 'date-fns';
import EndOfStudyMessage from '../studyCommon/EndOfStudyMessage';
import {SMILER_VISIT} from '../../../model/studies/StudyHelper';
import {useEffect} from 'react';

/**
 * This function builds and returns the Smiler-Impact study overview.
 * @param props - properties of this view.
 * @param props.smilerImpactWorkflow
 * @param props.audioExerciseModel
 * @param props.smilerWorkflow
 * @param props.impactWorkflow
 * @param props.documentModel
 * @returns - the Smiler-Impact study overview.
 */
export default function SmilerImpactOverview(props: {
    smilerWorkflow: SmilerWorkflow;
    impactWorkflow: ImpactWorkflow;
    smilerImpactWorkflow: SmilerImpactWorkflow;
    documentModel: DocumentModel;
    audioExerciseModel: AudioExerciseModel;
}): ReactElement {
    const {
        // current visit of the participant or undefined for the smiler-impact study -> used to display the active visit for the participant
        currentVisit,
        // getting the details for each visit for smiler-impact study -> used to highlight active tasks and blur inactive tasks
        visitsProperties,
        // getting the amount of days until the next visit becomes available for smiler-impact study
        daysUntilNextVisit,
        // current study day of the smiler-impact study -> used to keep the study progress up to date
        currentStudyDay,
        // an array containing the active and upcoming visits => done visits are not displayed anymore
        visits,
        // getting the tasks categories
        tasks,
        // finished task for the active visit
        finishedTasks
    } = useSmilerImpactStudyData(props.smilerImpactWorkflow);

    useEffect(() => {
        changeBackgroundColorToWhite();
        hideCircularProgress();

        return () => {
            removeWhiteBackgroundColor();
        };
    }, []);

    return (
        <>
            <div className="circular-progress-container">
                <CircularProgress className="circular-progress" size={35} />
            </div>

            <div className="page-container hide home-screen">
                <div className="menu-button-header">
                    <MenuButton />
                    <h2 className="headline"> {i18n.t('common:menu.overview')}</h2>
                </div>
                <CurrentDate />
                <p className="next-visit">
                    {daysUntilNextVisit === -1 ||
                    (currentVisit === SMILER_VISIT.Day_28 &&
                        visitsProperties[currentVisit].tasksCompleted ===
                            visitsProperties[currentVisit].totalTasks) ? (
                        <i>
                            {i18n.t('studies:endOfStudy1')}
                            <br />
                            {i18n.t('studies:endOfStudy2')}
                        </i>
                    ) : currentVisit !== undefined &&
                      visitsProperties[currentVisit].totalTasks !==
                          visitsProperties[currentVisit].tasksCompleted &&
                      dateFns.isSameDay(visitsProperties[currentVisit].visitDate, new Date()) ? (
                        <i>{i18n.t('studies:smiler-impact.activeVisit')}</i>
                    ) : (
                        daysUntilNextVisit !== -1 && (
                            <i>
                                {i18n.t('studies:smiler-impact.inactiveVisit')}
                                {daysUntilNextVisit}
                                {i18n.t('studies:days')}
                            </i>
                        )
                    )}
                </p>
                <SmilerImpactTimeline
                    currentDayOfTheStudy={currentStudyDay}
                    currentVisit={currentVisit}
                    visitsProperties={visitsProperties}
                    impactWorkflow={props.impactWorkflow}
                    smilerWorkflow={props.smilerWorkflow}
                    documentModel={props.documentModel}
                    audioExerciseModel={props.audioExerciseModel}
                    visits={visits}
                    tasks={tasks}
                    finishedTasks={finishedTasks}
                />
                {(daysUntilNextVisit === -1 ||
                    (currentVisit === SMILER_VISIT.Day_28 &&
                        visitsProperties[currentVisit].tasksCompleted ===
                            visitsProperties[currentVisit].totalTasks)) && <EndOfStudyMessage />}
            </div>
        </>
    );
}
