import {useEffect, useState} from 'react';
import type {ReactElement} from 'react';
import * as dateFns from 'date-fns';
import './Calendar.css';
import '../../Primary.css';
import {nameOfDaysOfWeek, stringHours} from './CalendarCommon';
import {getCurrentEventInformation, useEventList} from '../modelHelper/JournalHelper';
import type JournalModel from 'one.models/lib/models/JournalModel';
import type {EventListEntry} from 'one.models/lib/models/JournalModel';
import type {EventTypes} from '../journal/Journal';
import JournalDialog from '../journal/JournalDialog';
import {CircularProgress} from '@material-ui/core';
import i18n from '../../i18n';
import {hideCircularProgress} from '../utils/Utils';

/**
 * This component build and return the calendar in a week view.
 * @param props - Properties of this view.
 * @param props.journalModel
 * @param props.eventTypes
 * @returns - entire week view of the calendar.
 */
export default function WeekCalendarView(props: {
    journalModel: JournalModel;
    eventTypes: EventTypes[];
}): ReactElement {
    const [currentDate, setCurrentDate] = useState(new Date());
    const eventList = useEventList(props.journalModel);
    const [clickedEvent, setClickedEvent] = useState<EventListEntry>();
    const [clickedEventType, setClickedEventType] = useState('');
    const [dialogState, setDialogState] = useState(false);

    useEffect(() => {
        hideCircularProgress();
    }, [eventList]);

    /**
     *  This method is called for each cell in the week calendar view and return an array
     *  of icons - specific icon for each event type.
     *  @param {number} hour - the hour when the event was completed.
     *  @param {Date} date - the date when the event was completed.
     *  @param {EventTypes[]} eventTypes - The list of event types
     *  @returns {ReactElement} - an array containing specific icon for each event type from the event list.
     */
    function buildIconByEventType(
        hour: number,
        date: Date,
        eventTypes: EventTypes[]
    ): ReactElement {
        const displayedIcons = [];

        for (const event of eventList) {
            if (
                dateFns.isSameDay(event.data.creationTime, date) &&
                event.data.creationTime.getHours() === hour
            ) {
                const currentEventInfo = getCurrentEventInformation(event, eventTypes);

                displayedIcons.push(
                    <div
                        key={event.data.creationTime.getTime()}
                        style={{width: '32px', display: 'inline-block'}}
                        onClick={() => {
                            setDialogState(!dialogState);
                            setClickedEventType(currentEventInfo.type);
                            setClickedEvent(event);
                        }}
                    >
                        {currentEventInfo.icon}
                    </div>
                );
            }
        }

        return <div className="survey-icon">{displayedIcons}</div>;
    }

    const dateFormat = 'MMMM yyyy';
    const dayFormatter = 'd';
    const rows = [];
    const days = [];

    let formattedDate = '';
    let dayOfMonth = 0;

    const day = dateFns.startOfWeek(currentDate);

    //  calendar body - for each day 24 hours
    for (let i = 0; i < 7; i++) {
        const currentDay = dateFns.addDays(day, i);

        formattedDate = dateFns.format(currentDay, dayFormatter);
        dayOfMonth = parseInt(dateFns.format(currentDay, dayFormatter), 10);

        days.push(
            <div className="column cell" key={dayOfMonth}>
                <div key={i} className="weekColumn">
                    {nameOfDaysOfWeek[i]}
                </div>
                <div className="weekColumn">{formattedDate}</div>

                <div>
                    {stringHours.map((hour, idx) => {
                        return (
                            <div className="weekCell" key={hour}>
                                {buildIconByEventType(idx, currentDay, props.eventTypes)}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
    rows.push(
        <div className="row" key={dayOfMonth}>
            {days}
        </div>
    );

    return (
        <>
            <div className="circular-progress-container">
                <CircularProgress className="circular-progress" size={35} />
            </div>
            <div>
                <div className="page-container calendar hide">
                    {dialogState && clickedEvent ? (
                        <JournalDialog
                            dialogState={dialogState}
                            setDialogState={setDialogState}
                            event={clickedEvent}
                            type={clickedEventType}
                        />
                    ) : (
                        <div />
                    )}
                    <div className="calendarHeader row flex-middle">
                        <div
                            className="icon_calendar"
                            onClick={() => setCurrentDate(dateFns.subWeeks(currentDate, 1))}
                        >
                            chevron_left
                        </div>
                        <div className="title">
                            {i18n.t(
                                'common:months.' +
                                    dateFns.format(currentDate, dateFormat).split(' ')[0]
                            )}
                            {dateFns.format(currentDate, dateFormat).split(' ')[1]}
                        </div>
                        <div
                            className="icon_calendar"
                            onClick={() => setCurrentDate(dateFns.addWeeks(currentDate, 1))}
                        >
                            chevron_right
                        </div>
                    </div>
                    <div>{rows}</div>
                </div>
                <div className="hoursDiv">
                    {stringHours.map((hour, index) => {
                        return (
                            <div key={index} className="listItem">
                                {hour}
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
}
