import i18n from '../../../i18n';
import type {ReactElement} from 'react';

/**
 * Represents the smiler end of the study message.
 */
export default function EndOfStudyMessage(): ReactElement {
    return (
        <div className="end-of-study-box">
            <div className="smiler-end-of-study">
                {i18n.t('studies:endOfStudy1')}
                <br />
                {i18n.t('studies:endOfStudy2')}
            </div>
        </div>
    );
}
