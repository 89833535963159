import EventEmitter from 'events';
import {closeInstance, initInstance, registerRecipes} from 'one.core/lib/instance';
import type {Module, Instance, Person, Recipe} from 'one.core/lib/recipes';
import type {SHA256Hash} from 'one.core/lib/util/type-checks';
import {
    createSingleObjectThroughPurePlan,
    VERSION_UPDATES,
    createManyObjectsThroughPurePlan
} from 'one.core/lib/storage';
import type {VersionedObjectResult} from 'one.core/lib/storage';
import {getDbInstance} from 'one.core/lib/system/storage-base';
import {implode} from 'one.core/lib/microdata-imploder';
import type {ConsentFileModel} from 'one.models/lib/models';
import type ChannelManager from 'one.models/lib/models/ChannelManager';
import type AccessModel from 'one.models/lib/models/AccessModel';
import i18nModelsInstance from '../i18n';
import {createRandomString} from 'one.core/lib/system/crypto-helpers';
import {calculateIdHashOfObj} from 'one.core/lib/util/object';
import {getNthVersionMapHash} from 'one.core/lib/version-map-query';
import {OEvent} from 'one.models/lib/misc/OEvent';
import windowAPI from '../ui/modelHelper/WebViewHelper';
import oneModules from 'one.models/lib/generated/oneModules';

/**
 * This is only a temporary solution, until all Freeda group stuff is moved out from this model
 * It must match the group definition in the main project.
 *
 * ATTENTION: Do not dare to export this definition in order to use it in another model
 *            I am just in the process of getting rid of it everywhere!
 *            If you do - you will experience your personal Judgment day. I'll be back!
 *            (If you do not know what that is - google the movie terminator)
 *
 * TODO: remove me when the model is cleaned up from app specific stuff
 */
const FREEDA_ACCESS_GROUPS = {
    partner: 'partners',
    clinic: 'clinic',
    myself: 'myself'
} as const;

/**
 * Represents the state of authentication.
 */
export const AUTHENTICATION_STATE = {
    NotAuthenticated: 0,
    Authenticating: 1,
    Authenticated: 2
} as const;

/** The type definition based on the AUTHENTICATION_STATE value. **/
export type Authentication = typeof AUTHENTICATION_STATE[keyof typeof AUTHENTICATION_STATE];

/**
 * Represent the mode the user will choose to logout
 *       ->purge Data: logout and delete the current indexedDB instance
 *       ->logout: simply close instance
 */
export const LOGOUT_MODE = {
    PurgeData: 0,
    KeepData: 1
} as const;

/** The type definition based on the LOGOUT_MODE value. **/
export type LogoutType = typeof LOGOUT_MODE[keyof typeof LOGOUT_MODE];

/**
 * Import all plan modules
 */
async function importModules(): Promise<VersionedObjectResult<Module>[]> {
    const modules = Object.keys(oneModules).map(key => ({
        moduleName: key,
        code: oneModules[key as keyof typeof oneModules]
    }));

    return Promise.all(
        modules.map(module =>
            createSingleObjectThroughPurePlan(
                {
                    module: '@one/module-importer',
                    versionMapPolicy: {
                        '*': VERSION_UPDATES.NONE_IF_LATEST
                    }
                },
                module
            )
        )
    );
}

/**
 * Model that exposes functionality closely related to one.core
 */
export default class OneInstanceModel extends EventEmitter {
    /**
     * Event emitted:
     * - when a new instance is created with takeOver
     * - on login
     * - on logout
     * - on registration
     */
    public onAuthStateChange = new OEvent<() => void>();
    /**
     * Event is emitted when the number of patients connections (and the partner state) changes.
     */
    public onPartnerStateChange = new OEvent<() => void>();
    /**
     * Event is emitted when the user registration state changes. This is triggered on login if the user is doing IoM
     * initialisation or if doesn't have the consent file, when starting the registration process and when the
     * registration process is finished.
     * */
    public onRegistrationStateChange = new OEvent<() => void>();

    /**
     * This event is emitted just before the login finishes and after the instance is
     * create so that you can initialize the models.
     */
    public onLogin = new OEvent<
        (
            currentRegistrationState: boolean,
            anonOneymousEmail?: string,
            takeOver?: boolean,
            recoveryState?: boolean
        ) => void
    >();

    /**
     * This event is emitted just before the logout finishes and before the instance is
     * closed so that you can shutdown the models.
     */
    public onLogout = new OEvent<() => void>();

    /** Keeps track of the current user state. */
    private currentAuthenticationState: Authentication;
    /**
     * Keeps track of user registration state:
     * true -> user is register and need to agree privacy policy
     * false -> user has finished the registration process
     */
    private currentRegistrationState: boolean;
    /**
     * is set in the registration process
     */
    private currentPatientTypeState: string;
    /**
     * if the partner has no patient, the state is true,
     * after the patient - partner connection is established,
     * the partner state becomes false
     */
    private currentPartnerState: boolean;

    private password: string;
    private randomEmail: string | null;
    private randomInstanceName: string | null;
    private initialRecipes: Recipe[];

    private channelManager: ChannelManager;
    private consentFileModel: ConsentFileModel;
    private accessModel: AccessModel;

    // encrypt everything by default
    private encryptStorage: boolean = true;

    private readonly phoneLockHandle: () => void;

    /**
     * Construct a new model instance
     *
     * @param {ChannelManager} channelManager
     * @param {ConsentFileModel} consentFileModel
     * @param {AccessModel} accessModel
     * @param {Recipe[]} initialRecipes
     */
    constructor(
        channelManager: ChannelManager,
        consentFileModel: ConsentFileModel,
        accessModel: AccessModel,
        initialRecipes: Recipe[]
    ) {
        super();
        this.password = '';
        this.randomEmail = '';
        this.randomInstanceName = '';
        this.initialRecipes = initialRecipes;
        this.currentAuthenticationState = AUTHENTICATION_STATE.NotAuthenticated;
        this.currentRegistrationState = false;
        this.currentPartnerState = false;
        this.currentPatientTypeState = '';
        this.channelManager = channelManager;
        this.consentFileModel = consentFileModel;
        this.accessModel = accessModel;

        // listen for update events in access model and check for patient connections
        this.accessModel.onGroupsUpdated(() => {
            if (
                this.currentAuthenticationState === AUTHENTICATION_STATE.Authenticated &&
                this.currentPatientTypeState.includes('partner')
            ) {
                this.updatePartnerState().catch(e => console.error(e));
            }
        });

        this.phoneLockHandle = () => {
            void this.logout(LOGOUT_MODE.KeepData);
        };
    }

    init(): void {
        if (windowAPI !== undefined) {
            document.addEventListener('phoneLocked', this.phoneLockHandle);
        }
    }

    authenticationState(): Authentication {
        return this.currentAuthenticationState;
    }

    registrationState(): boolean {
        return this.currentRegistrationState;
    }

    patientTypeState(): string {
        return this.currentPatientTypeState;
    }

    partnerState(): boolean {
        return this.currentPartnerState;
    }

    /**
     * Both in register and login cases we need to know if the instance already exists:
     * if the user has login before on this device, the instance name will be available
     * in local storage.
     *
     * @returns {boolean}
     */
    private static checkIfInstanceExists(): boolean {
        return !!localStorage.getItem('instance');
    }

    /**
     * When the recovery process is started, the previously generated email is read from the qr code.
     * The previously created instance with that email as owner is deleted and a new one is created.
     * The user has to re-enter a password, which will be used for the new instance.
     *
     * After the instance is created, the person keys are overwritten with the old ones read from
     * the qr code, because the person is the same, just the password has to change on recovery process.
     *
     * @param {string} email
     * @param {string} secret
     * @param {string} patientType
     * @param {string} anonymousEmail
     * @returns {Promise<void>}
     */
    async recoverInstance(
        email: string,
        secret: string,
        patientType: string,
        anonymousEmail: string
    ): Promise<void> {
        this.currentPatientTypeState = patientType;

        try {
            const ownerIdHash = await calculateIdHashOfObj({
                $type$: 'Person',
                email: localStorage.getItem('email')
            } as Person);
            const instanceIdHash = await calculateIdHashOfObj({
                $type$: 'Instance',
                name: localStorage.getItem('instance'),
                owner: ownerIdHash
            } as Instance);
            await this.deleteInstance('data#' + instanceIdHash);
        } catch (e) {
            /**
             * When there is no instance in the browser the recovery process
             * should continue, no error should be thrown.
             */
            if (e.code !== 'O2M-CVAL1') {
                throw Error(i18nModelsInstance.t('errors:login.userNotFound'));
            }
        }
        this.password = secret;
        /**
         * In the recovery state the email and the anonymous email are read from the
         * url, but the recovery state has to be passed to the models initialisation
         * in order to overwrite the new generated person keys with the old ones.
         */
        await this.createNewInstanceWithReceivedEmail(email, false, anonymousEmail, true);
    }

    /**
     * In instance take over case, the new instance will receive the user email
     * via qr code and the new instance will be created using that email.
     *
     * @param {string} email
     * @param {boolean} takeOver
     * @param {string} anonymousEmail
     * @param {boolean} recoveryState
     */
    async createNewInstanceWithReceivedEmail(
        email: string,
        takeOver = false,
        anonymousEmail?: string,
        recoveryState?: boolean
    ): Promise<void> {
        this.randomEmail = email;
        this.randomInstanceName = await createRandomString(64);
        localStorage.setItem('device_id', await createRandomString(64));
        localStorage.setItem('email', this.randomEmail);
        localStorage.setItem('instance', this.randomInstanceName);

        const {encryptStorage} = this;

        await initInstance({
            name: this.randomInstanceName,
            email: this.randomEmail,
            secret: this.password,
            encryptStorage,
            ownerName: 'name' + this.randomEmail,
            initialRecipes: this.initialRecipes
        });

        await importModules();
        this.unregister();
        await this.initialisingApplication(anonymousEmail, takeOver, recoveryState);
        this.emit('authstate_changed');
        this.onAuthStateChange.emit();
    }

    /**
     * Open an existing instance or create a new one if the instance does not exist.
     * @param {string} secret - Secret for decryption
     * @param {string} email
     * @param {string} secretEncryptionKey
     * @param {string} secretSignKey
     * @param {string} publicEncryptionKey
     * @param {string} publicSignKey
     */
    async initialiseInstance(
        secret: string,
        email?: string,
        secretEncryptionKey?: string,
        secretSignKey?: string,
        publicEncryptionKey?: string,
        publicSignKey?: string
    ): Promise<void> {
        this.currentAuthenticationState = AUTHENTICATION_STATE.Authenticating;
        this.password = secret;
        this.randomEmail = localStorage.getItem('email');
        this.randomInstanceName = localStorage.getItem('instance');

        if (this.randomEmail === null || email) {
            this.randomEmail = email ? email : await createRandomString(20);
            localStorage.setItem('email', this.randomEmail);
        }

        if (this.randomInstanceName === null) {
            this.randomInstanceName = await createRandomString(64);
            localStorage.setItem('instance', this.randomInstanceName);
        }

        await initInstance({
            name: this.randomInstanceName,
            email: this.randomEmail,
            secret: secret,
            encryptStorage: this.encryptStorage,
            ownerName: 'name' + this.randomEmail,
            initialRecipes: this.initialRecipes,
            secretEncryptionKey: secretEncryptionKey,
            secretSignKey: secretSignKey,
            publicEncryptionKey: publicEncryptionKey,
            publicSignKey: publicSignKey
        });

        await importModules();

        // needed because if the instance already exists initInstance will not load new recipes
        await registerRecipes(this.initialRecipes);

        await this.initialisingApplication();
    }

    /**
     * Helper function for initialising the modules of the application.
     * @param {string} anonymousEmail
     * @param {boolean} takeOver
     * @param {boolean} recoveryState
     */
    async initialisingApplication(
        anonymousEmail?: string,
        takeOver?: boolean,
        recoveryState?: boolean
    ): Promise<void> {
        // The AUTHENTICATION_STATE is needed to be on Authenticated so that
        // the models can be initialised (see Model.ts init method).
        this.currentAuthenticationState = AUTHENTICATION_STATE.Authenticated;

        await this.onLogin.emitAll(
            this.currentRegistrationState,
            anonymousEmail,
            takeOver,
            recoveryState
        );

        if (this.currentPatientTypeState.includes('partner')) {
            this.updatePartnerState().catch(e => console.error(e));
        }
    }

    async updatePartnerState(): Promise<void> {
        // if a partner has no patients associated, then he enters in a
        // partner state, where the application is not available until a
        // patient is being associated with this partner
        const availablePatientConnections = await this.accessModel.getAccessGroupPersons(
            FREEDA_ACCESS_GROUPS.partner
        );

        if (availablePatientConnections.length > 0) {
            this.currentPartnerState = false;
            this.emit('partner_state_changed');
            this.onPartnerStateChange.emit();
        } else {
            this.currentPartnerState = true;
            this.emit('partner_state_changed');
            this.onPartnerStateChange.emit();
        }
    }

    /**
     * Login into the one instance using an existing instance.
     *
     * @param {string} secret - Secret for decryption
     * @param {string} patientType - type of the patient or of the partner
     * @param {string} isPersonalCloudInvite - if the url contains an invite
     * for personal cloud, the instance should not be initialised yet
     */
    async login(
        secret: string,
        patientType: string,
        isPersonalCloudInvite: boolean
    ): Promise<void> {
        this.currentPatientTypeState = patientType;

        if (isPersonalCloudInvite) {
            this.password = secret;
            this.currentRegistrationState = true;
            this.currentAuthenticationState = AUTHENTICATION_STATE.Authenticated;
            this.emit('registration_state_changed');
            this.onRegistrationStateChange.emit();
            this.emit('authstate_changed');
            this.onAuthStateChange.emit();
            return;
        }

        if (!OneInstanceModel.checkIfInstanceExists()) {
            throw TypeError(i18nModelsInstance.t('errors:login.userNotFound'));
        }

        const name = localStorage.getItem('instance');
        const email = localStorage.getItem('email');

        if (name && email) {
            await this.initialiseInstance(secret);

            try {
                await this.consentFileModel.getOwnerConsentFile();
                this.currentRegistrationState = false;
            } catch {
                this.currentRegistrationState = true;
                this.emit('registration_state_changed');
                this.onRegistrationStateChange.emit();
            }

            this.emit('authstate_changed');
            this.onAuthStateChange.emit();
        }
    }

    /**
     * Depending on the logoutMode user will logout or the instance will be deleted.
     *
     * @param {LogoutType} logoutMode
     */
    async logout(logoutMode: LogoutType): Promise<void> {
        // Signal the application that it should shutdown one dependent models
        // and wait for them to shut down
        try {
            await this.onLogout.emitAll();
        } finally {
            // Close the one instance -> why delayed?
            const dbInstance = getDbInstance();
            setTimeout(() => {
                dbInstance.close();
                closeInstance();
            }, 1500);

            // Delete the one instance if requested
            if (logoutMode === LOGOUT_MODE.PurgeData) {
                await this.deleteInstance(dbInstance.name);
            }

            this.currentAuthenticationState = AUTHENTICATION_STATE.NotAuthenticated;
            this.emit('authstate_changed');
            this.onAuthStateChange.emit();
        }
    }

    /**
     * Register into the one instance by creating a new one.
     * @param {string} secret - Secret for decryption
     * @param {string} patientType
     * @param {string} email
     * @param {string} secretEncryptionKey
     * @param {string} secretSignKey
     * @param {string} publicEncryptionKey
     * @param {string} publicSignKey
     */
    async register(
        secret: string,
        patientType: string,
        email?: string,
        secretEncryptionKey?: string,
        secretSignKey?: string,
        publicEncryptionKey?: string,
        publicSignKey?: string
    ): Promise<void> {
        if (!OneInstanceModel.checkIfInstanceExists()) {
            this.currentRegistrationState = true;
            this.currentPatientTypeState = patientType;

            await this.initialiseInstance(
                secret,
                email,
                secretEncryptionKey,
                secretSignKey,
                publicEncryptionKey,
                publicSignKey
            );
            this.emit('registration_state_changed');
            this.onRegistrationStateChange.emit();
            this.emit('authstate_changed');
            this.onAuthStateChange.emit();
            return;
        }

        throw EvalError(i18nModelsInstance.t('errors:oneInstanceModel.loginNotRegister'));
    }

    /**
     * After the user accepts the privacy policy or synchronise the current device
     * with another device that he owns, the registration state will be set to false
     * and the application will be displayed.
     */
    unregister(): void {
        this.currentRegistrationState = false;
        this.emit('registration_state_changed');
        this.onRegistrationStateChange.emit();
    }

    /**
     * Create a backup of the whole instance.
     *
     * TODO: fix the bug that not the latest merged version, but the latest version is saved
     *
     * @returns {Promise<Blob>} The exported content
     */
    async backupInstance(): Promise<Blob> {
        const hashesToImplode: SHA256Hash[] = [];
        const channelsInfo = await this.channelManager.channels();
        await Promise.all(
            channelsInfo.map(async channelInfo => {
                // Warning: this is broken, because it doesn't load the latest merged
                // version, it just loads the latest version
                // This bug existed before redesigning, so I won't fix it now (time reasons)
                // If we want to do this right we need to add additional functionality to
                // the model - but not now
                const channelIdHash = await calculateIdHashOfObj({
                    $type$: 'ChannelInfo',
                    id: channelInfo.id,
                    owner: channelInfo.owner
                });
                const channelHash = await getNthVersionMapHash(channelIdHash);
                return hashesToImplode.push(channelHash);
            })
        );

        const implodedHashesResult = await Promise.all(
            hashesToImplode.map(async hashToImplode => await implode(hashToImplode))
        ).then(microdataArray => {
            return JSON.stringify(microdataArray);
        });

        return new Blob([implodedHashesResult], {type: 'text/html'});
    }

    /**
     * Restore an instance from an export
     *
     * @param {Blob} data - The data from which to restore the instance
     */
    async restoreInstance(data: Blob): Promise<void> {
        const dataText = await new Promise((resolve, reject) => {
            const fr = new FileReader();

            fr.addEventListener('load', () => {
                resolve(fr.result);
            });

            fr.addEventListener('error', err => {
                reject(err);
            });

            fr.readAsText(data);
        });

        if (typeof dataText === 'string') {
            const microdataArray = JSON.parse(dataText);

            await createManyObjectsThroughPurePlan(
                {
                    module: '@module/explodeObject',
                    versionMapPolicy: {
                        '*': VERSION_UPDATES.ALWAYS
                    }
                },
                microdataArray
            );
        }
    }

    getSecret(): string {
        return this.password;
    }

    /**
     * Deletes the instance db which name is given as argument.
     *
     * @param {string} dbInstanceName
     * @returns {Promise<void>}
     */
    async deleteInstance(dbInstanceName: string): Promise<void> {
        localStorage.clear();
        sessionStorage.clear();
        return new Promise((resolve, reject) => {
            const deletion = indexedDB.deleteDatabase(dbInstanceName);

            deletion.onsuccess = () => {
                resolve();
            };

            deletion.onerror = () => {
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                reject(new Error(`Error deleting indexedDB: ${deletion.error}`));
            };
        });
    }

    /**
     * Erase the instance while the user is logged out.
     */
    async eraseWhileLoggedOut(): Promise<void> {
        const dbInstance = getDbInstance();

        setTimeout(() => {
            dbInstance.close();
        }, 1500);

        await this.deleteInstance(dbInstance.name);
    }

    /**
     *  Delete the unopened instance, this happens when the indexDb is not initialized
     * @returns {Promise<void>}
     */
    async deleteUnopenedInstance(): Promise<void> {
        const instance = localStorage.getItem('instance');
        const email = localStorage.getItem('email');

        if (!instance || !email) {
            return;
        }

        const instanceIdHash = await calculateIdHashOfObj({
            $type$: 'Instance',
            name: localStorage.getItem('instance'),
            owner: await calculateIdHashOfObj({
                $type$: 'Person',
                email: email
            })
        } as Instance);

        await this.deleteInstance(`data#${instanceIdHash}`);
    }

    shutdown(): void {
        document.removeEventListener('phoneLocked', this.phoneLockHandle);
    }
}
