import {useEffect, useState} from 'react';
import type {ReactElement} from 'react';
import * as dateFns from 'date-fns';
import {nameOfDaysOfWeek, stringHours} from './CalendarCommon';
import i18n from '../../i18n';
import {getCurrentEventInformation, useEventList} from '../modelHelper/JournalHelper';
import type JournalModel from 'one.models/lib/models/JournalModel';
import type {EventListEntry} from 'one.models/lib/models/JournalModel';
import './Calendar.css';
import '../../Primary.css';
import type {EventTypes} from '../journal/Journal';
import JournalDialog from '../journal/JournalDialog';
import {CircularProgress} from '@material-ui/core';
import {hideCircularProgress} from '../utils/Utils';

/**
 * This component builds and returns the calendar in the day view.
 * @param props - the models used in this component.
 * @param props.journalModel
 * @param props.eventTypes
 * @returns - The calendar in day format
 */
export default function DayCalendarView(props: {
    journalModel: JournalModel;
    eventTypes: EventTypes[];
}): ReactElement {
    const [currentDate, setCurrentDate] = useState(new Date());
    const [selectedHour, setSelectedHour] = useState(0);
    const events = useEventList(props.journalModel);

    const [clickedEvent, setClickedEvent] = useState<EventListEntry>();
    const [clickedEventType, setClickedEventType] = useState('');
    const [dialogState, setDialogState] = useState(false);

    useEffect(() => {
        hideCircularProgress();
    }, [events]);

    /**
     * Provide the icons for each event from events list
     *
     * @param {Date} date - of the day in calendar view
     * @param {number} hour - of the cell.
     * @param {EventTypes[]} eventTypes - The list of event types
     * @returns {ReactElement} - The icons elements witch will be shown in calendar
     */
    function displayQuestionnaireIcon(
        date: Date,
        hour: number,
        eventTypes: EventTypes[]
    ): ReactElement {
        const displayedSurveys = [];

        for (const event of events) {
            if (
                event.data.creationTime &&
                dateFns.isSameDay(event.data.creationTime, date) &&
                event.data.creationTime.getHours() === hour
            ) {
                const currentEventInfo = getCurrentEventInformation(event, eventTypes);

                displayedSurveys.push(
                    <div
                        className="dayCell"
                        onClick={() => {
                            setDialogState(!dialogState);
                            setClickedEventType(currentEventInfo.type);
                            setClickedEvent(event);
                        }}
                    >
                        {currentEventInfo.icon}
                    </div>
                );
            }
        }

        return <div className="survey-icon">{displayedSurveys}</div>;
    }

    /**
     * Builds the calendar element for the day format
     * @param {EventTypes[]} eventTypes - The list of event types
     * @returns {ReactElement} The cells from the calendar view (day format) for each hour from a day
     */
    function buildCalendarDayVersion(eventTypes: EventTypes[]): ReactElement {
        const hours = [];
        const end = dateFns.endOfDay(currentDate);

        let start = dateFns.startOfDay(currentDate).getHours();

        while (start <= end.getHours()) {
            const cloneHour = start;
            hours.push(
                <div className="iconContainer">
                    <div
                        className={`columnDay cellDay ${start === selectedHour ? 'selected' : ''}`}
                        key={start}
                        onClick={() => {
                            setSelectedHour(cloneHour);
                        }}
                    >
                        <div style={{display: 'inline-block'}}>
                            {displayQuestionnaireIcon(currentDate, start, eventTypes)}
                        </div>
                        <div key={start} />
                        <span className="bg">{stringHours[start]}</span>
                        <span className="number">{stringHours[start]}</span>
                    </div>
                </div>
            );
            start++;
        }

        return (
            <div className="body">
                <div>{hours}</div>
            </div>
        );
    }

    return (
        <>
            <div className="circular-progress-container">
                <CircularProgress className="circular-progress" size={35} />
            </div>
            <div className="page-container calendar hide">
                {dialogState && clickedEvent ? (
                    <JournalDialog
                        dialogState={dialogState}
                        setDialogState={setDialogState}
                        event={clickedEvent}
                        type={clickedEventType}
                    />
                ) : (
                    <></>
                )}
                <div className="calendarHeader row flex-middle">
                    <div
                        className="icon_calendar"
                        onClick={() => {
                            setCurrentDate(dateFns.subDays(currentDate, 1));
                        }}
                    >
                        chevron_left
                    </div>
                    <span className="title titleDay">
                        {i18n.t(
                            'common:months.' +
                                dateFns.format(currentDate, 'MMMM yyyy').split(' ')[0]
                        )}
                        {dateFns.format(currentDate, 'MMMM yyyy').split(' ')[1]}
                    </span>
                    <span className="title titleDay">{nameOfDaysOfWeek[currentDate.getDay()]}</span>
                    <div
                        className="icon_calendar"
                        onClick={() => setCurrentDate(dateFns.addDays(currentDate, 1))}
                    >
                        chevron_right
                    </div>
                </div>
                <div>{buildCalendarDayVersion(props.eventTypes)}</div>
            </div>
        </>
    );
}
