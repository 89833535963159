import type SmilerWorkflow from '../../model/studies/SmilerWorkflow';
import type {SmilerVisitTask, SmilerVisitProperties} from '../../model/studies/SmilerWorkflow';
import type ImpactWorkflow from '../../model/studies/ImpactWorkflow';
import type {ImpactVisitProperties, ImpactVisitTask} from '../../model/studies/ImpactWorkflow';
import type SmilerImpactWorkflow from '../../model/studies/SmilerImpactWorkflow';
import type {
    SmilerImpactTasks,
    SmilerImpactVisitProperties
} from '../../model/studies/SmilerImpactWorkflow';
import type {WindowAPI} from './WebViewHelper';
import type {
    SmilerStudyVisit,
    TaskCategories,
    ImpactStudyVisit
} from '../../model/studies/StudyHelper';
import {useEffect, useState} from 'react';

/**
 * Represents the data of the SMILER study.
 */
type SmilerStudyData = {
    visitsProperties: SmilerVisitProperties;
    displayStudyEndedMessage: boolean;
    currentStudyDay: number;
    currentVisit: SmilerStudyVisit | undefined;
    daysUntilNextVisit: number;
    tasks: TaskCategories<SmilerVisitTask>;
    finishedTasks: SmilerVisitTask[];
};

/**
 * Represents the data of the IMPACT study.
 */
type ImpactStudyData = {
    visitsProperties: ImpactVisitProperties;
    displayStudyEndedMessage: boolean;
    currentStudyDay: number;
    currentVisit: ImpactStudyVisit | undefined;
    daysUntilNextVisit: number;
    tasks: TaskCategories<ImpactVisitTask>;
    finishedTasks: ImpactVisitTask[];
    pastVisits: Set<ImpactStudyVisit>;
};

/**
 * Represents the data of the SMILER-IMPACT study.
 */
type SmilerImpactStudyData = {
    visitsProperties: SmilerImpactVisitProperties;
    currentVisit: SmilerStudyVisit | ImpactStudyVisit | undefined;
    daysUntilNextVisit: number;
    currentStudyDay: number;
    visits: (SmilerStudyVisit | ImpactStudyVisit)[];
    tasks: SmilerImpactTasks;
    finishedTasks: (SmilerVisitTask | ImpactVisitTask)[];
};

const NOTIFICATION_SCHEDULER_RETRY_NUMBER = 3 as const;

/**
 * This function is used to get the SMILER study data.
 * @param {SmilerWorkflow} smilerWorkflow - the smiler workflow model.
 * @returns {SmilerStudyData} all the needed data for SMILER study.
 */
export function useSmilerStudyData(smilerWorkflow: SmilerWorkflow): SmilerStudyData {
    // current study day returned by the model is a day within [0,28]
    // but the value that we want do display in the ui should be between 1 and 29
    // => that's the reason of adding 1 to the returned result
    const [visitsProperties, setVisitsProperties] = useState<SmilerStudyData>({
        visitsProperties: smilerWorkflow.getVisitProperties(),
        displayStudyEndedMessage: smilerWorkflow.getDisplayStudyEndedMessage(),
        currentStudyDay: smilerWorkflow.getCurrentStudyDay() + 1,
        currentVisit: smilerWorkflow.getActiveVisit(),
        daysUntilNextVisit: smilerWorkflow.getDaysUntilNextVisit(),
        tasks: smilerWorkflow.getTasks(),
        finishedTasks: smilerWorkflow.getFinishedTasks()
    });

    useEffect(() => {
        function fetchSmilerStudyData(): void {
            setVisitsProperties({
                visitsProperties: smilerWorkflow.getVisitProperties(),
                displayStudyEndedMessage: smilerWorkflow.getDisplayStudyEndedMessage(),
                currentStudyDay: smilerWorkflow.getCurrentStudyDay() + 1,
                currentVisit: smilerWorkflow.getActiveVisit(),
                daysUntilNextVisit: smilerWorkflow.getDaysUntilNextVisit(),
                tasks: smilerWorkflow.getTasks(),
                finishedTasks: smilerWorkflow.getFinishedTasks()
            });
        }

        const disconnect = smilerWorkflow.onUpdated(fetchSmilerStudyData);
        fetchSmilerStudyData();

        return disconnect;
    }, [smilerWorkflow]);

    return visitsProperties;
}

/**
 * This function is used to get the IMPACT study data.
 * @param impactWorkflow - the impact workflow model.
 * @returns - all the needed data for IMPACT study.
 */
export function useImpactStudyData(impactWorkflow: ImpactWorkflow): ImpactStudyData {
    // current study day returned by the model is a day within [0,28]
    // but the value that we want do display in the ui should be between 1 and 29
    // => that's the reason of adding 1 to the returned result
    const [visitsProperties, setVisitsProperties] = useState<ImpactStudyData>({
        visitsProperties: impactWorkflow.getVisitProperties(),
        displayStudyEndedMessage: impactWorkflow.getDisplayStudyEndedMessage(),
        currentStudyDay: impactWorkflow.getCurrentStudyDay() + 1,
        currentVisit: impactWorkflow.getActiveVisit(),
        daysUntilNextVisit: impactWorkflow.getDaysUntilNextVisit(),
        tasks: impactWorkflow.getTasks(),
        finishedTasks: impactWorkflow.getFinishedTasks(),
        pastVisits: impactWorkflow.getPastVisits()
    });

    useEffect(() => {
        function fetchImpactStudyData(): void {
            setVisitsProperties({
                visitsProperties: impactWorkflow.getVisitProperties(),
                displayStudyEndedMessage: impactWorkflow.getDisplayStudyEndedMessage(),
                currentStudyDay: impactWorkflow.getCurrentStudyDay() + 1,
                currentVisit: impactWorkflow.getActiveVisit(),
                daysUntilNextVisit: impactWorkflow.getDaysUntilNextVisit(),
                tasks: impactWorkflow.getTasks(),
                finishedTasks: impactWorkflow.getFinishedTasks(),
                pastVisits: impactWorkflow.getPastVisits()
            });
        }

        const disconnect = impactWorkflow.onUpdated(fetchImpactStudyData);
        fetchImpactStudyData();

        return disconnect;
    }, [impactWorkflow]);

    return visitsProperties;
}

/**
 * This function is used to get the SMILER-IMPACT study data.
 * @param {SmilerImpactWorkflow} smilerImpactWorkflow - the SMILER-IMPACT workflow model.
 * @returns {SmilerImpactStudyData} all the needed data for SMILER-IMPACT study.
 */
export function useSmilerImpactStudyData(
    smilerImpactWorkflow: SmilerImpactWorkflow
): SmilerImpactStudyData {
    const [visitsProperties, setVisitsProperties] = useState<SmilerImpactStudyData>({
        visitsProperties: smilerImpactWorkflow.getSmilerImpactVisitProperties(),
        currentVisit: smilerImpactWorkflow.calculateCurrentVisit(),
        daysUntilNextVisit: smilerImpactWorkflow.calculateDaysUntilNextVisit(),
        currentStudyDay: smilerImpactWorkflow.calculateCurrentDayOfTheStudy(),
        visits: smilerImpactWorkflow.getVisits(),
        tasks: smilerImpactWorkflow.getTasks(),
        finishedTasks: smilerImpactWorkflow.getFinishedTasks()
    });

    useEffect(() => {
        function fetchSmilerImpactStudyData(): void {
            setVisitsProperties({
                visitsProperties: smilerImpactWorkflow.getSmilerImpactVisitProperties(),
                currentVisit: smilerImpactWorkflow.calculateCurrentVisit(),
                daysUntilNextVisit: smilerImpactWorkflow.calculateDaysUntilNextVisit(),
                currentStudyDay: smilerImpactWorkflow.calculateCurrentDayOfTheStudy(),
                visits: smilerImpactWorkflow.getVisits(),
                tasks: smilerImpactWorkflow.getTasks(),
                finishedTasks: smilerImpactWorkflow.getFinishedTasks()
            });
        }

        const disconnect = smilerImpactWorkflow.onUpdated(fetchSmilerImpactStudyData);
        fetchSmilerImpactStudyData();

        return disconnect;
    }, [smilerImpactWorkflow]);

    return visitsProperties;
}

/**
 * Used to schedule the notifications for Smiler and Impact studies.
 * @param windowAPI -  the windowAPI object to know if we are on the required device.
 * @param notifications - an array of notifications that will be scheduled.
 * @param retryCounter - number of retries if the scheduler failed.
 */
export function scheduleStudyNotification(
    windowAPI: WindowAPI,
    notifications: {title: string; body: string; timestamp: number}[],
    retryCounter: number
): void {
    const notificationScheduledFlag = localStorage.getItem('notificationsScheduled');

    if (notificationScheduledFlag === 'true') {
        return;
    }

    windowAPI
        .scheduleNotifications(notifications)
        .then(() => {
            localStorage.setItem('notificationsScheduled', 'true');
        })
        .catch(() => {
            if (retryCounter < NOTIFICATION_SCHEDULER_RETRY_NUMBER) {
                scheduleStudyNotification(windowAPI, notifications, ++retryCounter);
            }
        });
}
