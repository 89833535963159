import {Button} from '@material-ui/core';
import type {ReactElement} from 'react';
import i18n from '../../i18n';
import {useEffect, useState} from 'react';
import * as dateFns from 'date-fns';

export const DATA_RANGE = {
    Week: 'week',
    Month: 'month',
    Year: 'year'
} as const;

/** The type definition based on the DATA_RANGE value. **/
export type FilterRange = typeof DATA_RANGE[keyof typeof DATA_RANGE];

/**
 * Represents the filters of the graph. Currently there are 3 filters: week, month and year.
 * The displayed data will be within the following range: [latest added data.timestamp - active
 * filter (one week, one month or one year); latest added data.timestamp].
 * @param props
 * @param props.intervalEndDate - the timestamp that represents the end of the interval.
 * @param props.setActiveFilterCallback - callback to set the active filter of the graph
 * whenever another filter is selected.
 * @param props.setStartDateCallback - callback to adjust the start date of the interval when
 * the filter is changed.
 */
export default function Filters(props: {
    intervalEndDate: number;
    setActiveFilterCallback: (newFilter: FilterRange) => void;
    setStartDateCallback: (startDate: number) => void;
}): ReactElement {
    /** active filter state **/
    const [activeFilter, setActiveFilter] = useState<FilterRange>(DATA_RANGE.Week);
    const {intervalEndDate, setActiveFilterCallback, setStartDateCallback} = props;

    useEffect(() => {
        setActiveFilterCallback(activeFilter);

        switch (activeFilter) {
            case DATA_RANGE.Week:
                setStartDateCallback(dateFns.subWeeks(intervalEndDate, 1).getTime());
                break;
            case DATA_RANGE.Month:
                setStartDateCallback(dateFns.subMonths(intervalEndDate, 1).getTime());
                break;
            case DATA_RANGE.Year:
                setStartDateCallback(dateFns.subYears(intervalEndDate, 1).getTime());
                break;
            default:
                break;
        }
    }, [activeFilter, intervalEndDate, setActiveFilterCallback, setStartDateCallback]);

    /**
     * When the filters are displayed for the first time week filter is active.
     */
    useEffect(() => {
        setActiveFilter(DATA_RANGE.Week);
    }, []);

    return (
        <div className="buttons-container">
            {Object.values(DATA_RANGE).map(value => {
                return (
                    <Button
                        variant="contained"
                        color={activeFilter === value ? 'primary' : 'default'}
                        key={value}
                        className="button button-margin-left"
                        onClick={() => {
                            setActiveFilter(value);
                        }}
                    >
                        {i18n.t(`common:date.${value}`)}
                    </Button>
                );
            })}
        </div>
    );
}
