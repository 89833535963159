import {useEffect, useState} from 'react';
import type {ChangeEvent, ReactElement} from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import MenuButton from '../menu/MenuButton';
import * as dateFns from 'date-fns';
import i18n from '../../i18n';
import './BloodGlucoseTable.css';
import type BloodGlucoseModel from 'one.models/lib/models/BloodGlucoseModel';
import type {ObjectData} from 'one.models/lib/models/ChannelManager';
import {Order} from 'one.models/lib/models/ChannelManager';
import type {BloodGlucose} from 'one.models/lib/recipes/BloodGlucoseRecipes';

type BloodGlucoseColumn = {
    id: 'startTimestamp' | 'endTimestamp' | 'value';
    format?: (value: number) => string;
};

const columns: BloodGlucoseColumn[] = [
    {
        id: 'startTimestamp',
        format: (value: number) => dateFns.format(value, 'yyyy-MM-dd HH:mm:ss')
    },
    {
        id: 'endTimestamp',
        format: (value: number) => dateFns.format(value, 'yyyy-MM-dd HH:mm:ss')
    },
    {id: 'value'}
];

function useBloodGlucoseMeasurements(
    bloodGlucoseModel: BloodGlucoseModel
): ObjectData<BloodGlucose>[] {
    const [responses, setResponses] = useState<ObjectData<BloodGlucose>[]>([]);

    useEffect(() => {
        /**
         * Proceed the call to the model to retrieve all the blood glucose measurements in
         * ascending order.
         */
        function fetchBloodGlucoseMeasurements(): void {
            void bloodGlucoseModel
                .retrieveWithQueryOptions({orderBy: Order.Descending})
                .then((value: ObjectData<BloodGlucose>[]) => {
                    setResponses(value);
                });
        }

        const disconnect = bloodGlucoseModel.onUpdated(fetchBloodGlucoseMeasurements);
        fetchBloodGlucoseMeasurements();

        return disconnect;
    }, [bloodGlucoseModel]);

    return responses;
}

/**
 * Table containing the blood glucose measurements.
 * @param props
 * @param props.bloodGlucoseModel
 */
export default function BloodGlucoseTable(props: {
    bloodGlucoseModel: BloodGlucoseModel;
}): ReactElement {
    const bloodGlucoseMeasurements = useBloodGlucoseMeasurements(props.bloodGlucoseModel);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    /**
     * Changing the page of the table.
     * @param newPage - new page that will be displayed within the table.
     */
    function handleChangePage(newPage: number): void {
        setPage(newPage);
    }

    /**
     * Changing the number of the displayed rows of the table.
     * @param event
     */
    function handleChangeRowsPerPage(event: ChangeEvent<HTMLInputElement>): void {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }

    return (
        <div className="page-container">
            <div className="menu-button-header">
                <MenuButton />
                <h2 className="headline"> {i18n.t('common:menu.bloodGlucose')}</h2>
            </div>
            <Paper className="prevent-footer-overlap">
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map(column => (
                                    <TableCell key={column.id} className="glucose-header-cell">
                                        {i18n.t(`bloodGlucose:${column.id}`)}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {bloodGlucoseMeasurements
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                        {columns.map((column, idx) => {
                                            const value = row.data[column.id];
                                            return (
                                                <TableCell key={idx}>
                                                    {column.format !== undefined &&
                                                    value !== undefined
                                                        ? column.format(value)
                                                        : value}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    className="table-pagination-footer"
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    count={bloodGlucoseMeasurements.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(_, newPage: number) => handleChangePage(newPage)}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    );
}
