import type {ReactElement} from 'react';
import Paper from '@material-ui/core/Paper';
import {Alert, AlertTitle} from '@material-ui/lab';
import i18n from '../../i18n';
import {Button, TextField} from '@material-ui/core';
import type OneInstanceModel from '../../model/OneInstanceModel';
import InfoMessage, {MESSAGE_TYPE} from '../errors/InfoMessage';
import './ConnectionErrorViews.css';
import {useHistory} from 'react-router-dom';
import {isStandalone} from '../utils/Utils';
import {useState} from 'react';

/**
 * This component implement all error views in case invitation failed
 * Cases:
 *      -> Your Devices : SmilerOverview error view and web view
 */

/**
 * Renders the error page if the connection between your devices did
 * not succeed. Depending on whether or not it is a standalone application(a application saved on home screen),
 * setting an appropriate error message and hiding how to re-enter the password.
 *
 * @param props - properties of the view.
 * @param props.oneInstanceModel
 *
 * @returns
 */
export function ConnectionClosedIoM(props: {oneInstanceModel: OneInstanceModel}): ReactElement {
    const [secret, setSecret] = useState('');
    const [errorState, setErrorState] = useState('');
    const history = useHistory();
    // TODO: Remove the patientType, after restructuring of the oneInstanceModel functions to allow for type undefined. It just does not make sense to pass it from the ui.
    const patientType = 'patient';

    /**
     * Delete unopened instance and redirect to homepage
     *
     * @param {OneInstanceModel} oneInstanceModel
     * @returns {Promise<void>}
     */
    async function deleteInstanceOnErrorView(oneInstanceModel: OneInstanceModel): Promise<void> {
        await oneInstanceModel.deleteUnopenedInstance();
        history.push('/');
        window.location.reload();
    }

    async function onLogin(): Promise<void> {
        try {
            await props.oneInstanceModel.login(secret, patientType, true);
            localStorage.setItem('appBrokeOnInvite', '');
        } catch (err) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
            if (err.message.includes('SC-INIT1')) {
                setErrorState('passwordNotValid');
            } else {
                setErrorState('errors:unknownError');
            }
        }
    }

    return (
        <>
            <InfoMessage
                errorMessage={errorState}
                setDisplayMessage={setErrorState}
                messageType={MESSAGE_TYPE.Warning}
            />
            <div className="product-type-wrapper">
                <Paper
                    square
                    className="message-font-size error-color  error-message-box color-box connection-iom-close-alert"
                >
                    <Alert className="patient-info" severity="error" key="alert">
                        <AlertTitle className="message-title">
                            {i18n.t('errors:titleError')}
                        </AlertTitle>
                        {isStandalone() ? (
                            <p>
                                {i18n.t('errors:connection.connectionClosedYourDevicesHomeScreen')}
                            </p>
                        ) : (
                            <p>{i18n.t('errors:connection.connectionClosedYourDevicesWebApp')}</p>
                        )}
                        <TextField
                            className="password-text-field"
                            label={i18n.t('login:password')}
                            type="password"
                            value={secret}
                            onChange={e => {
                                setSecret(e.target.value);
                            }}
                            onKeyPress={async event => {
                                if (event.key === 'Enter') {
                                    await onLogin();
                                }
                            }}
                            required
                        />
                        <div className="connection-iom-closed-buttons">
                            <Button
                                onClick={async () => {
                                    await onLogin();
                                }}
                                color="primary"
                                variant="contained"
                            >
                                {i18n.t('common:buttons.reenter')}
                            </Button>
                            {isStandalone() ? (
                                <></>
                            ) : (
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={async () => {
                                        await deleteInstanceOnErrorView(props.oneInstanceModel);
                                    }}
                                >
                                    {i18n.t('common:buttons.deleteAvailableData')}
                                </Button>
                            )}
                        </div>
                    </Alert>
                </Paper>
            </div>
        </>
    );
}
