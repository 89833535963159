import type ECGModel from 'one.models/lib/models/ECGModel';
import windowAPI, {WindowAPI} from './WebViewHelper';
import type {Electrocardiogram} from 'one.models/lib/recipes/ECGRecipes';

/**
 * The class offers the synchronization features for ECG data between the native part (iOS/Android)
 * and JS, through the WebView.
 */
export class ECGDataSynchronizer {
    private ecgModel: ECGModel;

    /**
     * Number of ECGs to be fetched from native in one JSON.
     * @private
     */
    private numberOfSamples = 10;

    private TIMEOUT_PERIOD = 5 * 1000;

    /**
     * Flag specifying if synchronization is already in progress.
     * @private
     */
    private isSyncing = false;

    private startSyncWithNativeCalled = false;

    private retryOnErrorCounter = 0;

    private retryTimeout: number | undefined;

    private ecgWithTSfailed: number | undefined;

    private userStoppedSynchronization = false;

    private readonly syncMethod: () => void;

    public constructor(ecgModel: ECGModel) {
        this.ecgModel = ecgModel;

        this.syncMethod = () => {
            void this.syncRemainingECGs();
        };
    }

    public isECGSynchronizationAvailable(): boolean {
        return windowAPI !== undefined;
    }

    /**
     * Starts the synchronisation mechanism. ECGs will be fetched from the native side and posted
     * in into the channel.
     */
    public startECGSynchronizationWithNative(): void {
        if (this.startSyncWithNativeCalled) {
            throw new Error('startECGSynchronizationWithNative has already been called');
        }

        if (!windowAPI) {
            throw new Error('WindowAPI is not available');
        }

        this.userStoppedSynchronization = false;

        windowAPI.log('startECGSynchronizationWithNative', WindowAPI.LOG_LEVELS.INFO);
        void this.syncRemainingECGs();

        // register for new ECG events
        document.addEventListener('newECGData', this.syncMethod);
    }

    /**
     * This will stop the ECGDataSynchronizer listening for new ECG events.
     * NOTE: The current sync in progress will continue.
     */
    public stopECGSynchronizationWithNative(): void {
        if (windowAPI !== undefined) {
            windowAPI.log('stopECGSynchronizationWithNative', WindowAPI.LOG_LEVELS.INFO);
        }

        document.removeEventListener('newECGData', this.syncMethod);

        if (this.retryTimeout) {
            window.clearTimeout(this.retryTimeout);
        }

        this.userStoppedSynchronization = true;
        this.startSyncWithNativeCalled = false;
        this.isSyncing = false;
    }

    // ---------------------------- Utilities ----------------------------

    private async syncRemainingECGs(): Promise<void> {
        try {
            if (!windowAPI) {
                return;
            }

            if (this.isSyncing) {
                // ignore, because the current sync will include the latest ecg
                windowAPI.log(
                    'Ignored sync event. Synchronization already in progress.',
                    WindowAPI.LOG_LEVELS.INFO
                );
                return;
            }

            this.isSyncing = true;

            let lastECGStartTime = await this.ecgModel.getLastECGTimestamp();

            if (this.retryOnErrorCounter === 2 && this.ecgWithTSfailed) {
                // failed 3 times for one ecg, move forward
                lastECGStartTime = this.ecgWithTSfailed;
                this.retryOnErrorCounter = 0;

                // the failing ECG was skipped, get 10 samples at once again
                this.numberOfSamples = 10;
            }

            let ecgs: Electrocardiogram[] = [];

            try {
                ecgs = await windowAPI.getECGsAfterTS(lastECGStartTime, this.numberOfSamples);
            } catch (e) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                if (e.name !== 'InvalidECGError') {
                    // if it's not our customized error (because of in invalid ecg), throw it further
                    throw e;
                }

                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
                this.ecgWithTSfailed = e.failingTS;
                this.retryOnErrorCounter++;
                this.retryTimeout = window.setTimeout(
                    () => this.syncRemainingECGs(),
                    this.TIMEOUT_PERIOD
                );

                /**
                 * one of the fetched ECGs is invalid, but maybe not the exactly following
                 * one, so start fetching one by one (when it reaches the invalid one, it
                 * will fail to fetch it 3 times, therefore only the invalid ECG will be
                 * skipped)
                 */
                this.numberOfSamples = 1;
            }

            if (this.ecgWithTSfailed && lastECGStartTime > this.ecgWithTSfailed) {
                this.numberOfSamples = 10;
            }

            for (const ecg of ecgs) {
                if (this.userStoppedSynchronization) {
                    // stopSync was called
                    this.isSyncing = false;
                    break;
                }

                try {
                    // needed, ecgs must be posted in the received order
                    // eslint-disable-next-line no-await-in-loop
                    await this.ecgModel.postECG({
                        $type$: 'Electrocardiogram',
                        symptoms: ecg.symptoms,
                        averageHeartRateBPM: ecg.averageHeartRateBPM,
                        classification: ecg.classification,
                        samplingFrequencyHz: ecg.samplingFrequencyHz,
                        endTimestamp: ecg.endTimestamp,
                        startTimestamp: ecg.startTimestamp,
                        voltageMeasurements: ecg.voltageMeasurements,
                        readings: ecg.readings,
                        typeDescription: ecg.typeDescription
                    });
                    windowAPI.log('The electrocardiogram was saved', WindowAPI.LOG_LEVELS.INFO);
                    this.retryOnErrorCounter = 0;
                } catch {
                    windowAPI.log(
                        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                        'The electrocardiogram could not be saved ' + ecg.startTimestamp,
                        WindowAPI.LOG_LEVELS.ERROR
                    );

                    this.retryOnErrorCounter++;

                    if (this.retryOnErrorCounter === 2 && this.ecgWithTSfailed) {
                        this.ecgWithTSfailed = ecg.startTimestamp;
                    }

                    this.retryTimeout = window.setTimeout(
                        () => this.syncRemainingECGs(),
                        this.TIMEOUT_PERIOD
                    );
                }
            }

            if (ecgs.length === this.numberOfSamples) {
                // Received same number of ecgs as asked, check if more available
                this.isSyncing = false;
                await this.syncRemainingECGs();
            } else {
                this.isSyncing = false;
                windowAPI.log('ECGs data is up-to-date.', WindowAPI.LOG_LEVELS.INFO);
            }
        } catch (e) {
            console.error(e);
        }
    }
}
