import AccessModel from 'one.models/lib/models/AccessModel';
import BodyTemperatureModel from 'one.models/lib/models/BodyTemperatureModel';
import ConnectionsModel from 'one.models/lib/models/ConnectionsModel';
import ConsentFileModel from 'one.models/lib/models/ConsentFileModel';
import ContactModel from 'one.models/lib/models/ContactModel';
import DiaryModel from 'one.models/lib/models/DiaryModel';
import DocumentModel from 'one.models/lib/models/DocumentModel';
import ECGModel from 'one.models/lib/models/ECGModel';
import HeartEventModel from 'one.models/lib/models/HeartEventModel';
import JournalModel, {EventType} from 'one.models/lib/models/JournalModel';
import NewsModel from 'one.models/lib/models/NewsModel';
import type PropertyTree from 'one.models/lib/models/SettingsModel';
import PropertyTreeStore from 'one.models/lib/models/SettingsModel';
import QuestionnaireModel from 'one.models/lib/models/QuestionnaireModel';
import RecoveryModel from 'one.models/lib/models/RecoveryModel';
import WbcDiffModel from 'one.models/lib/models/WbcDiffModel';
import ChannelManager from 'one.models/lib/models/ChannelManager';
import OneInstanceModel from './OneInstanceModel';
import InstancesModel from 'one.models/lib/models/InstancesModel';
import SmilerAccessRightsManager, {SmilerAccessGroups} from './SmilerAccessRightsManager';
import type {Person} from 'one.core/lib/recipes';
import type {SHA256IdHash} from 'one.core/lib/util/type-checks';
import RecipesStable from 'one.models/lib/recipes/recipes-stable';
import RecipesExperimental from 'one.models/lib/recipes/recipes-experimental';
import {QuestionnaireEQ5D3L as QuestionnaireEQ5D3L_de} from './questionnaires/de/QuestionnaireEQ5D3L';
import {QuestionnaireEQ5D3L_old as QuestionnaireEQ5D3L_de_old} from './questionnaires/de/QuestionnaireEQ5D3L_old';
import {PIF_Questionnaire as QuestionnairePIF_en} from './questionnaires/en/PIF_Questionnaire';

import type {Questionnaire} from 'one.models/lib/models/QuestionnaireModel';
import {ECGDataSynchronizer} from '../ui/modelHelper/ECGDataSynchronizer';
import SmilerWorkflow from './studies/SmilerWorkflow';
import WBCDataParser from './WBCDataParser';
import type {QueryOptions} from 'one.models/src/models/ChannelManager';
import AudioExerciseModel from 'one.models/lib/models/AudioExerciseModel';
import DataSyncManager from './DataSyncManager';
import {ReportModel, ReportRecipes} from '../one.smiler.replicant-dependencies/Minimal-ReportModel';
import ImpactWorkflow from './studies/ImpactWorkflow';
import SmilerImpactWorkflow from './studies/SmilerImpactWorkflow';
import BloodGlucoseModel from 'one.models/lib/models/BloodGlucoseModel';

/* import * as logger from 'one.core/lib/logger';
logger.start({
    includeInstanceName: false,
    types: ['error', 'alert', 'log']
});*/

/**
 * List with available questionnaires
 */
const questionnaires: Questionnaire[] = [
    QuestionnaireEQ5D3L_de,
    QuestionnaireEQ5D3L_de_old,
    QuestionnairePIF_en
];

export default class Model {
    constructor(commServerUrl: string) {
        // Setup basic models
        this.accessModel = new AccessModel();
        this.channelManager = new ChannelManager(this.accessModel);
        this.instancesModel = new InstancesModel();
        this.contactModel = new ContactModel(this.instancesModel, commServerUrl, false);
        this.connections = new ConnectionsModel(this.contactModel, this.instancesModel, {
            commServerUrl,
            acceptIncomingConnections: true,
            acceptUnknownInstances: true,
            acceptUnknownPersons: false,
            allowOneTimeAuth: true,
            authTokenExpirationDuration: 60000 * 15, // qr-code (invitation) timeout
            establishOutgoingConnections: true,
            connectToOthersWithAnonId: false
        });
        this.settings = new PropertyTreeStore('Settings', '.');

        // Setup freeda specific models
        this.consentFile = new ConsentFileModel(this.channelManager);
        this.oneInstance = new OneInstanceModel(
            this.channelManager,
            this.consentFile,
            this.accessModel,
            [...RecipesStable, ...RecipesExperimental, ...ReportRecipes]
        );
        this.smilerAccessRightsManager = new SmilerAccessRightsManager(
            this.accessModel,
            this.channelManager,
            this.contactModel
        );
        this.recoveryModel = new RecoveryModel(this.connections);

        // Setup data manging models
        this.questionnaires = new QuestionnaireModel(this.channelManager);
        this.wbcDiffs = new WbcDiffModel(this.channelManager);
        this.heartEvents = new HeartEventModel(this.channelManager);
        this.documents = new DocumentModel(this.channelManager);
        this.diary = new DiaryModel(this.channelManager);
        this.bodyTemperature = new BodyTemperatureModel(this.channelManager);
        this.news = new NewsModel(this.channelManager);
        this.ecgModel = new ECGModel(this.channelManager);
        this.ecgDataSynchronizer = new ECGDataSynchronizer(this.ecgModel);
        this.bloodGlucoseModel = new BloodGlucoseModel(this.channelManager);
        this.audioExercise = new AudioExerciseModel(this.channelManager);

        /** commented because is not needed for the moment **/
        /*
            this.bloodGlucoseDataSynchronizer = new BloodGlucoseDataSynchronizer(
                this.bloodGlucoseModel
            );
        */

        this.journal = new JournalModel([
            {
                model: this.wbcDiffs,
                retrieveFn: (queryOptions?: QueryOptions) =>
                    this.wbcDiffs.observationsIterator(queryOptions),
                eventType: EventType.WbcDiffMeasurement
            },
            {
                model: this.questionnaires,
                retrieveFn: (queryOptions?: QueryOptions) =>
                    this.questionnaires.responsesIterator(queryOptions),
                eventType: EventType.QuestionnaireResponse
            },
            {
                model: this.heartEvents,
                retrieveFn: (queryOptions?: QueryOptions) =>
                    this.heartEvents.heartEventsIterator(queryOptions),
                eventType: EventType.HeartEvent
            },
            {
                model: this.documents,
                retrieveFn: (queryOptions?: QueryOptions) =>
                    this.documents.documentsIterator(queryOptions),
                eventType: EventType.DocumentInfo
            },
            {
                model: this.diary,
                retrieveFn: (queryOptions?: QueryOptions) =>
                    this.diary.entriesIterator(queryOptions),
                eventType: EventType.DiaryEntry
            },
            {
                model: this.bodyTemperature,
                retrieveFn: (queryOptions?: QueryOptions) =>
                    this.bodyTemperature.bodyTemperaturesIterator(queryOptions),
                eventType: EventType.BodyTemperature
            },
            {
                model: this.consentFile,
                retrieveFn: (queryOptions?: QueryOptions) =>
                    this.consentFile.entriesIterator(queryOptions),
                eventType: EventType.ConsentFileEvent
            },
            {
                model: this.ecgModel,
                retrieveFn: (queryOptions?: QueryOptions) =>
                    this.ecgModel.electrocardiogramsIterator({...queryOptions, omitData: true}),
                eventType: EventType.ECGEvent
            },
            {
                model: this.audioExercise,
                retrieveFn: (queryOptions?: QueryOptions) =>
                    this.audioExercise.audioExercisesIterator(queryOptions),
                eventType: EventType.AudioExercise
            }
        ]);

        this.smilerWorkflow = new SmilerWorkflow(
            this.ecgModel,
            this.wbcDiffs,
            this.documents,
            this.questionnaires,
            this.audioExercise,
            this.channelManager
        );

        this.impactWorkflow = new ImpactWorkflow(
            this.ecgModel,
            this.wbcDiffs,
            this.documents,
            this.questionnaires,
            this.audioExercise,
            this.channelManager
        );

        this.smilerImpactWorkflow = new SmilerImpactWorkflow(
            this.smilerWorkflow,
            this.impactWorkflow
        );

        this.wbcDataParser = new WBCDataParser(this.wbcDiffs);
        this.dataSyncManager = new DataSyncManager(
            this.connections,
            this.questionnaires,
            this.wbcDiffs,
            this.ecgModel,
            this.documents
        );

        // Setup event handler that initialize the models when somebody logged in
        // and shuts down the model when somebody logs out.
        this.oneInstance.onLogin(this.init.bind(this));
        this.oneInstance.onLogout(this.shutdown.bind(this));
    }

    /**
     * Initialize all the models.
     *
     * TODO: Note that if any of the model initialization fails we would need to shutdown all
     * the models!
     * @param {boolean} registrationState
     * @param {string} anonymousEmail
     * @param {boolean} takeOver
     * @param recoveryState
     * @returns {Promise<void>}
     */
    public async init(
        registrationState: boolean,
        anonymousEmail?: string,
        takeOver?: boolean,
        recoveryState?: boolean
    ): Promise<void> {
        try {
            const secret = this.oneInstance.getSecret();

            /**
             * In instance take over and in recovery process the main person and
             * the anonymous person keys will be overwritten, so the first generated
             * keys can be ignored, because they will not be used after the overwrite
             * process is completed.
             *
             * This is just a temporary workaround! (only a hack!)
             */
            const ownerWillBeOverwritten = takeOver || recoveryState;

            // Initialize contact model. This is the base for identity handling and everything
            await this.contactModel.init(ownerWillBeOverwritten);
            await this.accessModel.init();
            await this.instancesModel.init(secret);

            // Setup the identities
            const {anonymousId} = await this.setupMyIds(anonymousEmail, ownerWillBeOverwritten);
            this.consentFile.setPersonId(anonymousId);

            // Initialize the rest of the models
            await this.channelManager.init();
            await this.consentFile.init();
            await this.news.init();
            await this.questionnaires.init();
            this.questionnaires.registerQuestionnaires(questionnaires);
            await this.diary.init();
            await this.bodyTemperature.init();
            await this.settings.init();
            this.connections.setPassword(secret);
            await this.wbcDiffs.init();
            this.recoveryModel.setPassword(secret);
            await this.ecgModel.init();
            await this.bloodGlucoseModel.init();
            await this.audioExercise.init();

            // creating the channel for the report model
            await this.channelManager.createChannel(ReportModel.actionChannelId);

            await this.impactWorkflow.init();
            await this.smilerWorkflow.init();
            this.smilerImpactWorkflow.init();
            this.wbcDataParser.init();

            if (this.ecgDataSynchronizer.isECGSynchronizationAvailable()) {
                this.ecgDataSynchronizer.startECGSynchronizationWithNative();
            }

            /** commented because is not needed for the moment **/
            // if (this.bloodGlucoseDataSynchronizer.isBloodGlucoseSynchronizationAvailable()) {
            //     this.bloodGlucoseDataSynchronizer.startBloodGlucoseSynchronizationWithNative();
            // }

            await this.journal.init();

            if (recoveryState) {
                // In the recovery process the person keys have to be overwritten because the
                // instance has to be recreated with the old information received in the url.
                await this.recoveryModel.overwritePersonKeyWithReceivedEncryptedOnes();

                // Try to connect with the clinic in order to recover data before staring the
                // applications. This will try to establish a connection to the clinic and if
                // no connection was established the application will be initialised without
                // the recovered data.
                const clinicPersons = await this.accessModel.getAccessGroupPersons(
                    SmilerAccessGroups.clinic
                );

                try {
                    await Promise.all(
                        clinicPersons.map(async replicantPerson => {
                            await this.connections.connectOneTime(replicantPerson, 1000, 10 * 1000);
                        })
                    );
                } catch (e) {
                    if (
                        e.message ===
                        'The connection could not be established before the timeout was reached!'
                    ) {
                        // timeout exceed and data was not recovered
                    }
                    console.error(e);
                }
            }

            await this.connections.init();
            await this.documents.init();
            await this.heartEvents.init();
            await this.smilerAccessRightsManager.init(); // is this the correct location?
            this.dataSyncManager.init();
            this.oneInstance.init();
        } catch (e) {
            // Shutdown all models when initialization failed.
            // Shutdown should not throw, even if models were not initialized.
            // So this call should never throw. If it throws we should return the
            // original error, not the one from shutdown, because otherwise the original
            // problem will be obfuscated. => console.error is ok here. Perhaps later we
            // should emit it as error event when we have a proper setup how to handle those.
            await this.shutdown().catch(console.error);
            throw e;
        }
    }

    /**
     * Shutdown the models.
     *
     * @returns {Promise<void>}
     */
    public async shutdown(): Promise<void> {
        try {
            await this.smilerAccessRightsManager.shutdown();
        } catch (e) {
            console.error(e);
        }

        try {
            this.dataSyncManager.shutdown();
        } catch (e) {
            console.error(e);
        }

        try {
            this.smilerWorkflow.shutdown();
        } catch (e) {
            console.error(e);
        }

        try {
            this.impactWorkflow.shutdown();
        } catch (e) {
            console.error(e);
        }

        try {
            this.smilerImpactWorkflow.shutdown();
        } catch (e) {
            console.error(e);
        }

        try {
            await this.connections.shutdown();
        } catch (e) {
            console.error(e);
        }

        try {
            await this.diary.shutdown();
        } catch (e) {
            console.error(e);
        }

        try {
            await this.questionnaires.shutdown();
        } catch (e) {
            console.error(e);
        }

        try {
            await this.news.shutdown();
        } catch (e) {
            console.error(e);
        }

        try {
            await this.consentFile.shutdown();
        } catch (e) {
            console.error(e);
        }

        try {
            await this.channelManager.shutdown();
        } catch (e) {
            console.error(e);
        }

        try {
            await this.contactModel.shutdown();
        } catch (e) {
            console.error(e);
        }

        try {
            await this.documents.shutdown();
        } catch (e) {
            console.error(e);
        }

        try {
            this.wbcDataParser.shutdown();
        } catch (e) {
            console.error(e);
        }

        try {
            await this.wbcDiffs.shutdown();
        } catch (e) {
            console.error(e);
        }

        this.ecgDataSynchronizer.stopECGSynchronizationWithNative();

        try {
            await this.ecgModel.shutdown();
        } catch (e) {
            console.error(e);
        }

        try {
            this.journal.shutdown();
        } catch (e) {
            console.error(e);
        }

        try {
            await this.heartEvents.shutdown();
        } catch (e) {
            console.error(e);
        }

        try {
            await this.audioExercise.shutdown();
        } catch (e) {
            console.error(e);
        }

        /** commented because is not needed for the moment **/
        // this.bloodGlucoseDataSynchronizer.stopBloodGlucoseSynchronizationWithNative();

        try {
            await this.bloodGlucoseModel.shutdown();
        } catch (e) {
            console.error(e);
        }

        this.oneInstance.shutdown();
    }

    /**
     * Sets up my own ids.
     *
     * For this project it is just a main id and an anonymous one.
     *
     * TODO: remove the takeover flag when the hack is removed
     *
     * @param {string} anonymousEmail - If specified use this email instead of a random one for the anon id.
     * @param {boolean} takeOver - On takeover omit the public person key from the contact object (only a hack!)
     * @returns {Promise<{mainId: SHA256IdHash<Person>; anonymousId: SHA256IdHash<Person>}>}
     */
    private async setupMyIds(
        anonymousEmail?: string,
        takeOver?: boolean
    ): Promise<{mainId: SHA256IdHash<Person>; anonymousId: SHA256IdHash<Person>}> {
        // Setup identities if necessary
        let anonymousId;
        const mainId = await this.contactModel.myMainIdentity();
        const myIdentities = await this.contactModel.myIdentities();

        if (myIdentities.length === 2) {
            anonymousId = myIdentities[0] === mainId ? myIdentities[1] : myIdentities[0];
        } else if (anonymousEmail) {
            anonymousId = await this.contactModel.createNewIdentity(true, anonymousEmail, takeOver);
        } else {
            anonymousId = await this.contactModel.createNewIdentity(true);
        }

        return {
            mainId,
            anonymousId
        };
    }

    public channelManager: ChannelManager;
    public contactModel: ContactModel;
    public journal: JournalModel;
    public questionnaires: QuestionnaireModel;
    public wbcDiffs: WbcDiffModel;
    public heartEvents: HeartEventModel;
    public documents: DocumentModel;
    public news: NewsModel;
    public oneInstance: OneInstanceModel;
    public connections: ConnectionsModel;
    public diary: DiaryModel;
    public bodyTemperature: BodyTemperatureModel;
    public consentFile: ConsentFileModel;
    public settings: PropertyTree;
    public accessModel: AccessModel;
    public instancesModel: InstancesModel;
    public smilerAccessRightsManager: SmilerAccessRightsManager;
    public recoveryModel: RecoveryModel;
    public ecgModel: ECGModel;
    public ecgDataSynchronizer: ECGDataSynchronizer;
    public bloodGlucoseModel: BloodGlucoseModel;
    /** commented because is not needed for the moment **/
    // public bloodGlucoseDataSynchronizer: BloodGlucoseDataSynchronizer;
    public smilerWorkflow: SmilerWorkflow;
    public wbcDataParser: WBCDataParser;
    public dataSyncManager: DataSyncManager;
    public audioExercise: AudioExerciseModel;
    public impactWorkflow: ImpactWorkflow;
    public smilerImpactWorkflow: SmilerImpactWorkflow;
}
