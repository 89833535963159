import type {ReactElement} from 'react';
import TimelineContent from '@material-ui/lab/TimelineContent/TimelineContent';
import i18n from '../../../i18n';
import type {SmilerVisitTask} from '../../../model/studies/SmilerWorkflow';
import type {ImpactVisitTask} from '../../../model/studies/ImpactWorkflow';
import type {ImpactStudyVisit} from '../../../model/studies/StudyHelper';
import {getAudioExerciseName} from './CommonFunctionalities';

/**
 * Represents the three states of a visit.
 */
export const VISIT_STATE = {
    active: 'active',
    passed: 'past',
    next: 'next'
} as const;

/** The type definition based on the VISIT_STATE value. **/
export type VisitState = typeof VISIT_STATE[keyof typeof VISIT_STATE];

/**
 * Used to display the timeline of a study in a different way than normal.
 * @param props
 * @param props.finishedTasks - the tasks that are done within a visit.
 * @param props.studyTasks - all the study tasks.
 * @param props.visitState - flag which specify how the visit timeline will be displayed.
 * @param props.visitIdentifier  - used to get the audio file name for the audio task.
 */
export default function StudyTimelineContent<T extends SmilerVisitTask | ImpactVisitTask>(props: {
    finishedTasks: T[];
    studyTasks: T[];
    visitState: VisitState;
    visitIdentifier?: ImpactStudyVisit;
}): ReactElement {
    return (
        <TimelineContent className="timeline-content">
            {props.studyTasks.map((task, index) => (
                <div
                    key={index}
                    className={`study-timeline ${
                        (props.visitState === VISIT_STATE.active &&
                            props.finishedTasks.indexOf(task) === -1) ||
                        props.visitState === VISIT_STATE.next
                            ? ''
                            : 'blur'
                    }`}
                >
                    {i18n.t(`studies:stepAction.${task}`)}
                    {props.visitIdentifier !== undefined &&
                        ': ' + getAudioExerciseName(props.visitIdentifier)}
                    {index < props.studyTasks.length - 1 && ','}&nbsp;
                </div>
            ))}
        </TimelineContent>
    );
}
