import type {ReactElement} from 'react';
import QRCode from 'qrcode.react';
import {Button, ListItem, ListItemIcon} from '@material-ui/core';
import Clipboard from 'react-clipboard.js';
import {Prompt, Redirect, useHistory, useLocation} from 'react-router-dom';
import type OneInstanceModel from '../../model/OneInstanceModel';
import i18n from '../../i18n';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import {Icon} from '../icon/Icon';
import InfoMessage, {MESSAGE_TYPE} from '../errors/InfoMessage';
import type ConnectionsModel from 'one.models/lib/models/ConnectionsModel';
import type {PairingInformation} from 'one.models/lib/models/ConnectionsModel';
import MenuButton from '../menu/MenuButton';
import {Alert} from '@material-ui/lab';
import {SmilerAccessGroups} from '../../model/SmilerAccessRightsManager';
import type AccessModel from 'one.models/lib/models/AccessModel';
import {acceptUrl} from '../modelHelper/ConnectionsHelper';
import {useEffect, useState, Fragment} from 'react';

/**
 * The instance that has received the invitation has to explicitly
 * connect to the instance that has generated the invitation.
 *
 * In case of an connection with a personal cloud device the instance
 * that has received the invitation has to first create a new instance
 * with the email received in the QR-code and after that has to initiate
 * the connection.
 * The email for the anonymous user is also sent in the invitation.
 * @param oneInstanceModel
 * @param connectionsModel
 * @param pairingInformation
 * @param setError
 */
function connectUsingReceivedPairingInformation(
    oneInstanceModel: OneInstanceModel,
    connectionsModel: ConnectionsModel,
    pairingInformation: PairingInformation,
    setError: (value: string) => void
): void {
    const secret = oneInstanceModel.getSecret();

    if (pairingInformation.takeOver && pairingInformation.takeOverDetails) {
        oneInstanceModel
            .createNewInstanceWithReceivedEmail(
                pairingInformation.takeOverDetails.email,
                true,
                pairingInformation.takeOverDetails.anonymousEmail
            )
            .then(() => {
                connectToOtherInstanceUsingPairingInformation(
                    connectionsModel,
                    pairingInformation,
                    secret,
                    setError
                );
            })
            .catch((err: Error) => {
                setError(err.message);
            });
    } else {
        connectToOtherInstanceUsingPairingInformation(
            connectionsModel,
            pairingInformation,
            secret,
            setError
        );
    }
}

/**
 * Initiates the connection with the instance that has generated the invite.
 * @param connectionsModel
 * @param pairingInformation
 * @param secret
 * @param setError
 */
function connectToOtherInstanceUsingPairingInformation(
    connectionsModel: ConnectionsModel,
    pairingInformation: PairingInformation,
    secret: string,
    setError: (value: string) => void
): void {
    connectionsModel
        .connectUsingPairingInformation(pairingInformation, secret)
        .catch((e: Error) => {
            setError(e.message);
        });
}

/**
 * When the state of the connection changes update the UI.
 *
 * @param connectionsModel
 * @param pairingInformation
 * @param setSuccessfulConnection
 * @returns {boolean}
 */
function useConnectionEstablishment(
    connectionsModel: ConnectionsModel,
    pairingInformation: PairingInformation,
    setSuccessfulConnection: (value: boolean) => void
): boolean {
    const [connectionEstablished, setConnectionEstablished] = useState<boolean>(false);

    useEffect(() => {
        function connectionFinished(authenticationToken: string): void {
            if (authenticationToken === pairingInformation.authenticationTag) {
                setConnectionEstablished(true);
                setSuccessfulConnection(true);
            }
        }

        return connectionsModel.onOneTimeAuthSuccess(connectionFinished);
    }, [connectionsModel, pairingInformation, setSuccessfulConnection]);

    return connectionEstablished;
}

/**
 * @param {{}} props
 * @param {ConnectionsModel} props.connectionsModel
 * @param {OneInstanceModel} props.oneInstanceModel
 * @param {AccessModel} props.accessModel
 * @param {((value:boolean) => void)} props.setSuccessfulConnection -> if the invitation succeeded or not
 * @returns {ReactElement}
 */
export default function CreateAcceptInvites(props: {
    connectionsModel: ConnectionsModel;
    oneInstanceModel: OneInstanceModel;
    accessModel: AccessModel;
    setSuccessfulConnection: (value: boolean) => void;
}): ReactElement {
    const location = useLocation();
    const takeOver = location.pathname.includes('personalCloud');

    return (
        <>
            {location.search.includes('invited=true') ? (
                <AcceptInvite
                    connectionsModel={props.connectionsModel}
                    connectInformation={JSON.parse(decodeURIComponent(location.hash.substring(1)))}
                    oneInstanceModel={props.oneInstanceModel}
                    accessModel={props.accessModel}
                    setSuccessfulConnection={props.setSuccessfulConnection}
                />
            ) : (
                <DisplayInvite
                    connectionsModel={props.connectionsModel}
                    oneInstanceModel={props.oneInstanceModel}
                    takeOver={takeOver}
                    setSuccessfulConnection={props.setSuccessfulConnection}
                />
            )}
        </>
    );
}

function DisplayInvite(props: {
    connectionsModel: ConnectionsModel;
    oneInstanceModel: OneInstanceModel;
    takeOver: boolean;
    setSuccessfulConnection: (value: boolean) => void;
}): ReactElement {
    const [generateNewInvite, setGenerateNewInvite] = useState(true);
    const [counter, setCounter] = useState(props.connectionsModel.authTokenExpirationDuration);
    const [displayTimeout, setDisplayTimeout] = useState('00:00');
    const [pairingInformation, setPairingInformation] = useState<PairingInformation>(
        {} as PairingInformation
    );
    const [inviteExpired, setInviteExpired] = useState(false);
    const [displayPopup, setDisplayPopup] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const location = useLocation();

    useEffect(() => {
        return () => {
            props.connectionsModel.invalidateCurrentInvitation(pairingInformation);
        };
    }, [props.connectionsModel, pairingInformation]);

    /**
     * If the counter is greater than 0, then decrease it at every second and compute
     * the displayed value by converting from milliseconds to minutes and seconds.
     *
     * If the counter has reached 0 then display the expiration warning in the page.
     */
    useEffect(() => {
        function computeDisplayedTimeoutValue(): void {
            const minutes = Math.floor(counter / 60000);
            const seconds = (counter % 60000) / 1000;
            setDisplayTimeout(
                `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
            );
        }

        let counter_id: ReturnType<typeof setTimeout>;

        if (counter > 0) {
            counter_id = setTimeout(() => setCounter(counter - 1000), 1000);
        } else {
            setInviteExpired(true);
        }
        computeDisplayedTimeoutValue();

        return () => {
            clearInterval(counter_id);
            clearTimeout(counter_id);
        };
    }, [counter, inviteExpired, displayTimeout]);

    /**
     * If the user tries to navigate out from the generated invitation page a popup will appear
     * to inform him that the invitation expires if he leaves
     */
    useEffect(() => {
        setDisplayPopup(true);
    }, [location.pathname]);

    /**
     * When the generate new invite state is enabled, invalidate the current displayed invite,
     * generate a new invite, remove the previously  counter and reinitialise the counter with
     * the expiration value of the current displayed invite.
     */
    useEffect(() => {
        function fetchNewPairingInformation(): void {
            props.connectionsModel
                .generatePairingInformation(props.takeOver)
                .then((pi: PairingInformation) => {
                    setPairingInformation(pi);
                })
                .catch((e: Error) => {
                    setErrorMessage(e.message);
                });
        }

        if (generateNewInvite) {
            // when creating a new invitation the old one becomes invalid
            props.connectionsModel.invalidateCurrentInvitation(pairingInformation);
            fetchNewPairingInformation();
            setGenerateNewInvite(false);
            setInviteExpired(false);

            // reset the counter to the initial value
            setCounter(props.connectionsModel.authTokenExpirationDuration);
        }
    }, [props.connectionsModel, props.takeOver, generateNewInvite, counter, pairingInformation]);

    const encryptedInformationString = JSON.stringify(pairingInformation);
    const encodedInformation = encodeURIComponent(encryptedInformationString);
    const urlForAccept = acceptUrl(encodedInformation);
    const connectionEstablished = useConnectionEstablishment(
        props.connectionsModel,
        pairingInformation,
        props.setSuccessfulConnection
    );

    const qrCodePageType = props.takeOver ? 'PersonalCloud' : 'Patient';

    if (connectionEstablished) {
        props.oneInstanceModel.unregister();
        return <Redirect to="/connections" />;
    }

    return (
        <>
            <Fragment>
                <Prompt
                    when={displayPopup}
                    message={i18n.t('errors:connection.leaveInvitationPageWarning')}
                />
            </Fragment>
            <div className="page-container">
                {inviteExpired && (
                    <Paper
                        square
                        elevation={3}
                        className="stick-message-top message-font-size warning-color"
                    >
                        <Alert severity="warning">{i18n.t('connections:invitationExpired')}</Alert>
                    </Paper>
                )}
                <InfoMessage
                    errorMessage={errorMessage}
                    setDisplayMessage={setErrorMessage}
                    messageType={MESSAGE_TYPE.Error}
                />
                <div className="menu-button-header">
                    <MenuButton />
                    <h2 className="headline">{i18n.t(`connections:qr${qrCodePageType}.title`)}</h2>
                </div>
                <Paper square elevation={3} className="page-content-box">
                    <div className="qr-code">
                        <Clipboard component="div" data-clipboard-text={urlForAccept}>
                            <QRCode value={urlForAccept} size={200} />
                        </Clipboard>
                    </div>
                    <div className="qr-code-details">
                        <div className="refresh-countdown">
                            <div className="counter">{displayTimeout}</div>
                            <Button
                                onClick={() => {
                                    setGenerateNewInvite(true);
                                }}
                            >
                                <Icon name="Refresh" />
                            </Button>
                        </div>
                        <div className="paper-font-size">
                            <br />
                            {i18n.t(`connections:qr${qrCodePageType}.details`)}
                            <br />
                            {i18n.t(`connections:qr${qrCodePageType}.copy`)}
                        </div>
                        <Clipboard component="div" data-clipboard-text={urlForAccept}>
                            <ListItem key="fileCopy" button className="copyIcon">
                                <ListItemIcon>
                                    <Icon name="FileCopy" />
                                </ListItemIcon>
                            </ListItem>
                        </Clipboard>
                    </div>
                </Paper>
            </div>
        </>
    );
}

function AcceptInvite(props: {
    connectionsModel: ConnectionsModel;
    connectInformation: PairingInformation;
    oneInstanceModel: OneInstanceModel;
    accessModel: AccessModel;
    setSuccessfulConnection: (value: boolean) => void;
}): ReactElement {
    const history = useHistory();
    const {
        connectionsModel,
        connectInformation,
        oneInstanceModel,
        accessModel,
        setSuccessfulConnection
    } = props;

    const [errorMessage, setErrorMessage] = useState('');
    const [connectionType, setConnectionType] = useState('');

    /**
     * Automatically accept the invite without using the invite button and set
     * the connection type based on the URL invite.
     */
    useEffect(() => {
        setConnectionType('IoM');

        /**
         * If connection succeeded loading is set to false and
         * if there is no error message will redirect to connection page
         */
        connectUsingReceivedPairingInformation(
            oneInstanceModel,
            connectionsModel,
            connectInformation,
            setErrorMessage
        );
        // In order to achieve componentDidMount() behaviour, we need an empty array of dependencies.
    }, []);

    /**
     * If a new connection is fetched and the invitation between the two worked and they will be redirected to connections page
     */
    useEffect(() => {
        function navigateToConnections(): void {
            oneInstanceModel.unregister();
            history.push('/connections');
            setSuccessfulConnection(true);
        }

        async function fetchConnections(): Promise<void> {
            const allConnections = connectionsModel.connectionsInfo();

            if (!allConnections || allConnections.length < 1) {
                return;
            }

            const {
                obj: {person: clinicPerson}
            } = await accessModel.getAccessGroupByName(SmilerAccessGroups.clinic);

            const lastConnected = allConnections.reverse()[0];

            // check against the replicant which is already connected as a partner in allConnections
            if (
                lastConnected.isConnected &&
                !clinicPerson.includes(lastConnected.targetPersonId) &&
                !errorMessage
            ) {
                navigateToConnections();
            }
        }

        const disconnect = connectionsModel.onConnectionsChange(fetchConnections);
        fetchConnections().catch(error => setErrorMessage(error));

        return disconnect;
    }, [
        history,
        oneInstanceModel,
        setSuccessfulConnection,
        errorMessage,
        connectionsModel,
        accessModel
    ]);

    /**
     * If an error is caught appBrokeOnInvite is set into localStorage for the error page
     * to appear and user is automatically logged out with the type of connection
     */
    useEffect(() => {
        if (errorMessage) {
            localStorage.setItem('appBrokeOnInvite', connectionType);
            window.location.reload();
        }
    }, [connectionType, errorMessage]);

    return (
        <>
            <div className="circular-progress-container">
                <CircularProgress className="circular-progress" size={35} />
            </div>
        </>
    );
}
