import {TextField} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import type {Question, QuestionnaireValue} from 'one.models/lib/models/QuestionnaireModel';
import * as dateFns from 'date-fns';
import i18n from '../../i18n';
import {formatAnswerRestrictionDate} from './QuestionnaireCommon';

/**
 * This component builds and returns the string question
 *
 * @param props - Properties of this view:
 * @param props.currentValue - The current value
 * @param props.question - The question
 * @param props.setFinishedQuestion - Setter for the finished question id
 * @param props.setQuestionAnswer - Setter for the question answer
 * @param props.viewMode - flag that specifies if the question should be in the view mode or not
 * @returns {React.ReactElement}
 */
export default function StringQuestion(props: {
    currentValue: string;
    question: Question;
    setQuestionAnswer: (data: QuestionnaireValue[]) => void;
    setFinishedQuestion: (linkId: string) => void;
    viewMode: boolean;
}): React.ReactElement {
    const [value, setValue] = useState('');

    useEffect(() => {
        setValue(props.currentValue);
    }, [props.currentValue]);

    /**
     * Checks whether the given answer for a date question respect the future date not allowed rule.
     */
    function isDateError(): boolean {
        if (
            !props.viewMode &&
            props.question.type === 'date' &&
            props.question.answerRestriction &&
            props.question.answerRestriction.maxValue &&
            props.question.answerRestriction.maxValue.valueDate
        ) {
            const givenAnswer = dateFns.parseISO(value);

            // get the max Date allowed from the questionnaire
            const maxAllowedAnswer = formatAnswerRestrictionDate(
                props.question.answerRestriction.maxValue.valueDate
            );

            if (
                (props.question.answerRestriction.maxInclusive === undefined ||
                    props.question.answerRestriction.maxInclusive) &&
                dateFns.isAfter(givenAnswer, maxAllowedAnswer)
            ) {
                return true;
            }

            if (
                props.question.answerRestriction.maxInclusive !== undefined &&
                !props.question.answerRestriction.maxInclusive &&
                dateFns.isAfter(givenAnswer, dateFns.subDays(maxAllowedAnswer, 1))
            ) {
                return true;
            }
        }

        return false;
    }

    return (
        <TextField
            className={`${
                props.question.type === 'string' ? 'qtn-text-input-answer' : 'qtn-text-field-answer'
            }`}
            value={value}
            fullWidth={props.question.type === 'string'}
            multiline={props.question.type === 'string'}
            type={`${props.question.type}`}
            inputProps={{
                maxLength: props.question.maxLength
            }}
            error={isDateError()}
            helperText={
                props.question.type === 'string' && props.question.maxLength
                    ? `${value.length}/${props.question.maxLength}`
                    : isDateError()
                    ? i18n.t('errors:covidQuestionaire.invalidDate')
                    : ''
            }
            onKeyPress={event => {
                if (event.key === 'Enter') {
                    props.setFinishedQuestion(props.question.linkId);
                }
            }}
            onChange={event => {
                setValue(event.target.value);

                if (props.question.type === 'date') {
                    props.setQuestionAnswer([{valueDate: event.target.value}]);
                }
            }}
            onBlur={event => {
                props.setQuestionAnswer([
                    props.question.type === 'string'
                        ? {valueString: event.target.value}
                        : {valueDate: event.target.value}
                ]);
            }}
            variant="outlined"
        />
    );
}
