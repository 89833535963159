import type {ReactElement} from 'react';
import type {ViewFooter} from '../Ui';

/**
 * @param {{}} props
 * @param {Function} props.setFooter
 * @param {Function} props.setNavigationDrawerState
 * @returns {ReactElement}
 */
export default function FooterElement(props: {
    setFooter: (value: ViewFooter) => void;
    setNavigationDrawerState: (value: boolean) => void;
}): ReactElement {
    return (
        <footer>
            <div className="footer-right">
                <div
                    onClick={() => {
                        window.location.href = 'https://refinio.net/';
                        props.setFooter(1);
                    }}
                >
                    powered by refinio
                </div>
            </div>
        </footer>
    );
}
