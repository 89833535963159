import type {ReactElement} from 'react';
import {useEffect, useState} from 'react';
import type {Product} from '../../Ui';
import type AudioExerciseModel from 'one.models/lib/models/AudioExerciseModel';
import type DocumentModel from 'one.models/lib/models/DocumentModel';
import Dashboard, {TASK_TYPES} from './Dashboard';
import {Timeline} from '@material-ui/lab';
import type {ImpactStudyVisit, SmilerStudyVisit} from '../../../model/studies/StudyHelper';
import {changeBackgroundColorToWhite, removeWhiteBackgroundColor} from '../../utils/Utils';
import MenuButton from '../../menu/MenuButton';
import i18n from '../../../i18n';
import {PRODUCT_TYPE} from '../../Ui';
import {SMILER_TASK} from '../../../model/studies/SmilerWorkflow';
import EntryView, {EntryViewContext} from '../../journal/EntryView';
import type ImpactWorkflow from '../../../model/studies/ImpactWorkflow';
import {useImpactStudyData} from '../../modelHelper/StudyWorkflowHelper';
import PastAudioExercisesDashboard from './PastAudioExercisesDashboard';

const SMILER_TASKS = [
    SMILER_TASK.Ecg,
    SMILER_TASK.Wbc,
    SMILER_TASK.Photo,
    SMILER_TASK.Questionnaire
];

/**
 * @param props
 * @param props.productType
 * @param props.documentModel
 * @param props.impactWorkflow
 * @param props.audioExerciseModel
 * @param props.currentVisit
 */
export default function OptionalTasksView(props: {
    productType: Product;
    documentModel: DocumentModel;
    impactWorkflow: ImpactWorkflow;
    audioExerciseModel?: AudioExerciseModel;
    currentVisit?: SmilerStudyVisit | ImpactStudyVisit;
}): ReactElement {
    const [audioPlayer, setAudioPlayer] = useState<ReactElement>(<></>);
    const [isAudioPlayerOpen, setAudioPlayerOpen] = useState(false);
    const [title, setTitle] = useState('');

    const {pastVisits} = useImpactStudyData(props.impactWorkflow);

    useEffect(() => {
        changeBackgroundColorToWhite();

        return () => {
            removeWhiteBackgroundColor();
        };
    }, []);

    return (
        <div className="page-container home-screen">
            <div className="menu-button-header">
                <MenuButton />
                <h2 className="headline">{i18n.t('common:menu.dataCollection')}</h2>
            </div>
            <EntryViewContext.Provider
                value={{
                    closeEntryViewCallback: setAudioPlayerOpen,
                    setContent: setAudioPlayer,
                    setTitle: setTitle
                }}
            >
                <Timeline className="overview-timeline" align="left">
                    <Dashboard
                        documentModel={props.documentModel}
                        tasks={[...SMILER_TASKS]}
                        taskTypes={TASK_TYPES.optional}
                        audioExerciseModel={props.audioExerciseModel}
                        currentVisit={props.currentVisit}
                    />
                    {props.productType === PRODUCT_TYPE.Smiler_impact && (
                        <PastAudioExercisesDashboard
                            pastVisits={[...pastVisits]}
                            audioExerciseModel={props.audioExerciseModel}
                        />
                    )}
                </Timeline>
            </EntryViewContext.Provider>
            <EntryView
                isOpen={isAudioPlayerOpen}
                closeEntryViewCallback={setAudioPlayerOpen}
                content={audioPlayer}
                title={title}
            />
        </div>
    );
}
