import type {ReactElement} from 'react';
import type QuestionnaireModel from 'one.models/lib/models/QuestionnaireModel';
import {useLocation, useHistory, useParams} from 'react-router-dom';
import MenuButton from '../menu/MenuButton';
import QuestionnaireView from './QuestionnaireView';

/**
 * @param props
 * @param props.questionnaireModel
 */
export default function QuestionnaireDemo(props: {
    questionnaireModel: QuestionnaireModel;
}): ReactElement {
    // Extract the parameters from the URL
    const history = useHistory();
    const {action} = useParams<{action: string}>();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const paramResponse = queryParams.get('response') || undefined;
    const paramLanguage = queryParams.get('language') || undefined;
    const paramQuestionnaire = queryParams.get('questionnaires') || undefined;

    return (
        <QuestionnaireView
            questionnaireModel={props.questionnaireModel}
            questionnaireName={paramQuestionnaire}
            responseId={paramResponse}
            language={paramLanguage}
            action={action}
            redirectAfterSubmit={() => history.push('/journal')}
            menuButton={<MenuButton />}
        />
    );
}
