import type {Questionnaire} from 'one.models/lib/models/QuestionnaireModel';

export const PIF_Questionnaire: Questionnaire = {
    resourceType: 'Questionnaire',
    language: 'en',
    url: 'http://uk-erlangen.de/dev/questionnaire/PIF_en',
    name: 'PIF',
    status: 'active',
    item: [
        {
            linkId: 'PIF1',
            prefix: 'PIF1',
            text: 'Please fill in this 4-page form before you visit your physiotherapist the first time. It gives you (and your carer) the chance to think about the problems you would like the physiotherapist to address. This information will help your physiotherapist in building a picture of what you consider your main problems, as well as your physical capability.',
            type: 'display'
        },
        {
            linkId: 'PIF2',
            prefix: 'PIF2',
            text: 'Date:',
            type: 'date',
            required: true
        },
        {
            linkId: 'PIF3',
            prefix: 'PIF3',
            text: 'Your Name: ',
            type: 'string',
            required: true,
            minLength: 2
        },
        {
            linkId: 'PIF4',
            prefix: 'PIF4',
            text: 'Your physiotherapy goal',
            type: 'group',
            item: [
                {
                    linkId: 'PIF4.1',
                    prefix: 'PIF4.1',
                    text: '1. What problems would you like to work on first?',
                    type: 'string',
                    required: true,
                    minLength: 2
                },
                {
                    linkId: 'PIF4.2',
                    prefix: 'PIF4.2',
                    text: '2. In what way have you tried to tackle these problems?',
                    type: 'string',
                    required: true,
                    minLength: 2
                },
                {
                    linkId: 'PIF4.3',
                    prefix: 'PIF4.3',
                    text: '3. How effective were these methods?',
                    type: 'string',
                    required: true,
                    minLength: 2
                },
                {
                    linkId: 'PIF4.4',
                    prefix: 'PIF4.4',
                    text: '4. What are your expectations of physiotherapy?',
                    type: 'string',
                    required: true,
                    minLength: 2
                },
                {
                    linkId: 'PIF4.5',
                    prefix: 'PIF4.5',
                    text:
                        '5. Any other information you want your physiotherapist to know about,' +
                        " such as health problems other than Parkinson's?",
                    type: 'string',
                    required: true,
                    minLength: 2
                },
                {
                    linkId: 'PIF4.6',
                    prefix: 'PIF4.6',
                    text: '6. Any other questions you want to ask?',
                    type: 'string',
                    required: true,
                    minLength: 2
                }
            ]
        },
        {
            linkId: 'PIF5',
            prefix: 'PIF5',
            text: 'Falls',
            type: 'group',
            item: [
                {
                    linkId: 'PIF5.1',
                    prefix: 'PIF5.1',
                    text:
                        '7. In the last 12 months, have you fallen at all for any reason, any' +
                        ' trips or slips, even if they probably had nothing to do with' +
                        " Parkinson's disease?",
                    type: 'choice',
                    required: true,
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/dev/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '0',
                                display: 'No'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/dev/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '1',
                                display: 'Yes'
                            }
                        }
                    ]
                },
                {
                    linkId: 'PIF5.2',
                    prefix: 'PIF5.2',
                    text:
                        '8. Have you had any near misses in the last 12 months when you nearly' +
                        ' did, but were able to stop a full fall?',
                    type: 'choice',
                    required: true,
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/dev/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '0',
                                display: 'No'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/dev/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '1',
                                display: 'Yes'
                            }
                        }
                    ]
                },
                {
                    linkId: 'PIF5.3',
                    prefix: 'PIF5.3',
                    text: '9. How afraid are you of falling over?',
                    type: 'choice',
                    required: true,
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/dev/valueCoding/AfraidLevel_en',
                                version: '1.0',
                                code: '0',
                                display: 'Not at all'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/dev/valueCoding/AfraidLevel_en',
                                version: '1.0',
                                code: '1',
                                display: 'A little'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/dev/valueCoding/AfraidLevel_en',
                                version: '1.0',
                                code: '2',
                                display: 'Quite a bit'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/dev/valueCoding/AfraidLevel_en',
                                version: '1.0',
                                code: '3',
                                display: 'Very much'
                            }
                        }
                    ]
                }
            ]
        },
        {
            linkId: 'PIF6',
            prefix: 'PIF6',
            text: 'Freezing of gait',
            type: 'group',
            item: [
                {
                    linkId: 'PIF6.1',
                    prefix: 'PIF6.1',
                    text: 'Freezing is the feeling of your feet being glued to the floor. Sometimes it is accompanied by trembling of the legs and small shuffling steps. It may happen when you start walking, when you make a turn, when you are walking through narrow spaces or when you are walking in crowded places. If you are not sure if you freeze, please watch the freezing of gait video at www.parkinsonnet.info/euguideline',
                    type: 'display'
                },
                {
                    linkId: 'PIF6.2',
                    prefix: 'PIF6.2',
                    text: 'Have you experienced freezing episodes over the past month?',
                    type: 'choice',
                    required: true,
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/dev/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '0',
                                display: 'No'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/dev/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '1',
                                display: 'Yes'
                            }
                        }
                    ]
                }
            ]
        },
        {
            linkId: 'PIF7',
            prefix: 'PIF7',
            text: 'Physical activity',
            type: 'group',
            item: [
                {
                    linkId: 'PIF7.1',
                    prefix: 'PIF7.1',
                    text:
                        '11. For every activity you have carried out during the past week,' +
                        ' please fill in for how long this was for. Please give a total for all 7 days together.',
                    type: 'group',
                    item: [
                        {
                            linkId: 'PIF7.1.1',
                            prefix: 'PIF7.1.1',
                            type: 'integer',
                            text: 'Walking on ground level (indoors, outdoors or on a treadmill)',
                            answerRestriction: {
                                minValue: {
                                    valueInteger: '0'
                                },
                                maxValue: {
                                    valueInteger: '10080'
                                }
                            },
                            required: true
                        },
                        {
                            linkId: 'PIF7.1.2',
                            prefix: 'PIF7.1.2',
                            type: 'integer',
                            text: 'Walking uphill, upstairs or running',
                            answerRestriction: {
                                minValue: {
                                    valueInteger: '0'
                                },
                                maxValue: {
                                    valueInteger: '10080'
                                }
                            },
                            required: true
                        },
                        {
                            linkId: 'PIF7.1.3',
                            prefix: 'PIF7.1.3',
                            type: 'integer',
                            text: 'Bicycling on ground level (outdoors or on a treadmill)',
                            answerRestriction: {
                                minValue: {
                                    valueInteger: '0'
                                },
                                maxValue: {
                                    valueInteger: '10080'
                                }
                            },
                            required: true
                        },
                        {
                            linkId: 'PIF7.1.4',
                            prefix: 'PIF7.1.4',
                            type: 'integer',
                            text: 'Bicycling uphill or at a fast pace (outdoors or on a treadmill)',
                            answerRestriction: {
                                minValue: {
                                    valueInteger: '0'
                                },
                                maxValue: {
                                    valueInteger: '10080'
                                }
                            },
                            required: true
                        },
                        {
                            linkId: 'PIF7.1.5',
                            prefix: 'PIF7.1.5',
                            type: 'integer',
                            text:
                                'Dancing, recreational swimming, gymnastics, exercise group' +
                                ' training, yoga, playing doubles in tennis or playing golf',
                            answerRestriction: {
                                minValue: {
                                    valueInteger: '0'
                                },
                                maxValue: {
                                    valueInteger: '10080'
                                }
                            },
                            required: true
                        },
                        {
                            linkId: 'PIF7.1.6',
                            prefix: 'PIF7.1.6',
                            type: 'integer',
                            text:
                                'Swimming steadily paced laps, playing singles in tennis or' +
                                ' rowing Sweeping, washing windows or raking in the garden or yard',
                            answerRestriction: {
                                minValue: {
                                    valueInteger: '0'
                                },
                                maxValue: {
                                    valueInteger: '10080'
                                }
                            },
                            required: true
                        },
                        {
                            linkId: 'PIF7.1.7',
                            prefix: 'PIF7.1.7',
                            type: 'integer',
                            text:
                                'Digging in the garden or yard, heavy construction, heavy' +
                                ' lifting, chopping wood or shovelling snow',
                            answerRestriction: {
                                minValue: {
                                    valueInteger: '0'
                                },
                                maxValue: {
                                    valueInteger: '10080'
                                }
                            },
                            required: true
                        },
                        {
                            linkId: 'PIF7.1.8',
                            prefix: 'PIF7.1.8',
                            type: 'integer',
                            text: 'Other activities',
                            answerRestriction: {
                                minValue: {
                                    valueInteger: '0'
                                },
                                maxValue: {
                                    valueInteger: '10080'
                                }
                            },
                            required: true
                        },
                        {
                            linkId: 'PIF7.1.9',
                            prefix: 'PIF7.1.9',
                            type: 'string',
                            text: 'Please describe: ',
                            required: true,
                            minLength: 2
                        }
                    ]
                },
                {
                    linkId: 'PIF7.2',
                    prefix: 'PIF7.2',
                    text:
                        '12. Compared to other weeks, have you been as physically active this' +
                        ' week?',
                    type: 'choice',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/dev/valueCoding/WeekActivity_en',
                                version: '1.0',
                                code: '0',
                                display: 'More active this week'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/dev/valueCoding/WeekActivity_en',
                                version: '1.0',
                                code: '1',
                                display: 'Same'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/dev/valueCoding/WeekActivity_en',
                                version: '1.0',
                                code: '2',
                                display: 'Less active this week'
                            }
                        }
                    ],
                    required: true
                },
                {
                    linkId: 'PIF7.3',
                    prefix: 'PIF7.3',
                    text: '13. What regular activities have you stopped during the past 12 months?',
                    type: 'string',
                    required: true,
                    minLength: 2
                },
                {
                    linkId: 'PIF7.4',
                    prefix: 'PIF7.4',
                    text: '14. Why did you stop?',
                    type: 'string',
                    required: true,
                    minLength: 2
                },
                {
                    linkId: 'PIF7.5',
                    prefix: 'PIF7.5',
                    text: '15. Please tick whether you find these activities difficult to perform or if you experience other problems such as freezing, losing balance or pain when performing them:',
                    type: 'group',
                    item: [
                        {
                            linkId: 'PIF7.5.1',
                            prefix: 'PIF7.5.1',
                            text: 'Walking',
                            type: 'group',
                            item: [
                                {
                                    linkId: 'PIF7.5.1.1',
                                    prefix: 'PIF7.5.1.1',
                                    text: 'walking indoors',
                                    type: 'choice',
                                    answerOption: [
                                        {
                                            valueCoding: {
                                                system: 'http://uk-erlangen.de/dev/valueCoding/DifficultNotDifficult_en',
                                                version: '1.0',
                                                code: '0',
                                                display: 'Difficult'
                                            }
                                        },
                                        {
                                            valueCoding: {
                                                system: 'http://uk-erlangen.de/dev/valueCoding/DifficultNotDifficult_en',
                                                version: '1.0',
                                                code: '1',
                                                display: 'Not difficult'
                                            }
                                        }
                                    ],
                                    required: true
                                },
                                {
                                    linkId: 'PIF7.5.1.2',
                                    prefix: 'PIF7.5.1.2',
                                    text: 'walking outdoors',
                                    type: 'choice',
                                    answerOption: [
                                        {
                                            valueCoding: {
                                                system: 'http://uk-erlangen.de/dev/valueCoding/DifficultNotDifficult_en',
                                                version: '1.0',
                                                code: '0',
                                                display: 'Difficult'
                                            }
                                        },
                                        {
                                            valueCoding: {
                                                system: 'http://uk-erlangen.de/dev/valueCoding/DifficultNotDifficult_en',
                                                version: '1.0',
                                                code: '1',
                                                display: 'Not difficult'
                                            }
                                        }
                                    ],
                                    required: true
                                },
                                {
                                    linkId: 'PIF7.5.1.3',
                                    prefix: 'PIF7.5.1.3',
                                    text: 'turning',
                                    type: 'choice',
                                    answerOption: [
                                        {
                                            valueCoding: {
                                                system: 'http://uk-erlangen.de/dev/valueCoding/DifficultNotDifficult_en',
                                                version: '1.0',
                                                code: '0',
                                                display: 'Difficult'
                                            }
                                        },
                                        {
                                            valueCoding: {
                                                system: 'http://uk-erlangen.de/dev/valueCoding/DifficultNotDifficult_en',
                                                version: '1.0',
                                                code: '1',
                                                display: 'Not difficult'
                                            }
                                        }
                                    ],
                                    required: true
                                },
                                {
                                    linkId: 'PIF7.5.1.4',
                                    prefix: 'PIF7.5.1.4',
                                    text: 'start walking',
                                    type: 'choice',
                                    answerOption: [
                                        {
                                            valueCoding: {
                                                system: 'http://uk-erlangen.de/dev/valueCoding/DifficultNotDifficult_en',
                                                version: '1.0',
                                                code: '0',
                                                display: 'Difficult'
                                            }
                                        },
                                        {
                                            valueCoding: {
                                                system: 'http://uk-erlangen.de/dev/valueCoding/DifficultNotDifficult_en',
                                                version: '1.0',
                                                code: '1',
                                                display: 'Not difficult'
                                            }
                                        }
                                    ],
                                    required: true
                                },
                                {
                                    linkId: 'PIF7.5.1.5',
                                    prefix: 'PIF7.5.1.5',
                                    text: 'climbing and descending stairs walking while performing dual tasks walking through narrow passages stop walking',
                                    type: 'choice',
                                    answerOption: [
                                        {
                                            valueCoding: {
                                                system: 'http://uk-erlangen.de/dev/valueCoding/DifficultNotDifficult_en',
                                                version: '1.0',
                                                code: '0',
                                                display: 'Difficult'
                                            }
                                        },
                                        {
                                            valueCoding: {
                                                system: 'http://uk-erlangen.de/dev/valueCoding/DifficultNotDifficult_en',
                                                version: '1.0',
                                                code: '1',
                                                display: 'Not difficult'
                                            }
                                        }
                                    ],
                                    required: true
                                }
                            ]
                        },
                        {
                            linkId: 'PIF7.5.2',
                            prefix: 'PIF7.5.2',
                            text: 'Transfers',
                            type: 'group',
                            item: [
                                {
                                    linkId: 'PIF7.5.2.1',
                                    prefix: 'PIF7.5.2.1',
                                    text: 'turning in bed',
                                    type: 'choice',
                                    answerOption: [
                                        {
                                            valueCoding: {
                                                system: 'http://uk-erlangen.de/dev/valueCoding/DifficultNotDifficult_en',
                                                version: '1.0',
                                                code: '0',
                                                display: 'Difficult'
                                            }
                                        },
                                        {
                                            valueCoding: {
                                                system: 'http://uk-erlangen.de/dev/valueCoding/DifficultNotDifficult_en',
                                                version: '1.0',
                                                code: '1',
                                                display: 'Not difficult'
                                            }
                                        }
                                    ],
                                    required: true
                                },
                                {
                                    linkId: 'PIF7.5.2.2',
                                    prefix: 'PIF7.5.2.2',
                                    text: 'getting into or out of bed',
                                    type: 'choice',
                                    answerOption: [
                                        {
                                            valueCoding: {
                                                system: 'http://uk-erlangen.de/dev/valueCoding/DifficultNotDifficult_en',
                                                version: '1.0',
                                                code: '0',
                                                display: 'Difficult'
                                            }
                                        },
                                        {
                                            valueCoding: {
                                                system: 'http://uk-erlangen.de/dev/valueCoding/DifficultNotDifficult_en',
                                                version: '1.0',
                                                code: '1',
                                                display: 'Not difficult'
                                            }
                                        }
                                    ],
                                    required: true
                                },
                                {
                                    linkId: 'PIF7.5.2.3',
                                    prefix: 'PIF7.5.2.3',
                                    text: 'getting into or out of a car',
                                    type: 'choice',
                                    answerOption: [
                                        {
                                            valueCoding: {
                                                system: 'http://uk-erlangen.de/dev/valueCoding/DifficultNotDifficult_en',
                                                version: '1.0',
                                                code: '0',
                                                display: 'Difficult'
                                            }
                                        },
                                        {
                                            valueCoding: {
                                                system: 'http://uk-erlangen.de/dev/valueCoding/DifficultNotDifficult_en',
                                                version: '1.0',
                                                code: '1',
                                                display: 'Not difficult'
                                            }
                                        }
                                    ],
                                    required: true
                                },
                                {
                                    linkId: 'PIF7.5.2.4',
                                    prefix: 'PIF7.5.2.4',
                                    text: 'getting into or out of a chair',
                                    type: 'choice',
                                    answerOption: [
                                        {
                                            valueCoding: {
                                                system: 'http://uk-erlangen.de/dev/valueCoding/DifficultNotDifficult_en',
                                                version: '1.0',
                                                code: '0',
                                                display: 'Difficult'
                                            }
                                        },
                                        {
                                            valueCoding: {
                                                system: 'http://uk-erlangen.de/dev/valueCoding/DifficultNotDifficult_en',
                                                version: '1.0',
                                                code: '1',
                                                display: 'Not difficult'
                                            }
                                        }
                                    ],
                                    required: true
                                },
                                {
                                    linkId: 'PIF7.5.2.5',
                                    prefix: 'PIF7.5.2.5',
                                    text: 'getting onto or off a toilet seat',
                                    type: 'choice',
                                    answerOption: [
                                        {
                                            valueCoding: {
                                                system: 'http://uk-erlangen.de/dev/valueCoding/DifficultNotDifficult_en',
                                                version: '1.0',
                                                code: '0',
                                                display: 'Difficult'
                                            }
                                        },
                                        {
                                            valueCoding: {
                                                system: 'http://uk-erlangen.de/dev/valueCoding/DifficultNotDifficult_en',
                                                version: '1.0',
                                                code: '1',
                                                display: 'Not difficult'
                                            }
                                        }
                                    ],
                                    required: true
                                },
                                {
                                    linkId: 'PIF7.5.2.6',
                                    prefix: 'PIF7.5.2.6',
                                    text: 'getting into or out of a bath',
                                    type: 'choice',
                                    answerOption: [
                                        {
                                            valueCoding: {
                                                system: 'http://uk-erlangen.de/dev/valueCoding/DifficultNotDifficult_en',
                                                version: '1.0',
                                                code: '0',
                                                display: 'Difficult'
                                            }
                                        },
                                        {
                                            valueCoding: {
                                                system: 'http://uk-erlangen.de/dev/valueCoding/DifficultNotDifficult_en',
                                                version: '1.0',
                                                code: '1',
                                                display: 'Not difficult'
                                            }
                                        }
                                    ],
                                    required: true
                                },
                                {
                                    linkId: 'PIF7.5.2.7',
                                    prefix: 'PIF7.5.2.7',
                                    text: 'picking up an object from the floor',
                                    type: 'choice',
                                    answerOption: [
                                        {
                                            valueCoding: {
                                                system: 'http://uk-erlangen.de/dev/valueCoding/DifficultNotDifficult_en',
                                                version: '1.0',
                                                code: '0',
                                                display: 'Difficult'
                                            }
                                        },
                                        {
                                            valueCoding: {
                                                system: 'http://uk-erlangen.de/dev/valueCoding/DifficultNotDifficult_en',
                                                version: '1.0',
                                                code: '1',
                                                display: 'Not difficult'
                                            }
                                        }
                                    ],
                                    required: true
                                },
                                {
                                    linkId: 'PIF7.5.2.8',
                                    prefix: 'PIF7.5.2.8',
                                    text: 'getting up from the floor',
                                    type: 'choice',
                                    answerOption: [
                                        {
                                            valueCoding: {
                                                system: 'http://uk-erlangen.de/dev/valueCoding/DifficultNotDifficult_en',
                                                version: '1.0',
                                                code: '0',
                                                display: 'Difficult'
                                            }
                                        },
                                        {
                                            valueCoding: {
                                                system: 'http://uk-erlangen.de/dev/valueCoding/DifficultNotDifficult_en',
                                                version: '1.0',
                                                code: '1',
                                                display: 'Not difficult'
                                            }
                                        }
                                    ],
                                    required: true
                                },
                                {
                                    linkId: 'PIF7.5.2.9',
                                    prefix: 'PIF7.5.2.9',
                                    text: 'getting on or off a bicycle',
                                    type: 'choice',
                                    answerOption: [
                                        {
                                            valueCoding: {
                                                system: 'http://uk-erlangen.de/dev/valueCoding/DifficultNotDifficult_en',
                                                version: '1.0',
                                                code: '0',
                                                display: 'Difficult'
                                            }
                                        },
                                        {
                                            valueCoding: {
                                                system: 'http://uk-erlangen.de/dev/valueCoding/DifficultNotDifficult_en',
                                                version: '1.0',
                                                code: '1',
                                                display: 'Not difficult'
                                            }
                                        }
                                    ],
                                    required: true
                                }
                            ]
                        },
                        {
                            linkId: 'PIF7.5.3',
                            prefix: 'PIF7.5.3',
                            text: 'Manual activities',
                            type: 'group',
                            item: [
                                {
                                    linkId: 'PIF7.5.3.1',
                                    prefix: 'PIF7.5.3.1',
                                    text: 'in domestic life, such as when preparing meals, doing housework',
                                    type: 'choice',
                                    answerOption: [
                                        {
                                            valueCoding: {
                                                system: 'http://uk-erlangen.de/dev/valueCoding/YesNo_en',
                                                version: '1.0',
                                                code: '0',
                                                display: 'No'
                                            }
                                        },
                                        {
                                            valueCoding: {
                                                system: 'http://uk-erlangen.de/dev/valueCoding/YesNo_en',
                                                version: '1.0',
                                                code: '1',
                                                display: 'Yes'
                                            }
                                        }
                                    ],
                                    required: true
                                },
                                {
                                    linkId: 'PIF7.5.3.2',
                                    prefix: 'PIF7.5.3.2',
                                    text: 'in self-care, such as when eating, washing and dressing',
                                    type: 'choice',
                                    answerOption: [
                                        {
                                            valueCoding: {
                                                system: 'http://uk-erlangen.de/dev/valueCoding/YesNo_en',
                                                version: '1.0',
                                                code: '0',
                                                display: 'No'
                                            }
                                        },
                                        {
                                            valueCoding: {
                                                system: 'http://uk-erlangen.de/dev/valueCoding/YesNo_en',
                                                version: '1.0',
                                                code: '1',
                                                display: 'Yes'
                                            }
                                        }
                                    ],
                                    required: true
                                }
                            ]
                        }
                    ]
                },
                {
                    linkId: 'PIF7.6',
                    prefix: 'PIF7.6',
                    text: '16. Please tick whether you experience these sensations:',
                    type: 'group',
                    item: [
                        {
                            linkId: 'PIF7.6.1',
                            prefix: 'PIF7.6.1',
                            text: 'Physical functions',
                            type: 'group',
                            item: [
                                {
                                    linkId: 'PIF7.6.1.1',
                                    prefix: 'PIF7.6.1.1',
                                    text: 'easily out of breath',
                                    type: 'choice',
                                    answerOption: [
                                        {
                                            valueCoding: {
                                                system: 'http://uk-erlangen.de/dev/valueCoding/YesNo_en',
                                                version: '1.0',
                                                code: '0',
                                                display: 'No'
                                            }
                                        },
                                        {
                                            valueCoding: {
                                                system: 'http://uk-erlangen.de/dev/valueCoding/YesNo_en',
                                                version: '1.0',
                                                code: '1',
                                                display: 'Yes'
                                            }
                                        }
                                    ],
                                    required: true
                                },
                                {
                                    linkId: 'PIF7.6.1.2',
                                    prefix: 'PIF7.6.1.2',
                                    text: 'muscle weakness',
                                    type: 'choice',
                                    answerOption: [
                                        {
                                            valueCoding: {
                                                system: 'http://uk-erlangen.de/dev/valueCoding/YesNo_en',
                                                version: '1.0',
                                                code: '0',
                                                display: 'No'
                                            }
                                        },
                                        {
                                            valueCoding: {
                                                system: 'http://uk-erlangen.de/dev/valueCoding/YesNo_en',
                                                version: '1.0',
                                                code: '1',
                                                display: 'Yes'
                                            }
                                        }
                                    ],
                                    required: true
                                },
                                {
                                    linkId: 'PIF7.6.1.3',
                                    prefix: 'PIF7.6.1.3',
                                    text: 'stiffness',
                                    type: 'choice',
                                    answerOption: [
                                        {
                                            valueCoding: {
                                                system: 'http://uk-erlangen.de/dev/valueCoding/YesNo_en',
                                                version: '1.0',
                                                code: '0',
                                                display: 'No'
                                            }
                                        },
                                        {
                                            valueCoding: {
                                                system: 'http://uk-erlangen.de/dev/valueCoding/YesNo_en',
                                                version: '1.0',
                                                code: '1',
                                                display: 'Yes'
                                            }
                                        }
                                    ],
                                    required: true
                                }
                            ]
                        },
                        {
                            linkId: 'PIF7.6.2',
                            prefix: 'PIF7.6.2',
                            text: 'Pain',
                            type: 'group',
                            item: [
                                {
                                    linkId: 'PIF7.6.2.1',
                                    prefix: 'PIF7.6.2.1',
                                    text: 'pain',
                                    type: 'choice',
                                    answerOption: [
                                        {
                                            valueCoding: {
                                                system: 'http://uk-erlangen.de/dev/valueCoding/YesNo_en',
                                                version: '1.0',
                                                code: '0',
                                                display: 'No'
                                            }
                                        },
                                        {
                                            valueCoding: {
                                                system: 'http://uk-erlangen.de/dev/valueCoding/YesNo_en',
                                                version: '1.0',
                                                code: '1',
                                                display: 'Yes'
                                            }
                                        }
                                    ],
                                    required: true
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            linkId: 'PIF8',
            prefix: 'PIF8',
            text: 'Consider bringing your carer or a friend with you when you visit your physiotherapist. Two heads are better than one!',
            type: 'display'
        }
    ]
};
