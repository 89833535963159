import {createRef, useCallback, useEffect, useState} from 'react';
import type {ReactElement, ChangeEvent, KeyboardEvent} from 'react';
import {
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    TextField
} from '@material-ui/core';
import './Login.css';
import type OneInstanceModel from '../../model/OneInstanceModel';
import i18n from '../../i18n';
import Paper from '@material-ui/core/Paper';
import {useHistory} from 'react-router-dom';
import {Icon} from '../icon/Icon';
import {displayCircularProgress, hideCircularProgress, isStandalone} from '../utils/Utils';
import type ContactModel from 'one.models/lib/models/ContactModel';
import type RecoveryModel from 'one.models/lib/models/RecoveryModel';
import {isMobile} from 'react-device-detect';
import {NOTIFICATION, useNotificationContext} from '../notification/SnackbarNotification';
import DialogActions from '@material-ui/core/DialogActions';
import windowAPI from '../modelHelper/WebViewHelper';
import CircularProgress from '@material-ui/core/CircularProgress';
import type {Product} from '../Ui';

const USER_MODE = {
    NoUser: 0,
    NewUser: 1,
    UserHasAccountOnOtherDevice: 2,
    ExistingUser: 3,
    Recovery: 4,
    ChooseInstanceOrInvitation: 5
} as const;

/** The type definition based on the USER_MODE value. **/
type UserState = typeof USER_MODE[keyof typeof USER_MODE];

type JsonFileContent = {
    email: string;
    secretEncryptionKey: string;
    secretSignKey: string;
    publicEncryptionKey: string;
    publicSignKey: string;
    productType: Product;
};

/**
 * Displays Login page
 * @param props
 * @param props.oneInstanceModel
 * @param props.fromErase
 * @param props.setFromErase
 * @param props.contactModel
 * @param props.recoveryModel
 * @param props.onRegistrationSelected
 * @param props.isRegistered
 * @param props.setProductTypeCallback
 * @param props.isLoggedIn
 * @returns
 */
export default function Login(props: {
    oneInstanceModel: OneInstanceModel;
    fromErase: boolean;
    setFromErase: (setLogoutFromErase: boolean) => void;
    contactModel: ContactModel;
    recoveryModel: RecoveryModel;
    onRegistrationSelected: () => void;
    isRegistered: (value: boolean) => void;
    setProductTypeCallback: (productType: Product) => void;
    isLoggedIn: (value: boolean) => void;
}): ReactElement {
    const [userMode, setUserMode] = useState<UserState>(USER_MODE.NoUser);
    const [showEraseDialog, setShowEraseDialog] = useState(false);

    const [secretLogin, setSecretLogin] = useState('');

    const [secretRegister, setSecretRegister] = useState('');
    const [confirmSecretRegister, setConfirmSecretRegister] = useState('');

    const [secretRecovery, setSecretRecovery] = useState('');
    const [confirmSecretRecovery, setConfirmSecretRecovery] = useState('');
    const [recoveryKey, setRecoveryKey] = useState('');

    const {setNotificationMessage, setNotificationType} = useNotificationContext();

    const [isPersonalCloudInvite, setIsPersonalCouldInvite] = useState(false);
    const [isInviteFromCache, setIsInviteFromCache] = useState(false);

    const [savedToken, setSavedToken] = useState('');

    const [disableLogin, setDisableLogin] = useState(false);
    const [jsonContent, setJsonContent] = useState<JsonFileContent>();
    const inputFileRef = createRef<HTMLDivElement>();

    // consider just the case for instance recover, and not the recovery page
    const isRecoveryMode = window.location.pathname.split('/')[1] === 'recovery';
    const history = useHistory();
    // TODO: Remove the patientType, after restructuring of the oneInstanceModel functions to allow for type undefined. It just does not make sense to pass it from the ui.
    const patientType = 'patient';

    // prevent the spam of the scan qr button
    const [isScanQrClicked, setScanQrClicked] = useState(false);

    let disconnect: () => void;

    useEffect(() => {
        if (
            window.location.pathname === '/invites/personalCloud/' &&
            window.location.search.includes('invited=true')
        ) {
            setIsPersonalCouldInvite(true);
        }

        // changing the color of the footer to be visible
        document.getElementsByTagName('footer')[0].style.color = '#ffffff';

        const rootHMTMLElement = document.getElementById('root');

        if (rootHMTMLElement) {
            rootHMTMLElement.classList.add('root-for-login');
        }

        return () => {
            if (rootHMTMLElement) {
                rootHMTMLElement.classList.remove('root-for-login');
            }

            document.getElementsByTagName('footer')[0].style.color = '#2a5fa6';
        };
    }, []);

    /**
     * Handler for when the user decides to continue with the current instance
     */
    const handleContinueWithCurrentInstance = useCallback(() => {
        history.push('/');

        setSavedToken('');
        setIsInviteFromCache(false);
        setIsPersonalCouldInvite(false);

        setUserMode(USER_MODE.ExistingUser);
    }, [history]);

    /**
     * This useEffect has the role of setting the type of a user, which then depending on this userMode will be loaded
     * the correct view on the page. There are several types of users:
     *  - USER_MODE.ChooseInstanceOrInvitation: is a user, who must choose between keeping the current instance or accepting the received invitation.
     *  - USER_MODE.Recovery: is a user, who wants to recover his password.
     *  - USER_MODE.ExistingUser: is an existing user, because there is already an instance in localStorage.
     *  - USER_MODE.NoUser: is a new user within the application, who will have to register.
     */
    useEffect(() => {
        if (
            ((window.location.pathname.includes('invites') &&
                window.location.search.includes('invited=true')) ||
                savedToken) &&
            localStorage.getItem('instance') &&
            !isStandalone()
        ) {
            setUserMode(USER_MODE.ChooseInstanceOrInvitation);
        } else if (
            ((window.location.pathname.includes('invites') &&
                window.location.search.includes('invited=true')) ||
                savedToken) &&
            localStorage.getItem('instance') &&
            isStandalone()
        ) {
            handleContinueWithCurrentInstance();
        } else if (
            (isRecoveryMode || savedToken.includes('recovery')) &&
            isStandalone() &&
            localStorage.getItem('instance')
        ) {
            // hack for STH: because the dynamic manifest is used, the start_url it's always the original one
            handleContinueWithCurrentInstance();
        } else if (isRecoveryMode || savedToken.includes('recovery')) {
            setUserMode(USER_MODE.Recovery);
        } else if (localStorage.getItem('instance') || isPersonalCloudInvite) {
            setUserMode(USER_MODE.ExistingUser);
        } else {
            setUserMode(USER_MODE.NoUser);
        }
    }, [
        showEraseDialog,
        isPersonalCloudInvite,
        isRecoveryMode,
        savedToken,
        handleContinueWithCurrentInstance
    ]);

    /**
     * Listener for authstate_changed event
     */
    function listenForAuthentication(): void {
        disconnect = props.oneInstanceModel.onAuthStateChange(removeAuthenticationListener);
    }

    /**
     * Removes listener for authstate_changed event
     */
    function removeAuthenticationListener(): void {
        disconnect();
        hideCircularProgress();
    }

    /**
     * Login process
     * @param isForgotPasswordHack - a flag that specify if the function was called to init the instance for the delete data functionality.
     */
    async function onLogin(isForgotPasswordHack?: boolean): Promise<void> {
        /**
         * That's just about the forgot password functionality.
         * We need to initialize the instance before deleting the data
         * otherwise the db instance will be NULL and the data can not be deleted.
         *
         * Major issue: If someone has his password the string that is passed as
         * secret then by pressing on Forgot Password he will be logged in.
         *
         */
        if (isForgotPasswordHack) {
            try {
                await props.oneInstanceModel.login(
                    'Can not be empty!',
                    patientType,
                    isPersonalCloudInvite
                );
            } catch (_) {
                return;
            }

            return;
        }

        setDisableLogin(true);

        try {
            if (secretLogin === '') {
                setNotificationMessage('errors:oneInstanceModel.fillAllFields');
                setNotificationType(NOTIFICATION.Error);
                setDisableLogin(false);
                return;
            }

            await props.oneInstanceModel.login(secretLogin, patientType, isPersonalCloudInvite);

            // Used to know when the user is logged in.
            // Will be used in the UI.tsx to know if the study notifications should be scheduled
            // or not. We schedule the notification just after the user is logged in.
            props.isLoggedIn(true);

            // Only for home screen apps -> adds the hash saved in CacheStorage to the path
            if (isInviteFromCache && savedToken) {
                history.push(savedToken);
            }
            listenForAuthentication();
        } catch (err) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
            if (err.message.includes('SC-INIT1')) {
                setNotificationMessage('errors:login.passwordNotValid');
                setNotificationType(NOTIFICATION.Error);
            } else {
                console.error(err);
                setNotificationMessage(err);
                setNotificationType(NOTIFICATION.Error);
            }
            setDisableLogin(false);
            hideCircularProgress();
        }
    }

    /**
     * Register process
     */
    async function onRegister(): Promise<void> {
        let email,
            secretEncryptionKey,
            secretSignKey,
            publicEncryptionKey,
            publicSignKey,
            productType: Product | undefined;

        if (secretRegister === '') {
            setNotificationMessage('errors:oneInstanceModel.fillAllFields');
            setNotificationType(NOTIFICATION.Error);
            return;
        }

        if (confirmSecretRegister === '') {
            setNotificationMessage('errors:oneInstanceModel.fillAllFields');
            setNotificationType(NOTIFICATION.Error);
            return;
        }

        if (secretRegister !== confirmSecretRegister) {
            setNotificationMessage('errors:login.samePassword');
            setNotificationType(NOTIFICATION.Error);
            return;
        }

        displayCircularProgress();

        if (jsonContent) {
            email = jsonContent.email;
            secretEncryptionKey = jsonContent.secretEncryptionKey;
            secretSignKey = jsonContent.secretSignKey;
            publicEncryptionKey = jsonContent.publicEncryptionKey;
            publicSignKey = jsonContent.publicSignKey;
            productType = jsonContent.productType;
        }

        if (windowAPI) {
            try {
                const identityInfo = await windowAPI.getQRConfiguration();
                email = identityInfo.email;
                secretEncryptionKey = identityInfo.secretEncryptionKey;
                secretSignKey = identityInfo.secretSignKey;
                publicEncryptionKey = identityInfo.publicEncryptionKey;
                publicSignKey = identityInfo.publicSignKey;
                productType = identityInfo.productType;
            } catch {
                // promise is rejected if the QR configuration was not scanned. nothing to do
            }
        }

        try {
            props.onRegistrationSelected();
            await props.oneInstanceModel.register(
                secretRegister,
                patientType,
                email,
                secretEncryptionKey,
                secretSignKey,
                publicEncryptionKey,
                publicSignKey
            );

            if (productType) {
                localStorage.setItem('productType', productType);
                props.setProductTypeCallback(productType);
            }

            props.isRegistered(true);

            // Only for home screen apps -> adds the hash saved in CacheStorage to the path
            if (isInviteFromCache && savedToken) {
                history.push(savedToken);
            }
            listenForAuthentication();
        } catch (err) {
            setNotificationMessage(err.message);
            setNotificationType(NOTIFICATION.Error);
        }
    }

    /**
     * Recovery process
     */
    async function onRecovery(): Promise<void> {
        if (secretRecovery !== confirmSecretRecovery) {
            setNotificationMessage('errors:login.samePassword');
            setNotificationType(NOTIFICATION.Error);
            return;
        }

        history.push(window.location.hash.replace('%23', '#'));

        // the path for the recovery case is
        // #recoveryNonce#encryptedPersonInformation
        const recoveryNonce = window.location.hash.split('#')[1];
        const encryptedPersonInformation = window.location.hash.split('#')[2];

        try {
            displayCircularProgress();

            // Decrypt the person information from the qr code using the recovery nonce from the url
            // and the recovery key that the user has entered. From the decrypted information extract
            // user email and anonymous user email.
            const personEmails = await props.recoveryModel.decryptReceivedRecoveryInformation(
                recoveryKey,
                recoveryNonce,
                encryptedPersonInformation
            );
            await props.oneInstanceModel.recoverInstance(
                personEmails.personEmail,
                secretRecovery,
                patientType,
                personEmails.anonPersonEmail
            );
            listenForAuthentication();
        } catch (error) {
            setNotificationMessage(error);
            setNotificationType(NOTIFICATION.Error);
            hideCircularProgress();
        }
        history.push('/');
    }

    /**
     * Handler for the key down event during login
     * @param e
     * @private
     */
    async function _handleKeyDownLogin(e: KeyboardEvent<HTMLDivElement>): Promise<void> {
        if (e.key === 'Enter') {
            await onLogin();
        }
    }

    /**
     * Handler for the key down event during registration
     * @param e
     * @private
     */
    async function _handleKeyDownRegister(e: KeyboardEvent<HTMLDivElement>): Promise<void> {
        if (e.key === 'Enter') {
            await onRegister();
        }
    }

    /**
     * Handler for the key down event during recovery
     * @param e
     * @private
     */
    async function _handleKeyDownRecovery(e: KeyboardEvent<HTMLDivElement>): Promise<void> {
        if (e.key === 'Enter') {
            await onRecovery();
        }
    }

    /**
     * Handler for when the user decides to delete the current instance and to continue with the invitation
     */
    async function handleDeleteInstance(): Promise<void> {
        // todo figure out why this is an unsafe call
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        await props.oneInstanceModel.deleteUnopenedInstance();
        window.location.reload();
    }

    /**
     * Displays the erase paper
     * @returns {ReactElement}
     */
    function erasePaper(): ReactElement {
        return (
            <>
                {showEraseDialog && (
                    <Dialog
                        open={showEraseDialog}
                        className="erase-instance-popup"
                        onClose={() => setShowEraseDialog(false)}
                    >
                        <DialogTitle className="erase-instance-title">
                            {i18n.t('login:configuration.resetPasswordTitle')}
                        </DialogTitle>
                        <DialogContent className="erase-instance-content">
                            <DialogContentText>
                                {i18n.t('login:configuration.resetPasswordMessage1')}
                                <br />
                                {i18n.t('login:configuration.resetPasswordMessage2')}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions className="dialog-actions">
                            <Button
                                onClick={async () => {
                                    await props.oneInstanceModel.eraseWhileLoggedOut();
                                    window.location.reload();
                                }}
                                color="default"
                                className="remove-instance-btn"
                                variant="contained"
                            >
                                {i18n.t('login:configuration.removeInstance')}
                            </Button>
                            <Button
                                onClick={() => setShowEraseDialog(false)}
                                color="default"
                                className="close-dialog-btn"
                                variant="contained"
                            >
                                {i18n.t('common:settings.cancel')}
                            </Button>
                        </DialogActions>
                    </Dialog>
                )}
            </>
        );
    }

    /**
     * Trigger to open the 'Open file' dialog
     */
    function triggerInputFile(): void {
        if (inputFileRef.current !== undefined && inputFileRef.current !== null) {
            if (inputFileRef.current.click !== undefined) {
                inputFileRef.current.click();
            }
        }
    }

    /**
     *
     * @param event
     *
     */
    function importJSONInstance(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
        let uploadedFile: File | undefined;

        if (event.target instanceof HTMLInputElement) {
            if (event.target.files !== null) {
                uploadedFile = event.target.files[0];
            }
        }

        if (!uploadedFile) {
            return;
        }

        const fileReader = new FileReader();
        fileReader.readAsText(uploadedFile, 'UTF-8');

        fileReader.onload = function (e) {
            if (e.target !== null && e.target.result) {
                setJsonContent(JSON.parse(e.target.result as string) as JsonFileContent);
                setUserMode(USER_MODE.NewUser);
            }
        };
    }

    /**
     * This function renders the initial view, when there is no instance and no invitation
     * @returns {ReactElement}
     */
    function renderStartView(): ReactElement {
        return (
            <>
                <div className="login-introduction-box">
                    <Paper square elevation={3} className="paper-login paper-font-size">
                        {i18n.t('login:configuration.information')}
                    </Paper>
                </div>
                <div className="login-button">
                    {isMobile && windowAPI !== undefined && (
                        <Button
                            onClick={async () => {
                                if (windowAPI) {
                                    setScanQrClicked(true);
                                    await windowAPI.scanQRConfiguration();
                                    setScanQrClicked(false);

                                    try {
                                        await windowAPI.getQRConfiguration();
                                        setUserMode(USER_MODE.NewUser);
                                    } catch {
                                        setUserMode(USER_MODE.NoUser);
                                    }
                                }
                            }}
                            disabled={isScanQrClicked}
                            color="default"
                            variant="contained"
                            className="scan-qr-button"
                        >
                            {i18n.t('common:menu.scanQr')}
                        </Button>
                    )}
                    {windowAPI === undefined && (
                        <>
                            <Button
                                onClick={triggerInputFile}
                                color="default"
                                variant="contained"
                                className="upload-json-btn"
                            >
                                {i18n.t('common:buttons.uploadJson')}
                            </Button>
                            <Button
                                onClick={() => {
                                    setUserMode(USER_MODE.NewUser);
                                    localStorage.setItem('isRandomInstance', 'true');
                                }}
                                color="default"
                                variant="contained"
                            >
                                {i18n.t('common:buttons.newAccount')}
                            </Button>
                        </>
                    )}
                </div>
                <label>
                    <TextField
                        ref={inputFileRef}
                        type="file"
                        id="file-input"
                        inputProps={{accept: '.json'}}
                        onChange={importJSONInstance}
                        className="hide"
                    />
                </label>
                <Dialog
                    onClose={() => {
                        // eslint-disable-next-line react/prop-types
                        props.setFromErase(false);
                    }}
                    // eslint-disable-next-line react/prop-types
                    open={props.fromErase}
                >
                    <DialogContent>
                        <DialogContentText> {i18n.t('common:loginAfterErase')} </DialogContentText>
                        {/* eslint-disable-next-line react/prop-types */}
                        <Button className="ok-button" onClick={() => props.setFromErase(false)}>
                            {i18n.t('common:buttons.okButton')}
                        </Button>
                    </DialogContent>
                </Dialog>
            </>
        );
    }

    /**
     * This function renders the registration view
     * @returns {ReactElement}
     */
    function renderNewUser(): ReactElement {
        return (
            <div className="login-introduction-box">
                <Paper square elevation={3} className="paper-login paper-font-size">
                    {i18n.t('login:configuration.passwordInformation1')}
                    <br />
                    <br />
                    {i18n.t('login:configuration.passwordInformation2')}
                </Paper>
                <Paper square elevation={3} className="password-paper paper-font-size">
                    <div className="login-text-fields">
                        <TextField
                            fullWidth
                            label={i18n.t('login:configuration.password')}
                            type="password"
                            onKeyDown={async e => {
                                await _handleKeyDownRegister(e);
                            }}
                            value={secretRegister}
                            onChange={e => {
                                setSecretRegister(e.target.value);
                            }}
                            required
                        />
                    </div>
                    <div className="login-text-fields">
                        <TextField
                            fullWidth
                            label={i18n.t('login:configuration.confirmPassword')}
                            type="password"
                            value={confirmSecretRegister}
                            onKeyDown={async e => {
                                await _handleKeyDownRegister(e);
                            }}
                            onChange={e => setConfirmSecretRegister(e.target.value)}
                            required
                        />
                    </div>
                    <Button
                        className="set-password-button"
                        variant="contained"
                        color="default"
                        disabled={secretRegister === '' || confirmSecretRegister === ''}
                        onClick={async () => onRegister()}
                    >
                        {i18n.t('login:configuration.setPassword')}
                    </Button>
                </Paper>
            </div>
        );
    }

    /**
     * This function renders the recovery view
     * @returns {ReactElement}
     */
    function renderRecovery(): ReactElement {
        return (
            <div className="existing-user-space">
                <label className="title-register">{i18n.t('login:recovery')}</label>
                <div className="login-text-fields">
                    <TextField
                        fullWidth
                        label={i18n.t('login:password')}
                        type="password"
                        onKeyDown={async e => {
                            await _handleKeyDownRecovery(e);
                        }}
                        value={secretRecovery}
                        onChange={e => setSecretRecovery(e.target.value)}
                        required
                    />
                </div>
                <div className="login-text-fields">
                    <TextField
                        fullWidth
                        label={i18n.t('login:confirmPassword')}
                        type="password"
                        value={confirmSecretRecovery}
                        onKeyDown={async e => {
                            await _handleKeyDownRecovery(e);
                        }}
                        onChange={e => setConfirmSecretRecovery(e.target.value)}
                        required
                    />
                </div>
                <div className="login-text-fields">
                    <TextField
                        fullWidth
                        label={i18n.t('login:recoveryKey')}
                        value={recoveryKey}
                        onKeyDown={async e => {
                            await _handleKeyDownRecovery(e);
                        }}
                        onChange={e => setRecoveryKey(e.target.value)}
                        required
                    />
                </div>
                <div className="login-button">
                    <Button variant="contained" color="primary" onClick={async () => onRecovery()}>
                        {i18n.t('login:recovery')}
                    </Button>
                </div>
            </div>
        );
    }

    /**
     * This function renders the view when the user has an account on another device
     * @returns {ReactElement}
     */
    function renderUserHasAccountOnADevice(): ReactElement {
        return (
            <>
                <div className="title-register">{i18n.t('login:connect')}</div>
                <div className="login-text-fields">{i18n.t('login:existingUser')}</div>
            </>
        );
    }

    /**
     * This function renders the login view
     * @returns {ReactElement}
     */
    function renderExistingUser(): ReactElement {
        return (
            <div className="login-introduction-box">
                <Paper square elevation={3} className="paper-login paper-font-size">
                    {i18n.t('login:configuration.loginInformation')}
                </Paper>
                <Paper square elevation={3} className="password-paper paper-font-size">
                    <div className="login-text-fields">
                        <TextField
                            fullWidth
                            disabled={disableLogin}
                            label={i18n.t('login:configuration.password')}
                            type="password"
                            onKeyDown={async e => {
                                await _handleKeyDownLogin(e);
                            }}
                            value={secretLogin}
                            onChange={e => {
                                setSecretLogin(e.target.value);
                            }}
                            required
                        />
                    </div>
                    <div
                        className="forgot-password"
                        onClick={async () => {
                            await onLogin(true);
                            setShowEraseDialog(true);
                        }}
                    >
                        {i18n.t('login:configuration.forgotPassword')}
                    </div>
                    <Button
                        className="set-password-button"
                        disabled={secretLogin === '' || disableLogin}
                        variant="contained"
                        color="default"
                        onClick={async () => onLogin()}
                    >
                        {i18n.t('login:loginButton')}
                    </Button>
                </Paper>
            </div>
        );
    }

    /**
     * This function renders the view where the user needs to choose between the current instance or the invitation.
     * This view is loaded when there is already an instance on the current page/application, but the user receives a new invitation.
     *
     * Rule: If there is already an instance, then a new invitation cannot be accepted.
     * The user must choose between deleting or continuing with the current instance.
     * @returns {ReactElement}
     */
    function renderChooseInstanceOrInvitationView(): ReactElement {
        return (
            <>
                <p className="login-description-text">{i18n.t('login:disallowIoMInvitation')}</p>

                <div className="login-button">
                    <Button onClick={handleDeleteInstance} color="primary" variant="contained">
                        {i18n.t('common:buttons.deleteAvailableData')}
                    </Button>
                    <Button
                        onClick={handleContinueWithCurrentInstance}
                        color="primary"
                        variant="contained"
                    >
                        {i18n.t('login:continueButton')}
                    </Button>
                </div>
            </>
        );
    }

    /**
     * This function renders the right view, depending on the userMode
     * @returns {ReactElement}
     */
    function renderWhichTypeOfUserView(): ReactElement {
        switch (userMode) {
            case USER_MODE.NoUser:
                return renderStartView();
            case USER_MODE.NewUser:
                return renderNewUser();
            case USER_MODE.ExistingUser:
                return renderExistingUser();
            case USER_MODE.UserHasAccountOnOtherDevice:
                return (
                    <>
                        <IconButton
                            className="login-back-button"
                            onClick={() => setUserMode(USER_MODE.NoUser)}
                        >
                            <Icon name="ArrowLeft" />
                        </IconButton>
                        <>{renderUserHasAccountOnADevice()}</>
                    </>
                );
            case USER_MODE.Recovery:
                return renderRecovery();
            case USER_MODE.ChooseInstanceOrInvitation:
                return renderChooseInstanceOrInvitationView();
            default:
                return renderStartView();
        }
    }

    return (
        <div className="login-page-wrapper">
            <div className="circular-progress-container hide">
                <CircularProgress className="circular-progress register-loading" size={35} />
            </div>
            <div className="login-content page-container">{renderWhichTypeOfUserView()}</div>
            {erasePaper()}
        </div>
    );
}
