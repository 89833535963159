import type Model from '../model/Model';
import {Suspense, useCallback, useEffect, useState} from 'react';
import type {ReactElement} from 'react';
import {isIOS, isMobile, isSafari} from 'react-device-detect';
import './Ui.css';
import MenuButton from './menu/MenuButton';
import NavigationDrawer from './menu/NavigationDrawer';
import type {NavigationDrawerItemsType} from './menu/NavigationDrawer';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import Journal from './journal/Journal';
import type {EventTypes} from './journal/Journal';
import EventsCalendar from './calendar/EventsCalendar';
import {AUTHENTICATION_STATE, LOGOUT_MODE} from '../model/OneInstanceModel';
import {useAuthenticationState} from './modelHelper/OneInstanceHelper';
import Login from './login/Login';
import Loading from './loading/Loading';
import NewsCreator from './news/NewsCreator';
import NewsReader from './news/NewsReader';
import EraseDialog from './eraseInstance/EraseDialog';
import PersonalCloud from './cloudConnections/PersonalCloud';
import CreateAcceptInvites from './cloudConnections/CreateAcceptInvites';
import AddWBCMeasurement from './wbcForm/AddWBCMeasurement';
import DiaryEntryPage from './diary/DiaryEntry';
import AddBodyTemperature from './bodyTemperature/AddBodyTemperature';
import SmilerOverview from './studies/smilerStudy/SmilerOverview';
import i18n from '../i18n';
import '../Primary.css';
import {smilerEventTypes, smilerNavigationDrawerItems} from './productData/smilerData';
import {devEventTypes, devNavigationDrawerItems} from './productData/devData';
import Backup from './backup/Backup';
import Logout from './logout/Logout';
import AddToHomeScreen from './addToHomeScreen/AddToHomeScreen';
import {ReactComponent as LandscapeMode} from './../resources/phone-rotate-portrait.svg';
import AutoLogout from './autoLogout/AutoLogout';
import SharedDetailsDialog from './journal/ShareDetailsDialog';
import OneBrowser from './oneBrowser/OneBrowser';
import type {PropertyTree} from 'one.models/lib/models/SettingsModel';
import RecoveryPage from './recovery/RecoveryPage';
import Erase from './eraseInstance/Erase';
import FooterElement from './footer/Footer';
import Feedback from './feedback/Feedback';
import FeedbackReader from './feedback/FeedbackReader';
import BackButton from './menu/BackButton';
import ChannelsView from './channels/ChannelsView';
import ChannelDetailedView from './channels/ChannelDetailedView';
import Connections from './cloudConnections/Connections';
import {isStandalone, usePrevious} from './utils/Utils';
import {ConnectionClosedIoM} from './cloudConnections/ConnectionErrorViews';
import Reset from './logout/Reset';
import EQ5D3LQuestionnaire from './questionnaire/EQ5D3LQuestionnaire';
import {EcgData} from './ecg/EcgData';
import DetailedView from './bodyTemperature/DetailedView';
import FileViewer from './importDocument/FileViewer';
import SnackbarNotification, {
    NOTIFICATION,
    NotificationContext
} from './notification/SnackbarNotification';
import type {NotificationType} from './notification/SnackbarNotification';
import {
    scheduleStudyNotification,
    useImpactStudyData,
    useSmilerStudyData
} from './modelHelper/StudyWorkflowHelper';
import Graph from './wbc/Graph';
import ConfigurationProcess from './configuration/ConfigurationProcess';
import {useIsDataSynced} from './modelHelper/DataSyncManagerHelper';
import {
    smilerImpactEventTypes,
    smilerImpactNavigationDrawerItems
} from './productData/smilerImpactData';
import ImpactOverview from './studies/ImpactStudy/ImpactOverview';
import SmilerImpactOverview from './studies/smilerImpactStudy/SmilerImpactOverview';
import EntryView from './journal/EntryView';
import EndOfStudyMessage from './studies/studyCommon/EndOfStudyMessage';
import windowAPI from './modelHelper/WebViewHelper';
import * as dateFns from 'date-fns';
import BloodGlucoseTable from './bloodGlucose/BloodGlucoseTable';
import {SMILER_VISIT, IMPACT_VISIT} from '../model/studies/StudyHelper';
import {CircularProgress} from '@material-ui/core';
import OptionalTasksView from './studies/studyCommon/OptionalTasksView';
import QuestionnaireDemo from './DevQuestionnaire/QuestionnaireDemo';

/**
 * Additional properties for React Route.
 */
type RouteProps = {
    to: string;
};

/**
 * List of product types
 */
export const PRODUCT_TYPE = {
    Smiler: 'smiler',
    Dev: 'dev',
    Smiler_impact: 'smiler_impact'
} as const;

/** The type definition based on the PRODUCT_TYPE value. **/
export type Product = typeof PRODUCT_TYPE[keyof typeof PRODUCT_TYPE];

const FOOTER = {
    NoFooter: 0,
    FooterPage: 1
} as const;

/** The type definition based on the FOOTER value. **/
export type ViewFooter = typeof FOOTER[keyof typeof FOOTER];

const UPLOAD_SUCCESS = {
    Document: 'document',
    Wbc: 'wbc',
    Ecg: 'ecg',
    Questionnaire: 'questionnaire'
} as const;

/** The type definition based on the UPLOAD_SUCCESS value. **/
export type UploadedObject = typeof UPLOAD_SUCCESS[keyof typeof UPLOAD_SUCCESS];

/**
 * Render the ui (top level element)
 *
 * TODO: remove the patient type property again when it is handled by the models
 *
 * @param {{}} props
 * @param {Model} props.model
 * @param {PropertyTree} props.settings
 * @returns {ReactElement}
 */
export default function Ui(props: {model: Model; settings: PropertyTree}): ReactElement {
    // Since we are providing the product type through the .json file
    // or qr code and we store it in localStorage then we need to load it and to display the right product type
    const product = localStorage.getItem('productType');

    /** the product type must exist as a state for re-rendering the UI in case of change **/
    const [productType, setProductType] = useState<Product>(
        product === null || product === 'smiler'
            ? PRODUCT_TYPE.Smiler
            : product === 'smiler_impact'
            ? PRODUCT_TYPE.Smiler_impact
            : product === 'dev'
            ? PRODUCT_TYPE.Dev
            : PRODUCT_TYPE.Smiler
    );
    const authenticationState = useAuthenticationState(props.model.oneInstance);
    const [navigationDrawerState, setNavigationDrawerState] = useState(false);
    const [menuContentStyle, setMenuContentStyle] = useState('app-left-container');
    const [navigationDrawerItemsState, updateNavigationDrawerItemsState] = useState<
        NavigationDrawerItemsType[]
    >([]);
    const [footerPage, setFooterPage] = useState<ViewFooter>(FOOTER.NoFooter);
    const [eventTypesState, updateEventTypesState] = useState<EventTypes[]>([]);
    const [showFilterButtons, updateShowFilterButtons] = useState(false);
    const [logoutFromErase, setLogoutFromErase] = useState(false);
    const [offlineDialog, setOfflineDialog] = useState(false);
    const [successfulConnection, setSuccessfulConnection] = useState(false);
    const [displayRegistrationStepper, setDisplayRegistrationStepper] = useState(false);
    // flag for knowing when we are fresh registered in order to display the loading wheel while data it's syncing in the home screen
    const [isRegistered, setRegistered] = useState(false);

    // Flag for knowing if the user is logged in.
    // This is used by the notification scheduler in order to schedule the notifications just
    // after the user is logged in the application.
    const [isLoggedIn, setLoggedIn] = useState(false);

    // When Smiler study it's over then we need to display a popup with a specific message,
    // the smiler workflow model provide us a notification when the study is over and then the
    // popup is displayed.
    const displayStudyEndedMessage_Smiler = useSmilerStudyData(
        props.model.smilerWorkflow
    ).displayStudyEndedMessage;

    // When Impact study it's over then we need to display a popup with a specific message,
    // the impact workflow model provide us a notification when the study is over and then the
    // popup is displayed.
    const displayStudyEndedMessage_Impact = useImpactStudyData(
        props.model.impactWorkflow
    ).displayStudyEndedMessage;

    // state for handling the end of the study popup
    const [endOfStudyMessage, setEndOfStudyMessage] = useState<ReactElement>(<></>);
    const [displayEndOfStudyPopup, setDisplayEndOfStudyPopup] = useState(false);

    // this checks if the invitation failed
    const appBrokeOnInvite = localStorage.getItem('appBrokeOnInvite');

    // used to handle the notification
    const [notificationMessage, setNotificationMessage] = useState<string>('');
    const [notificationType, setNotificationType] = useState<NotificationType>(
        NOTIFICATION.Success
    );

    // needed to enable/disable the notification that appears when a questionnaire/photo/ecg/wbc is posted to the channel
    // also used to display the loading wheel until the sync with the replicant is done for
    // smiler and smiler-impact product types
    const isSyncWithReplicantDone = useIsDataSynced(props.model.dataSyncManager);

    // the previous value of the display registration stepper flag, in this way we know when the
    // user proceed the registration steps and we can display the loading wheel until the data
    // sync is done
    const prevDisplayRegistrationStepper = usePrevious(displayRegistrationStepper);

    // impact active visit
    const impactVisit = props.model.impactWorkflow.getActiveVisit();

    /**
     * Updates the productData -> Smiler, Smiler_impact or Dev
     */
    const updateProductData = useCallback(() => {
        // This switch is there to get rid of the ts any type error ...
        // a different solution would be nice
        switch (productType) {
            case PRODUCT_TYPE.Smiler:
                updateNavigationDrawerItemsState(smilerNavigationDrawerItems());
                updateEventTypesState(smilerEventTypes());
                updateShowFilterButtons(true);
                document.title = 'smiler';
                break;
            case PRODUCT_TYPE.Smiler_impact:
                updateNavigationDrawerItemsState(smilerImpactNavigationDrawerItems());
                updateEventTypesState(smilerImpactEventTypes());
                updateShowFilterButtons(true);
                document.title = 'smiler_impact';
                break;
            case PRODUCT_TYPE.Dev:
                updateNavigationDrawerItemsState(devNavigationDrawerItems());
                updateEventTypesState(devEventTypes());
                updateShowFilterButtons(true);
                document.title = 'development';
                break;
        }
    }, [productType]);

    /**
     * When shift+click on menu button is register, the product
     * type will be changed with the new product type.
     */
    function updateCurrentProductType(): void {
        switch (productType) {
            case PRODUCT_TYPE.Smiler:
                setProductType(PRODUCT_TYPE.Smiler_impact);
                break;
            case PRODUCT_TYPE.Smiler_impact:
                setProductType(PRODUCT_TYPE.Dev);
                break;
            case PRODUCT_TYPE.Dev:
                setProductType(PRODUCT_TYPE.Smiler);
                break;
            default:
                break;
        }
    }

    /**
     * Depending on the logout model either log out the
     * user either erase all data from this device.
     */
    function renderLogoutComponent(): ReactElement {
        const path = window.location.href.split('=');
        const logoutMode = path[path.length - 1];

        if (logoutMode === 'true') {
            return (
                <Logout
                    setNavigationDrawer={setNavigationDrawerState}
                    oneInstanceModel={props.model.oneInstance}
                    setLoggedIn={setLoggedIn}
                />
            );
        } else {
            return (
                <EraseDialog
                    setLogoutFromErase={setLogoutFromErase}
                    consentFileModel={props.model.consentFile}
                    oneInstanceModel={props.model.oneInstance}
                    setNavigationDrawer={setNavigationDrawerState}
                    settings={props.settings}
                />
            );
        }
    }

    useEffect(() => {
        window.location.pathname === '/impressum' || window.location.pathname === '/privacyPolicy'
            ? setFooterPage(FOOTER.FooterPage)
            : setFooterPage(FOOTER.NoFooter);
        window.addEventListener('popstate', () => {
            window.location.pathname === '/impressum' ||
            window.location.pathname === '/privacyPolicy'
                ? setFooterPage(FOOTER.FooterPage)
                : setFooterPage(FOOTER.NoFooter);
        });
    }, [footerPage]);

    useEffect(() => {
        updateProductData();
    }, [updateProductData]);

    // Set a listener for the online state, when the onlineState is false means you are in offline mode.
    // So we have to let the user know about this.
    useEffect(() => {
        /**
         * Handler for the 'onlineStateChange' event -> this event is triggered when the application goes offline
         * @param {boolean} onlineState
         */
        function handleOnlineStateChangeEvent(onlineState: boolean): void {
            if (!onlineState && !offlineDialog) {
                setOfflineDialog(true);
            }
        }

        const disconnect = props.model.connections.onOnlineStateChange(
            handleOnlineStateChangeEvent
        );

        /**
         * Handler for the 'visibilityChange' event -> this event is triggered when the standalone application(saved on home screen)
         * is opened or closed from background.
         */
        function handleVisibilityChangeEvent(): void {
            if (localStorage.getItem('instance')) {
                if (document.hidden) {
                    localStorage.setItem('sleepSinceTS', new Date().getTime().toString());
                    return;
                }

                if (localStorage.getItem('sleepSinceTS')) {
                    const sleepSinceTS = Number(localStorage.getItem('sleepSinceTS'));
                    const nowTS = new Date().getTime();

                    // 60000  = 1 minute
                    if (nowTS - sleepSinceTS > 10 * 60000) {
                        props.model.oneInstance
                            .logout(LOGOUT_MODE.KeepData)
                            .catch(err => console.error(err));
                    }
                }
            }
        }

        if (isStandalone()) {
            document.addEventListener('visibilitychange', handleVisibilityChangeEvent);
        }

        return () => {
            disconnect();
            document.removeEventListener('visibilitychange', handleVisibilityChangeEvent);
        };
    }, [offlineDialog, props.model.connections, props.model.oneInstance]);

    // Set the style for open / closed menu
    useEffect(() => {
        if (navigationDrawerState) {
            setMenuContentStyle('app-right-container');
        } else {
            setMenuContentStyle('app-left-container');
        }
    }, [navigationDrawerState, authenticationState]);

    i18n.on('languageChanged', () => {
        updateProductData();
    });

    /**
     * This effect is used to listen for events that are triggered by the models.
     * Based on the model that trigger the event,
     * a success notification will be displayed with a proper message.
     */
    useEffect(() => {
        /**
         * Used to set the message of the notification when a questionnaire/photo/ecg/wbc was added.
         * @param uploadedData - which data was added.
         */
        function buildMessage(uploadedData: UploadedObject): void {
            if (isSyncWithReplicantDone) {
                setNotificationMessage(`common:successNotification.${uploadedData}`);
                setNotificationType(NOTIFICATION.Success);
            }
        }

        const documentModelDisconnect = props.model.documents.onUpdated(() =>
            buildMessage(UPLOAD_SUCCESS.Document)
        );
        const questionnaireModelDisconnect = props.model.questionnaires.onUpdated(() =>
            buildMessage(UPLOAD_SUCCESS.Questionnaire)
        );

        const wbcModelDisconnect = props.model.wbcDiffs.onUpdated(() =>
            buildMessage(UPLOAD_SUCCESS.Wbc)
        );

        const ecgModelDisconnect = props.model.ecgModel.onUpdated(() =>
            buildMessage(UPLOAD_SUCCESS.Ecg)
        );

        return () => {
            documentModelDisconnect();
            questionnaireModelDisconnect();
            wbcModelDisconnect();
            ecgModelDisconnect();
        };
    }, [
        props.model.documents,
        props.model.questionnaires,
        props.model.wbcDiffs,
        props.model.ecgModel,
        isSyncWithReplicantDone
    ]);

    /**
     * Used to set the display end of the study message flag to false after it was displayed.
     * This ensure that the message it's displayed once.
     */
    useEffect(() => {
        if (!displayEndOfStudyPopup) {
            props.model.smilerWorkflow.setDisplayStudyEndedMessage(false);
            props.model.impactWorkflow.setDisplayStudyEndedMessage(false);
        }
    }, [displayEndOfStudyPopup, props.model.smilerWorkflow, props.model.impactWorkflow]);

    /**
     * Display the end of the study message when
     * the flag for displaying it received from the model is true.
     */
    useEffect(() => {
        if (displayStudyEndedMessage_Smiler || displayStudyEndedMessage_Impact) {
            setEndOfStudyMessage(<EndOfStudyMessage />);
            setDisplayEndOfStudyPopup(true);
        }
    }, [displayStudyEndedMessage_Smiler, displayStudyEndedMessage_Impact]);

    /**
     * Used to schedule the local push notifications for user reminders to fill out their study
     * tasks in e.g. the Smiler Study and Impact Study.
     */
    useEffect(() => {
        if (windowAPI === undefined || (!isLoggedIn && !isRegistered) || !isSyncWithReplicantDone) {
            return;
        }

        const smilerNotifications = [
            {
                title: i18n.t('studies:smiler.notificationTitle'),
                body: i18n.t('studies:smiler.notificationBody'),
                timestamp: dateFns
                    .addDays(props.model.smilerWorkflow.getStartStudy(), SMILER_VISIT.Day_14)
                    .setHours(8, 0, 0)
            },
            {
                title: i18n.t('studies:smiler.notificationTitle'),
                body: i18n.t('studies:smiler.notificationBody'),
                timestamp: dateFns
                    .addDays(props.model.smilerWorkflow.getStartStudy(), SMILER_VISIT.Day_28)
                    .setHours(8, 0, 0)
            }
        ];

        /**
         * In the case of an instance recovery old data gets synchronized which can trigger
         * wrong notifications that need to be filtered out.
         */
        let filteredNotifications: {title: string; body: string; timestamp: number}[] = [];

        switch (productType) {
            case PRODUCT_TYPE.Smiler_impact: {
                filteredNotifications = [
                    ...smilerNotifications,
                    {
                        title: i18n.t('studies:impact.notificationTitle'),
                        body: i18n.t('studies:impact.notificationBody'),
                        timestamp: dateFns
                            .addDays(props.model.smilerWorkflow.getStartStudy(), IMPACT_VISIT.Day_7)
                            .setHours(8, 0, 0)
                    },
                    {
                        title: i18n.t('studies:impact.notificationTitle'),
                        body: i18n.t('studies:impact.notificationBody'),
                        timestamp: dateFns
                            .addDays(
                                props.model.smilerWorkflow.getStartStudy(),
                                IMPACT_VISIT.Day_14
                            )
                            // added 3 more seconds because we have already a notification
                            // scheduled at 8:00:00 AM and iOS is unable to send 2
                            // notifications at the same time (preventing spam probably)
                            .setHours(8, 0, 3)
                    },
                    {
                        title: i18n.t('studies:impact.notificationTitle'),
                        body: i18n.t('studies:impact.notificationBody'),
                        timestamp: dateFns
                            .addDays(
                                props.model.smilerWorkflow.getStartStudy(),
                                IMPACT_VISIT.Day_21
                            )
                            .setHours(8, 0, 0)
                    }
                ].filter(notification => notification.timestamp > Date.now());
                break;
            }
            // Smiler and Dev have the same behaviour so fall trough
            case PRODUCT_TYPE.Smiler:
            case PRODUCT_TYPE.Dev:
                filteredNotifications = smilerNotifications.filter(
                    notification => notification.timestamp > Date.now()
                );
                break;
            default:
                break;
        }

        scheduleStudyNotification(windowAPI, filteredNotifications, 0);
    }, [
        isLoggedIn,
        isRegistered,
        isSyncWithReplicantDone,
        productType,
        props.model.smilerWorkflow
    ]);

    /**
     *
     * if there is the appBrokeOnInvite flag set this means that the invitation failed and
     * the login page will not be shown anymore and an error blocking page will appear
     * if not login is rendered normally
     */
    function renderLoginOrErrorView(): ReactElement {
        if (!appBrokeOnInvite) {
            return (
                <Login
                    oneInstanceModel={props.model.oneInstance}
                    fromErase={logoutFromErase}
                    setFromErase={setLogoutFromErase}
                    contactModel={props.model.contactModel}
                    recoveryModel={props.model.recoveryModel}
                    onRegistrationSelected={() => {
                        setDisplayRegistrationStepper(true);
                    }}
                    isRegistered={setRegistered}
                    setProductTypeCallback={setProductType}
                    isLoggedIn={setLoggedIn}
                />
            );
        }

        if (appBrokeOnInvite === 'IoM') {
            return <ConnectionClosedIoM oneInstanceModel={props.model.oneInstance} />;
        }

        return (
            <Login
                oneInstanceModel={props.model.oneInstance}
                fromErase={logoutFromErase}
                setFromErase={setLogoutFromErase}
                contactModel={props.model.contactModel}
                recoveryModel={props.model.recoveryModel}
                onRegistrationSelected={() => {
                    setDisplayRegistrationStepper(true);
                }}
                isRegistered={setRegistered}
                setProductTypeCallback={setProductType}
                isLoggedIn={setLoggedIn}
            />
        );
    }

    return (
        // added to prevent the double tap zoom for ios devices
        // NOTE: the click listener is mandatory together with css: "touch-action: manipulation"
        // in order to prevent the double tap zoom
        <div className="remove-double-tap-zoom" onClick={() => undefined}>
            <Suspense fallback="loading">
                <BrowserRouter>
                    <div className="mobile-landscape-notification">
                        <LandscapeMode />
                        <div>{i18n.t('common:landscapeWarning')}</div>
                    </div>
                    <div className={menuContentStyle}>
                        <div
                            className="mobile-app-overlay"
                            onClick={() => setNavigationDrawerState(false)}
                        />
                        <div className="app-content-wrapper">
                            <NotificationContext.Provider
                                value={{
                                    setNotificationMessage: setNotificationMessage,
                                    setNotificationType: setNotificationType
                                }}
                            >
                                <BackButton isHidden={true} />
                                {isIOS && isMobile && isSafari && !isStandalone() ? (
                                    <AddToHomeScreen />
                                ) : authenticationState === AUTHENTICATION_STATE.Authenticated &&
                                  footerPage === FOOTER.NoFooter ? (
                                    <>
                                        {displayRegistrationStepper ? (
                                            <Switch>
                                                <Route path={'/invites/personalCloud'}>
                                                    <CreateAcceptInvites
                                                        connectionsModel={props.model.connections}
                                                        oneInstanceModel={props.model.oneInstance}
                                                        accessModel={props.model.accessModel}
                                                        setSuccessfulConnection={
                                                            setSuccessfulConnection
                                                        }
                                                    />
                                                </Route>
                                                <Route>
                                                    <ConfigurationProcess
                                                        oneInstanceModel={props.model.oneInstance}
                                                        onRegistrationCompleted={() => {
                                                            setDisplayRegistrationStepper(false);
                                                        }}
                                                    />
                                                </Route>
                                            </Switch>
                                        ) : (
                                            <>
                                                {!isSyncWithReplicantDone &&
                                                !displayRegistrationStepper &&
                                                prevDisplayRegistrationStepper ? (
                                                    <div className="circular-progress-container">
                                                        <CircularProgress
                                                            className="circular-progress"
                                                            size={35}
                                                        />
                                                    </div>
                                                ) : (
                                                    <Switch>
                                                        <Route exact path="/settings/erase">
                                                            <Erase
                                                                setLogoutFromErase={
                                                                    setLogoutFromErase
                                                                }
                                                                consentFileModel={
                                                                    props.model.consentFile
                                                                }
                                                                oneInstanceModel={
                                                                    props.model.oneInstance
                                                                }
                                                                setNavigationDrawer={
                                                                    setNavigationDrawerState
                                                                }
                                                                settings={props.settings}
                                                            />
                                                        </Route>
                                                        <Route exact path="/">
                                                            {productType ===
                                                            PRODUCT_TYPE.Smiler_impact ? (
                                                                <SmilerImpactOverview
                                                                    impactWorkflow={
                                                                        props.model.impactWorkflow
                                                                    }
                                                                    smilerWorkflow={
                                                                        props.model.smilerWorkflow
                                                                    }
                                                                    smilerImpactWorkflow={
                                                                        props.model
                                                                            .smilerImpactWorkflow
                                                                    }
                                                                    audioExerciseModel={
                                                                        props.model.audioExercise
                                                                    }
                                                                    documentModel={
                                                                        props.model.documents
                                                                    }
                                                                />
                                                            ) : (
                                                                <SmilerOverview
                                                                    smilerWorkflow={
                                                                        props.model.smilerWorkflow
                                                                    }
                                                                    documentModel={
                                                                        props.model.documents
                                                                    }
                                                                    dataSyncManager={
                                                                        props.model.dataSyncManager
                                                                    }
                                                                    productType={productType}
                                                                />
                                                            )}
                                                        </Route>
                                                        <Route exact path="/smiler">
                                                            <SmilerOverview
                                                                smilerWorkflow={
                                                                    props.model.smilerWorkflow
                                                                }
                                                                documentModel={
                                                                    props.model.documents
                                                                }
                                                                dataSyncManager={
                                                                    props.model.dataSyncManager
                                                                }
                                                                productType={productType}
                                                            />
                                                        </Route>
                                                        <Route exact path="/impact">
                                                            <ImpactOverview
                                                                impactWorkflow={
                                                                    props.model.impactWorkflow
                                                                }
                                                                audioExerciseModel={
                                                                    props.model.audioExercise
                                                                }
                                                                productType={productType}
                                                            />
                                                        </Route>
                                                        <Route path="/eventsCalendar">
                                                            <EventsCalendar
                                                                journalModel={props.model.journal}
                                                                eventTypes={eventTypesState}
                                                            />
                                                        </Route>
                                                        <Route path="/feedback">
                                                            <Feedback
                                                                newsModel={props.model.news}
                                                            />
                                                        </Route>
                                                        <Route path="/feedbacks">
                                                            <FeedbackReader
                                                                newsModel={props.model.news}
                                                            />
                                                        </Route>
                                                        <Route path="/questionnaire/:action">
                                                            <QuestionnaireDemo
                                                                questionnaireModel={
                                                                    props.model.questionnaires
                                                                }
                                                            />
                                                        </Route>
                                                        <Route path="/journal">
                                                            <Journal
                                                                showFilterButtons={
                                                                    showFilterButtons
                                                                }
                                                                journalModel={props.model.journal}
                                                                eventTypes={eventTypesState}
                                                                productType={productType}
                                                                ecgModel={props.model.ecgModel}
                                                                questionnaireModel={
                                                                    props.model.questionnaires
                                                                }
                                                                documentModel={
                                                                    props.model.documents
                                                                }
                                                                wbcModel={props.model.wbcDiffs}
                                                            />
                                                        </Route>
                                                        <Route path="/wbc-graph">
                                                            <Graph
                                                                wbcDataParser={
                                                                    props.model.wbcDataParser
                                                                }
                                                                smilerWorkflow={
                                                                    props.model.smilerWorkflow
                                                                }
                                                            />
                                                        </Route>
                                                        <Route<RouteProps>
                                                            path="/shareDetailsView/:entryId"
                                                            to="/shareDetailsView/:entryId?sharedByWhom=true|false"
                                                            render={() => {
                                                                const path =
                                                                    window.location.href.split('=');

                                                                const sharedWithWhom =
                                                                    path[path.length - 1];

                                                                if (sharedWithWhom === 'true') {
                                                                    return (
                                                                        <SharedDetailsDialog
                                                                            sharedByWhom={true}
                                                                            journalModel={
                                                                                props.model.journal
                                                                            }
                                                                        />
                                                                    );
                                                                } else {
                                                                    return (
                                                                        <SharedDetailsDialog
                                                                            sharedByWhom={false}
                                                                            journalModel={
                                                                                props.model.journal
                                                                            }
                                                                        />
                                                                    );
                                                                }
                                                            }}
                                                        />
                                                        <Route path="/diary/view/:diaryId">
                                                            <DiaryEntryPage
                                                                diaryModel={props.model.diary}
                                                            />
                                                        </Route>
                                                        <Route path="/diary">
                                                            <DiaryEntryPage
                                                                diaryModel={props.model.diary}
                                                            />
                                                        </Route>
                                                        <Route path="/view/:type/:documentId">
                                                            <FileViewer
                                                                documentModel={
                                                                    props.model.documents
                                                                }
                                                            />
                                                        </Route>
                                                        <Route path="/add-body-temperature">
                                                            <AddBodyTemperature
                                                                bodyTemperatureModel={
                                                                    props.model.bodyTemperature
                                                                }
                                                            />
                                                        </Route>
                                                        <Route path="/answerQuestionnaire/:action">
                                                            <EQ5D3LQuestionnaire
                                                                questionnaireModel={
                                                                    props.model.questionnaires
                                                                }
                                                            />
                                                        </Route>
                                                        <Route exact path="/settings/backup">
                                                            <Backup
                                                                oneInstanceModel={
                                                                    props.model.oneInstance
                                                                }
                                                            />
                                                        </Route>
                                                        <Route exact path="/personalCloud">
                                                            <PersonalCloud
                                                                connectionsModel={
                                                                    props.model.connections
                                                                }
                                                            />
                                                        </Route>
                                                        <Route
                                                            exact
                                                            path="/connections/personalCloud"
                                                        >
                                                            <PersonalCloud
                                                                connectionsModel={
                                                                    props.model.connections
                                                                }
                                                            />
                                                        </Route>
                                                        <Route exact path="/connections">
                                                            <Connections
                                                                connectionsModel={
                                                                    props.model.connections
                                                                }
                                                                accessModel={
                                                                    props.model.accessModel
                                                                }
                                                                instancesModel={
                                                                    props.model.instancesModel
                                                                }
                                                                oneInstanceModel={
                                                                    props.model.oneInstance
                                                                }
                                                                isDevelopmentMode={
                                                                    productType === PRODUCT_TYPE.Dev
                                                                }
                                                                successfulConnection={
                                                                    successfulConnection
                                                                }
                                                                setSuccessfulConnection={
                                                                    setSuccessfulConnection
                                                                }
                                                                setNavigationDrawer={
                                                                    setNavigationDrawerState
                                                                }
                                                            />
                                                        </Route>
                                                        <Route path="/invites">
                                                            <CreateAcceptInvites
                                                                connectionsModel={
                                                                    props.model.connections
                                                                }
                                                                oneInstanceModel={
                                                                    props.model.oneInstance
                                                                }
                                                                accessModel={
                                                                    props.model.accessModel
                                                                }
                                                                setSuccessfulConnection={
                                                                    setSuccessfulConnection
                                                                }
                                                            />
                                                        </Route>
                                                        <Route path="/addWbcMeasurement">
                                                            <AddWBCMeasurement
                                                                wbcModel={props.model.wbcDiffs}
                                                            />
                                                        </Route>
                                                        <Route path="/create-news">
                                                            <NewsCreator
                                                                newsModel={props.model.news}
                                                            />
                                                        </Route>
                                                        <Route path="/news">
                                                            <NewsReader
                                                                newsModel={props.model.news}
                                                            />
                                                        </Route>
                                                        <Route path="/body-temperature">
                                                            <DetailedView
                                                                bodyTemperatureModel={
                                                                    props.model.bodyTemperature
                                                                }
                                                            />
                                                        </Route>
                                                        <Route path="/body-temperature/:timestamp">
                                                            <DetailedView
                                                                bodyTemperatureModel={
                                                                    props.model.bodyTemperature
                                                                }
                                                            />
                                                        </Route>
                                                        <Route path="/settings/recovery">
                                                            <RecoveryPage
                                                                consentFileModel={
                                                                    props.model.consentFile
                                                                }
                                                                recoveryModel={
                                                                    props.model.recoveryModel
                                                                }
                                                            />
                                                        </Route>
                                                        <Route<RouteProps>
                                                            path="/logout"
                                                            to="/logout?keep=true|false"
                                                            render={renderLogoutComponent}
                                                        />
                                                        <Route path="/settings/oneBrowser">
                                                            <OneBrowser />
                                                        </Route>
                                                        <Route
                                                            exact
                                                            path="/settings/channels/:channelRegistryHash/:channelHash"
                                                        >
                                                            <ChannelDetailedView
                                                                channelManager={
                                                                    props.model.channelManager
                                                                }
                                                            />
                                                        </Route>
                                                        <Route path="/settings/channels">
                                                            <ChannelsView />
                                                        </Route>
                                                        <Route path="/ecgData/:ecgHash">
                                                            <EcgData
                                                                ecgModel={props.model.ecgModel}
                                                            />
                                                        </Route>
                                                        <Route exact path="/blood-glucose">
                                                            <BloodGlucoseTable
                                                                bloodGlucoseModel={
                                                                    props.model.bloodGlucoseModel
                                                                }
                                                            />
                                                        </Route>
                                                        <Route exact path="/tasks">
                                                            <OptionalTasksView
                                                                documentModel={
                                                                    props.model.documents
                                                                }
                                                                productType={productType}
                                                                impactWorkflow={
                                                                    props.model.impactWorkflow
                                                                }
                                                                audioExerciseModel={
                                                                    props.model.audioExercise
                                                                }
                                                                currentVisit={impactVisit}
                                                            />
                                                        </Route>
                                                    </Switch>
                                                )}
                                                {/* Display the top bar */}
                                                <AutoLogout
                                                    oneInstanceModel={props.model.oneInstance}
                                                />
                                                <MenuButton
                                                    isMenuOpen={navigationDrawerState}
                                                    isHidden={true}
                                                    clickCallback={() => {
                                                        setNavigationDrawerState(
                                                            !navigationDrawerState
                                                        );
                                                    }}
                                                    shiftClickCallback={(
                                                        typeOfProduct?: Product
                                                    ) => {
                                                        if (typeOfProduct) {
                                                            setProductType(typeOfProduct);
                                                        } else {
                                                            updateCurrentProductType();
                                                        }
                                                    }}
                                                />

                                                {/* Display the menu on the left*/}
                                                <div className="app-menu">
                                                    {navigationDrawerState ? (
                                                        <NavigationDrawer
                                                            navigationDrawerItems={
                                                                navigationDrawerItemsState
                                                            }
                                                            setNavigationDrawerState={
                                                                setNavigationDrawerState
                                                            }
                                                            settings={props.settings.getChild(
                                                                'navigationDrawer'
                                                            )}
                                                            shiftClickCallback={
                                                                updateCurrentProductType
                                                            }
                                                            documentModel={props.model.documents}
                                                        />
                                                    ) : (
                                                        <></>
                                                    )}
                                                </div>
                                                <EntryView
                                                    isOpen={displayEndOfStudyPopup}
                                                    closeEntryViewCallback={
                                                        setDisplayEndOfStudyPopup
                                                    }
                                                    content={endOfStudyMessage}
                                                />
                                            </>
                                        )}
                                    </>
                                ) : authenticationState === AUTHENTICATION_STATE.NotAuthenticated ||
                                  authenticationState === AUTHENTICATION_STATE.Authenticated ? (
                                    footerPage === FOOTER.NoFooter &&
                                    localStorage.getItem('connectionViaLink') !== 'true' && (
                                        <Switch>
                                            <Route exact path="/reset">
                                                <Reset
                                                    oneInstanceModel={props.model.oneInstance}
                                                    setNavigationDrawer={setNavigationDrawerState}
                                                />
                                            </Route>
                                            <Route path="/">{renderLoginOrErrorView()}</Route>
                                        </Switch>
                                    )
                                ) : (
                                    footerPage === FOOTER.NoFooter && <Loading />
                                )}
                            </NotificationContext.Provider>
                        </div>
                    </div>
                    <FooterElement
                        setNavigationDrawerState={setNavigationDrawerState}
                        setFooter={setFooterPage}
                    />
                    <SnackbarNotification
                        message={notificationMessage}
                        setMessageCallback={setNotificationMessage}
                        type={notificationType}
                    />
                </BrowserRouter>
            </Suspense>
        </div>
    );
}
