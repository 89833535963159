import i18n from '../../../i18n';
import Button from '@material-ui/core/Button';
import type {ReactElement} from 'react';
import {useHistory} from 'react-router-dom';

/**
 * Used as study headline for each study within smiler-impact product type.
 * @param props
 * @param props.completedTasks
 * @param props.totalTasks
 */
export default function StudyHeadline(props: {
    completedTasks: number;
    totalTasks: number;
}): ReactElement {
    const history = useHistory();

    return (
        <>
            {i18n.t('studies:smiler-impact.visitProgress1')}
            {props.completedTasks}/{props.totalTasks}
            {i18n.t('studies:smiler-impact.visitProgress2')}
            <Button
                color="default"
                variant="contained"
                className="my-tasks-button"
                onClick={() => history.push('/')}
            >
                {i18n.t('common:menu.overview')}
            </Button>
        </>
    );
}
