import * as dateFns from 'date-fns';
import type {ObjectData} from 'one.models/lib/models/ChannelManager';
import type {QuestionnaireResponses} from 'one.models/lib/recipes/QuestionnaireRecipes/QuestionnaireResponseRecipes';
import type {DocumentInfo} from 'one.models/lib/models/DocumentModel';
import type {Electrocardiogram} from 'one.models/lib/recipes/ECGRecipes';
import type {WbcObservation} from 'one.models/lib/recipes/WbcDiffRecipes';
import type {AudioExercise} from 'one.models/lib/recipes/AudioExerciseRecipes';
import {calculateTimeUntilTomorrow} from '../Utils';
import type {OEvent} from 'one.models/lib/misc/OEvent';

/**
 * Represents the three phases of the Smiler study.
 */
export const SMILER_VISIT = {
    Day_0: 0,
    Day_14: 14,
    Day_28: 28
} as const;

/** The type definition based on the SMILER_VISIT value. **/
export type SmilerStudyVisit = typeof SMILER_VISIT[keyof typeof SMILER_VISIT];

/**
 * Represents the four phases of the Impact study.
 */
export const IMPACT_VISIT = {
    Day_0: 0,
    Day_7: 7,
    Day_14: 14,
    Day_21: 21
} as const;

/** The type definition based on the IMPACT_VISIT value. **/
export type ImpactStudyVisit = typeof IMPACT_VISIT[keyof typeof IMPACT_VISIT];

/**
 * Represents the existing tasks categories for a visit for the study.
 */
export type TaskCategories<T> = {
    activeTasks: Set<T>;
    missingTasks: Set<T>;
};

/**
 * Represents the common data of a visit.
 */
export type VisitDataCommon = {
    totalTasks: number;
    tasksCompleted: number;
    visitDate: Date;
};

/**
 * Holds a list of common functionalities for the existing studies.
 */
export default class StudyHelper {
    /**
     * Calculate the remaining days until the next visit will be available.
     * If the study has ended -1 will be returned.
     * @param currentStudyDay - the day which represents the progress of the study.
     * @param studyDuration - total number of the days the study lasts.
     * @param visitIntervalDays - the number of days between 2 visits.
     */
    public calculateDaysUntilNextVisit(
        currentStudyDay: number,
        studyDuration: number,
        visitIntervalDays: number
    ): number {
        if (currentStudyDay >= studyDuration) {
            return -1;
        }

        return visitIntervalDays - (currentStudyDay % visitIntervalDays);
    }

    /**
     * Calculate the current study day of the study based on when the study began.
     * @param startStudyDate - the date when the study started.
     */
    public calculateCurrentStudyDay(startStudyDate: Date): number {
        return dateFns.differenceInCalendarDays(new Date(), startStudyDate);
    }

    /**
     * Check if a questionnaire/photo/ecg/wbc/audio exercise was added during a visit.
     * @param visitDay - the visit for which the verification is made.
     * @param object - the object that is checked in which visit was added.
     * @param daysOffsetAcceptanceBeforeVisitDate - how many days before the date of the visit,
     * the visit becomes active.
     * @param daysOffsetAcceptanceAfterVisitDate - how many days after the date of the visit,
     * the visit is still active.
     * @param studyStartDate - the date when the study started.
     * @param studyDuration - total number of the days the study lasts.
     */
    public isTaskCompletedWithinVisit<T extends SmilerStudyVisit | ImpactStudyVisit>(
        visitDay: T,
        object: ObjectData<
            | QuestionnaireResponses
            | DocumentInfo
            | Electrocardiogram
            | WbcObservation
            | AudioExercise
        >,
        daysOffsetAcceptanceBeforeVisitDate: number,
        daysOffsetAcceptanceAfterVisitDate: number,
        studyStartDate: Date,
        studyDuration: number
    ): boolean {
        const {startDayOfVisit, endDayOfVisit} = StudyHelper.getEdgesOfVisit<T>(
            visitDay,
            daysOffsetAcceptanceBeforeVisitDate,
            daysOffsetAcceptanceAfterVisitDate,
            studyDuration
        );

        for (let day = startDayOfVisit; day <= endDayOfVisit; day++) {
            if (dateFns.isSameDay(dateFns.addDays(studyStartDate, day), object.creationTime)) {
                return true;
            }
        }

        return false;
    }

    /**
     * Used to calculate the start date of a Visit based on the current study day.
     * @param activeVisit - the visit that is currently active.
     * @param daysOffsetAcceptanceBeforeVisitDate - how many days before the date of the visit,
     * the visit becomes active.
     * @param daysOffsetAcceptanceAfterVisitDate - how many days after the date of the visit,
     * the visit is still active.
     * @param currentStudyDay - the day which represents the progress of the study.
     * @param studyDuration - total number of the days the study lasts.
     */
    public calculateStartDateOfVisit<T extends SmilerStudyVisit | ImpactStudyVisit>(
        activeVisit: T | undefined,
        daysOffsetAcceptanceBeforeVisitDate: number,
        daysOffsetAcceptanceAfterVisitDate: number,
        currentStudyDay: number,
        studyDuration: number
    ): Date | undefined {
        if (activeVisit === undefined) {
            return undefined;
        }

        const {startDayOfVisit, endDayOfVisit} = StudyHelper.getEdgesOfVisit<T>(
            activeVisit,
            daysOffsetAcceptanceBeforeVisitDate,
            daysOffsetAcceptanceAfterVisitDate,
            studyDuration
        );

        for (let visitDay = startDayOfVisit; visitDay <= endDayOfVisit; visitDay++) {
            if (currentStudyDay === visitDay) {
                return dateFns.subDays(
                    dateFns.setHours(dateFns.setMinutes(dateFns.setSeconds(new Date(), 0), 0), 0),
                    visitDay - startDayOfVisit
                );
            }
        }

        return undefined;
    }

    /**
     * Based on current day and a specific Visit the function checks whether the Visit it's active or not.
     * @param currentDay - the day that is verified within the Visit.
     * @param visit - the Visit that is checked.
     * @param daysOffsetAcceptanceBeforeVisitDate - how many days before the date of the visit,
     * the visit becomes active.
     * @param daysOffsetAcceptanceAfterVisitDate - how many days after the date of the visit,
     * the visit is still active.
     * @param studyDuration - total number of the days the study lasts.
     */
    public isVisitActive<T extends ImpactStudyVisit | SmilerStudyVisit>(
        currentDay: number,
        visit: T,
        daysOffsetAcceptanceBeforeVisitDate: number,
        daysOffsetAcceptanceAfterVisitDate: number,
        studyDuration: number
    ): boolean {
        const {startDayOfVisit, endDayOfVisit} = StudyHelper.getEdgesOfVisit<T>(
            visit,
            daysOffsetAcceptanceBeforeVisitDate,
            daysOffsetAcceptanceAfterVisitDate,
            studyDuration
        );

        return dateFns.isWithinInterval(currentDay, {
            start: startDayOfVisit,
            end: endDayOfVisit
        });
    }

    /**
     * Used to classify the tasks in two categories: active and missing.
     * Active tasks are those tasks that should be complete when the user is in the visit date of the current visit.
     * Missing tasks are those tasks which should be completed within visit acceptance range.
     * @param activeVisit - the active visit of the study.
     * @param tasks - the current tasks state of the study.
     * @param allTasks - all possible tasks for a visit of the study.
     * @param finishedTasks - the tasks that are done for the active visit.
     * @param visitDate - the visit date of the active visit.
     * @param daysOffsetAcceptanceBeforeVisitDate - how many days before the date of the visit,
     * the visit becomes active.
     */
    public classifyTasks<T, U>(
        activeVisit: T | undefined,
        tasks: TaskCategories<U>,
        allTasks: U[],
        finishedTasks: U[],
        visitDate: Date | undefined,
        daysOffsetAcceptanceBeforeVisitDate: number = 0
    ): TaskCategories<U> {
        // if no active visit then we don't have any active or missing tasks
        if (activeVisit === undefined || visitDate === undefined) {
            tasks.activeTasks = new Set<U>();
            tasks.missingTasks = new Set<U>();
            return tasks;
        }

        const currentDate = new Date();
        const startDate = dateFns.subDays(visitDate, daysOffsetAcceptanceBeforeVisitDate);

        // if we are in the visit date then everything which is answered should be removed from
        // the active tasks array and we have no missing tasks
        if (
            dateFns.isSameDay(currentDate, startDate) ||
            dateFns.isSameDay(currentDate, visitDate) ||
            dateFns.isWithinInterval(currentDate, {
                start: startDate,
                end: visitDate
            })
        ) {
            tasks.missingTasks = new Set<U>();
            tasks.activeTasks = StudyHelper.populateSetWithTasks(allTasks);
            tasks.activeTasks = this.removeDoneTasks(tasks.activeTasks, finishedTasks);
        } else {
            tasks.activeTasks = new Set<U>();
            tasks.missingTasks = StudyHelper.populateSetWithTasks(allTasks);
            tasks.missingTasks = this.removeDoneTasks(tasks.missingTasks, finishedTasks);
        }

        return tasks;
    }

    /**
     * Used to update the time dependent properties of a study.
     * @param studyStartDate - the date when the study started.
     * @param studyDuration - total number of the days the study lasts.
     * @param visitDaysInterval - the number of days between 2 visits.
     */
    public updateTimeProperties(
        studyStartDate: Date,
        studyDuration: number,
        visitDaysInterval: number
    ): {
        currentStudyDay: number;
        daysUntilNextVisit: number;
    } {
        const currentStudyDay = this.calculateCurrentStudyDay(studyStartDate);

        return {
            currentStudyDay: currentStudyDay,
            daysUntilNextVisit: this.calculateDaysUntilNextVisit(
                currentStudyDay,
                studyDuration,
                visitDaysInterval
            )
        };
    }

    /**
     * Used to init the timer which will update the time dependent properties of the study.
     * @param calculateTimePropertiesFn - the function provided by the study which updates the
     * internal properties.
     * @param onUpdated - the event emitter of the study.
     */
    public initTimer(
        calculateTimePropertiesFn: () => void,
        onUpdated: OEvent<() => void>
    ): ReturnType<typeof setTimeout> {
        const millisUntilNextDay = calculateTimeUntilTomorrow();
        return setTimeout(() => {
            calculateTimePropertiesFn();
            onUpdated.emit();
        }, millisUntilNextDay);
    }

    // ############# private functions ################

    /**
     * Getting the edges of a visit. Basically here it's calculated the interval for a Visit.
     * @param visit - the visit for which we calculate the interval.
     * @param daysOffsetAcceptanceBeforeVisitDate - how many days before the date of the visit,
     * the visit becomes active.
     * @param daysOffsetAcceptanceAfterVisitDate - how many days after the date of the visit,
     * the visit is still active.
     * @param studyDuration - total number of the days the study lasts.
     */
    private static getEdgesOfVisit<T extends ImpactStudyVisit | SmilerStudyVisit>(
        visit: T,
        daysOffsetAcceptanceBeforeVisitDate: number,
        daysOffsetAcceptanceAfterVisitDate: number,
        studyDuration: number
    ): {startDayOfVisit: number; endDayOfVisit: number} {
        return {
            startDayOfVisit: visit === 0 ? visit : visit - daysOffsetAcceptanceBeforeVisitDate,
            endDayOfVisit:
                visit === studyDuration ? visit : visit + daysOffsetAcceptanceAfterVisitDate
        };
    }

    /**
     * Update the tasks sets based on the received tasks array.
     * @param set - the set that will loose the answered tasks.
     * @param array - an array containing the tasks that will be removed from the set.
     */
    private removeDoneTasks<T>(set: Set<T>, array: T[]): Set<T> {
        array.forEach(item => {
            set.delete(item);
        });

        return set;
    }

    /**
     * Used to initialize a Set with all the tasks for a visit.
     * @param array - the array containing the tasks that is involved when the Set is created.
     */
    private static populateSetWithTasks<T>(array: T[]): Set<T> {
        return new Set<T>(array);
    }
}
