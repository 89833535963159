import {useEffect, useState} from 'react';
import type {ReactElement} from 'react';
import {Tooltip, XAxis, YAxis, ResponsiveContainer, LineChart, Line, ReferenceLine} from 'recharts';
import './GenericGraph.css';
import type {GraphData} from '../../model/WBCDataParser';
import GenericGraphTooltip from './GenericGraphTooltip';
import CustomizedTick from './CustomizedTick';
import Filters, {DATA_RANGE} from './Filters';
import type {FilterRange} from './Filters';
import InfoMessage, {MESSAGE_TYPE} from '../errors/InfoMessage';
import * as dateFns from 'date-fns';
import IntervalSelector from './IntervalSelector';

/**
 * Tick object used to build the x axis ticks and y axis ticks for the graph.
 */
export type AxisProperty = {
    // this is the first tick that is displayed on the axis.
    firstTick: number;
    // this is the lastTick that is displayed on the axis.
    lastTick: number;
    // optional property that is used to build the ticks of an axis.
    // (e.g. firstTick = 0; lastTick = 10; tickStep = 2; then the ticks will be: 0,2,4...10)
    // if it's not supplied then it will be 1.
    tickStep?: number;
    // optional parameter that is used to build the displayed labels of the ticks.
    // (e.g. firstTick = 0; lastTick = 10; tickLabelStep = 5; then the labels of ticks will be: 0,5,10)
    // if it's not supplied then it will be 1.
    tickLabelStep?: number;
    reference?: Reference;
};

/**
 * Used to draw the reference interval onto the graph.
 * The reference is an horizontal area between 2 ticks of the x axis.
 */
type Reference = {
    start: number;
    end: number;
};

/**
 * The color that is used for the entire graph.
 */
const GRAPH_COLOR = '#2a5fa6' as const;

/**
 * Used to build the axis ticks.
 * @param firstTick - first tick of the axis.
 * @param lastTick - last tick of the axis.
 * @param tickStep - the step between the ticks of the axis.
 * @param isTimeRepresentation - true when the ticks that are displayed represents time moments.
 * @param activeFilter - the active filter of the graph.
 */
function buildTicks(
    firstTick: number,
    lastTick: number,
    tickStep?: number,
    isTimeRepresentation?: boolean,
    activeFilter?: FilterRange
): number[] {
    const ticks: number[] = [];

    let step = tickStep ? tickStep : 1;

    if (activeFilter === DATA_RANGE.Year) {
        step *= 30;
    }

    if (isTimeRepresentation) {
        const oneDayInMillis = 86400000;
        step *= oneDayInMillis;
    }

    for (let i = firstTick; i <= lastTick; i += step) {
        ticks.push(i);
    }

    return ticks;
}

/**
 * Used to format the ticks labels.
 * @param value - the tick value that will be formatted.
 * @param labelStep - used to format the tick label.
 */
export function formatTickLabel(value: number, labelStep?: number): string {
    const tickLabelStep = labelStep ? labelStep : 1;

    if (value === 0 || value % tickLabelStep === 0) {
        return value.toString();
    }

    return '';
}

/**
 * Represents a configurable graph component.
 * @param props
 * @param props.graphTitle - the graph headline.
 * @param props.xAxisProperty - the properties of the x axis.
 * @param props.yAxisProperty - the properties of the y axis.
 * @param props.data - data displayed onto the graph.
 * @param props.displayFilters - boolean flag for displaying the filters for the graph.
 * @param props.intervalSelector - boolean flag for displaying the interval selectors for the graph.
 * @param props.measurementUnit - represents the measurement unit that will be displayed in the
 * tooltip.
 */
export default function GenericGraph(props: {
    graphTitle?: string;
    xAxisProperty: AxisProperty;
    yAxisProperty: AxisProperty;
    data: GraphData[];
    measurementUnit: string;
    displayFilters?: boolean;
    intervalSelector?: boolean;
}): ReactElement {
    // states for handling the tooltip position
    const [tooltipXPosition, setTooltipXPosition] = useState(0);
    const [tooltipYPosition, setTooltipYPosition] = useState(0);

    // states for storing tooltip dimensions
    const [tooltipWidth, setTooltipWidth] = useState(0);
    const [tooltipHeight, setTooltipHeight] = useState(0);

    // state for storing the x coordinate from the chart
    const [xActiveCoordinate, setXActiveCoordinate] = useState(0);

    // getting the tooltip
    const tooltip = document.getElementsByClassName('tooltip')[0];

    // initializing the start date state with the right value
    const [intervalStartDate, setIntervalStartDate] = useState(props.xAxisProperty.firstTick);

    // initializing the end date state with the right value
    const [intervalEndDate, setIntervalEndDate] = useState(props.xAxisProperty.lastTick);

    // warnings and errors handlers
    const [warningMessage, setWarningMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    // active filter for filtering the body temperatures that are displayed on the chart
    const [activeFilter, setActiveFilter] = useState<FilterRange | undefined>();

    // the data that will be displayed on the graph
    const [displayedData, setDisplayedData] = useState<GraphData[]>([]);

    /**
     * Used to filter the data that is displayed on the graph when the time interval is changed.
     */
    useEffect(() => {
        setDisplayedData(
            props.data.filter(
                data => data.timestamp >= intervalStartDate && data.timestamp <= intervalEndDate
            )
        );
    }, [props.data, intervalStartDate, intervalEndDate]);

    /**
     * Set the tooltip dimension when the tooltip appears.
     */
    useEffect(() => {
        if (tooltip) {
            setTooltipWidth(tooltip.clientWidth);
            setTooltipHeight(tooltip.clientHeight);
        }
    }, [tooltip, xActiveCoordinate]);

    /**
     * Calculates the tooltip positioning based on
     * its dimension and on the active dot from the graph.
     */
    useEffect(() => {
        setTooltipXPosition(xActiveCoordinate - tooltipWidth / 2);
        // because we want the tooltip to be displayed on top of the chart, we have to shift it
        // upwards its height (-tooltiHeight) and an extra padding to be sure that it doesn't
        // overlap the chart (-5)
        setTooltipYPosition(-tooltipHeight - 5);
    }, [props.yAxisProperty.lastTick, tooltipWidth, tooltipHeight, xActiveCoordinate]);

    /**
     * When new data is passed through props we need to update the time interval.
     */
    useEffect(() => {
        if (props.data.length > 0 && (props.displayFilters || props.intervalSelector)) {
            setIntervalEndDate(props.data[props.data.length - 1].timestamp);

            switch (activeFilter) {
                case DATA_RANGE.Week:
                    setIntervalStartDate(
                        dateFns.subWeeks(props.data[props.data.length - 1].timestamp, 1).getTime()
                    );
                    break;
                case DATA_RANGE.Month:
                    setIntervalStartDate(
                        dateFns.subMonths(props.data[props.data.length - 1].timestamp, 1).getTime()
                    );
                    break;
                case DATA_RANGE.Year:
                    setIntervalStartDate(
                        dateFns.subYears(props.data[props.data.length - 1].timestamp, 1).getTime()
                    );
                    break;
                default:
                    break;
            }
        }
    }, [activeFilter, props.data, props.displayFilters, props.intervalSelector]);

    /**
     * Update the x active coordinate.
     * @param event - event triggered by onClick and onMouseMove graph events.
     */
    function handleMouseInteraction(event: any | null): void {
        if (event && event.activeCoordinate) {
            setXActiveCoordinate(event.activeCoordinate.x);
        }
    }

    return (
        <>
            <InfoMessage
                errorMessage={errorMessage}
                setDisplayMessage={setErrorMessage}
                messageType={MESSAGE_TYPE.Error}
            />
            <InfoMessage
                errorMessage={warningMessage}
                setDisplayMessage={setWarningMessage}
                messageType={MESSAGE_TYPE.Warning}
            />
            <div className="graph-time-handlers">
                {props.displayFilters && (
                    <Filters
                        intervalEndDate={intervalEndDate}
                        setActiveFilterCallback={setActiveFilter}
                        setStartDateCallback={setIntervalStartDate}
                    />
                )}
                {props.intervalSelector && (
                    <IntervalSelector
                        activeFilter={activeFilter}
                        intervalStartDate={intervalStartDate}
                        setEndDateCallback={setIntervalEndDate}
                        setStartDateCallback={setIntervalStartDate}
                        setWarningMessageCallback={setWarningMessage}
                        intervalEndDate={intervalEndDate}
                        customTimeInterval={
                            props.xAxisProperty.lastTick - props.xAxisProperty.firstTick
                        }
                    />
                )}
            </div>
            <div className="graph-headline headline-color">
                {props.graphTitle === undefined ? '' : props.graphTitle}
            </div>
            <ResponsiveContainer height={500} aspect={16.0 / 9.0} className="generic-chart">
                <LineChart
                    data={displayedData}
                    margin={{
                        top: 10,
                        right: 5,
                        left: -35
                    }}
                    onClick={handleMouseInteraction}
                    onMouseMove={handleMouseInteraction}
                >
                    <XAxis
                        dataKey="timestamp"
                        stroke={GRAPH_COLOR}
                        domain={[intervalStartDate, intervalEndDate]}
                        ticks={buildTicks(
                            intervalStartDate,
                            intervalEndDate,
                            props.xAxisProperty.tickStep,
                            true,
                            activeFilter
                        )}
                        interval={
                            activeFilter === DATA_RANGE.Month
                                ? 2
                                : activeFilter === DATA_RANGE.Year
                                ? 1
                                : 0
                        }
                        type="number"
                        scale="time"
                        tick={<CustomizedTick activeFilter={activeFilter} />}
                    />
                    <YAxis
                        stroke={GRAPH_COLOR}
                        dataKey="value"
                        domain={[props.yAxisProperty.firstTick, props.yAxisProperty.lastTick]}
                        ticks={buildTicks(
                            props.yAxisProperty.firstTick,
                            props.yAxisProperty.lastTick,
                            props.yAxisProperty.tickStep
                        )}
                        type="number"
                        interval={0}
                        tickFormatter={value =>
                            formatTickLabel(value, props.yAxisProperty.tickLabelStep)
                        }
                    />
                    <Tooltip
                        cursor={{
                            stroke: GRAPH_COLOR,
                            strokeWidth: displayedData.length === 0 ? 0 : 1
                        }}
                        position={{x: tooltipXPosition, y: tooltipYPosition}}
                        content={
                            <GenericGraphTooltip
                                label={props.measurementUnit}
                                labelFormatter={() => <> {props.measurementUnit}</>}
                            />
                        }
                    />
                    <ReferenceLine x={intervalEndDate} stroke={GRAPH_COLOR} />
                    <ReferenceLine y={props.yAxisProperty.lastTick} stroke={GRAPH_COLOR} />
                    {props.yAxisProperty.reference && (
                        <ReferenceLine
                            y={props.yAxisProperty.reference.start}
                            stroke={GRAPH_COLOR}
                            opacity={0.5}
                        />
                    )}
                    {props.yAxisProperty.reference && (
                        <ReferenceLine
                            y={props.yAxisProperty.reference.end}
                            stroke={GRAPH_COLOR}
                            opacity={0.5}
                        />
                    )}
                    <Line
                        isAnimationActive={false}
                        type="linear"
                        dataKey="value"
                        stroke={GRAPH_COLOR}
                        strokeDasharray="4"
                        dot={{fill: GRAPH_COLOR, strokeWidth: 0}}
                    />
                </LineChart>
            </ResponsiveContainer>
        </>
    );
}
