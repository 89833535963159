import {useEffect, useState} from 'react';
import type {ReactElement} from 'react';
import {Button, List, ListItem, withStyles} from '@material-ui/core';
import Slider from '@material-ui/core/Slider';
import './Questionnaire.css';
import type QuestionnaireModel from 'one.models/lib/models/QuestionnaireModel';
import HealthInfoPage from './HealthInfoPage';
import FinalizePage from './FinalizePage';
import InformativePage from './InformativePage';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import i18n from '../../i18n';
import type {QuestionnaireResponseBuilderItem} from './QuestionnaireResponseBuilder';
import InfoMessage, {MESSAGE_TYPE} from '../errors/InfoMessage';
import {useForceRender} from '../modelHelper/useForceRender';
import {
    changeBackgroundColorToWhite,
    removeWhiteBackgroundColor,
    useWindowSize
} from '../utils/Utils';
import {
    useEQ5D3LQuestionnaireAndResponse,
    useQuestionnairesBuilder
} from '../modelHelper/QuestionnaireModelHelper';
import PopupBox, {POPUP_BOX_TYPE} from '../popupBox/PopupBox';

const CustomSlider = withStyles({
    root: {
        color: '#000000',
        height: 2,
        padding: '15px 0'
    },
    thumb: {
        height: 16,
        width: 16,
        transform: 'translateX(-1.5px)',
        backgroundColor: '#3880ff',
        boxShadow:
            '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)',
        marginTop: -14,
        marginLeft: -14,
        '&:focus, &:hover, &$active': {
            boxShadow:
                '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                boxShadow:
                    '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)'
            }
        }
    },
    active: {},
    valueLabel: {
        transform: 'translateX(-30px) translateY(-7.5px) !important',
        top: '0px !important',
        cursor: 'pointer',
        '& *': {
            background: '#3880ff',
            color: '#ffffff',
            borderRadius: '50% 50% 0% 50% !important'
        },
        fontSize: '11px'
    },
    rail: {
        display: 'none'
    },
    mark: {
        width: '15px',
        marginLeft: '-6px',
        height: '1px',
        marginTop: '-3px',
        backgroundColor: '#000000',
        '&[data-index="0"], &[data-index="50"], &[data-index="100"]': {
            width: '30px',
            height: '1.5px',
            marginLeft: '-12px'
        }
    },
    markActive: {
        opacity: 1,
        backgroundColor: 'currentColor'
    },
    markLabel: {
        fontSize: '11px',
        left: '40px !important'
    }
})(Slider);

const PAGES = {
    Informative: 0,
    Question_1: 1,
    Question_2: 2,
    Question_3: 3,
    Question_4: 4,
    Question_5: 5,
    Health_Info: 6,
    Slider: 7,
    Finalize: 8
} as const;

/**
 * This component build the EQ-5D-3L.
 * @param props - Properties of this view.
 * @param props.questionnaireModel
 * @returns - EQ-5D-3L questionnaire.
 */
export default function EQ5D3LQuestionnaire(props: {
    questionnaireModel: QuestionnaireModel;
}): ReactElement {
    /** single questionnaire accepted by the component **/
    const questionnaireType = 'EQ5D3L';

    /** Component specific **/
    const [index, setIndex] = useState(0);
    const {action} = useParams<{action: string}>();
    const viewMode = action !== 'new';

    /** Error handling **/
    const [error, setError] = useState('');
    const [questionNotAnswered, setQuestionNotAnswered] = useState(false);
    const [answerQuestionMessage, setAnswerQuestionMessage] = useState('');

    /** Hooks && variables for EQ5D3L questionnaire **/
    const [width] = useWindowSize();
    const history = useHistory();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const paramResponse = queryParams.get('response');
    const paramLanguage = queryParams.get('language');
    const forceRender = useForceRender();
    const [questionnaireMap, responsesMap] = useEQ5D3LQuestionnaireAndResponse(
        props.questionnaireModel,
        setError,
        questionnaireType,
        paramLanguage ? paramLanguage : undefined,
        paramResponse ? paramResponse : undefined
    );
    const questionnaire = questionnaireMap.get(questionnaireType);
    const builderMap = useQuestionnairesBuilder(responsesMap, forceRender, questionnaire);
    const builder = builderMap.get(questionnaireType);

    // ---------------------------------------------------------------------------------
    // -------------------------------- Use Effects ------------------------------------
    // ---------------------------------------------------------------------------------

    /**
     * Set the index to 0 if the viewMode change (going from completed to new)
     */
    useEffect(() => {
        setIndex(0);
    }, [viewMode]);

    /**
     * Used to set the background color of the entire page to white and to display/hide the footer.
     */
    useEffect(() => {
        changeBackgroundColorToWhite();
        // hiding the footer from the view when the eq-5d-3l is rendered
        document.getElementsByTagName('footer')[0].style.display = 'none';

        return () => {
            removeWhiteBackgroundColor();

            // displaying again the footer when the eq-5d-3l view is unmounted
            document.getElementsByTagName('footer')[0].style.display = 'flex';
        };
    }, []);

    // ---------------------------------------------------------------------------------
    // -------------------------------- Utilities --------------------------------------
    // ---------------------------------------------------------------------------------

    /**
     * Returns the marks for the VAS Thermometer.
     * @param {boolean} isForMobile - flag to determine if the returned marks are for mobile or desktop view.
     * @param {number} start - first possible answer.
     * @param {number} end - last possible answer.
     */
    function createMarks(
        isForMobile: boolean,
        start: number,
        end: number
    ): {value: number; label?: number}[] {
        const marks = [];
        const labelStep = isForMobile ? 50 : 25;

        for (let i = start; i <= end; i += 1) {
            if (i % labelStep === 0) {
                marks.push({value: i, label: i});
            } else {
                marks.push({value: i});
            }
        }

        return marks;
    }

    // ---------------------------------------------------------------------------------
    // -------------------------------- Button handlers --------------------------------
    // ---------------------------------------------------------------------------------

    /**
     * Used to store the EQ5D3L questionnaire.
     */
    async function saveQuestionnaire(): Promise<void> {
        if (!builder) {
            return;
        }

        /** Check if questionnaire is valid then store it **/
        if (builder.validate()) {
            builder.setStatus('completed');

            const res = builder.buildResponse();
            await props.questionnaireModel.postResponseCollection(
                [res],
                undefined,
                questionnaireType
            );

            setError('');
            history.push('/journal');
        } else {
            setError('errors:questionnaire.answerAllQuestions');
        }
    }

    // ---------------------------------------------------------------------------------
    // -------------------------------- Rendering --------------------------------------
    // ---------------------------------------------------------------------------------

    /**
     * Renders the current question component(Slider/Question).
     * @param currentIndex - the index of the question that will be built.
     */
    function renderCurrentQuestion(currentIndex: number): ReactElement {
        if (questionnaire) {
            if (builder !== undefined) {
                const questionnaireResponseBuilderItems = Array.from(
                    builder.questionIterator({hideDisabledValues: true})
                );

                return (
                    <>{buildCurrentQuestionView(questionnaireResponseBuilderItems[currentIndex])}</>
                );
            }
        }

        return <div />;
    }

    /**
     * Creates the question view for 'choice' and 'integer' questions. (e.g Question/Slider)
     * @param questionResponseBuilderItem
     */
    function buildCurrentQuestionView(
        questionResponseBuilderItem: QuestionnaireResponseBuilderItem
    ): ReactElement | null {
        const answers = questionResponseBuilderItem.question.answerOption
            ? questionResponseBuilderItem.question.answerOption
            : [];

        /** need it for disabling the next button until the user answer the question. **/
        if (
            index > 0 &&
            index < 8 &&
            questionNotAnswered !== questionResponseBuilderItem.validationFailed
        ) {
            setQuestionNotAnswered(questionResponseBuilderItem.validationFailed);
        }

        // Only implemented for 'choice' or 'integer'
        switch (questionResponseBuilderItem.question.type) {
            case 'choice': {
                return (
                    <>
                        <div
                            className="question-header"
                            key={questionResponseBuilderItem.question.linkId}
                        >
                            {questionResponseBuilderItem.question.text}
                            {questionResponseBuilderItem.question.linkId === 'usualactivity' && (
                                <i className="remove-bold">{i18n.t('eq5:additionalInfo')}</i>
                            )}
                        </div>
                        <List className="answer-list">
                            {answers.map((answer, idx) => {
                                const displayedAnswer = answer.valueCoding
                                    ? answer.valueCoding.display
                                    : '';

                                const value =
                                    questionResponseBuilderItem.value[0] &&
                                    questionResponseBuilderItem.value[0].valueCoding
                                        ? questionResponseBuilderItem.value[0].valueCoding.display
                                        : '';

                                return (
                                    <ListItem
                                        key={
                                            questionResponseBuilderItem.question.linkId +
                                            idx.toString()
                                        }
                                    >
                                        <Button
                                            variant="contained"
                                            value={value}
                                            key={idx}
                                            className={`answer-button ${
                                                value === displayedAnswer
                                                    ? 'selected'
                                                    : 'unanswered-button'
                                            }`}
                                            onClick={() => {
                                                questionResponseBuilderItem.setAnswer([answer]);
                                            }}
                                        >
                                            {displayedAnswer}
                                        </Button>
                                    </ListItem>
                                );
                            })}
                        </List>
                    </>
                );
            }
            case 'integer': {
                const value =
                    questionResponseBuilderItem.value[0] &&
                    questionResponseBuilderItem.value[0].valueInteger
                        ? Number(questionResponseBuilderItem.value[0].valueInteger)
                        : -1;

                // default values
                let minValue = 0;
                let maxValue = 100;

                if (
                    questionResponseBuilderItem.question.answerRestriction !== undefined &&
                    questionResponseBuilderItem.question.answerRestriction.minValue &&
                    questionResponseBuilderItem.question.answerRestriction.minValue.valueInteger &&
                    questionResponseBuilderItem.question.answerRestriction.maxValue &&
                    questionResponseBuilderItem.question.answerRestriction.maxValue.valueInteger
                ) {
                    maxValue = Number(
                        questionResponseBuilderItem.question.answerRestriction.maxValue.valueInteger
                    );
                    minValue = Number(
                        questionResponseBuilderItem.question.answerRestriction.minValue.valueInteger
                    );
                }

                return (
                    <>
                        <div className="health-info">{i18n.t('eq5:indication')}</div>
                        <div
                            className="thermometer-view"
                            key={questionResponseBuilderItem.question.linkId}
                        >
                            <div className="textContainer">
                                <div className="text-container-align-middle">
                                    <p className="par health-meter">
                                        {questionResponseBuilderItem.question.text}
                                        {'= '}
                                        {value === -1 ? null : value}
                                    </p>
                                </div>
                            </div>
                            <div className="slider-container">
                                <div className="thermometer-info margin-bottom">
                                    {i18n.t('eq5:healthStatusG')}{' '}
                                </div>
                                <CustomSlider
                                    className={`${value === -1 ? 'hide-thumb' : ''}`}
                                    track={false}
                                    aria-labelledby="track-false-slider"
                                    valueLabelDisplay="auto"
                                    orientation="vertical"
                                    value={value}
                                    valueLabelFormat={() => (value === -1 ? '' : value.toString())}
                                    marks={
                                        width && width <= 768
                                            ? createMarks(true, minValue, maxValue)
                                            : createMarks(false, minValue, maxValue)
                                    }
                                    onChange={(_, newValue: number | number[]) => {
                                        questionResponseBuilderItem.setAnswer([
                                            {
                                                valueInteger: (newValue as number).toString()
                                            }
                                        ]);
                                    }}
                                />
                                <div className="thermometer-info top-margin">
                                    {i18n.t('eq5:healthStatusW')}{' '}
                                </div>
                            </div>
                        </div>
                    </>
                );
            }
            default:
                return null;
        }
    }

    /**
     * Renders the navigation buttons
     */
    function renderNavigationButtons(): ReactElement {
        return (
            <div className="navButtons">
                {index === PAGES.Finalize ? null : (
                    <div className="copyrights-footer">{i18n.t('eq5:footer.copyrights')}</div>
                )}
                <div className="control-buttons-container">
                    <Button
                        disabled={index === PAGES.Informative}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            setIndex(prevIndex => prevIndex - 1);
                            setQuestionNotAnswered(false);
                        }}
                        className="control-button"
                    >
                        <div className={'arrow-back'} />
                        {i18n.t('common:buttons.previous')}
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={async () => {
                            if (index === PAGES.Finalize) {
                                if (!viewMode) {
                                    await saveQuestionnaire();
                                }
                            } else if (questionNotAnswered) {
                                setAnswerQuestionMessage(i18n.t('eq5:answerNotProvided'));
                            } else {
                                setIndex(prevIndex => prevIndex + 1);
                            }
                        }}
                        className="control-button next"
                    >
                        {index === PAGES.Finalize
                            ? viewMode
                                ? i18n.t('common:buttons.backToJournal')
                                : i18n.t('common:buttons.finalize')
                            : i18n.t('common:buttons.next')}
                        <div
                            className={
                                index === PAGES.Finalize && !viewMode ? 'tick-mark' : 'arrow-next'
                            }
                        />
                    </Button>
                </div>
            </div>
        );
    }

    /**
     *  Getting the current displayed page from the EQ5D3L questionnaire.
     * @returns {ReactElement} the content from a specific page based on the index.
     */
    function renderCurrentPageView(): ReactElement {
        switch (index) {
            case PAGES.Informative:
                return <InformativePage />;
            case PAGES.Question_1:
            case PAGES.Question_2:
            case PAGES.Question_3:
            case PAGES.Question_4:
            case PAGES.Question_5:
                return <>{renderCurrentQuestion(index - 1)}</>;
            case PAGES.Health_Info:
                return <HealthInfoPage />;
            case PAGES.Slider:
                return <>{renderCurrentQuestion(index - 2)}</>;
            case PAGES.Finalize:
                return <FinalizePage />;
            default:
                setIndex(0);
                return renderCurrentPageView();
        }
    }

    return (
        <>
            <div className="page-container eq-5d-3l-font-family">
                <InfoMessage
                    errorMessage={error}
                    setDisplayMessage={setError}
                    messageType={MESSAGE_TYPE.Error}
                />
                <h2 className="headline">{i18n.t('eq5:title')}</h2>
                <div className={`surveyContainer ${viewMode ? 'disable-user-interaction' : ''}`}>
                    {renderCurrentPageView()}
                </div>
                {renderNavigationButtons()}
            </div>
            <PopupBox
                popupType={POPUP_BOX_TYPE.Info}
                popupText={answerQuestionMessage}
                setPopupText={setAnswerQuestionMessage}
            />
        </>
    );
}
