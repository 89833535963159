import {useEffect, useState} from 'react';
import type {ReactElement} from 'react';
import MenuButton from '../menu/MenuButton';
import i18n from '../../i18n';
import {EcgDataTable} from './EcgDataTable';
import {EcgChart} from './EcgChart';
import {useParams} from 'react-router-dom';
import type {Electrocardiogram} from 'one.models/lib/recipes/ECGRecipes';
import type {SHA256Hash} from 'one.core/lib/util/type-checks';
import InfoMessage, {MESSAGE_TYPE} from '../errors/InfoMessage';
import type ECGModel from 'one.models/lib/models/ECGModel';
import {displayCircularProgress, hideCircularProgress} from '../utils/Utils';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Paper} from '@material-ui/core';
import {Alert} from '@material-ui/lab';

/**
 *
 * @param props
 * @param props.ecgModel
 * @returns
 */
export function EcgData(props: {ecgModel: ECGModel}): ReactElement {
    const [errorState, setErrorState] = useState('');

    const {ecgHash}: {ecgHash: SHA256Hash<Electrocardiogram>} = useParams();
    const [ecgDataMeasurement, setEcgDataMeasurement] = useState<Electrocardiogram>();

    useEffect(() => {
        displayCircularProgress();
        props.ecgModel
            .retrieveElectrocardiogramByHash(ecgHash)
            .then((res: Electrocardiogram) => {
                setEcgDataMeasurement(res);
                hideCircularProgress();
            })
            .catch((ignored: Error) => {
                setErrorState(i18n.t('errors:ecg.couldNotRetrieveECG'));
                hideCircularProgress();
            });
    }, [ecgHash, props.ecgModel]);

    return (
        <div className="page-container">
            <InfoMessage
                setDisplayMessage={setErrorState}
                errorMessage={errorState}
                messageType={MESSAGE_TYPE.Error}
            />
            {ecgDataMeasurement &&
                ecgDataMeasurement.readings &&
                ecgDataMeasurement.readings.length < 1 && (
                    <Paper square elevation={3} className="warning-color">
                        <Alert severity="warning">
                            {i18n.t('errors:ecg.couldNotRetrieveReadings')}
                        </Alert>
                    </Paper>
                )}
            <div className="menu-button-header">
                <MenuButton />
                <h2 className="headline">{i18n.t('common:menu.ecgData')}</h2>
            </div>
            <div className="circular-progress-container">
                <CircularProgress className="circular-progress" size={35} />
            </div>
            {ecgDataMeasurement && ecgDataMeasurement.readings ? (
                <>
                    <EcgDataTable ecgDataMeasurement={ecgDataMeasurement} />
                    <EcgChart readings={ecgDataMeasurement.readings} />
                </>
            ) : null}
        </div>
    );
}
