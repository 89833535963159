import type {ReactElement} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import i18n from '../../../i18n';

const useStyles = makeStyles({
    table: {
        minWidth: 600
    }
});

interface NameValueTableItemType {
    name: string;
    value: string;
    isId?: boolean;
}

interface ObjectDetailsType {
    rows: NameValueTableItemType[];
    hash: string;
    idHash: string;
    status: string;
    timestamp: number | undefined;
}

/**
 * @param {{}} props
 * @param {ObjectDetailsType} props.data
 * @returns {ReactElement}
 */
export default function SimpleTable(props: {data: ObjectDetailsType | undefined}): ReactElement {
    const classes = useStyles();

    return (
        <TableContainer style={{margin: 25, padding: 25, width: 'auto'}} component={Paper}>
            <div>
                <b>{i18n.t('common:oneBrowser.hash')} </b>
                {props?.data?.hash}
            </div>
            <div>
                <b>{i18n.t('common:oneBrowser.idHash')} </b>
                {props?.data?.idHash}
            </div>
            <div>
                <b>{i18n.t('common:oneBrowser.status')}: </b>
                {props?.data?.status}
            </div>
            <div>
                <b>{i18n.t('common:oneBrowser.creationDate')}: </b>
                {new Date(
                    props?.data?.timestamp === undefined ? '' : props?.data?.timestamp
                ).toLocaleString()}
            </div>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>{i18n.t('common:oneBrowser.fieldName')}</TableCell>
                        <TableCell align="right">
                            {i18n.t('common:oneBrowser.fieldValue')}
                        </TableCell>
                        <TableCell align="right"> {i18n.t('common:oneBrowser.isId')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props?.data?.rows.map((row: NameValueTableItemType) => (
                        <TableRow key={row.name}>
                            <TableCell component="th" scope="row">
                                {row.name}
                            </TableCell>
                            <TableCell align="right">{row.value}</TableCell>
                            <TableCell align="right">{row.isId ? 'true' : 'false'}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
