import type {ReactElement} from 'react';
import ReactMarkdown from 'react-markdown';
import '../../Primary.css';

/**
 * This component build and return the preview of the news.
 * @param {{}} props - Properties of this view.
 * @param {string} props.preview
 * @returns {ReactElement} the preview of the news.
 */
export default function NewsPreview(props: {preview: string}): ReactElement {
    return (
        <div className="preview">
            <ReactMarkdown className="content-preview">{props.preview}</ReactMarkdown>
        </div>
    );
}
