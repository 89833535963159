import type ECGModel from 'one.models/lib/models/ECGModel';
import type WbcDiffModel from 'one.models/lib/models/WbcDiffModel';
import type DocumentModel from 'one.models/lib/models/DocumentModel';
import type QuestionnaireModel from 'one.models/lib/models/QuestionnaireModel';
import * as dateFns from 'date-fns';
import type ChannelManager from 'one.models/lib/models/ChannelManager';
import type {ObjectData} from 'one.models/lib/models/ChannelManager';
import type {AudioExercise} from 'one.models/lib/recipes/AudioExerciseRecipes';
import type {OneUnversionedObjectTypes} from 'one.core/lib/recipes';
import type AudioExerciseModel from 'one.models/lib/models/AudioExerciseModel';
import StudyWorkflow from './StudyWorkflow';
import {STUDY_DURATION} from './StudyWorkflow';
import type {ImpactStudyVisit, VisitDataCommon} from './StudyHelper';
import {IMPACT_VISIT} from './StudyHelper';
import type StudyCommonInterface from './StudyCommonInterface';

/**
 * All available tasks of the Impact study.
 */
export const IMPACT_TASK = {
    AudioExercise: 'audio'
} as const;

/** The type definition based on the IMPACT_TASK value. **/
export type ImpactVisitTask = typeof IMPACT_TASK[keyof typeof IMPACT_TASK];

/**
 * The type representing the data of a visit.
 */
export type ImpactVisitData = {
    isAudioExerciseDone: boolean;
} & VisitDataCommon;

/**
 * The visits properties.
 */
export type ImpactVisitProperties = {
    [IMPACT_VISIT.Day_0]: ImpactVisitData;
    [IMPACT_VISIT.Day_7]: ImpactVisitData;
    [IMPACT_VISIT.Day_14]: ImpactVisitData;
    [IMPACT_VISIT.Day_21]: ImpactVisitData;
};

/**
 * This model represents the workflow for the Impact study.
 */
export default class ImpactWorkflow
    extends StudyWorkflow<ImpactVisitTask, ImpactStudyVisit>
    implements StudyCommonInterface<ImpactVisitProperties, ImpactStudyVisit>
{
    private readonly VISIT_INTERVAL_DAYS = 7;
    private readonly DAYS_OFFSET_ACCEPTANCE = 7;
    private static readonly VISIT_TASKS_NUMBER = 1;
    private visitProperties: ImpactVisitProperties;
    private readonly pastVisits: Set<ImpactStudyVisit>;

    constructor(
        ecgModel: ECGModel,
        wbcModel: WbcDiffModel,
        documentModel: DocumentModel,
        questionnaireModel: QuestionnaireModel,
        audioExerciseModel: AudioExerciseModel,
        channelManager: ChannelManager
    ) {
        super(
            ecgModel,
            wbcModel,
            documentModel,
            questionnaireModel,
            audioExerciseModel,
            channelManager
        );

        this.visitProperties = this.resetVisitProperties();
        this.pastVisits = new Set<ImpactStudyVisit>();
    }

    async init(): Promise<void> {
        // initialize a timer to emit an event when the current day passed,
        // in this way we can update the ui properly because some of the properties are affected when the current day passed
        if (this.timer === undefined) {
            this.timer = this.studyHelper.initTimer(
                this.updateTimeDependentProperties.bind(this),
                this.onUpdated
            );
        }

        await this.initializeStartStudyDate();
        await this.updateVisitsProperties();
        this.activeVisit = this.calculateActiveVisit();
        this.updateTimeDependentProperties();

        this.audioExerciseDisconnect = this.audioExerciseModel.onUpdated(
            this.handleOnUpdate.bind(this)
        );
        this.questionnaireDisconnect = this.questionnaireModel.onUpdated(
            this.handleOnStudyUpdate.bind(this)
        );
        this.wbcDisconnect = this.wbcModel.onUpdated(this.handleOnStudyUpdate.bind(this));
        this.ecgDisconnect = this.ecgModel.onUpdated(this.handleOnStudyUpdate.bind(this));
        this.documentDisconnect = this.documentModel.onUpdated(this.handleOnStudyUpdate.bind(this));
    }

    getVisitProperties(): ImpactVisitProperties {
        return this.visitProperties;
    }

    getVisitTasksNumber(): number {
        return ImpactWorkflow.VISIT_TASKS_NUMBER;
    }

    handleOnUpdate(objectData?: ObjectData<OneUnversionedObjectTypes>): void {
        if (!objectData) {
            return;
        }

        // if the object is from the past then update the start study date
        if (dateFns.isBefore(objectData.creationTime, this.studyStartDate)) {
            this.studyStartDate = objectData.creationTime;
        }

        const visitsFulfilled = this.checkVisitsTasksCompletion([
            objectData as ObjectData<AudioExercise>
        ]);

        const lastVisit_isAudioExerciseDone =
            this.visitProperties[IMPACT_VISIT.Day_21].isAudioExerciseDone;

        if (objectData.data.$type$ === 'AudioExercise') {
            // Assign the calculated value only if the task isn't already done. Let's assume that
            // the received object is an audio exercise completed in the second visit. The
            // visitsFulfilled object will have the data calculated correctly just for the
            // received object ("visitsFulfilled.day7_isTaskCompleted" will be true, the others
            // false). If the audio exercise from the first visit is done, then by assigning
            // directly the calculated value, we will loose the right data. Checking the value of
            // the object before assigning the new value ensure the data accuracy.
            this.visitProperties[IMPACT_VISIT.Day_0].isAudioExerciseDone = this.visitProperties[
                IMPACT_VISIT.Day_0
            ].isAudioExerciseDone
                ? this.visitProperties[IMPACT_VISIT.Day_0].isAudioExerciseDone
                : visitsFulfilled.day0_isTaskCompleted;
            this.visitProperties[IMPACT_VISIT.Day_7].isAudioExerciseDone = this.visitProperties[
                IMPACT_VISIT.Day_7
            ].isAudioExerciseDone
                ? this.visitProperties[IMPACT_VISIT.Day_7].isAudioExerciseDone
                : visitsFulfilled.day7_isTaskCompleted;
            this.visitProperties[IMPACT_VISIT.Day_14].isAudioExerciseDone = this.visitProperties[
                IMPACT_VISIT.Day_14
            ].isAudioExerciseDone
                ? this.visitProperties[IMPACT_VISIT.Day_14].isAudioExerciseDone
                : visitsFulfilled.day14_isTaskCompleted;
            this.visitProperties[IMPACT_VISIT.Day_21].isAudioExerciseDone = this.visitProperties[
                IMPACT_VISIT.Day_21
            ].isAudioExerciseDone
                ? this.visitProperties[IMPACT_VISIT.Day_21].isAudioExerciseDone
                : visitsFulfilled.day21_isTaskCompleted;
        } else {
            throw new Error(`Error: Received wrong object type ${objectData.data.$type$}.`);
        }

        this.visitProperties[IMPACT_VISIT.Day_0].tasksCompleted = this.visitProperties[
            IMPACT_VISIT.Day_0
        ].isAudioExerciseDone
            ? ImpactWorkflow.VISIT_TASKS_NUMBER
            : 0;
        this.visitProperties[IMPACT_VISIT.Day_7].tasksCompleted = this.visitProperties[
            IMPACT_VISIT.Day_7
        ].isAudioExerciseDone
            ? ImpactWorkflow.VISIT_TASKS_NUMBER
            : 0;
        this.visitProperties[IMPACT_VISIT.Day_14].tasksCompleted = this.visitProperties[
            IMPACT_VISIT.Day_14
        ].isAudioExerciseDone
            ? ImpactWorkflow.VISIT_TASKS_NUMBER
            : 0;
        this.visitProperties[IMPACT_VISIT.Day_21].tasksCompleted = this.visitProperties[
            IMPACT_VISIT.Day_21
        ].isAudioExerciseDone
            ? ImpactWorkflow.VISIT_TASKS_NUMBER
            : 0;

        this.visitProperties[IMPACT_VISIT.Day_0].visitDate = this.studyStartDate;
        this.visitProperties[IMPACT_VISIT.Day_7].visitDate = dateFns.addDays(
            this.studyStartDate,
            IMPACT_VISIT.Day_7
        );
        this.visitProperties[IMPACT_VISIT.Day_14].visitDate = dateFns.addDays(
            this.studyStartDate,
            IMPACT_VISIT.Day_14
        );
        this.visitProperties[IMPACT_VISIT.Day_21].visitDate = dateFns.addDays(
            this.studyStartDate,
            IMPACT_VISIT.Day_21
        );

        // If the received object completes the last visits then we set the display end of the
        // study message flag to true.
        if (
            this.visitProperties[IMPACT_VISIT.Day_21].isAudioExerciseDone &&
            !lastVisit_isAudioExerciseDone
        ) {
            this.displayStudyEndedMessage = true;
        }

        this.updateTimeDependentProperties();

        this.onUpdated.emit();
    }

    resetVisitProperties(): ImpactVisitProperties {
        const initialCompletedTasks = 0;

        return {
            [IMPACT_VISIT.Day_0]: {
                isAudioExerciseDone: false,
                totalTasks: ImpactWorkflow.VISIT_TASKS_NUMBER,
                tasksCompleted: initialCompletedTasks,
                visitDate: new Date()
            },
            [IMPACT_VISIT.Day_7]: {
                isAudioExerciseDone: false,
                totalTasks: ImpactWorkflow.VISIT_TASKS_NUMBER,
                tasksCompleted: initialCompletedTasks,
                visitDate: dateFns.addDays(new Date(), IMPACT_VISIT.Day_7)
            },
            [IMPACT_VISIT.Day_14]: {
                isAudioExerciseDone: false,
                totalTasks: ImpactWorkflow.VISIT_TASKS_NUMBER,
                tasksCompleted: initialCompletedTasks,
                visitDate: dateFns.addDays(new Date(), IMPACT_VISIT.Day_14)
            },
            [IMPACT_VISIT.Day_21]: {
                isAudioExerciseDone: false,
                totalTasks: ImpactWorkflow.VISIT_TASKS_NUMBER,
                tasksCompleted: initialCompletedTasks,
                visitDate: dateFns.addDays(new Date(), IMPACT_VISIT.Day_21)
            }
        };
    }

    async updateVisitsProperties(): Promise<void> {
        const lastVisit_isAudioExerciseDone =
            this.visitProperties[IMPACT_VISIT.Day_21].isAudioExerciseDone;
        // get the audio exercises
        const audioExercises = await this.audioExerciseModel.audioExercises();

        // check for which visit the audio exercise exists
        const visitsWithAudioExerciseFulfilled = this.checkVisitsTasksCompletion(audioExercises);

        // assign for each visit the results
        const day0_isAudioExerciseAdded = visitsWithAudioExerciseFulfilled.day0_isTaskCompleted;
        const day7_isAudioExerciseAdded = visitsWithAudioExerciseFulfilled.day7_isTaskCompleted;
        const day14_isAudioExerciseAdded = visitsWithAudioExerciseFulfilled.day14_isTaskCompleted;
        const day21_isAudioExerciseAdded = visitsWithAudioExerciseFulfilled.day21_isTaskCompleted;

        // If the last visit is completed during the update and it wasn't before then we set the
        // display end of the study message flag to true.
        if (day21_isAudioExerciseAdded && !lastVisit_isAudioExerciseDone) {
            this.displayStudyEndedMessage = true;
        }

        this.visitProperties = {
            [IMPACT_VISIT.Day_0]: {
                isAudioExerciseDone: day0_isAudioExerciseAdded,
                totalTasks: ImpactWorkflow.VISIT_TASKS_NUMBER,
                tasksCompleted: day0_isAudioExerciseAdded ? ImpactWorkflow.VISIT_TASKS_NUMBER : 0,
                visitDate: this.studyStartDate
            },
            [IMPACT_VISIT.Day_7]: {
                isAudioExerciseDone: day7_isAudioExerciseAdded,
                totalTasks: ImpactWorkflow.VISIT_TASKS_NUMBER,
                tasksCompleted: day7_isAudioExerciseAdded ? ImpactWorkflow.VISIT_TASKS_NUMBER : 0,
                visitDate: dateFns.addDays(this.studyStartDate, IMPACT_VISIT.Day_7)
            },
            [IMPACT_VISIT.Day_14]: {
                isAudioExerciseDone: day14_isAudioExerciseAdded,
                totalTasks: ImpactWorkflow.VISIT_TASKS_NUMBER,
                tasksCompleted: day14_isAudioExerciseAdded ? ImpactWorkflow.VISIT_TASKS_NUMBER : 0,
                visitDate: dateFns.addDays(this.studyStartDate, IMPACT_VISIT.Day_14)
            },
            [IMPACT_VISIT.Day_21]: {
                isAudioExerciseDone: day21_isAudioExerciseAdded,
                totalTasks: ImpactWorkflow.VISIT_TASKS_NUMBER,
                tasksCompleted: day21_isAudioExerciseAdded ? ImpactWorkflow.VISIT_TASKS_NUMBER : 0,
                visitDate: dateFns.addDays(this.studyStartDate, IMPACT_VISIT.Day_21)
            }
        };
    }

    calculateActiveVisit(): ImpactStudyVisit | undefined {
        if (this.currentStudyDay < IMPACT_VISIT.Day_7) {
            return IMPACT_VISIT.Day_0;
        }

        if (this.currentStudyDay < IMPACT_VISIT.Day_14) {
            return IMPACT_VISIT.Day_7;
        }

        if (this.currentStudyDay < IMPACT_VISIT.Day_21) {
            return IMPACT_VISIT.Day_14;
        }

        // subtract one because the study duration is 29 representing [0;28] interval
        // but we need the 28 value as a day of finishing the study
        if (this.currentStudyDay < STUDY_DURATION - 1) {
            return IMPACT_VISIT.Day_21;
        }

        return undefined;
    }

    updateTasksCategories(): void {
        this.tasks = this.studyHelper.classifyTasks<ImpactStudyVisit, ImpactVisitTask>(
            this.activeVisit,
            this.tasks,
            [IMPACT_TASK.AudioExercise],
            this.finishedTasks,
            this.activeVisit === undefined
                ? undefined
                : this.visitProperties[this.activeVisit].visitDate
        );
    }

    calculateFinishedTasks(): void {
        if (this.activeVisit === undefined) {
            return;
        }

        this.finishedTasks = this.visitProperties[this.activeVisit].isAudioExerciseDone
            ? [IMPACT_TASK.AudioExercise]
            : [];
    }

    updateTimeDependentProperties(): void {
        const {currentStudyDay, daysUntilNextVisit} = this.studyHelper.updateTimeProperties(
            this.studyStartDate,
            STUDY_DURATION,
            this.VISIT_INTERVAL_DAYS
        );
        this.currentStudyDay = currentStudyDay;
        this.daysUntilNextVisit = daysUntilNextVisit;
        this.activeVisit = this.calculateActiveVisit();
        this.calculateFinishedTasks();
        this.updateTasksCategories();
        this.calculatePastVisits();
    }

    public getPastVisits(): Set<ImpactStudyVisit> {
        return this.pastVisits;
    }

    // ############################## private functions ##########################################

    /**
     * Check for each visit if a task was completed.
     * @param {ObjectData<AudioExercise>[]} audioExercises -
     * the audio exercises array that will be checked if they were added within a visit.
     */
    private checkVisitsTasksCompletion(audioExercises: ObjectData<AudioExercise>[]): {
        day0_isTaskCompleted: boolean;
        day7_isTaskCompleted: boolean;
        day14_isTaskCompleted: boolean;
        day21_isTaskCompleted: boolean;
    } {
        let day0_isTaskCompleted = false;
        let day7_isTaskCompleted = false;
        let day14_isTaskCompleted = false;
        let day21_isTaskCompleted = false;

        for (const object of audioExercises) {
            if (
                this.studyHelper.isTaskCompletedWithinVisit<ImpactStudyVisit>(
                    IMPACT_VISIT.Day_0,
                    object,
                    0,
                    this.DAYS_OFFSET_ACCEPTANCE,
                    this.studyStartDate,
                    STUDY_DURATION - 1
                )
            ) {
                day0_isTaskCompleted = true;
            }

            if (
                this.studyHelper.isTaskCompletedWithinVisit<ImpactStudyVisit>(
                    IMPACT_VISIT.Day_7,
                    object,
                    0,
                    this.DAYS_OFFSET_ACCEPTANCE,
                    this.studyStartDate,
                    STUDY_DURATION - 1
                )
            ) {
                day7_isTaskCompleted = true;
            }

            if (
                this.studyHelper.isTaskCompletedWithinVisit<ImpactStudyVisit>(
                    IMPACT_VISIT.Day_14,
                    object,
                    0,
                    this.DAYS_OFFSET_ACCEPTANCE,
                    this.studyStartDate,
                    STUDY_DURATION - 1
                )
            ) {
                day14_isTaskCompleted = true;
            }

            if (
                this.studyHelper.isTaskCompletedWithinVisit<ImpactStudyVisit>(
                    IMPACT_VISIT.Day_21,
                    object,
                    0,
                    this.DAYS_OFFSET_ACCEPTANCE,
                    this.studyStartDate,
                    STUDY_DURATION - 1
                )
            ) {
                day21_isTaskCompleted = true;
            }
        }

        return {
            day0_isTaskCompleted: day0_isTaskCompleted,
            day7_isTaskCompleted: day7_isTaskCompleted,
            day14_isTaskCompleted: day14_isTaskCompleted,
            day21_isTaskCompleted: day21_isTaskCompleted
        };
    }

    /**
     * Used to transform each visit from a string to an Impact study visit data type.
     * @param visit - the visit that will be transformed.
     */
    private static transformVisitString(visit: string): ImpactStudyVisit {
        switch (visit) {
            case '0':
                return IMPACT_VISIT.Day_0;
            case '7':
                return IMPACT_VISIT.Day_7;
            case '14':
                return IMPACT_VISIT.Day_14;
            case '21':
                return IMPACT_VISIT.Day_21;
            default:
                // Should never happen. Just if new impact visits are added then it's mandatory
                // to update the function.
                throw new Error('The received visit string is not an Impact visit.');
        }
    }

    /**
     * Used to calculate the visits that have passed.
     */
    private calculatePastVisits(): void {
        for (const visit of Object.keys(this.visitProperties)) {
            try {
                const transformedVisit = ImpactWorkflow.transformVisitString(visit);
                const currentDay = dateFns.addDays(this.studyStartDate, this.currentStudyDay);

                if (
                    dateFns.isAfter(currentDay, this.visitProperties[transformedVisit].visitDate) ||
                    dateFns.isSameDay(currentDay, this.visitProperties[transformedVisit].visitDate)
                ) {
                    this.pastVisits.add(transformedVisit);
                }
            } catch (error) {
                // @TODO need to discuss how it should be handled properly
                console.error(error);
            }
        }
    }
}
