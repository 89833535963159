import type {NavigationDrawerItemsType} from '../menu/NavigationDrawer';
import i18n, {effectiveLanguage} from '../../i18n';
import {Icon} from '../icon/Icon';
import type {EventTypes} from '../journal/Journal';
import {EventType} from 'one.models/lib/models/JournalModel';
import {Book, HeartBroken, InvertColors, Note, Thermometer} from 'mdi-material-ui';
import {settingsPoliciesMenuItems} from './smilerData';
import {ACCEPTED_FILE_TYPES} from '../importDocument/ImportFile';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ImageIcon from '@material-ui/icons/Image';
import AudiotrackIcon from '@material-ui/icons/Audiotrack';

/**
 * This function returns the items that will be displayed in the left menu, for the Development product.
 * @returns - items for the left menu
 */
export function devNavigationDrawerItems(): NavigationDrawerItemsType[] {
    const activeLanguage = effectiveLanguage().toLowerCase();

    return [
        {
            name: i18n.t('common:menu.overview'),
            functionality: {path: '/'},
            icon: <Icon name="Home" />
        },
        {name: '----'},
        {
            name: i18n.t('common:menu.journal'),
            functionality: {path: '/journal'},
            icon: <Icon name="Journal" />
        },
        {
            name: i18n.t('common:menu.wbcGraph'),
            functionality: {path: '/wbc-graph'},
            icon: <Icon name="Graph" />
        },
        {name: '----'},
        {
            name: i18n.t('common:menu.addWbcMeasurement'),
            icon: <Icon name="AddWBCMeasurement" />,
            functionality: {path: '/addWbcMeasurement'}
        },
        {
            name: i18n.t('common:menu.bodyTemperature'),
            icon: <Icon name="Thermometer" />,
            functionality: {path: '/add-body-temperature'}
        },
        {
            name: i18n.t('common:menu.answerQuestionnaire'),
            functionality: {path: `/answerQuestionnaire/new?language=${activeLanguage}`},
            icon: <Icon name="AnswerQuestionnaire" />
        },
        {
            name: i18n.t('common:menu.importDocument'),
            icon: <Icon name="ImportDocument" />,
            functionality: {acceptedFileType: ACCEPTED_FILE_TYPES.Pdf}
        },
        {
            name: i18n.t('common:menu.importPicture'),
            icon: <Icon name="ImportDocument" />,
            functionality: {acceptedFileType: ACCEPTED_FILE_TYPES.Pic}
        },
        {
            name: i18n.t('common:menu.bodyTemperatureGraph'),
            functionality: {path: '/body-temperature'},
            icon: <Icon name="Thermometer" />
        },
        {
            name: i18n.t('common:menu.bloodGlucose'),
            icon: <Icon name="BloodGlucoseTable" />,
            functionality: {path: '/blood-glucose'}
        },
        {
            name: i18n.t('common:menu.dataCollection'),
            icon: <Icon name="DataCollection" />,
            functionality: {path: '/tasks'}
        },
        {
            name: i18n.t('common:menu.PIFQuestionnaire'),
            functionality: {path: 'questionnaire/edit?questionnaires=PIF&language=en'},
            icon: <Icon name="AnswerQuestionnaire" />
        },
        {name: '----l'},
        {
            name: i18n.t('common:settings.connections'),
            functionality: {path: '/connections'},
            icon: <Icon name="Devices" />
        },
        {
            name: i18n.t('common:settings.information'),
            icon: <Icon name="Information" />,
            functionality: {path: '/information'},
            subItems: settingsPoliciesMenuItems()
        },
        {
            name: i18n.t('common:menu.settings'),
            icon: <Icon name="Settings" />,
            subItems: devSettingsMenuItems()
        },
        {name: '----'},
        {
            name: i18n.t('common:menu.logout'),
            functionality: {path: '/logout?keep=true'},
            icon: <Icon name="Logout" />
        }
    ];
}

/**
 *  This function returns the list of the menu items provided to the settings page, for the Development product.
 *  @returns - items for the settings page
 */
export function devSettingsMenuItems(): NavigationDrawerItemsType[] {
    return [
        {name: '----'},
        {
            name: i18n.t('common:settings.backup'),
            functionality: {path: '/settings/backup'},
            icon: <Icon name="Backup" />
        },
        {
            name: i18n.t('common:settings.oneBrowser'),
            functionality: {path: '/settings/oneBrowser/versioned-object'},
            icon: <Icon name="Settings" />
        },
        {
            name: i18n.t('common:settings.channels'),
            functionality: {path: '/settings/channels'},
            icon: <Icon name="ChannelIcon" />
        },
        {
            name: i18n.t('common:settings.erase'),
            functionality: {path: '/settings/erase'},
            icon: <Icon name="Erase" />
        }
    ];
}

/**
 *  This function returns the list of event types, for the Development product.
 *  @returns - list of event types
 */
export function devEventTypes(): EventTypes[] {
    return [
        {
            type: EventType.QuestionnaireResponse,
            name: i18n.t('common:eventTypes.QuestionnaireResponses'),
            icon: <Note className="note" />
        },
        {
            type: EventType.WbcDiffMeasurement,
            name: i18n.t('common:eventTypes.WbcObservation'),
            icon: <InvertColors className="invert-colors" />
        },
        {
            type: EventType.HeartEvent,
            name: i18n.t('common:eventTypes.heartEvent'),
            icon: <HeartBroken className="broken-heart" />
        },
        {
            type: EventType.DocumentInfo,
            name: i18n.t('common:documentFilter.documents'),
            icon: <ImageIcon className="file-document" />
        },
        {
            type: EventType.DiaryEntry,
            name: i18n.t('common:eventTypes.DiaryEntry'),
            icon: <Book />
        },
        {
            type: EventType.BodyTemperature,
            name: i18n.t('common:eventTypes.BodyTemperature'),
            icon: <Thermometer />
        },
        {
            type: EventType.ECGEvent,
            name: i18n.t('common:eventTypes.Electrocardiogram'),
            icon: <FavoriteIcon className="black-color" />
        },
        {
            type: EventType.AudioExercise,
            name: i18n.t('common:eventTypes.AudioExercise'),
            icon: <AudiotrackIcon className="green-color" />
        }
    ];
}
