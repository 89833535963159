import type SmilerWorkflow from '../../../model/studies/SmilerWorkflow';
import type {SmilerVisitTask} from '../../../model/studies/SmilerWorkflow';
import TimelineItem from '@material-ui/lab/TimelineItem/TimelineItem';
import TimelineContent from '@material-ui/lab/TimelineContent/TimelineContent';
import i18n from '../../../i18n';
import Timeline from '@material-ui/lab/Timeline/Timeline';
import {useState} from 'react';
import type {ReactElement} from 'react';
import {TimelineOppositeContent} from '@material-ui/lab';
import * as dateFns from 'date-fns';
import type ImpactWorkflow from '../../../model/studies/ImpactWorkflow';
import type {ImpactVisitTask} from '../../../model/studies/ImpactWorkflow';
import type {
    SmilerImpactTasks,
    SmilerImpactVisitProperties
} from '../../../model/studies/SmilerImpactWorkflow';
import type AudioExerciseModel from 'one.models/lib/models/AudioExerciseModel';
import type DocumentModel from 'one.models/lib/models/DocumentModel';
import Dashboard, {TASK_TYPES} from '../studyCommon/Dashboard';
import {buildTaskDoneHistory} from '../studyCommon/DashboardCommon';
import EntryView, {EntryViewContext} from '../../journal/EntryView';
import type {ImpactStudyVisit, SmilerStudyVisit} from '../../../model/studies/StudyHelper';
import {getAudioExerciseName, verifyImpactVisit} from '../studyCommon/CommonFunctionalities';

/**
 * Represents the Smiler-Impact timeline which includes all the visits of a participant.
 * @param props - props of the view.
 * @param props.currentDayOfTheStudy - current study day.
 * @param props.currentVisit - active visit or undefined.
 * @param props.visitsProperties - visit properties for each visit.
 * @param props.audioExerciseModel - the audio exercise model used to save an object when an user start to listen an audio file.
 * @param props.documentModel - the document model used to upload pictures.
 * @param props.visits - the active and the upcoming visits of smiler-impact study.
 * @param props.tasks - the tasks categories.
 * @param props.smilerWorkflow - the smiler study model.
 * @param props.impactWorkflow - the impact study model.
 * @param props.finishedTasks - finished tasks for the active visit.
 */
export default function SmilerImpactTimeline(props: {
    currentDayOfTheStudy: number;
    currentVisit: SmilerStudyVisit | ImpactStudyVisit | undefined;
    visitsProperties: SmilerImpactVisitProperties;
    smilerWorkflow: SmilerWorkflow;
    impactWorkflow: ImpactWorkflow;
    audioExerciseModel: AudioExerciseModel;
    documentModel: DocumentModel;
    visits: (SmilerStudyVisit | ImpactStudyVisit)[];
    tasks: SmilerImpactTasks;
    finishedTasks: (SmilerVisitTask | ImpactVisitTask)[];
}): ReactElement {
    const [audioPlayer, setAudioPlayer] = useState<ReactElement>(<></>);
    const [isAudioPlayerOpen, setAudioPlayerOpen] = useState(false);
    const [title, setTitle] = useState('');

    /**
     * Used to add the name of the audio exercises to the tasks history if this contains an
     * audio exercise.
     * @param visitIdentifier - the visit for which the history is build.
     */
    function buildHistory(visitIdentifier: ImpactStudyVisit | SmilerStudyVisit): string {
        const impactVisit = verifyImpactVisit(visitIdentifier);
        let history = buildTaskDoneHistory(
            props.visitsProperties[visitIdentifier].totalTasks ===
                props.smilerWorkflow.getVisitTasksNumber()
                ? [...props.smilerWorkflow.getFinishedTasks()]
                : props.visitsProperties[visitIdentifier].totalTasks ===
                  props.impactWorkflow.getVisitTasksNumber()
                ? [...props.impactWorkflow.getFinishedTasks()]
                : [...props.finishedTasks]
        );

        if (history.includes(i18n.t('studies:stepAction.audio')) && impactVisit !== undefined) {
            history += ': ' + getAudioExerciseName(impactVisit);
        }

        return history;
    }

    return (
        <Timeline className="overview-timeline" align="left">
            <EntryViewContext.Provider
                value={{
                    closeEntryViewCallback: setAudioPlayerOpen,
                    setContent: setAudioPlayer,
                    setTitle: setTitle
                }}
            >
                {props.visits.map((phaseIdentifier, index: number) => {
                    const impactVisit = verifyImpactVisit(phaseIdentifier);
                    return (
                        <div
                            key={index}
                            className={`${
                                props.currentVisit !== phaseIdentifier ||
                                props.visitsProperties[phaseIdentifier].totalTasks -
                                    props.visitsProperties[phaseIdentifier].tasksCompleted !==
                                    props.visitsProperties[phaseIdentifier].totalTasks ||
                                props.tasks.missingTasks.size ===
                                    props.visitsProperties[phaseIdentifier].totalTasks
                                    ? 'visit'
                                    : ''
                            }`}
                        >
                            {props.currentVisit !== undefined && index === 0 && (
                                <>
                                    <Dashboard
                                        documentModel={props.documentModel}
                                        tasks={[...props.tasks.missingTasks]}
                                        taskTypes={TASK_TYPES.missing}
                                        currentVisit={props.currentVisit}
                                        audioExerciseModel={props.audioExerciseModel}
                                    />
                                    <Dashboard
                                        documentModel={props.documentModel}
                                        tasks={[...props.tasks.activeTasks]}
                                        taskTypes={TASK_TYPES.active}
                                        visitDate={
                                            props.visitsProperties[props.currentVisit].visitDate
                                        }
                                        currentVisit={props.currentVisit}
                                        audioExerciseModel={props.audioExerciseModel}
                                    />
                                </>
                            )}
                            <TimelineItem key={index} className="timeline-item">
                                {(props.visitsProperties[phaseIdentifier].tasksCompleted ===
                                    props.visitsProperties[phaseIdentifier].totalTasks ||
                                    !dateFns.isSameDay(
                                        props.visitsProperties[phaseIdentifier].visitDate,
                                        new Date()
                                    )) && (
                                    <TimelineOppositeContent className="visit-date">
                                        {dateFns.format(
                                            props.visitsProperties[phaseIdentifier].visitDate,
                                            'dd/MM/yyyy'
                                        )}
                                    </TimelineOppositeContent>
                                )}
                                <TimelineContent
                                    className={`timeline-content ${
                                        phaseIdentifier <= props.currentDayOfTheStudy ? 'blur' : ''
                                    }`}
                                >
                                    {props.finishedTasks.length >= 0 &&
                                    dateFns.isSameDay(
                                        props.visitsProperties[phaseIdentifier].visitDate,
                                        new Date()
                                    ) ? (
                                        <div
                                            className={`${
                                                props.tasks.activeTasks.size === 0
                                                    ? ''
                                                    : 'milestone-information'
                                            }`}
                                        >
                                            {buildHistory(phaseIdentifier)}
                                        </div>
                                    ) : props.visitsProperties[phaseIdentifier].totalTasks ===
                                      props.smilerWorkflow.getVisitTasksNumber() ? (
                                        i18n.t('studies:smiler.smilerVisit')
                                    ) : props.visitsProperties[phaseIdentifier].totalTasks ===
                                      props.impactWorkflow.getVisitTasksNumber() ? (
                                        <>
                                            {i18n.t('studies:stepAction.audio')}
                                            {impactVisit !== undefined &&
                                                ': ' + getAudioExerciseName(impactVisit)}
                                        </>
                                    ) : (
                                        <>
                                            {i18n.t('studies:smiler-impact.fullVisit')}
                                            {impactVisit !== undefined &&
                                                ': ' + getAudioExerciseName(impactVisit)}
                                        </>
                                    )}
                                </TimelineContent>
                            </TimelineItem>
                        </div>
                    );
                })}
            </EntryViewContext.Provider>
            <EntryView
                isOpen={isAudioPlayerOpen}
                closeEntryViewCallback={setAudioPlayerOpen}
                content={audioPlayer}
                title={title}
            />
        </Timeline>
    );
}
