import type AccessModel from 'one.models/lib/models/AccessModel';
import type ChannelManager from 'one.models/lib/models/ChannelManager';
import type {SHA256IdHash} from 'one.core/lib/util/type-checks';
import type {Person} from 'one.core/lib/recipes';
import type {Contact} from 'one.models/lib/recipes/ContactRecipes';
import {calculateIdHashOfObj} from 'one.core/lib/util/object';
import {
    createManyObjectsThroughPurePlan,
    getObject,
    getObjectByIdHash,
    SET_ACCESS_MODE,
    VERSION_UPDATES,
    createSingleObjectThroughPurePlan
} from 'one.core/lib/storage';
import type {UnversionedObjectResult} from 'one.core/lib/storage';
import type {ContactModel} from 'one.models/lib/models';
import {serializeWithType} from 'one.core/lib/util/promise';
import {ReportModel} from '../one.smiler.replicant-dependencies/Minimal-ReportModel';

/**
 * Those are the access groups of the whole application.
 *
 * @type {{ clinic: string}}
 */
export const SmilerAccessGroups = {
    clinic: 'clinic' // This group holds the clinic ids. Usually one - the replicant.
};

/**
 * This type defines how access rights for channels are specified
 */
type ChannelAccessRights = {
    owner: SHA256IdHash<Person>; // The owner of the channels
    persons: SHA256IdHash<Person>[]; // The persons who should gain access
    channels: string[]; // The channels that should gain access
};

/**
 * This class manages all access rights for freeda patients.
 *
 * The replicant has its own file in its own repo.
 *
 */
export default class SmilerAccessRightsManager {
    private readonly accessModel: AccessModel;
    private readonly channelManager: ChannelManager;
    private readonly contactModel: ContactModel;
    private initialized: boolean;
    private mainId: SHA256IdHash<Person> | null;

    /**
     * Create a new instance.
     *
     * @param {AccessModel} accessModel
     * @param {ChannelManager} channelManager
     * @param {ContactModel} contactModel
     */
    constructor(
        accessModel: AccessModel,
        channelManager: ChannelManager,
        contactModel: ContactModel
    ) {
        this.accessModel = accessModel;
        this.channelManager = channelManager;
        this.contactModel = contactModel;
        this.initialized = false;
        this.mainId = null;
    }

    /**
     * Set up the access rights handling for the application on the current instance.
     *
     * @returns {Promise<void>}
     */
    public async init(): Promise<void> {
        if (this.initialized) {
            throw new Error('The SmilerAccessRightsModel is already initialized');
        }
        this.mainId = await this.contactModel.myMainIdentity();

        // Create the access group
        await this.accessModel.createAccessGroup(SmilerAccessGroups.clinic);

        // Import the replicant contact and add it to the clinic group
        const importedReplicantContact: UnversionedObjectResult<Contact>[] =
            await createManyObjectsThroughPurePlan(
                {
                    module: '@module/explodeObject',
                    versionMapPolicy: {'*': VERSION_UPDATES.NONE_IF_LATEST}
                },
                // He have our own configuration script and it's not recognised by the window type
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                decodeURI(window.Configs.REPLICANT_CONTACT.data)
            );

        // Used for the development purpose. If the flag for creating the instance via random
        // instance is present in the local storage and its value is true then we don't add the
        // replicant to the access group. In this way the connection with the replicant isn't made.
        if (localStorage.getItem('isRandomInstance') !== 'true') {
            await this.accessModel.addPersonToAccessGroup(
                SmilerAccessGroups.clinic,
                importedReplicantContact[0].obj.personId
            );
        }

        // Create the 'hacky' contact channels that mitigate a bug with distributing versioned objects
        // TODO: remove this hack and replace it with proper sharing
        await this.channelManager.createChannel('contacts');

        const mainContactObjects = await this.contactModel.getContactObjectHashes(this.mainId);
        await this.channelManager.postToChannelIfNotExist(
            'contacts',
            await getObject(mainContactObjects[0])
        );

        // Make sure the channels is shared with the right devices even if they aren't connected yet.
        await this.giveAccessToChannels();

        this.initialized = true;
    }

    /**
     * Shuts everything down.
     *
     * @returns {Promise<void>}
     */
    // We want a consistent interface
    // eslint-disable-next-line @typescript-eslint/require-await
    public async shutdown(): Promise<void> {
        // this.stopSendingReplicantRights();
        this.initialized = false;
        this.mainId = null;
    }

    /**
     * Setup access rights for the patient app.
     *
     * Note that this function is just a hack until groups are functioning properly
     * TODO: this function should be removed when the group data sharing is working
     *
     * @returns {Promise<void>}
     */
    private async giveAccessToChannels(): Promise<void> {
        if (!this.mainId) {
            throw new Error('mainId is not initialized: This Should never happen.');
        }

        const clinics = await this.accessModel.getAccessGroupPersons(SmilerAccessGroups.clinic);
        // Build list of access rights for our own channels
        const channelAccessRights = [
            {
                owner: this.mainId,
                persons: [this.mainId, ...clinics],
                channels: [
                    'contacts',
                    'wbc',
                    'document',
                    'electrocardiogram',
                    'questionnaireResponse',
                    'consentFile',
                    'feedbackChannel',
                    'audioExercise',
                    'bloodGlucose',
                    ReportModel.actionChannelId
                ]
            },
            {
                owner: this.mainId,
                persons: [this.mainId],
                channels: [
                    'bodyTemperature',
                    'diary',
                    'newsChannel',
                    'incompleteQuestionnaireResponse'
                ]
            }
        ];

        await this.applyAccessRights(channelAccessRights);
    }

    /**
     * Apply the specified channel access rights by writing access objects.
     *
     * Note that the array should not have duplicate entries in regard to owner / channelname combinations.
     * Otherwise only one of them will be applied. Which one is not deterministic.
     *
     * @param {ChannelAccessRights[]} channelAccessRights
     * @returns {Promise<void>}
     */
    private async applyAccessRights(channelAccessRights: ChannelAccessRights[]): Promise<void> {
        await serializeWithType('IdAccess', async () => {
            // Apply the access rights
            await Promise.all(
                channelAccessRights.map(async accessInfo => {
                    await Promise.all(
                        accessInfo.channels.map(async channelId => {
                            try {
                                const setAccessParam = {
                                    id: await calculateIdHashOfObj({
                                        $type$: 'ChannelInfo',
                                        id: channelId,
                                        owner: accessInfo.owner
                                    }),
                                    person: accessInfo.persons,
                                    group: [],
                                    mode: SET_ACCESS_MODE.REPLACE
                                };
                                await getObjectByIdHash(setAccessParam.id); // To check whether a channel with this id exists
                                await createSingleObjectThroughPurePlan({module: '@one/access'}, [
                                    setAccessParam
                                ]);
                            } catch (error) {
                                // If the partner was not connected with this instance previously,
                                // then the calculateIdHashOfObj function will return a FileNotFoundError.
                                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                                if (error.name !== 'FileNotFoundError') {
                                    console.error(error);
                                }
                            }
                        })
                    );
                })
            );
        });
    }
}
