import {Fragment} from 'react';
import type {ChangeEvent, ReactElement, MouseEvent} from 'react';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import {
    Box,
    CircularProgress,
    FormControl,
    IconButton,
    ListItem,
    ListItemText,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TextField
} from '@material-ui/core';
import {useHistory} from 'react-router-dom';
import i18n from '../../../i18n';
import './VersionedObjectsTableComponent.css';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import type {VersionedObjectDetails} from '../OneBrowserVersionedObjectsScreen';
import type {Person} from 'one.core/lib/recipes';
import type {SHA256IdHash} from 'one.core/lib/util/type-checks';
import {useState} from 'react';

type VersionedObjectProperties =
    | 'id'
    | 'obj'
    | 'type'
    | 'versionCount'
    | 'lastVersion'
    | 'idFields';

const headerCells = ['type', 'idFields', 'versionCount', 'lastVersion', 'id'];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T): number {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }

    if (b[orderBy] > a[orderBy]) {
        return 1;
    }

    return 0;
}

type Order = 'asc' | 'desc';

function getComparator(
    order: Order,
    orderBy: VersionedObjectProperties
): (a: VersionedObjectDetails, b: VersionedObjectDetails) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(
    array: VersionedObjectDetails[],
    comparator: (a: VersionedObjectDetails, b: VersionedObjectDetails) => number
): VersionedObjectDetails[] {
    const stabilizedThis = array.map(
        (el, index) => [el, index] as [VersionedObjectDetails, number]
    );
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);

        if (order !== 0) {
            return order;
        }

        return a[1] - b[1];
    });

    return stabilizedThis.map(el => el[0]);
}

function Row(props: {row: VersionedObjectDetails}): ReactElement {
    const history = useHistory();
    const [open, setOpen] = useState(false);

    function goToObject(idHash: string): void {
        history.push(`/settings/oneBrowser/versioned-object/${idHash}`);
    }

    function displayPersonsArray(persons: SHA256IdHash<Person>[]): ReactElement {
        const htmlElement: JSX.Element[] = [];

        persons.forEach(person => {
            htmlElement.push(
                <ListItem
                    button
                    key={person}
                    onClick={() => {
                        goToObject(person);
                    }}
                >
                    <ListItemText>{person}</ListItemText>
                </ListItem>
            );
        });

        return <>{htmlElement}</>;
    }

    function renderExpandablePanelTable(): ReactElement {
        return (
            <TableRow>
                <TableCell colSpan={6}>
                    <Box margin={1}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell className="object-table-cell" />
                                    <TableCell className="object-table-cell" />
                                    <TableCell className="object-table-cell" align="center">
                                        {i18n.t('common:oneBrowser.sizeOfObject')}
                                    </TableCell>
                                    <TableCell className="object-table-cell" align="center">
                                        {i18n.t('common:oneBrowser.createdBy')}
                                    </TableCell>
                                    <TableCell className="object-table-cell" align="center">
                                        {i18n.t('common:oneBrowser.sharedWith')}
                                    </TableCell>
                                    <TableCell className="object-table-cell" align="center">
                                        {i18n.t('common:oneBrowser.sharedBy')}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell className="no-border-cell" />
                                    <TableCell className="object-table-cell" />
                                    <TableCell className="object-table-cell" align="center">
                                        {props.row.sizeOfObject}
                                        {i18n.t('common:oneBrowser.bytes')}
                                    </TableCell>
                                    <TableCell className="object-table-cell" align="center">
                                        <ListItem
                                            button
                                            key={props.row.createdBy}
                                            onClick={() => {
                                                if (props.row.createdBy) {
                                                    goToObject(props.row.createdBy);
                                                }
                                            }}
                                        >
                                            <ListItemText>{props.row.createdBy}</ListItemText>
                                        </ListItem>
                                    </TableCell>
                                    <TableCell className="object-table-cell" align="center">
                                        {props.row.sharedWith.length > 0
                                            ? displayPersonsArray(props.row.sharedWith)
                                            : '-'}
                                    </TableCell>
                                    <TableCell className="object-table-cell" align="center">
                                        {props.row.sharedBy}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Box>
                </TableCell>
            </TableRow>
        );
    }

    return (
        <Fragment key={props.row.id}>
            <TableRow hover key={props.row.id}>
                <TableCell className="no-border-cell">
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell className="object-table-cell" align="center">
                    {props.row.type}
                </TableCell>
                <TableCell className="object-table-cell">{props.row.idFields}</TableCell>
                <TableCell className="object-table-cell" align="center">
                    {props.row.versionCount}
                </TableCell>
                <TableCell className="object-table-cell" align="center">
                    {props.row.lastVersion}
                </TableCell>
                <TableCell className="object-table-cell">
                    <ListItem
                        button
                        key={props.row.id}
                        onClick={() => {
                            if (props.row.id) {
                                goToObject(props.row.id);
                            }
                        }}
                    >
                        <ListItemText>{props.row.id}</ListItemText>
                    </ListItem>
                </TableCell>
            </TableRow>
            {open ? renderExpandablePanelTable() : <></>}
        </Fragment>
    );
}

/**
 * @param props
 * @param props.data
 * @returns
 */
export default function VersionedObjectsTable(props: {
    data: VersionedObjectDetails[];
}): ReactElement {
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<VersionedObjectProperties>('type');
    const [query, setQuery] = useState<string>('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    function handleRequestSort(property: VersionedObjectProperties): void {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    }

    // eslint-disable-next-line func-style
    const createSortHandler = (property: string) => (_: MouseEvent<unknown>) => {
        handleRequestSort(property as VersionedObjectProperties);
    };

    // eslint-disable-next-line func-style
    const handleChangePage = (_: unknown, newPage: number): void => {
        setPage(newPage);
    };

    // eslint-disable-next-line func-style
    const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>): void => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <>
            {props.data.length === 0 ? (
                <div className="circular-progress-container">
                    <CircularProgress className="circular-progress" size={35} />
                </div>
            ) : (
                <>
                    <FormControl className="select-column-to-query">
                        <TextField
                            value={query}
                            onChange={e => setQuery(e.target.value.toLowerCase())}
                            label={i18n.t('common:oneBrowser.search')}
                        />
                    </FormControl>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <Fragment key={headerCells.length}>
                                    <TableRow>
                                        <TableCell className="expand-icon" />
                                        {headerCells.map((headCell, index) => (
                                            <TableCell
                                                key={headCell}
                                                sortDirection={orderBy === headCell ? order : false}
                                                className="object-table-cell"
                                                align="center"
                                            >
                                                <TableSortLabel
                                                    key={index}
                                                    active
                                                    direction={orderBy === headCell ? order : 'asc'}
                                                    onClick={createSortHandler(headCell)}
                                                >
                                                    {i18n.t(`common:oneBrowser.${headCell}`)}
                                                </TableSortLabel>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </Fragment>
                            </TableHead>
                            <TableBody>
                                {stableSort(
                                    query
                                        ? props.data.filter(
                                              versionedObj =>
                                                  versionedObj.type.toLowerCase().includes(query) ||
                                                  versionedObj.idFields
                                                      .toLowerCase()
                                                      .includes(query) ||
                                                  versionedObj.versionCount
                                                      .toString()
                                                      .toLowerCase()
                                                      .includes(query) ||
                                                  versionedObj.lastVersion
                                                      .toLowerCase()
                                                      .includes(query) ||
                                                  versionedObj.id.toLowerCase().includes(query)
                                          )
                                        : props.data,
                                    getComparator(order, orderBy)
                                )
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map(row => (
                                        <Row key={row.id} row={row} />
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 50]}
                        component="div"
                        count={props.data.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </>
            )}
        </>
    );
}
