import type {ReactElement} from 'react';
import type {WbcDiffModel} from 'one.models/lib/models';
import {TableRow, Table, TableBody} from '@material-ui/core';
import i18n from '../../i18n';
import '../../Primary.css';
import './WbcEntryView.css';
import {Icon} from '../icon/Icon';
import type {ObjectData} from 'one.models/lib/models/ChannelManager';
import type {WbcMeasurement, WbcObservation} from 'one.models/lib/recipes/WbcDiffRecipes';
import TableCell from '@material-ui/core/TableCell';
import {useEffect, useState} from 'react';

function renderRowTable(type: string, wbcMeasurement: WbcMeasurement | undefined): ReactElement {
    if (wbcMeasurement === undefined) {
        return <></>;
    }

    return (
        <TableRow className="wbc-row">
            <TableCell align="left" className="wbc-text" padding={'none'}>
                {i18n.t(`wbcMeasurement:wbcFormTextfields.${type}`)}
            </TableCell>
            <TableCell align="left" className="wbc-value" size={'small'} padding={'none'}>
                {' '}
                {wbcMeasurement.value}
            </TableCell>
            <TableCell align="right" size={'small'} padding={'none'}>
                {' '}
                {wbcMeasurement.unit}
            </TableCell>
            {wbcMeasurement.unsafe && (
                <TableCell className="cell-right">
                    <Icon name="Alert" />
                </TableCell>
            )}
        </TableRow>
    );
}

/**
 * Render the table with the values from the wbc measurement.
 *
 * @param {WbcObservation} wbcObservation
 * @returns {ReactElement}
 */
function renderWbcTable(wbcObservation: ObjectData<WbcObservation> | undefined): ReactElement {
    if (wbcObservation === undefined) {
        return <></>;
    }

    const {Leukocytes, Neutrophils, Lymphocytes, Monocytes, Eosinophils, Basophils} =
        wbcObservation.data;

    return (
        <Table aria-label="simple table" size="small">
            <TableBody className="wbc-table">
                {renderRowTable('wbcTitle', Leukocytes)}
                {renderRowTable('neuTitle', Neutrophils)}
                {renderRowTable('lymTitle', Lymphocytes)}
                {renderRowTable('monTitle', Monocytes)}
                {renderRowTable('eosTitle', Eosinophils)}
                {renderRowTable('basTitle', Basophils)}
            </TableBody>
        </Table>
    );
}

/**
 * Renders the detailed view of a wbc entry
 * @param {{}} props
 * @param {WbcDiffModel} props.wbcModel
 * @param props.wbcId
 * @returns {ReactElement}
 */
export default function WbcEntryView(props: {wbcModel: WbcDiffModel; wbcId: string}): ReactElement {
    const [wbcMeasurement, setWbcMeasurement] = useState<ObjectData<WbcObservation>>();

    const [errorState, setErrorState] = useState('');

    useEffect(() => {
        /**
         * useEffect hook which updates the state on model changes
         */
        function fetchWbcMeasurement(): void {
            props.wbcModel
                .observationById(props.wbcId)
                .then(wbcEntry => {
                    if (wbcEntry) {
                        setWbcMeasurement(wbcEntry);
                    }
                })
                .catch((err: Error) => {
                    setErrorState(err.message);
                });
        }
        fetchWbcMeasurement();
    }, [props.wbcModel, props.wbcId]);

    return <div className="wbc">{errorState ? errorState : renderWbcTable(wbcMeasurement)}</div>;
}
