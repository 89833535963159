import {useEffect, useState} from 'react';
import type {ReactElement} from 'react';
import {CircularProgress, Paper} from '@material-ui/core';
import i18n from '../../i18n';
import * as dateFns from 'date-fns';
import {useHistory} from 'react-router-dom';
import {setTimeToEndOfTheDay, setTimeToStartOfTheDay} from '../utils/Utils';
import InfoMessage, {MESSAGE_TYPE} from '../errors/InfoMessage';
import MenuButton from '../menu/MenuButton';
import type BodyTemperatureModel from 'one.models/lib/models/BodyTemperatureModel';
import type {BodyTemperature} from 'one.models/lib/models/BodyTemperatureModel';
import './TemperatureChart.css';
import type {ObjectData} from 'one.models/lib/models/ChannelManager';
import type {GraphData} from '../../model/WBCDataParser';
import GenericGraph from '../graphComponent/GenericGraph';
import {hideCircularProgress} from '../utils/Utils';

/**
 * This function is used to fetch all the body temperatures from the one instance.
 * @param bodyTemperatureModel - body temperature model.
 * @param setErrorMessageCallback
 * @returns  - a list with body temperatures.
 */
export function useBodyTemperatures(
    bodyTemperatureModel: BodyTemperatureModel,
    setErrorMessageCallback: (errorMessage: string) => void
): ObjectData<BodyTemperature>[] {
    const [responses, setResponses] = useState<ObjectData<BodyTemperature>[]>([]);

    useEffect(() => {
        /**
         * Proceed the call to the model to retrieve all the body temperatures from one.
         */
        function fetchBodyTemperatures(): void {
            bodyTemperatureModel
                .getBodyTemperatures()
                .then((value: ObjectData<BodyTemperature>[]) => {
                    setResponses(value);
                })
                .catch(err => {
                    setErrorMessageCallback(err);
                });
        }

        const disconnect = bodyTemperatureModel.onUpdated(fetchBodyTemperatures);
        fetchBodyTemperatures();

        return disconnect;
    }, [bodyTemperatureModel, setErrorMessageCallback]);

    return responses;
}

/**
 * Transforms the received body temperatures in the right format for the chart.
 * @param bodyTemperatures - the array of the body temperatures that will be transformed.
 * @returns the body temperatures that will be displayed on the chart.
 */
function transformBodyTemperatures(bodyTemperatures: ObjectData<BodyTemperature>[]): GraphData[] {
    const displayedTemperatures: GraphData[] = [];

    for (const response of bodyTemperatures) {
        displayedTemperatures.push({
            timestamp: response.creationTime.getTime(),
            value: response.data.temperature
        });
    }

    return displayedTemperatures;
}

/**
 * This component represents the filter buttons of the body temperature chart. (Week, Month, Year)
 * @param props
 * @param props.bodyTemperatureModel
 */
export default function DetailedView(props: {
    bodyTemperatureModel: BodyTemperatureModel;
}): ReactElement {
    /** getting the date of the body temperature passed via url **/
    const history = useHistory();
    const urlSplit = history.location.pathname.split('/');
    const dateFromURL = urlSplit[urlSplit.length - 1];
    const dateOfBodyTemperature = parseInt(dateFromURL, 10);

    const [errorMessage, setErrorMessage] = useState('');

    const responses = useBodyTemperatures(props.bodyTemperatureModel, setErrorMessage);

    /** state for the displayed body temperatures **/
    const [transformedTemperatureData, setTransformedTemperatureData] = useState<GraphData[]>([]);

    useEffect(() => {
        hideCircularProgress();
        setTransformedTemperatureData(transformBodyTemperatures(responses));
    }, [responses]);

    // x axis properties for body temperature graph
    const xAxisProperty = {
        firstTick: isNaN(dateOfBodyTemperature)
            ? dateFns.subWeeks(setTimeToStartOfTheDay(new Date()), 1).getTime()
            : dateFns
                  .subWeeks(setTimeToStartOfTheDay(new Date(dateOfBodyTemperature)), 1)
                  .getTime(),
        lastTick: isNaN(dateOfBodyTemperature)
            ? setTimeToEndOfTheDay(new Date()).getTime()
            : setTimeToEndOfTheDay(new Date(dateOfBodyTemperature)).getTime()
    };

    // y axis properties for the body temperature graph
    const yAxisProperty = {
        firstTick: 35,
        lastTick: 45,
        tickLabelStep: 5
    };

    return (
        <>
            <div className="circular-progress-container">
                <CircularProgress className="circular-progress" size={35} />
            </div>
            <div className="page-container hide">
                <InfoMessage
                    errorMessage={errorMessage}
                    setDisplayMessage={setErrorMessage}
                    messageType={MESSAGE_TYPE.Error}
                />
                <div className="menu-button-header">
                    <MenuButton />
                    <h2 className="headline">{i18n.t('common:eventTypes.BodyTemperature')}</h2>
                </div>
                <Paper elevation={3} className="page-content-box margin-top">
                    <GenericGraph
                        xAxisProperty={xAxisProperty}
                        yAxisProperty={yAxisProperty}
                        data={transformedTemperatureData}
                        displayFilters={true}
                        intervalSelector={true}
                        measurementUnit={'°C'}
                    />
                </Paper>
            </div>
        </>
    );
}
