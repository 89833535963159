import {createRef, useEffect} from 'react';
import type {ReactElement} from 'react';
import './EcgLineGraph.css';
import type {ElectrocardiogramReadings} from 'one.models/lib/recipes/ECGRecipes';
import Chart from 'chart.js';
import type {ChartOptions} from 'chart.js';

/**
 * Custom created type because the @types/chart.js@2.9.32 mismatch the chart.js@2.9.4 even if the types package
 * says it's for 2.9 version
 */
type ChartInstance = {
    scales: {
        'y-axis-0': {
            _gridLineItems: {tx2: number; ty2: number}[];
            top: number;
            bottom: number;
            left: number;
            right: number;
        };
        'x-axis-0': {
            _gridLineItems: {tx2: number; ty2: number}[];
            top: number;
            bottom: number;
            left: number;
            right: number;
        };
    };
    chart: {ctx: CanvasRenderingContext2D};
    config: Chart.ChartConfiguration;
};

/**
 * @param {{readings: ElectrocardiogramReadings[]}} props
 * @param props.readings
 * @class
 */
export function EcgLineGraph(props: {readings: ElectrocardiogramReadings[]}): ReactElement {
    const chartRef = createRef<HTMLCanvasElement>();

    useEffect(() => {
        createLineGraph();
    });

    /**
     *
     */
    function createLineGraph(): void {
        if (chartRef.current) {
            const myChartRef = chartRef.current.getContext('2d');

            if (myChartRef) {
                new Chart(myChartRef, {
                    type: 'line',
                    plugins: [
                        /** cast to {@link Chart.PluginServiceRegistrationOptions} because the plugin is using our custom type **/
                        chartDrawPlugin() as unknown as Chart.PluginServiceRegistrationOptions
                    ],
                    data: {
                        datasets: [
                            {
                                data: props.readings.map((reading: ElectrocardiogramReadings) => {
                                    return {
                                        y: reading.leadVoltage,
                                        x: reading.timeSinceSampleStart
                                    };
                                }),
                                borderColor: '#a70000',
                                fill: false,
                                borderWidth: 2
                            }
                        ]
                    },
                    // Those options are custom created and added, so they are not present for the chart options
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    drawVerticalGridLines: true,
                    drawHorizontalGridLines: true,
                    options: chartOptions()
                });
            }
        }
    }

    /**
     * Returns the Chart Options
     */
    function chartOptions(): ChartOptions {
        return {
            maintainAspectRatio: false,
            responsive: false,
            legend: {
                display: false
            },
            title: {
                display: false,
                text: ''
            },
            elements: {
                point: {
                    radius: 0
                }
            },
            scales: {
                xAxes: [
                    {
                        type: 'linear',
                        distribution: 'series',
                        ticks: {
                            callback: function (value: number) {
                                if (Number(value) === value && value % 1 === 0) {
                                    return `${value}s`;
                                } else {
                                    return '';
                                }
                            },
                            padding: 20,
                            autoSkip: false,
                            stepSize: 0.04
                        },
                        gridLines: {
                            drawTicks: false,
                            color: 'rgb(234,234,234)'
                        }
                    }
                ],
                yAxes: [
                    {
                        gridLines: {
                            color: 'rgb(234,234,234)',
                            drawTicks: false,
                            zeroLineColor: 'none'
                        },
                        ticks: {
                            max: 0.00085,
                            min: -0.00065,
                            autoSkip: false,
                            callback: function () {
                                return '';
                            },
                            stepSize: 0.00005,
                            display: true
                        }
                    }
                ]
            }
        };
    }

    /**
     * Returns the chart plugin for drawing Horizontal and Vertical Lines on the chart
     */
    function chartDrawPlugin(): {
        renderHorizontalLine: (chartInstance: ChartInstance, ty2: number) => void;
        renderVerticalLine: (chartInstance: ChartInstance, tx2: number, isTick: boolean) => void;
        afterDatasetsDraw: (chart: ChartInstance) => void;
    } {
        return {
            renderVerticalLine: function (
                chartInstance: ChartInstance,
                tx2: number,
                isTick: boolean
            ) {
                const scale = chartInstance.scales['y-axis-0'];
                const context = chartInstance.chart.ctx;
                /** render vertical line **/
                context.beginPath();
                context.lineWidth = 1;
                context.strokeStyle = '#c4c4c4';
                context.moveTo(tx2, scale.top);
                context.lineTo(tx2, isTick ? scale.bottom + 10 : scale.bottom);
                context.stroke();
            },
            renderHorizontalLine: function (chartInstance: ChartInstance, ty2: number) {
                const scale = chartInstance.scales['x-axis-0'];
                const context = chartInstance.chart.ctx;
                /** render horizontal line **/
                context.beginPath();
                context.lineWidth = 1;
                context.strokeStyle = '#c4c4c4';
                context.moveTo(scale.left, ty2);
                context.lineTo(scale.right, ty2);
                context.stroke();
            },
            afterDatasetsDraw: function (chart: ChartInstance) {
                // Those options are custom created and added, so they are not present for the chart options
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                if (chart.config.drawVerticalGridLines) {
                    const grids = chart.scales['x-axis-0']._gridLineItems;

                    for (let i = 0; i < grids.length; i = i + 5) {
                        this.renderVerticalLine(chart, grids[i].tx2, i % 25 === 0);
                    }
                }

                // Those options are custom created and added, so they are not present for the chart options
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                if (chart.config.drawHorizontalGridLines) {
                    const grids = chart.scales['y-axis-0']._gridLineItems;

                    for (let i = 0; i < grids.length; i = i + 5) {
                        this.renderHorizontalLine(chart, grids[i].ty2);
                    }
                }
            }
        };
    }

    return (
        <div className={'graphContainer'}>
            <canvas width={4000} height={200} id="myChart" ref={chartRef} />
        </div>
    );
}
