import {useCallback, useEffect, useState} from 'react';
import type {ReactElement, ChangeEvent} from 'react';
import {useHistory} from 'react-router-dom';
import '../../Primary.css';
import './ChannelsView.css';
import {
    Button,
    CircularProgress,
    FormControl,
    Grid,
    IconButton,
    InputBase,
    InputLabel,
    NativeSelect,
    Tooltip,
    withStyles
} from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import InfoMessage, {MESSAGE_TYPE} from '../errors/InfoMessage';
import MenuButton from '../menu/MenuButton';
import i18n from '../../i18n';
import Paper from '@material-ui/core/Paper';
import type {ChannelInfo, ChannelRegistry} from 'one.models/lib/recipes/ChannelRecipes';
import type {SHA256Hash} from 'one.core/lib/util/type-checks';
import {getAllVersionMapEntries, getObject, getObjectByIdObj} from 'one.core/lib/storage';
import type {VersionMapEntry} from 'one.core/lib/storage';
import {calculateHashOfObj} from 'one.core/lib/util/object';
import {Alert} from '@material-ui/lab';
import {getNthVersionMapHash} from 'one.core/lib/version-map-query';
import {displayCircularProgress, hideCircularProgress} from '../utils/Utils';

const BootstrapInput = withStyles(theme => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(3)
        }
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"'
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
        }
    }
}))(InputBase);

type ExtendedChannelInfo = {hash: SHA256Hash<ChannelInfo>} & ChannelInfo;

/**
 * @returns {ReactElement}
 * @class
 */
export default function ChannelsView(): ReactElement {
    const history = useHistory();
    const [errorState, setErrorState] = useState<string>('');
    const [version, setVersion] = useState('');
    const [versions, setVersions] = useState<VersionMapEntry<ChannelRegistry>[]>([]);
    const [channels, setChannels] = useState<ExtendedChannelInfo[]>([]);
    const [blinkingChannels, setBlinkingChannels] = useState<string[]>([]);

    const fetchChannels = useCallback(() => {
        displayCircularProgress();

        getObjectByIdObj({$type$: 'ChannelRegistry', id: 'ChannelRegistry'})
            .then(async channelRegistry => {
                const channelsFromRegistry = await getChannelsFromChannelRegistry(
                    channelRegistry.obj
                );
                const versionsMapEntries = await getAllVersionMapEntries(channelRegistry.idHash);

                setVersion(channelRegistry.hash);
                setVersions(versionsMapEntries.reverse());
                setChannels(channelsFromRegistry);
                setBlinkingChannels([]);
                hideCircularProgress();
            })
            .catch(() => {
                setErrorState('errors:channels.fetchChannels');
                hideCircularProgress();
            });
    }, []);

    useEffect(() => {
        fetchChannels();
    }, [fetchChannels]);

    /**
     *
     * @param channelRegistry
     * @returns
     */
    async function getChannelsFromChannelRegistry(
        channelRegistry: ChannelRegistry
    ): Promise<ExtendedChannelInfo[]> {
        return await Promise.all(
            channelRegistry.channels.map(async regEntry => {
                const channelHash: SHA256Hash<ChannelInfo> = await getNthVersionMapHash(
                    regEntry.channelInfoIdHash,
                    regEntry.readVersionIndex
                );
                const channelInfo = await getObject(channelHash);
                return {...channelInfo, hash: channelHash};
            })
        );
    }

    /**
     *
     * @param nextChannelsFromRegistry
     */
    function handleBlinkingChannels(nextChannelsFromRegistry: ExtendedChannelInfo[]): void {
        const blinkChannels: string[] = [];

        for (const ch of channels) {
            const foundNextChannel = nextChannelsFromRegistry.find(channel => channel.id === ch.id);

            if (foundNextChannel && foundNextChannel.head !== ch.head) {
                blinkChannels.push(foundNextChannel.id);
            }
        }
        setBlinkingChannels(blinkChannels);
    }

    /**
     *
     * @param event
     */
    function handleVersionChange(event: ChangeEvent<{value: unknown}>): void {
        event.persist();

        if (event.target.value !== null) {
            displayCircularProgress();

            getObject(event.target.value as SHA256Hash<ChannelRegistry>)
                .then(async (channelRegistry: ChannelRegistry) => {
                    const channelsFromRegistry = await getChannelsFromChannelRegistry(
                        channelRegistry
                    );
                    handleBlinkingChannels(channelsFromRegistry);
                    setVersion(await calculateHashOfObj(channelRegistry));
                    setChannels(channelsFromRegistry);
                    hideCircularProgress();
                })
                .catch((ignored: Error) => {
                    setErrorState('errors:channels.fetchChannels');
                    hideCircularProgress();
                });
        }
    }

    return (
        <>
            <div className="circular-progress-container">
                <CircularProgress className="circular-progress" size={35} />
            </div>

            <div className="page-container hide">
                <InfoMessage
                    errorMessage={errorState}
                    setDisplayMessage={setErrorState}
                    messageType={MESSAGE_TYPE.Error}
                />
                <div className="menu-button-header">
                    <MenuButton />
                    <h2 className="headline"> {i18n.t('common:settings.channels')}</h2>
                </div>
                <div className="channels-page-content">
                    <FormControl className="channels-version-select">
                        <InputLabel htmlFor="demo-customized-select-native">
                            {i18n.t('common:channels.versionOfChannels')}
                        </InputLabel>
                        <NativeSelect
                            id="demo-customized-select-native"
                            value={version}
                            onChange={handleVersionChange}
                            input={<BootstrapInput />}
                        >
                            {versions.map((versionMapEntry: VersionMapEntry<ChannelRegistry>) => (
                                <option
                                    key={versionMapEntry.timestamp}
                                    value={versionMapEntry.hash}
                                >
                                    {new Date(Number(versionMapEntry.timestamp)).toUTCString()}
                                </option>
                            ))}
                        </NativeSelect>
                    </FormControl>
                    <IconButton
                        onClick={fetchChannels}
                        aria-label="refresh"
                        className="refresh-button"
                    >
                        <RefreshIcon />
                    </IconButton>
                    <Grid container spacing={3}>
                        {channels.length === 0 ? (
                            <Alert severity="info" key="alert">
                                {i18n.t('common:channels.emptyChannels')}
                            </Alert>
                        ) : (
                            channels.map((channelVersionedObject: ExtendedChannelInfo) => {
                                return (
                                    <Grid
                                        className={`channels-item-grid ${
                                            blinkingChannels.find(
                                                ch => ch === channelVersionedObject.id
                                            )
                                                ? 'blink'
                                                : ''
                                        }`}
                                        item
                                        key={channelVersionedObject.hash}
                                        xs={3}
                                    >
                                        <Tooltip
                                            title={channelVersionedObject.hash}
                                            aria-label="add"
                                        >
                                            <Paper className="channels-item-wrapper">
                                                <Button
                                                    onClick={() =>
                                                        history.push(
                                                            `/settings/channels/${version}/${channelVersionedObject.hash}`
                                                        )
                                                    }
                                                    className="channels-item-button"
                                                    color="primary"
                                                >
                                                    {channelVersionedObject.id}
                                                </Button>
                                            </Paper>
                                        </Tooltip>
                                    </Grid>
                                );
                            })
                        )}
                    </Grid>
                </div>
            </div>
        </>
    );
}
