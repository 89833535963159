import {createContext, useContext} from 'react';
import type {ReactElement} from 'react';
import {Dialog, IconButton} from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import i18n from '../../i18n';
import * as dateFns from 'date-fns';
import CloseIcon from '@material-ui/icons/Close';
import './EntryView.css';
import type {EventListEntry} from 'one.models/lib/models/JournalModel';

export type EntryViewContent = {
    closeEntryViewCallback: (value: boolean) => void;
    setContent: (content: ReactElement) => void;
    setTitle: (title: string) => void;
};

export const EntryViewContext = createContext<EntryViewContent>({
    closeEntryViewCallback: () => undefined,
    setContent: () => undefined,
    setTitle: () => undefined
});

/**
 * Forward the notification content context.
 */
export function useEntryViewContext(): EntryViewContent {
    return useContext(EntryViewContext);
}

/**
 * The journal entry popup view.
 * @param props
 * @param props.isOpen - based on this flag the popup it's open or closed.
 * @param props.closeEntryViewCallback - callback which is triggered when the popup is closed.
 * @param props.objectData - used to display data about the object (creation time, object type)
 * and to use difference customization based on what event type is displayed.
 * @param props.content - the react element displayed as content of the popup.
 * @param props.title
 */
export default function EntryView(props: {
    isOpen: boolean;
    closeEntryViewCallback: (state: boolean) => void;
    objectData?: EventListEntry;
    content: ReactElement;
    title?: string;
}): ReactElement {
    /**
     * Trigger the callback and close the popup.
     */
    function handleClose(): void {
        props.closeEntryViewCallback(false);
    }

    let objectType = '';

    if (props.objectData) {
        objectType = props.objectData.type;
    }

    return (
        <Dialog open={props.isOpen} className="entry-view" onClose={handleClose}>
            <DialogTitle className={`entry-view-headline ${objectType}`}>
                <i>
                    {objectType !== '' && <>{i18n.t(`common:entry.${objectType}`)}, </>}
                    {props.title}
                    {props.objectData && (
                        <>
                            {dateFns.format(props.objectData.data.creationTime, 'd')}.{' '}
                            {i18n.t(
                                `common:months:${dateFns.format(
                                    props.objectData.data.creationTime,
                                    'MMMM'
                                )}`
                            )}{' '}
                            {dateFns.format(props.objectData.data.creationTime, 'yyyy')}
                        </>
                    )}
                </i>
                <IconButton className="popup-close-button" onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent className={`entry-view-content-${objectType}`}>
                {props.content}
            </DialogContent>
        </Dialog>
    );
}
