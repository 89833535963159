import type {ReactElement} from 'react';
import * as dateFns from 'date-fns';
import {DATA_RANGE} from './Filters';
import type {FilterRange} from './Filters';
import i18n from '../../i18n';

/**
 * This function is used to format the date that is displayed on x axis based on the filter mode.
 * @param date - the date in millis.
 * @param activeFilter - the active filter.
 */
function tickFormatter(date: number, activeFilter: FilterRange | undefined): string {
    if (!dateFns.isValid(date)) {
        return '';
    }

    switch (activeFilter) {
        case DATA_RANGE.Week:
            return i18n.t(`bodyTemperature:daysAbbreviations.${dateFns.format(date, 'EEEEEE')}`);
        case DATA_RANGE.Month:
            return dateFns.format(date, 'dd');
        case DATA_RANGE.Year:
            return i18n.t(`bodyTemperature:monthsAbbreviations.${dateFns.format(date, 'MMM')}`);
        default:
            return dateFns.format(date, 'dd/MM/yyyy');
    }
}

/**
 * Customized tick component for x axis.
 * This ensure a right positioning for the ticks.
 * @param props
 */
export default function CustomizedTick(props: any): ReactElement {
    const {
        x,
        y,
        height,
        payload,
        index,
        visibleTicksCount,
        activeFilter
    }: {
        x: number;
        y: number;
        height: number;
        payload: {value: number};
        index: number;
        visibleTicksCount: number;
        activeFilter: FilterRange | undefined;
    } = props;

    return (
        <text
            x={x}
            y={height / 2 + y}
            fontSize={12}
            fill={'#2a5fa6'}
            textAnchor={index === 0 ? 'start' : index === visibleTicksCount - 1 ? 'end' : 'middle'}
        >
            {tickFormatter(payload.value, activeFilter)}
        </text>
    );
}
