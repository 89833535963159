import * as dateFns from 'date-fns';

/**
 * Get a date value as a string, its value can be as well 'now',
 * and returns the needed date based on the value date.
 * @param valueDate - The date value for a date question from a questionnaire
 * @returns The date
 */
export function formatAnswerRestrictionDate(valueDate: string): Date {
    return dateFns.parseISO(
        valueDate === 'now'
            ? dateFns.format(Date.now(), 'yyyy-MM-dd')
            : dateFns.format(dateFns.parseISO(valueDate), 'yyyy-MM-dd')
    );
}
