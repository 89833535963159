import type {EventListEntry} from 'one.models/lib/models/JournalModel';
import type {ReactElement} from 'react';
import './Calendar.css';
import '../../Primary.css';
import i18n from '../../i18n';

export const stringHours = [
    '0 AM',
    '1 AM',
    '2 AM',
    '3 AM',
    '4 AM',
    '5 AM',
    '6 AM',
    '7 AM',
    '8 AM',
    '9 AM',
    '10 AM',
    '11 AM',
    '12 PM',
    '1 PM',
    '2 PM',
    '3 PM',
    '4 PM',
    '5 PM',
    '6 PM',
    '7 PM',
    '8 PM',
    '9 PM',
    '10 PM',
    '11 PM'
];

export const nameOfDaysOfWeek = [
    i18n.t('common:days.Sunday'),
    i18n.t('common:days.Monday'),
    i18n.t('common:days.Tuesday'),
    i18n.t('common:days.Wednesday'),
    i18n.t('common:days.Thursday'),
    i18n.t('common:days.Friday'),
    i18n.t('common:days.Saturday')
];

/**
 * Generates the modal content
 * @param clickedEvent - from calendar view
 * @returns - the content based on information from the event param
 */
export function generateModalContent(clickedEvent?: EventListEntry): ReactElement {
    if (clickedEvent) {
        return (
            <div className="calendar_common">
                {Object.entries(clickedEvent.data).map(([name, value], index) => {
                    const notCamelCaseName = name.replace(/^[a-z]|[A-Z]/g, function (v, i) {
                        return i === 0 ? v.toUpperCase() : ' ' + v.toLowerCase();
                    });

                    return (
                        <div key={index}>
                            <>
                                {name === 'data' ? (
                                    <>
                                        {Object.entries(value).map(([identifier, val], idx) => {
                                            const formattedField = identifier
                                                .toString()
                                                .replace(/([A-Z]+)/g, ' $1')
                                                .replace(/([A-Z][a-z])/g, ' $1');
                                            const displayedField =
                                                formattedField.charAt(0).toUpperCase() +
                                                formattedField.slice(1);
                                            return (
                                                <div key={idx}>
                                                    <p>
                                                        {displayedField}: {val}
                                                    </p>
                                                </div>
                                            );
                                        })}
                                    </>
                                ) : (
                                    <>
                                        <p>
                                            {notCamelCaseName}: {value?.toString()}
                                        </p>
                                    </>
                                )}
                            </>
                        </div>
                    );
                })}
            </div>
        );
    }

    return <></>;
}
