import type {ReactElement, SetStateAction} from 'react';
import i18n from '../../i18n';
import './Feedback.css';
import Paper from '@material-ui/core/Paper';
import {useHistory} from 'react-router-dom';
import {Button, TextField} from '@material-ui/core';
import type NewsModel from 'one.models/lib/models/NewsModel';
import InfoMessage, {MESSAGE_TYPE} from '../errors/InfoMessage';
import MenuButton from '../menu/MenuButton';
import {useState} from 'react';

/**
 *
 * @param props
 * @param props.newsModel
 * @returns
 */
export default function Feedback(props: {newsModel: NewsModel}): ReactElement {
    const history = useHistory();

    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [fillAllFieldsError, setFillAllFieldsError] = useState('');

    async function sendFeedback(): Promise<void> {
        if (title === '' || content === '') {
            setFillAllFieldsError('errors:feedback.emptyField');
            return;
        }
        await props.newsModel
            .addFeedback(`<b>${title}</b><br/>${content}`)
            .then(() => {
                history.push('/');
            })
            .catch((err: {message: SetStateAction<string>}) => {
                setFillAllFieldsError(err.message);
            });
    }

    return (
        <>
            <div className="page-container feedback-container">
                <InfoMessage
                    errorMessage={fillAllFieldsError}
                    setDisplayMessage={setFillAllFieldsError}
                    messageType={MESSAGE_TYPE.Error}
                />
                <div className="menu-button-header">
                    <MenuButton />
                    <h2 className="headline">{i18n.t('common:settings.feedback')}</h2>
                </div>
                <Paper square elevation={3} className="page-content-box feedback-text-container">
                    <div className="feedback-text">{i18n.t('common:infos.feedback-text')}</div>
                </Paper>
                <Paper square elevation={3} className="page-content-box">
                    <div className="text-field-feedback">
                        <TextField
                            id="outlined-basic"
                            label={i18n.t('common:textfields.title')}
                            variant="outlined"
                            fullWidth
                            onChange={e => {
                                setTitle(e.target.value);
                            }}
                        />
                    </div>
                    <div className="text-field-feedback">
                        <TextField
                            id="outlined-basic"
                            className="text-field-feedback"
                            label={i18n.t('common:textfields.content')}
                            fullWidth
                            multiline={true}
                            rows={10}
                            variant="outlined"
                            onChange={e => {
                                setContent(e.target.value);
                            }}
                        />
                    </div>
                </Paper>
                <div className="buttons-container">
                    <Button
                        onClick={async () => {
                            await sendFeedback();
                        }}
                        color="primary"
                        variant="contained"
                        className="button"
                    >
                        {i18n.t('common:buttons.feedback-send')}
                    </Button>
                </div>
            </div>
        </>
    );
}
