import type {ReactElement} from 'react';
import type {SyntheticEvent} from 'react';
import './FileViewer.css';

/**
 * @param props
 * @param props.pictureURL
 * @param props.onLoadError
 * @param props.onLoadSuccess
 */
export default function ViewPictureComponent(props: {
    pictureURL: string;
    onLoadError: (errorName: string) => void;
    onLoadSuccess?: () => void;
}): ReactElement {
    return props.pictureURL ? (
        <div className="page-content-box remove-padding pdf-container">
            <img
                onLoad={() => {
                    if (props.onLoadSuccess) {
                        props.onLoadSuccess();
                    }
                }}
                onError={(_: SyntheticEvent<HTMLImageElement, Event>) => {
                    props.onLoadError('CorruptedImageFormat');
                }}
                className="photo-container"
                src={props.pictureURL}
                alt=""
            />
        </div>
    ) : (
        <></>
    );
}
