import type DataSyncManager from '../../model/DataSyncManager';
import {useEffect, useState} from 'react';

/**
 * Used to get catch the event when the sync is done.
 * @param dataSyncManager - the data sync manager which emits the event.
 */
export function useIsDataSynced(dataSyncManager: DataSyncManager): boolean {
    const [isDataSynced, setIsDataSynced] = useState<boolean>(false);

    useEffect(() => {
        function finishSyncData(): void {
            setIsDataSynced(dataSyncManager.getSyncDone());
        }

        finishSyncData();
        return dataSyncManager.onSyncDone(finishSyncData);
    }, [dataSyncManager]);

    // if the created instance is a random instance then we return true because the connection
    // with the replicant isn't made => no data sync for that instance
    return localStorage.getItem('isRandomInstance') === 'true' ? true : isDataSynced;
}
