import {useEffect, useState} from 'react';
import type {ReactElement} from 'react';
import i18n from '../../i18n';
import type OneInstanceModel from '../../model/OneInstanceModel';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Button, Checkbox} from '@material-ui/core';
import {
    changeBackgroundColorToWhite,
    hideCircularProgress,
    removeWhiteBackgroundColor
} from '../utils/Utils';
import './ConfigurationProcess.css';
import {useHistory} from 'react-router-dom';
import MarkdownPopup from '../displayMarkdowns/MarkdownPopup';

/**
 * @param props
 * @param props.oneInstanceModel
 * @param props.onRegistrationCompleted
 */
export default function ConfigurationProcess(props: {
    oneInstanceModel: OneInstanceModel;
    onRegistrationCompleted: () => void;
}): ReactElement {
    const [acceptTermOfUse, setAcceptTermsOfUse] = useState(false);
    const [acceptPrivacyPolicy, setAcceptPrivacyPolicy] = useState(false);
    const history = useHistory();
    const [markdownPath, setMarkdownPath] = useState('');

    /**
     * Effect for changing the background color to white
     * when the component it's rendered and also to
     * remove it when the component is unmounted.
     */
    useEffect(() => {
        hideCircularProgress();
        changeBackgroundColorToWhite();

        return () => removeWhiteBackgroundColor();
    }, []);

    /**
     * Select and deselect the checkbox for the Terms Of Use
     */
    function changeTermsOfUseCheckbox(): void {
        setAcceptTermsOfUse(!acceptTermOfUse);
    }

    /**
     * Select and deselect the checkbox for Privacy Policy
     */
    function changePrivacyPolicyCheckbox(): void {
        setAcceptPrivacyPolicy(!acceptPrivacyPolicy);
    }

    /**
     * Handler for accepting the Privacy Policy
     */
    function clickOnNextButton(): void {
        props.oneInstanceModel.unregister();

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore window.navigator.standalone is available only in safari
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const isInStandalone = window.navigator.standalone;

        props.onRegistrationCompleted();

        if (
            (isInStandalone || window.matchMedia('(display-mode: standalone)').matches) &&
            !window.location.pathname.includes('invites')
        ) {
            history.push('/');
        }
    }

    /**
     * Displays a markdown dialog box, whose path was sent as a parameter
     * @param filePath
     */
    function displayMarkdownDialog(filePath: string): void {
        setMarkdownPath(filePath);
    }

    return (
        <>
            <div className="circular-progress-container">
                <CircularProgress className="circular-progress" size={35} />
            </div>

            <div className="cnfg-process-container page-container hide">
                <div className="cnfg-welcome-step-bold">{i18n.t('config:welcomePart1')}</div>
                <div className="cnfg-welcome-step">{i18n.t('config:welcomePart2')}</div>
                <div className="cnfg-step">
                    <span>{i18n.t('config:termsOfUseBeforeLink')}</span>
                    <span
                        className="cnfg-step-link"
                        onClick={() => {
                            displayMarkdownDialog(i18n.t('markDown:termsOfUse'));
                        }}
                    >
                        {i18n.t('config:termsOfUseLink')}
                    </span>
                    <span>{i18n.t('config:termsOfUseAfterLink')}</span>
                </div>
                <div className="cnfg-accept-step">
                    <Checkbox
                        color="primary"
                        inputProps={{'aria-label': 'secondary checkbox'}}
                        onChange={changeTermsOfUseCheckbox}
                    />
                    <div>{i18n.t('config:acceptTermsOfUse')}</div>
                </div>
                <div className="cnfg-step">
                    <span>{i18n.t('config:privacyPolicyBeforeLink')}</span>
                    <span
                        className="cnfg-step-link"
                        onClick={() => {
                            displayMarkdownDialog(i18n.t('markDown:privacyPolicyPWA'));
                        }}
                    >
                        {i18n.t('config:privacyPolicyLink')}
                    </span>
                    <span>{i18n.t('config:privacyPolicyAfterLink')}</span>
                </div>
                <div className="cnfg-accept-step">
                    <Checkbox
                        color="primary"
                        inputProps={{'aria-label': 'secondary checkbox'}}
                        onChange={changePrivacyPolicyCheckbox}
                    />
                    <div>{i18n.t('config:acceptPrivacyPolicy')}</div>
                </div>
                <div className="cnfg-mandatory-steps">{i18n.t('config:necessarySteps')}</div>
                <Button
                    disabled={!(acceptTermOfUse && acceptPrivacyPolicy)}
                    className="cnfg-save-btn"
                    variant="contained"
                    color="default"
                    onClick={clickOnNextButton}
                >
                    {i18n.t('common:buttons.next')}
                </Button>
            </div>
            <MarkdownPopup filePath={markdownPath} setFilePath={setMarkdownPath} />
        </>
    );
}
