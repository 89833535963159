/**
 * @author Maximilian Wisgickl <wisigcklma@refinio.net>
 * @copyright REFINIO GmbH
 * @license SEE LICENSE IN LICENSE.md
 * @version 0.0.1
 **/

import {
    getObject,
    getObjectByIdHash,
    readBlobAsBase64,
    CREATION_STATUS,
    getTextFile
} from 'one.core/lib/storage';
import type {AnyObjectCreation, VersionedObjectResult} from 'one.core/lib/storage';
import type {OneVersionedObjectTypes, OneObjectTypes, Recipe} from 'one.core/lib/recipes';
import type {SHA256Hash, SHA256IdHash} from 'one.core/lib/util/type-checks';
import type {AnyObject} from 'one.core/lib/util/object';
import {getRecipe} from 'one.core/lib/object-recipes';

/**
 * gets an ONE object but also resolves all recursively all included references
 * @param {SHA256IdHash} idHash - Get object by this Hash
 * @returns {Promise<AnyObjectCreation>}
 */
export async function getResolvedIdObject<T extends OneVersionedObjectTypes>(
    idHash: SHA256IdHash<T>
): Promise<VersionedObjectResult<T>> {
    const creationRes = await getObjectByIdHash(idHash);
    const recipe = getRecipe(creationRes.obj.$type$);
    await checkRecipeNestedObjects(recipe, creationRes.obj);
    return creationRes;
}

/**
 * gets an ONE object but also resolves all recursively all included references
 * @param {SHA256Hash} hash - Get object by this Hash
 * @returns {Promise<AnyObjectCreation>}
 */
export async function getResolvedObject<T extends OneObjectTypes>(
    hash: SHA256Hash<T>
): Promise<AnyObjectCreation<T>> {
    const obj = await getObject(hash);
    const recipe = getRecipe(obj.$type$);

    await checkRecipeNestedObjects(recipe, obj);

    return {
        obj,
        hash,
        status: CREATION_STATUS.EXISTS
    } as AnyObjectCreation<T>;
}

/**
 * Checks a recipe for nested objects (References) and loads them
 * @param {Recipe} recipe
 * @param {OneObjectTypes} obj
 * @returns {Promise<void>}
 */
async function checkRecipeNestedObjects(recipe: Recipe, obj: OneObjectTypes): Promise<void> {
    for (const rule of recipe.rule) {
        const {itemprop} = rule;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const prop = (obj as AnyObject)[itemprop];

        if (rule.referenceToObj) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            if (prop && prop.length > 0) {
                (obj as AnyObject)[itemprop] = Array.isArray(prop)
                    ? // eslint-disable-next-line no-await-in-loop
                      await Promise.all(prop.map(getResolvedObject))
                    : // eslint-disable-next-line no-await-in-loop
                      await getResolvedObject(prop);
            }
        }

        if (rule.referenceToId) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            if (prop && prop.length > 0) {
                (obj as AnyObject)[itemprop] = Array.isArray(prop)
                    ? // eslint-disable-next-line no-await-in-loop
                      await Promise.all(prop.map(getResolvedIdObject))
                    : // eslint-disable-next-line no-await-in-loop
                      await getResolvedIdObject(prop);
            }
        }

        if (Object.prototype.hasOwnProperty.call(rule, 'referenceToBlob') && prop) {
            // eslint-disable-next-line no-await-in-loop
            (obj as AnyObject)[itemprop] = await readBlobAsBase64(prop);
        }

        if (Object.prototype.hasOwnProperty.call(rule, 'referenceToClob')) {
            // eslint-disable-next-line no-await-in-loop
            (obj as AnyObject)[itemprop] = await getTextFile(prop);
        }
    }
}
