import {initReactI18next} from 'react-i18next';
import i18nModelsInstance from 'one.models/lib/i18n';

import common_de from './translations/de/common.json';
import errors_de from './translations/de/errors.json';
import login_de from './translations/de/login.json';
import news_de from './translations/de/news.json';
import wbcMeasurement_de from './translations/de/wbcMeasurement.json';
import bodyTemperature_de from './translations/de/bodyTemperature.json';
import connections_de from './translations/de/connections.json';
import markDown_de from './translations/de/markdownPdfPaths.json';
import steppers_de from './translations/de/steppers.json';
import eq5_de from './translations/de/eq5.json';
import recovery_de from './translations/de/recovery.json';
import addToHomeScreen_de from './translations/de/addToHomeScreen.json';
import ecg_de from './translations/de/ecg.json';
import configuration_de from './translations/de/configuration.json';
import studies_de from './translations/de/studies.json';
import bloodGlucose_de from './translations/de/bloodGlucose.json';
import genericGraph_de from './translations/de/genericGraph.json';

const resources = {
    de: {
        common: common_de,
        errors: errors_de,
        login: login_de,
        news: news_de,
        wbcMeasurement: wbcMeasurement_de,
        bodyTemperature: bodyTemperature_de,
        connections: connections_de,
        markDown: markDown_de,
        steppers: steppers_de,
        eq5: eq5_de,
        recovery: recovery_de,
        addToHomeScreen: addToHomeScreen_de,
        ecg: ecg_de,
        config: configuration_de,
        studies: studies_de,
        bloodGlucose: bloodGlucose_de,
        genericGraph: genericGraph_de
    }
};

const supportedLanguages = new Set(Object.keys(resources));
const fallbackLng = 'de';

i18nModelsInstance
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng,
        lng: 'de'
    })
    .catch(ignored => undefined);

/**
 * Get effective language, i.e. the same language i18next loads the
 * resources from, which is the first language in the languages
 * field that has a resource.
 * @returns {string} - effective language
 */
export function effectiveLanguage(): string {
    return i18nModelsInstance.languages.find(lng => supportedLanguages.has(lng)) || fallbackLng;
}

export default i18nModelsInstance;
