import type {SHA256IdHash, SHA256Hash} from 'one.core/lib/util/type-checks';
import {useEffect, useState} from 'react';
import type {ChangeEvent, ReactElement} from 'react';
import {getAllVersionMapEntries, onVersionedObj} from 'one.core/lib/storage';
import type {AnyObjectCreation, VersionMapEntry, VersionedObjectResult} from 'one.core/lib/storage';
import {getResolvedIdObject, getResolvedObject} from './utils/explodeUtils';
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    InputLabel,
    MenuItem,
    Paper,
    Select
} from '@material-ui/core';
import ReactJson from 'react-json-view';
import {useParams} from 'react-router-dom';
import i18n from '../../i18n';
import './OneBrowserDetailedScreen.css';
import MenuButton from '../menu/MenuButton';

function ObjectDetailsComponent(props: {
    idHash: SHA256IdHash;
    setError: (e: Error) => void;
}): ReactElement {
    const [data, setData] = useState<AnyObjectCreation>();
    const [version, selectVersion] = useState('');
    const [versions, setVersions] = useState<VersionMapEntry[]>([]);
    const [checked, setChecked] = useState<boolean>(false);
    const [forLiveVersionUpdatesInList] = useState<number>(0);

    const {idHash, setError} = props;

    function handleSelect(event: ChangeEvent<{value: unknown}>): void {
        selectVersion(event.target.value as string);
    }

    function handleChangeCheckBox(event: ChangeEvent<HTMLInputElement>): void {
        setChecked(event.target.checked);
    }

    /**
     * When the checkbox changes
     */
    useEffect(() => {
        async function updateVersionList(caughtObject: VersionedObjectResult): Promise<void> {
            if (caughtObject.idHash === idHash) {
                const res = await getAllVersionMapEntries(idHash);
                setVersions(res.reverse());
            }
        }

        onVersionedObj.addListener(updateVersionList);

        return () => {
            if (checked) {
                onVersionedObj.removeListener(updateVersionList);
            }
        };
    }, [forLiveVersionUpdatesInList, checked, idHash]);

    /**
     * When the checkbox changes
     */
    useEffect(() => {
        async function updateObject(caughtObject: VersionedObjectResult): Promise<void> {
            if (caughtObject.idHash === idHash) {
                const myJson = await getResolvedIdObject(idHash);
                const res = await getAllVersionMapEntries(idHash);
                setData(myJson);
                setVersions(res.reverse());
            }
        }

        if (checked) {
            onVersionedObj.addListener(updateObject);
        }

        return () => {
            if (checked) {
                onVersionedObj.removeListener(updateObject);
            }
        };
    }, [checked, idHash]);

    /**
     * When the selected version changes
     */
    useEffect(() => {
        if (checked) {
            return;
        }

        async function getDataByHash(): Promise<AnyObjectCreation> {
            return await getResolvedObject(version as SHA256Hash);
        }

        getDataByHash()
            .then((myJson: AnyObjectCreation) => {
                setData(myJson);
            })
            .catch((ignored: string) => {
                // props.setError(new Error(e));
            });
    }, [checked, version]);

    /**
     * When the idHash in the URL changes
     */
    useEffect(() => {
        async function getDataByIdHash(): Promise<AnyObjectCreation> {
            return await getResolvedIdObject(idHash);
        }

        getDataByIdHash()
            .then((myJson: AnyObjectCreation) => {
                getAllVersionMapEntries(idHash)
                    .then((res: VersionMapEntry[]) => {
                        setVersions(res.reverse());
                        setData(myJson);
                    })
                    .catch((e: string) => {
                        setError(new Error(e));
                    });
            })
            .catch((e: string) => {
                setError(new Error(e));
            });
    }, [idHash, setError]);

    return (
        <Paper style={{margin: 20, padding: 20}} elevation={3}>
            <FormGroup row style={{marginBottom: 20, marginTop: 20}}>
                <FormControl className="version-select" disabled={checked}>
                    <InputLabel className="one-browser-input">
                        {i18n.t('common:oneBrowser.versionSelect')}
                    </InputLabel>
                    <Select value={version} onChange={handleSelect}>
                        {versions.map((versionDate: VersionMapEntry) => (
                            <MenuItem key={versionDate.hash} value={versionDate.hash}>
                                {new Date(versionDate.timestamp).toLocaleString()}
                            </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText>{i18n.t('common:oneBrowser.versionSelect')}</FormHelperText>
                </FormControl>
                <FormControlLabel
                    style={{marginLeft: 20}}
                    control={
                        <Checkbox
                            checked={checked}
                            onChange={handleChangeCheckBox}
                            name="checked"
                        />
                    }
                    label={i18n.t('common:oneBrowser.autoUpdate')}
                />
            </FormGroup>
            <div className="json-view">
                <ReactJson
                    src={data === undefined ? {} : data}
                    theme="bright:inverted"
                    displayDataTypes={true}
                    displayObjectSize={true}
                />
            </div>
        </Paper>
    );
}

/**
 * This component builds the details of the object
 * @returns {ReactElement} - Object's details
 */
export function OneBrowserDetailsScreen(): ReactElement {
    const {objectId}: {objectId: SHA256IdHash} = useParams();
    const [error, setError] = useState<Error>();

    return (
        <div>
            <MenuButton />
            {error?.message ? <div>Error happened: {error.toString()}</div> : <div />}
            <ObjectDetailsComponent idHash={objectId} setError={setError} />
        </div>
    );
}
