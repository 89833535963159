import type {FormEvent, ReactElement} from 'react';
import './FileViewer.css';
import ViewPDFComponent from './ViewPDFComponent';
import ViewPictureComponent from './ViewPictureComponent';
import type DocumentModel from 'one.models/lib/models/DocumentModel';
import {useHistory} from 'react-router-dom';
import heic2any from 'heic2any';
import windowAPI from '../modelHelper/WebViewHelper';
import {NOTIFICATION, useNotificationContext} from '../notification/SnackbarNotification';
import Button from '@material-ui/core/Button';
import {useState} from 'react';

export const ACCEPTED_FILE_TYPES = {
    Pdf: 'pdf',
    Pic: 'picture'
} as const;

/** The type definition based on the ACCEPTED_FILE_TYPES value. **/
export type AcceptedFile = typeof ACCEPTED_FILE_TYPES[keyof typeof ACCEPTED_FILE_TYPES];

/**
 * Defining the supported styles depending on where the component is displayed.
 * NOTE: If you want to integrate the component in others component you can use
 * on of the styles defined here if the style fit with your component,
 * or you can define a new style by adding it here and also the CSS rules in the FileViewer.css file.
 */
export const IMPORT_FILE_CLASSNAME = {
    DashboardActiveButton: 'active-task-button',
    DashboardInactiveButton: 'optional-task-button',
    DashboardHideButton: 'hide',
    DashboardMissingButton: 'missing-task-button',
    MenuButton: 'menu-button-file-import'
} as const;

/** The type definition based on the IMPORT_FILE_CLASSNAME value. **/
export type AcceptedClassNames = typeof IMPORT_FILE_CLASSNAME[keyof typeof IMPORT_FILE_CLASSNAME];

/**
 * @param props
 * @param props.type
 * @param props.documentModel
 * @param props.className
 * @param props.text
 */
export default function ImportFile(props: {
    type: AcceptedFile;
    documentModel: DocumentModel;
    className?: AcceptedClassNames;
    text?: string;
}): ReactElement {
    document.body.onfocus = closeDialogIfNoFilesWereSelected;

    const FILE_FORMATS: {[key: string]: string[]} = {
        pdf: ['pdf'],
        picture: ['png', 'jpg', 'jpeg', 'heic', 'webp', 'gif']
    };

    const {setNotificationMessage, setNotificationType} = useNotificationContext();
    const history = useHistory();

    /** Component related **/
    const [documentArrayBuffer, setDocumentArrayBuffer] = useState<ArrayBuffer | undefined>(
        undefined
    );
    const [documentName, setDocumentName] = useState('');
    const [documentURL, setDocumentURL] = useState('');

    /**
     * Triggers the close dialog hook after 0.5 seconds. Similar to the import mechanism, but in this c
     * ase it has different behaviour if the user does not select any files or clicks the close button.
     */
    function closeDialogIfNoFilesWereSelected(): void {
        setTimeout(() => {
            /** Clear the handler **/
            document.body.onfocus = null;
        }, 500);
    }

    /**
     * This function is called when the user upload a file.
     * If the file is a pdf document, is saved in ONE, otherwise an error is displayed.
     *
     * @param event - the file that was uploaded by the user.
     */
    function importFile(event: FormEvent<EventTarget>): void {
        let uploadedFile: File | undefined;

        if (event.target instanceof HTMLInputElement) {
            if (event.target.files !== null) {
                uploadedFile = event.target.files[0];
            }
        }

        if (!uploadedFile) {
            return;
        }

        const uploadedFileInfo = getFileNameAndExtension(uploadedFile.name);
        const reader = new FileReader();

        reader.onload = async function (e) {
            if (e.target !== null && uploadedFile !== undefined) {
                if (checkUploadedFileFormat(uploadedFileInfo.extension)) {
                    /** Handle special case when the desired file is in Heic format **/
                    if (uploadedFileInfo.extension.toLowerCase() === 'heic') {
                        /** if it's a heic upload from mobile **/
                        if (windowAPI === undefined) {
                            try {
                                const imageAsPng: Blob = await convertHEICToPNG(
                                    e.target.result as ArrayBuffer
                                );
                                const imageAsArrayBuffer: ArrayBuffer =
                                    await blobToArrayBufferWithReader(imageAsPng);
                                setDocumentArrayBuffer(imageAsArrayBuffer);
                                setDocumentName(uploadedFile.name);
                                setDocumentURL(window.URL.createObjectURL(imageAsPng));
                            } catch (_) {
                                /** catch any errors regarding conversions **/
                                setNotificationType(NOTIFICATION.Error);
                                setNotificationMessage('errors:importDocument.saveFileError');
                            }
                        } else {
                            /** catch any errors regarding conversions **/
                            setNotificationType(NOTIFICATION.Error);
                            setNotificationMessage(
                                'errors:importDocument.formatNotAcceptedpictureMobile'
                            );
                        }
                    } else {
                        /** Else if the file is not HEIC format, treat it normally **/
                        setDocumentArrayBuffer(e.target.result as ArrayBuffer);
                        setDocumentName(uploadedFile.name);
                        setDocumentURL(window.URL.createObjectURL(uploadedFile));
                    }
                } else {
                    setNotificationType(NOTIFICATION.Error);
                    setNotificationMessage(
                        `errors:importDocument.formatNotAccepted${props.type}${
                            windowAPI === undefined ? 'Desktop' : 'Mobile'
                        }`
                    );
                }
            }
        };

        reader.readAsArrayBuffer(uploadedFile);
    }

    /**
     * The regex will extract the name and the extension. E.g file.from.my.computer.txt -> {fileName: 'file.from.my.computer', extension: 'txt'}
     * @param fullFileName
     */
    function getFileNameAndExtension(fullFileName: string): {fileName: string; extension: string} {
        const arr = fullFileName.split(/\.(?=[^.]+$)/);
        return {fileName: arr[0], extension: arr[1]};
    }

    /**
     * HEIC format is used by Apple (importing photo from the native)
     * Converts HEIC to PNG and returns it as BLOB
     * @param dataArrayBuffer
     */
    async function convertHEICToPNG(dataArrayBuffer: ArrayBuffer): Promise<Blob> {
        const blobData = new Blob([dataArrayBuffer]);
        return new Promise((resolve, reject) => {
            heic2any({
                blob: blobData,
                quality: 0.1,
                toType: 'png'
            })
                .then((res: Blob | Blob[]) => {
                    resolve(res as Blob);
                })
                .catch((err: Error) => reject(err));
        });
    }

    /**
     * Converts blob to Array Buffer using FileReader
     * @param blob
     */
    async function blobToArrayBufferWithReader(blob: Blob): Promise<ArrayBuffer> {
        const fileReader = new FileReader();
        const resultPromise: Promise<ArrayBuffer> = new Promise((resolve, rejected) => {
            fileReader.onload = function (e: ProgressEvent<FileReader>) {
                if (e.target) {
                    resolve(e.target.result as ArrayBuffer);
                }
                rejected(new Error('Error converting blob to array buffer'));
            };
        });

        fileReader.readAsArrayBuffer(blob);
        return await resultPromise;
    }

    /** Check the accepted formats for each type
     * @param format
     */
    function checkUploadedFileFormat(format: string): boolean {
        if (props.type === ACCEPTED_FILE_TYPES.Pdf) {
            return FILE_FORMATS.pdf.includes(format.toLowerCase());
        }

        if (props.type === ACCEPTED_FILE_TYPES.Pic) {
            return FILE_FORMATS.picture.includes(format.toLowerCase());
        }

        return false;
    }

    /**
     * Get the input accepted file types.
     */
    function getInputAcceptedTypes(): string {
        let accept = '';
        FILE_FORMATS[props.type].forEach(type => {
            accept += '.' + type + ',';
        });
        accept = accept.slice(0, -1);
        return accept;
    }

    /**
     *
     */
    function renderFileViewer(): ReactElement {
        switch (props.type) {
            case ACCEPTED_FILE_TYPES.Pdf: {
                return (
                    <ViewPDFComponent
                        pdfURL={documentURL}
                        onLoadSuccess={saveUploadedFile}
                        onLoadError={uploadFailed}
                    />
                );
            }
            case ACCEPTED_FILE_TYPES.Pic: {
                return (
                    <ViewPictureComponent
                        pictureURL={documentURL}
                        onLoadSuccess={saveUploadedFile}
                        onLoadError={uploadFailed}
                    />
                );
            }
            default: {
                return <></>;
            }
        }
    }

    /**
     * Returns the mimeType based on the type given in the URL
     */
    function getMimeTypeBasedOnType(): string {
        if (props.type === ACCEPTED_FILE_TYPES.Pdf) {
            return 'application/pdf';
        }

        if (props.type === ACCEPTED_FILE_TYPES.Pic) {
            return 'image/jpeg';
        }

        throw new Error('This should not happen, type is not implemented or got undefined');
    }

    /**
     * Saving the file in to the ONE instance if the file is loaded successfully.
     *
     * @returns {Promise<void>}
     */
    function saveUploadedFile(): void {
        if (documentArrayBuffer) {
            props.documentModel
                .addDocument(documentArrayBuffer, getMimeTypeBasedOnType(), documentName)
                .then(_ => {
                    document.body.onfocus = null;
                    history.push('/journal');
                })
                .catch(_ => {
                    /** catch any errors regarding conversions **/
                    setNotificationType(NOTIFICATION.Error);
                    setNotificationMessage('errors:importDocument.saveFileError');
                });
        }
    }

    /**
     * This function set the error state to true if the uploaded file is not a pdf file.
     *
     * @param {string} errorName - the error name.
     */
    function uploadFailed(errorName: string): void {
        if (errorName === 'InvalidPDFException' || errorName === 'CorruptedImageFormat') {
            setNotificationType(NOTIFICATION.Error);
            setNotificationMessage('errors:importDocument.corruptedFile');
        }
    }

    return (
        <>
            <Button
                variant="contained"
                component="label"
                className={props.className}
                onChange={(e: FormEvent<EventTarget>) => {
                    importFile(e);
                }}
            >
                {props.text}
                <input type="file" hidden accept={getInputAcceptedTypes()} />
            </Button>
            <div className="hide">{renderFileViewer()}</div>
        </>
    );
}
