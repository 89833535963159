import type {Questionnaire} from 'one.models/lib/models/QuestionnaireModel';

export const QuestionnaireEQ5D3L: Questionnaire = {
    resourceType: 'Questionnaire',
    language: 'de',
    url: 'http://uk-erlangen.de/smiler/questionnaire/EQ5D3L_de',
    name: 'EQ5D3L',
    status: 'active',
    item: [
        {
            linkId: 'mobility',
            prefix: 'EQ5D3L1',
            text: 'Beweglichkeit / Mobilität HEUTE',
            type: 'choice',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/smiler/valueCoding/mobility',
                        version: '1.0',
                        code: '1',
                        display: 'Ich habe keine Probleme herumzugehen'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/smiler/valueCoding/mobility',
                        version: '1.0',
                        code: '2',
                        display: 'Ich habe einige Probleme herumzugehen'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/smiler/valueCoding/mobility',
                        version: '1.0',
                        code: '3',
                        display: 'Ich bin ans Bett gebunden'
                    }
                }
            ]
        },
        {
            linkId: 'selfcare',
            prefix: 'EQ5D3L2',
            text: 'Für sich selbst sorgen - HEUTE',
            type: 'choice',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/smiler/valueCoding/selfcare',
                        version: '1.0',
                        code: '1',
                        display: 'Ich habe keine Probleme, für mich selbst zu sorgen'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/smiler/valueCoding/selfcare',
                        version: '1.0',
                        code: '2',
                        display:
                            'Ich habe einige Probleme, mich selbst zu waschen oder mich anzuziehen'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/smiler/valueCoding/selfcare',
                        version: '1.0',
                        code: '3',
                        display: 'Ich bin nicht in der Lage, mich selbst zu waschen oder anzuziehen'
                    }
                }
            ]
        },
        {
            linkId: 'usualactivity',
            prefix: 'EQ5D3L3',
            text: 'Alltägliche Tätigkeiten HEUTE',
            type: 'choice',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/smiler/valueCoding/usualactivity',
                        version: '1.0',
                        code: '1',
                        display:
                            'Ich habe keine Probleme, meinen alltäglichen Tätigkeiten nachzugehen'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/smiler/valueCoding/usualactivity',
                        version: '1.0',
                        code: '2',
                        display:
                            'Ich habe einige Probleme, meinen alltäglichen Tätigkeiten nachzugehen'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/smiler/valueCoding/usualactivity',
                        version: '1.0',
                        code: '3',
                        display:
                            'Ich bin nicht in der Lage, meinen alltäglichen Tätigkeiten nachzugehen'
                    }
                }
            ]
        },
        {
            linkId: 'paindiscomf',
            prefix: 'EQ5D3L4',
            text: 'Schmerzen / körperliche Beschwerden HEUTE',
            type: 'choice',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/smiler/valueCoding/paindiscomf',
                        version: '1.0',
                        code: '1',
                        display: 'Ich habe keine Schmerzen oder Beschwerden'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/smiler/valueCoding/paindiscomf',
                        version: '1.0',
                        code: '2',
                        display: 'Ich habe mäßige Schmerzen oder Beschwerden'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/smiler/valueCoding/paindiscomf',
                        version: '1.0',
                        code: '3',
                        display: ' Ich habe extreme Schmerzen oder Beschwerden'
                    }
                }
            ]
        },
        {
            linkId: 'anxietydepression',
            prefix: 'EQ5D3L5',
            text: 'Angst / Niedergeschlagenheit HEUTE',
            type: 'choice',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/smiler/valueCoding/anxietydepression',
                        version: '1.0',
                        code: '1',
                        display: 'Ich bin nicht ängstlich oder deprimiert'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/smiler/valueCoding/anxietydepression',
                        version: '1.0',
                        code: '2',
                        display: 'Ich bin mäßig ängstlich oder deprimiert'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/smiler/valueCoding/anxietydepression',
                        version: '1.0',
                        code: '3',
                        display: 'Ich bin extrem ängstlich oder deprimiert'
                    }
                }
            ]
        },
        {
            linkId: 'healthscore',
            prefix: 'EQ5D3L6',
            text: 'IHRE GESUNDHEIT HEUTE',
            type: 'integer',
            answerRestriction: {
                minValue: {
                    valueInteger: '0'
                },
                maxValue: {
                    valueInteger: '100'
                }
            }
        }
    ]
};
