import type {ReactElement} from 'react';
import * as dateFns from 'date-fns';
import {CircularProgress} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import i18n from '../../i18n';
import type NewsModel from 'one.models/lib/models/NewsModel';
import ReactMarkdown from 'react-markdown';
import {useNewsFromAChannelId} from '../news/NewsReader';
import MenuButton from '../menu/MenuButton';
import {hideCircularProgress} from '../utils/Utils';
import {useEffect} from 'react';

/**
 * @param props
 * @param props.newsModel
 * @returns
 */
export default function FeedbackReader(props: {newsModel: NewsModel}): ReactElement {
    const feedbacks = useNewsFromAChannelId(props.newsModel, 'feedbackChannel').reverse();

    useEffect(() => {
        hideCircularProgress();
    }, [feedbacks]);

    return (
        <>
            <div className="circular-progress-container">
                <CircularProgress className="circular-progress" size={35} />
            </div>
            <div className="page-container hide">
                <div className="menu-button-header">
                    <MenuButton />
                    <h2 className="headline"> {i18n.t('common:menu.feedbackList')}</h2>
                </div>
                {feedbacks.length === 0 ? (
                    <></>
                ) : (
                    <>
                        <>
                            {feedbacks.map((post, index) => {
                                return (
                                    <Paper
                                        key={index}
                                        square
                                        elevation={3}
                                        className="page-content-box margin-box"
                                    >
                                        <ReactMarkdown className="paper-font-size">
                                            {post.data.content}
                                        </ReactMarkdown>
                                        <div className="date-style paper-font-size">
                                            {dateFns.format(
                                                post.creationTime,
                                                'MMMM dd yyyy HH:mm'
                                            )}
                                        </div>
                                    </Paper>
                                );
                            })}
                        </>
                    </>
                )}
            </div>
        </>
    );
}
