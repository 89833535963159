import type {ReactElement} from 'react';
import './Questionnaire.css';
import i18n from '../../i18n';

/**
 * @returns {ReactElement} - eleventh page of the EQ-5D-3L.
 */
export default function FinalizePage(): ReactElement {
    return (
        <div>
            <div>
                <div className="finish-message">{i18n.t('eq5:finalizePage.finish')}</div>
                <p className="text-survey-welcome">{i18n.t('eq5:finalizePage.observation2')}</p>
                <p className="text-survey-welcome">{i18n.t('eq5:finalizePage.observation3')}</p>
            </div>
        </div>
    );
}
