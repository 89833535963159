import type QuestionnaireModel from 'one.models/lib/models/QuestionnaireModel';
import type {Questionnaire} from 'one.models/lib/models/QuestionnaireModel';
import type {QuestionnaireResponse} from 'one.models/lib/recipes/QuestionnaireRecipes/QuestionnaireResponseRecipes';
import {useEffect, useState} from 'react';
import QuestionnaireResponseBuilder from '../questionnaire/QuestionnaireResponseBuilder';

/**
 * Load the questionnaire and the response objects.
 *
 * This function has the following modes:
 * 1) Load an old response by response hash -> used for view mode.
 * The EQ5D3L questionnaire is loaded from the questionnaire url that is stored in the
 * response.
 * -> responseId must be set to the hash of the response object to load
 * => questionnaireNames will be ignored
 * => language will be ignored
 * 2) Load questionnaires based on parameter.
 * -> questionnaireName must be the EQ5D3L questionnaire.
 * -> (optional) language must be the selected language
 * -> responseId must be undefined - otherwise it will be mode 1)
 * @param questionnaireModel
 * @param questionnaireName
 * @param language
 * @param responseId
 */
async function loadEQ5D3LQuestionnaireAndResponse(
    questionnaireModel: QuestionnaireModel,
    questionnaireName?: string,
    language?: string,
    responseId?: string
): Promise<[Map<string, Questionnaire>, Map<string, QuestionnaireResponse>]> {
    let response: QuestionnaireResponse | undefined;
    let questionnaireUrl: string = '';

    if (responseId) {
        // load the response
        const questionnaireResponse = (await questionnaireModel.responsesById(responseId)).data
            .response[0];

        if (questionnaireResponse.questionnaire) {
            questionnaireUrl = questionnaireResponse.questionnaire;
            response = questionnaireResponse;
        }
    } else if (questionnaireName) {
        // Load the urls from the questionnaire model by name and language
        questionnaireUrl = await questionnaireModel.questionnaireUrlByName(
            questionnaireName,
            language
        );
    }

    // Load the questionnaires
    const questionnaire = await questionnaireModel.questionnaireByUrl(questionnaireUrl);

    // Transform everything to maps
    const questionnaireMap = new Map<string, Questionnaire>();
    const responseMap = new Map<string, QuestionnaireResponse>();

    // Set the questionnaire in the questionnaire map
    if (questionnaire && questionnaire.name) {
        questionnaireMap.set(questionnaire.name, questionnaire);

        if (response) {
            responseMap.set(questionnaire.name, response);
        }
    }

    return [questionnaireMap, responseMap];
}

/**
 * This function loads the EQ5D3L questionnaire based on either the passed questionnaire names and language, or
 * based on the response.
 * @param questionnaireModel
 * @param setError
 * @param questionnaireName
 * @param language
 * @param responseId
 */
export function useEQ5D3LQuestionnaireAndResponse(
    questionnaireModel: QuestionnaireModel,
    setError: (error: string) => void,
    questionnaireName?: string,
    language?: string,
    responseId?: string
): [Map<string, Questionnaire>, Map<string, QuestionnaireResponse>] {
    const [ret, setRet] = useState<
        [Map<string, Questionnaire>, Map<string, QuestionnaireResponse>]
    >([new Map<string, Questionnaire>(), new Map<string, QuestionnaireResponse>()]);

    useEffect(() => {
        function fetchEQ5D3L(): void {
            loadEQ5D3LQuestionnaireAndResponse(
                questionnaireModel,
                questionnaireName,
                language,
                responseId
            )
                .then(qAndRMaps => setRet(qAndRMaps))
                .catch(err => setError(err));
        }

        fetchEQ5D3L();
    }, [questionnaireModel, questionnaireName, language, setError, responseId]);

    return ret;
}

/**
 * Build the QuestionnaireResponseBuilder for the EQ5D3L questionnaire.
 *
 * @param questionnaireResponse
 * @param updatedCallback
 * @param questionnaire
 */
export function useQuestionnairesBuilder(
    questionnaireResponse: Map<string, QuestionnaireResponse>,
    updatedCallback: () => void,
    questionnaire?: Questionnaire
): Map<string, QuestionnaireResponseBuilder> {
    const [builder, setBuilder] = useState(new Map<string, QuestionnaireResponseBuilder>());

    useEffect(() => {
        // Build the questionnaire response builder
        const map = new Map<string, QuestionnaireResponseBuilder>();

        if (questionnaire && questionnaire.name) {
            map.set(
                questionnaire.name,
                new QuestionnaireResponseBuilder(
                    questionnaire,
                    questionnaireResponse.get(questionnaire.name)
                )
            );
        }
        setBuilder(map);

        const disconnectHandlers: (() => void)[] = [];

        // Register the events
        for (const value of map.values()) {
            disconnectHandlers.push(value.onUpdated(updatedCallback));
        }

        // Deregister event handlers
        return () => {
            for (const disconnect of disconnectHandlers) {
                disconnect();
            }
        };
    }, [questionnaireResponse, questionnaire, updatedCallback]);

    return builder;
}
