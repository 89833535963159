import type {ReactElement} from 'react';
import type {TooltipProps} from 'recharts';
import * as dateFns from 'date-fns';
import type {GraphData} from '../../model/WBCDataParser';

/**
 * This is a custom Tooltip for the wbc configurable graph.
 * @param {TooltipProps} props - properties of the view.
 * @returns {ReactElement} the information window for each point from the graph.
 */
export default function GenericGraphTooltip(props: TooltipProps): ReactElement {
    if (props && props.payload && props.payload[0]) {
        // the props.payload[0].payload is any by default but the graph that uses this tooltip
        // passes objects of type GraphData to the tooltip, so that's why we have the typecast
        // to the GraphData, because it will always be a GraphData object.
        const graphData = props.payload[0].payload as GraphData;

        return (
            <div className="tooltip">
                {graphData.value}{' '}
                {props.labelFormatter !== undefined &&
                    props.labelFormatter(props.label ? props.label : '')}
                <br />
                {dateFns.format(graphData.timestamp, 'dd/MM/yyyy')}
            </div>
        );
    }

    return <></>;
}
