import type {ImpactVisitProperties, ImpactVisitTask} from '../../../model/studies/ImpactWorkflow';
import TimelineItem from '@material-ui/lab/TimelineItem/TimelineItem';
import TimelineContent from '@material-ui/lab/TimelineContent/TimelineContent';
import i18n from '../../../i18n';
import Timeline from '@material-ui/lab/Timeline/Timeline';
import {useState} from 'react';
import type {ReactElement} from 'react';
import {TimelineOppositeContent} from '@material-ui/lab';
import * as dateFns from 'date-fns';
import type AudioExerciseModel from 'one.models/lib/models/AudioExerciseModel';
import Dashboard, {TASK_TYPES} from '../studyCommon/Dashboard';
import EntryView, {EntryViewContext} from '../../journal/EntryView';
import {buildTaskDoneHistory} from '../studyCommon/DashboardCommon';
import {IMPACT_VISIT} from '../../../model/studies/StudyHelper';
import type {ImpactStudyVisit, TaskCategories} from '../../../model/studies/StudyHelper';
import {getAudioExerciseName} from '../studyCommon/CommonFunctionalities';
import {PRODUCT_TYPE} from '../../Ui';
import type {Product} from '../../Ui';
import StudyTimelineContent, {VISIT_STATE} from '../studyCommon/StudyTimelineContent';
import {IMPACT_TASK} from '../../../model/studies/ImpactWorkflow';

/** The participant visits for Impact study. **/
export const IMPACT_PHASES = [
    IMPACT_VISIT.Day_0,
    IMPACT_VISIT.Day_7,
    IMPACT_VISIT.Day_14,
    IMPACT_VISIT.Day_21
] as const;

/**
 * Represents the impact study timeline.
 * @param props - props of the view.
 * @param props.currentDayOfTheStudy - current study day.
 * @param props.currentVisit - active visit or undefined if the user it's between the visits.
 * @param props.visitsProperties - visit properties for each visit.
 * @param props.audioExerciseModel - the audio exercise model used to store an audio exercise object when the user start to listen the audio file.
 * @param props.tasks - the tasks categories.
 * @param props.finishedTasks - finished tasks for the active visit.
 * @param props.productType - the product type because we need to display different things
 * depending on it.
 */
export default function ImpactTimeline(props: {
    currentDayOfTheStudy: number;
    currentVisit: ImpactStudyVisit | undefined;
    visitsProperties: ImpactVisitProperties;
    audioExerciseModel: AudioExerciseModel;
    tasks: TaskCategories<ImpactVisitTask>;
    finishedTasks: ImpactVisitTask[];
    productType: Product;
}): ReactElement {
    const [audioPlayer, setAudioPlayer] = useState<ReactElement>(<></>);
    const [isAudioPlayerOpen, setAudioPlayerOpen] = useState(false);
    const [title, setTitle] = useState('');

    return (
        <Timeline className="overview-timeline" align="left">
            <EntryViewContext.Provider
                value={{
                    closeEntryViewCallback: setAudioPlayerOpen,
                    setContent: setAudioPlayer,
                    setTitle: setTitle
                }}
            >
                {IMPACT_PHASES.map((phaseIdentifier: ImpactStudyVisit, index: number) => (
                    <div
                        key={index}
                        className={`${
                            props.finishedTasks.length !== 0 ||
                            props.tasks.missingTasks.size !== 0 ||
                            props.currentVisit !== phaseIdentifier ||
                            props.productType === PRODUCT_TYPE.Smiler_impact
                                ? 'visit'
                                : ''
                        }`}
                    >
                        {props.productType !== PRODUCT_TYPE.Smiler_impact &&
                            props.currentVisit === phaseIdentifier && (
                                <>
                                    <Dashboard
                                        tasks={[...props.tasks.activeTasks]}
                                        taskTypes={TASK_TYPES.active}
                                        visitDate={
                                            props.visitsProperties[props.currentVisit].visitDate
                                        }
                                        currentVisit={props.currentVisit}
                                        audioExerciseModel={props.audioExerciseModel}
                                    />
                                    <Dashboard
                                        tasks={[...props.tasks.missingTasks]}
                                        taskTypes={TASK_TYPES.missing}
                                        currentVisit={props.currentVisit}
                                        audioExerciseModel={props.audioExerciseModel}
                                    />
                                </>
                            )}
                        <TimelineItem key={index} className="timeline-item">
                            {(props.visitsProperties[phaseIdentifier].tasksCompleted ===
                                props.visitsProperties[phaseIdentifier].totalTasks ||
                                !dateFns.isSameDay(
                                    props.visitsProperties[phaseIdentifier].visitDate,
                                    new Date()
                                ) ||
                                props.productType === PRODUCT_TYPE.Smiler_impact) && (
                                <TimelineOppositeContent className="visit-date">
                                    {dateFns.format(
                                        props.visitsProperties[phaseIdentifier].visitDate,
                                        'dd/MM/yyyy'
                                    )}
                                </TimelineOppositeContent>
                            )}
                            {props.productType === PRODUCT_TYPE.Smiler_impact ? (
                                <StudyTimelineContent
                                    finishedTasks={props.finishedTasks}
                                    studyTasks={[IMPACT_TASK.AudioExercise]}
                                    visitState={
                                        // subtract one because the phaseIdentifier is a value
                                        // between [0;28] and the currentStudyDay is a value between
                                        // [1;29]
                                        phaseIdentifier === props.currentDayOfTheStudy - 1
                                            ? VISIT_STATE.active
                                            : phaseIdentifier < props.currentDayOfTheStudy
                                            ? VISIT_STATE.passed
                                            : VISIT_STATE.next
                                    }
                                    visitIdentifier={phaseIdentifier}
                                />
                            ) : (
                                <TimelineContent
                                    className={`timeline-content ${
                                        phaseIdentifier < props.currentDayOfTheStudy ? 'blur' : ''
                                    }`}
                                >
                                    {props.finishedTasks.length >= 0 &&
                                    dateFns.isSameDay(
                                        props.visitsProperties[phaseIdentifier].visitDate,
                                        new Date()
                                    ) ? (
                                        <>
                                            {buildTaskDoneHistory([...props.finishedTasks])}
                                            {props.tasks.activeTasks.size === 0 &&
                                                ': ' + getAudioExerciseName(phaseIdentifier)}
                                        </>
                                    ) : (
                                        <>
                                            {i18n.t('studies:stepAction.audio')}
                                            {': ' + getAudioExerciseName(phaseIdentifier)}
                                        </>
                                    )}
                                </TimelineContent>
                            )}
                        </TimelineItem>
                    </div>
                ))}
            </EntryViewContext.Provider>
            <EntryView
                isOpen={isAudioPlayerOpen}
                closeEntryViewCallback={setAudioPlayerOpen}
                content={audioPlayer}
                title={title}
            />
        </Timeline>
    );
}
