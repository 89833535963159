import {useEffect, useState} from 'react';
import type {ReactElement} from 'react';
import type DocumentModel from 'one.models/lib/models/DocumentModel';
import type {DocumentInfo} from 'one.models/lib/models/DocumentModel';
import {hideCircularProgress} from '../utils/Utils';
import type {ObjectData} from 'one.models/lib/models/ChannelManager';
import './FileViewer.css';

/**
 * Used to get the image from the one instance and create teh url of it.
 * @param documentId - the picture id.
 * @param documentModel - the document model.
 * @param setErrorMessageCallback - callback for setting the error message if and error it's thrown.
 */
function useImage(
    documentId: string,
    documentModel: DocumentModel,
    setErrorMessageCallback: (value: string) => void
): string {
    const [imageURL, setImageURL] = useState('');

    useEffect(() => {
        function fetchImage(): void {
            documentModel
                .getDocumentById(documentId)
                .then(async (value: ObjectData<DocumentInfo>) => {
                    const arrayBuffer: ArrayBuffer = await documentModel.blobHashToArrayBuffer(
                        value.data
                    );
                    const array = new Uint8Array(arrayBuffer);
                    const blob = new Blob([array]);
                    setImageURL(window.URL.createObjectURL(blob));
                    hideCircularProgress();
                })
                .catch(error => {
                    setErrorMessageCallback(error);
                });
        }

        fetchImage();
    }, [documentModel, documentId, setErrorMessageCallback]);

    return imageURL;
}

/**
 * The image view used to display the image within the entry view popup.
 * @param props - properties of the view.
 * @param props.documentModel - the document model.
 * @param props.imageId - the image id.
 */
export default function ImageView(props: {
    documentModel: DocumentModel;
    imageId: string;
}): ReactElement {
    const [error, setError] = useState('');

    const imageUrl = useImage(props.imageId, props.documentModel, setError);

    return <>{error ? error : <img className="image-view" src={imageUrl} alt="" />}</>;
}
