import {useState} from 'react';
import type {ReactElement} from 'react';
import './News.css';
import {Button, CircularProgress, TextareaAutosize} from '@material-ui/core';
import NewsPreview from './NewsPreview';
import ReactMarkdown from 'react-markdown';
import type NewsModel from 'one.models/lib/models/NewsModel';
import i18n from '../../i18n';
import {Alert, AlertTitle} from '@material-ui/lab';
import '../../Primary.css';
import InfoMessage, {MESSAGE_TYPE} from '../errors/InfoMessage';
import MenuButton from '../menu/MenuButton';
import Paper from '@material-ui/core/Paper/Paper';
import {displayCircularProgress, hideCircularProgress} from '../utils/Utils';

/**
 * This component build and return the news creator view.
 * @param props - Properties of this view.
 * @param props.newsModel
 * @returns - news creator view.
 */
export default function NewsCreator(props: {newsModel: NewsModel}): ReactElement {
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [preview, setPreview] = useState(false);
    const markdownReference = i18n.t('news:viewMarkdown');
    const [successState, setSuccessState] = useState('');
    const [errorState, setErrorState] = useState('');

    /**
     * This function is called when the user want to save the news that was just created.
     * ** Currently is used addNewsFromAlice method provided by the model to simulate a single broadcast channel **
     */
    function saveNews(): void {
        if (title === '' || content === '') {
            setErrorState('errors:news:emptyField');
            return;
        }
        displayCircularProgress();
        props.newsModel
            .addNews(`${title}<br/>${content}`)
            .then(() => {
                hideCircularProgress();
                setSuccessState('The news was successfully posted.');
            })
            .catch(err => {
                hideCircularProgress();
                setErrorState(err);
            });
    }

    return (
        <>
            <div className="circular-progress-container hide">
                <CircularProgress className="circular-progress" size={35} />
            </div>
            <form className="page-container">
                <div>
                    <InfoMessage
                        errorMessage={errorState}
                        setDisplayMessage={setErrorState}
                        messageType={MESSAGE_TYPE.Error}
                    />
                    {successState && (
                        <Paper
                            square
                            elevation={3}
                            className="stick-message-top message-font-size success-color"
                        >
                            <Alert
                                severity="success"
                                className="news-success-alert"
                                onClose={() => {
                                    setSuccessState('');
                                }}
                            >
                                <AlertTitle className="message-title">
                                    {i18n.t('common:backup.successTitle')}
                                </AlertTitle>
                                {successState}
                            </Alert>
                        </Paper>
                    )}
                    <div className="menu-button-header">
                        <MenuButton />
                        <h2 className="headline"> {i18n.t('news:title')}</h2>
                    </div>
                    <TextareaAutosize
                        rowsMin={1}
                        className="title-of-news"
                        placeholder={i18n.t('news:title')}
                        onChange={event => setTitle('<b>' + event.target.value + '</b><br/>')}
                    />
                    <h2 className="headline"> {i18n.t('news:content')}</h2>
                    <TextareaAutosize
                        rowsMin={10}
                        cols={60}
                        className="content"
                        onChange={event => setContent(event.target.value)}
                    />
                    <ReactMarkdown className="markdown">{markdownReference}</ReactMarkdown>
                    <div className="buttons-container">
                        <Button
                            className="button"
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                saveNews();
                            }}
                        >
                            {i18n.t('common:buttons.submit')}
                        </Button>
                        <Button
                            className="button button-margin-left"
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                setPreview(!preview);
                            }}
                        >
                            {i18n.t('common:buttons.preview')}
                        </Button>
                    </div>
                    <>{preview ? <NewsPreview preview={title + content} /> : null}</>
                </div>
            </form>
        </>
    );
}
