import type SmilerWorkflow from './SmilerWorkflow';
import type {SmilerVisitTask} from './SmilerWorkflow';
import type ImpactWorkflow from './ImpactWorkflow';
import type {ImpactVisitTask} from './ImpactWorkflow';
import {OEvent} from 'one.models/lib/misc/OEvent';
import * as dateFns from 'date-fns';
import {SMILER_VISIT, IMPACT_VISIT} from './StudyHelper';
import type {ImpactStudyVisit, SmilerStudyVisit} from './StudyHelper';

/**
 * Type definition for a visit data for Smiler-Impact study.
 */
export type SmilerImpactVisitData = {
    isAudioExerciseDone?: boolean;
    isWbcAnswered?: boolean;
    isEcgAnswered?: boolean;
    isQuestionnaireAnswered?: boolean;
    isPhotoAnswered?: boolean;
    totalTasks: number;
    tasksCompleted: number;
    visitDate: Date;
};

/**
 * The visits of the Smiler-Impact study.
 */
export type SmilerImpactVisitProperties = {
    [IMPACT_VISIT.Day_0]: SmilerImpactVisitData;
    [IMPACT_VISIT.Day_7]: SmilerImpactVisitData;
    [IMPACT_VISIT.Day_14]: SmilerImpactVisitData;
    [IMPACT_VISIT.Day_21]: SmilerImpactVisitData;
    [SMILER_VISIT.Day_28]: SmilerImpactVisitData;
};

/**
 * The type containing the tasks categories.
 */
export type SmilerImpactTasks = {
    activeTasks: Set<ImpactVisitTask | SmilerVisitTask>;
    missingTasks: Set<ImpactVisitTask | SmilerVisitTask>;
};

/**
 * This model represents a workflow for Smiler-Impact overview.
 */
export default class SmilerImpactWorkflow {
    public onUpdated = new OEvent<() => void>();

    private smilerWorkflow: SmilerWorkflow;
    private impactWorkflow: ImpactWorkflow;
    private visitProperties: SmilerImpactVisitProperties;

    private smilerWorkflowDisconnect: (() => void) | null = null;
    private impactWorkflowDisconnect: (() => void) | null = null;
    private visits: (SmilerStudyVisit | ImpactStudyVisit)[];
    private finishedTasks: (SmilerVisitTask | ImpactVisitTask)[];

    private tasks: SmilerImpactTasks;

    constructor(smilerWorkflow: SmilerWorkflow, impactWorkflow: ImpactWorkflow) {
        this.smilerWorkflow = smilerWorkflow;
        this.impactWorkflow = impactWorkflow;
        this.visitProperties = this.resetVisitProperties();
        this.visits = [
            IMPACT_VISIT.Day_0,
            IMPACT_VISIT.Day_7,
            IMPACT_VISIT.Day_14,
            IMPACT_VISIT.Day_21,
            SMILER_VISIT.Day_28
        ];
        this.finishedTasks = [];

        this.tasks = SmilerImpactWorkflow.initializeTasks();
    }

    /**
     * Initialize the current instance.
     */
    init(): void {
        this.updateSmilerImpactData();
        this.filterOutdatedVisits();

        this.classifyTasks();
        this.calculateFinishedTasks();

        this.smilerWorkflowDisconnect = this.smilerWorkflow.onUpdated(
            this.updateSmilerImpactData.bind(this)
        );
        this.impactWorkflowDisconnect = this.impactWorkflow.onUpdated(
            this.updateSmilerImpactData.bind(this)
        );
    }

    /**
     * Get the current active visit of the Smiler-Impact overview.
     */
    public calculateCurrentVisit(): SmilerStudyVisit | ImpactStudyVisit | undefined {
        const smilerCurrentVisit = this.smilerWorkflow.getActiveVisit();
        const impactCurrentVisit = this.impactWorkflow.getActiveVisit();

        if (
            smilerCurrentVisit === undefined ||
            (impactCurrentVisit !== undefined && impactCurrentVisit < smilerCurrentVisit)
        ) {
            return impactCurrentVisit;
        }

        return smilerCurrentVisit;
    }

    /**
     * Calculate the remaining days until the next visit will be available.
     * Since this is a combination of smiler and impact the lower days value win.
     * If the study has ended -1 will be returned.
     */
    public calculateDaysUntilNextVisit(): number {
        const smilerDaysUntilNextVisit = this.smilerWorkflow.getDaysUntilNextVisit();
        const impactDaysUntilNextVisit = this.impactWorkflow.getDaysUntilNextVisit();

        if (smilerDaysUntilNextVisit === -1 || impactDaysUntilNextVisit === -1) {
            return -1;
        }

        if (smilerDaysUntilNextVisit <= impactDaysUntilNextVisit) {
            return smilerDaysUntilNextVisit;
        }

        return impactDaysUntilNextVisit;
    }

    /**
     * Return the current study day.
     * Because Smiler and Impact studies are synced,
     * then current study day of SMILER is equal with current study day of IMPACT.
     */
    public calculateCurrentDayOfTheStudy(): number {
        return this.smilerWorkflow.getCurrentStudyDay();
    }

    /**
     * Shutdown module
     */
    public shutdown(): void {
        if (this.smilerWorkflowDisconnect) {
            this.smilerWorkflowDisconnect();
        }

        if (this.impactWorkflowDisconnect) {
            this.impactWorkflowDisconnect();
        }
    }

    /**
     * Get the visit properties for the Smiler-Impact overview.
     */
    public getSmilerImpactVisitProperties(): SmilerImpactVisitProperties {
        return this.visitProperties;
    }

    /**
     * Get the study visits array.
     */
    public getVisits(): (SmilerStudyVisit | ImpactStudyVisit)[] {
        return this.visits;
    }

    /**
     * Getting the tasks categories.
     */
    public getTasks(): SmilerImpactTasks {
        return this.tasks;
    }

    public calculateFinishedTasks(): void {
        this.finishedTasks = [
            ...this.smilerWorkflow.getFinishedTasks(),
            ...this.impactWorkflow.getFinishedTasks()
        ];
    }

    public getFinishedTasks(): (SmilerVisitTask | ImpactVisitTask)[] {
        return this.finishedTasks;
    }

    // ############### private functions ###############

    /**
     * Update the visits properties.
     */
    private updateSmilerImpactData(): void {
        const smilerVisitProperties = this.smilerWorkflow.getVisitProperties();
        const impactVisitProperties = this.impactWorkflow.getVisitProperties();

        this.visitProperties = {
            [IMPACT_VISIT.Day_0]: {
                isAudioExerciseDone: impactVisitProperties[IMPACT_VISIT.Day_0].isAudioExerciseDone,
                isWbcAnswered: smilerVisitProperties[SMILER_VISIT.Day_0].isWbcAnswered,
                isEcgAnswered: smilerVisitProperties[SMILER_VISIT.Day_0].isEcgAnswered,
                isPhotoAnswered: smilerVisitProperties[SMILER_VISIT.Day_0].isPhotoAnswered,
                isQuestionnaireAnswered:
                    smilerVisitProperties[SMILER_VISIT.Day_0].isQuestionnaireAnswered,
                totalTasks:
                    this.smilerWorkflow.getVisitTasksNumber() +
                    this.impactWorkflow.getVisitTasksNumber(),
                tasksCompleted:
                    smilerVisitProperties[SMILER_VISIT.Day_0].tasksCompleted +
                    impactVisitProperties[IMPACT_VISIT.Day_0].tasksCompleted,
                visitDate: smilerVisitProperties[SMILER_VISIT.Day_0].visitDate
            },
            [IMPACT_VISIT.Day_7]: {
                isAudioExerciseDone: impactVisitProperties[IMPACT_VISIT.Day_7].isAudioExerciseDone,
                totalTasks: this.impactWorkflow.getVisitTasksNumber(),
                tasksCompleted: impactVisitProperties[IMPACT_VISIT.Day_7].tasksCompleted,
                visitDate: impactVisitProperties[IMPACT_VISIT.Day_7].visitDate
            },
            [IMPACT_VISIT.Day_14]: {
                isAudioExerciseDone: impactVisitProperties[IMPACT_VISIT.Day_14].isAudioExerciseDone,
                isWbcAnswered: smilerVisitProperties[SMILER_VISIT.Day_14].isWbcAnswered,
                isEcgAnswered: smilerVisitProperties[SMILER_VISIT.Day_14].isEcgAnswered,
                isPhotoAnswered: smilerVisitProperties[SMILER_VISIT.Day_14].isPhotoAnswered,
                isQuestionnaireAnswered:
                    smilerVisitProperties[SMILER_VISIT.Day_14].isQuestionnaireAnswered,
                totalTasks:
                    this.smilerWorkflow.getVisitTasksNumber() +
                    this.impactWorkflow.getVisitTasksNumber(),
                tasksCompleted:
                    smilerVisitProperties[SMILER_VISIT.Day_14].tasksCompleted +
                    impactVisitProperties[IMPACT_VISIT.Day_14].tasksCompleted,
                visitDate: smilerVisitProperties[SMILER_VISIT.Day_14].visitDate
            },
            [IMPACT_VISIT.Day_21]: {
                isAudioExerciseDone: impactVisitProperties[IMPACT_VISIT.Day_21].isAudioExerciseDone,
                totalTasks: this.impactWorkflow.getVisitTasksNumber(),
                tasksCompleted: impactVisitProperties[IMPACT_VISIT.Day_21].tasksCompleted,
                visitDate: impactVisitProperties[IMPACT_VISIT.Day_21].visitDate
            },
            [SMILER_VISIT.Day_28]: {
                isWbcAnswered: smilerVisitProperties[SMILER_VISIT.Day_28].isWbcAnswered,
                isEcgAnswered: smilerVisitProperties[SMILER_VISIT.Day_28].isEcgAnswered,
                isPhotoAnswered: smilerVisitProperties[SMILER_VISIT.Day_28].isPhotoAnswered,
                isQuestionnaireAnswered:
                    smilerVisitProperties[SMILER_VISIT.Day_28].isQuestionnaireAnswered,
                totalTasks: this.smilerWorkflow.getVisitTasksNumber(),
                tasksCompleted: smilerVisitProperties[SMILER_VISIT.Day_28].tasksCompleted,
                visitDate: smilerVisitProperties[SMILER_VISIT.Day_28].visitDate
            }
        };

        this.filterOutdatedVisits();
        this.classifyTasks();
        this.calculateFinishedTasks();

        this.onUpdated.emit();
    }

    /**
     * Reset the phase properties.
     */
    private resetVisitProperties(): SmilerImpactVisitProperties {
        const initialCompletedTasks = 0;

        return {
            [IMPACT_VISIT.Day_0]: {
                isAudioExerciseDone: false,
                isWbcAnswered: false,
                isEcgAnswered: false,
                isPhotoAnswered: false,
                isQuestionnaireAnswered: false,
                totalTasks:
                    this.smilerWorkflow.getVisitTasksNumber() +
                    this.impactWorkflow.getVisitTasksNumber(),
                tasksCompleted: initialCompletedTasks,
                visitDate: new Date()
            },
            [IMPACT_VISIT.Day_7]: {
                isAudioExerciseDone: false,
                totalTasks: this.impactWorkflow.getVisitTasksNumber(),
                tasksCompleted: initialCompletedTasks,
                visitDate: dateFns.addDays(new Date(), IMPACT_VISIT.Day_7)
            },
            [IMPACT_VISIT.Day_14]: {
                isAudioExerciseDone: false,
                isWbcAnswered: false,
                isEcgAnswered: false,
                isPhotoAnswered: false,
                isQuestionnaireAnswered: false,
                totalTasks:
                    this.smilerWorkflow.getVisitTasksNumber() +
                    this.impactWorkflow.getVisitTasksNumber(),
                tasksCompleted: initialCompletedTasks,
                visitDate: dateFns.addDays(new Date(), SMILER_VISIT.Day_14)
            },
            [IMPACT_VISIT.Day_21]: {
                isAudioExerciseDone: false,
                totalTasks: this.impactWorkflow.getVisitTasksNumber(),
                tasksCompleted: initialCompletedTasks,
                visitDate: dateFns.addDays(new Date(), IMPACT_VISIT.Day_21)
            },
            [SMILER_VISIT.Day_28]: {
                isWbcAnswered: false,
                isEcgAnswered: false,
                isPhotoAnswered: false,
                isQuestionnaireAnswered: false,
                totalTasks: this.smilerWorkflow.getVisitTasksNumber(),
                tasksCompleted: initialCompletedTasks,
                visitDate: dateFns.addDays(new Date(), SMILER_VISIT.Day_28)
            }
        };
    }

    /**
     * Filters visits whose date is in the past, so only visits that match the current date
     * or whose date is in the future will remain.
     */
    private filterOutdatedVisits(): void {
        const today = new Date();

        this.visits = this.visits.filter(
            visit =>
                dateFns.isBefore(today, this.visitProperties[visit].visitDate) ||
                dateFns.isSameDay(today, this.visitProperties[visit].visitDate)
        );
    }

    /**
     * Used to initialize the tasks.
     */
    private static initializeTasks(): SmilerImpactTasks {
        return {
            activeTasks: new Set<ImpactVisitTask | SmilerVisitTask>(),
            missingTasks: new Set<ImpactVisitTask | SmilerVisitTask>()
        };
    }

    /**
     * Update the smiler-impact tasks.
     */
    private classifyTasks(): void {
        const smilerTasks = this.smilerWorkflow.getTasks();
        const impactTasks = this.impactWorkflow.getTasks();
        this.tasks = {
            missingTasks: new Set<ImpactVisitTask | SmilerVisitTask>([
                ...smilerTasks.missingTasks,
                ...impactTasks.missingTasks
            ]),
            activeTasks: new Set<ImpactVisitTask | SmilerVisitTask>([
                ...smilerTasks.activeTasks,
                ...impactTasks.activeTasks
            ])
        };
    }
}
