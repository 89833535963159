import {useState} from 'react';
import type {ReactElement} from 'react';
import {Button, Dialog, DialogActions, DialogContent} from '@material-ui/core';
import {Alert} from '@material-ui/lab';
import {useHistory} from 'react-router-dom';
import type OneInstanceModel from '../../model/OneInstanceModel';
import {LOGOUT_MODE} from '../../model/OneInstanceModel';
import './EraseDialog.css';
import i18n from '../../i18n';
import type ConsentFileModel from 'one.models/lib/models/ConsentFileModel';
import type {PropertyTree} from 'one.models/lib/models/SettingsModel';

/**
 * returns the dialog for alerting the user what erase will do
 * @param props
 * @param props.oneInstanceModel
 * @param props.setNavigationDrawer
 * @param props.showEraseDialog
 * @param props.consentFileModel
 * @param props.setLogoutFromErase
 * @param props.settings
 * @returns
 */
export default function EraseDialog(props: {
    oneInstanceModel: OneInstanceModel;
    setNavigationDrawer: (value: boolean) => void;
    showEraseDialog?: (value: boolean) => void;
    setLogoutFromErase: (value: boolean) => void;
    consentFileModel: ConsentFileModel;
    settings: PropertyTree;
}): ReactElement {
    const [eraseDialog, setEraseDialog] = useState(true);
    const history = useHistory();

    /**
     * Handler for erase
     */
    async function erase(): Promise<void> {
        props.setNavigationDrawer(false);
        await props.oneInstanceModel.logout(LOGOUT_MODE.PurgeData);
        props.setLogoutFromErase(true);
        history.push('/');
        window.location.reload();
    }

    return (
        <>
            <Dialog
                open={eraseDialog}
                onClose={() => {
                    setEraseDialog(false);

                    if (props.showEraseDialog !== undefined) {
                        props.showEraseDialog(false);
                    }
                }}
            >
                <DialogContent className="dialog-error">
                    <Alert severity="error">{i18n.t('errors:eraseDialog')}</Alert>
                    <DialogActions className="erase-pop-up-buttons">
                        <Button
                            variant="contained"
                            className="pop-up-button"
                            color="primary"
                            onClick={() => {
                                setEraseDialog(false);

                                if (props.showEraseDialog === undefined) {
                                    history.goBack();
                                } else {
                                    props.showEraseDialog(false);
                                }
                            }}
                        >
                            {i18n.t('common:settings.cancel')}
                        </Button>
                        <Button
                            className="pop-up-button"
                            variant="contained"
                            color="primary"
                            onClick={async () => {
                                await erase().then(() => {
                                    history.push('/');
                                });
                            }}
                        >
                            {i18n.t('common:buttons.delete')}
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </>
    );
}
