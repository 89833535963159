import type ECGModel from 'one.models/lib/models/ECGModel';
import type WbcDiffModel from 'one.models/lib/models/WbcDiffModel';
import type DocumentModel from 'one.models/lib/models/DocumentModel';
import type {DocumentInfo} from 'one.models/lib/models/DocumentModel';
import type QuestionnaireModel from 'one.models/lib/models/QuestionnaireModel';
import type AudioExerciseModel from 'one.models/lib/models/AudioExerciseModel';
import type ChannelManager from 'one.models/lib/models/ChannelManager';
import type {ObjectData} from 'one.models/lib/models/ChannelManager';
import * as dateFns from 'date-fns';
import {AcceptedMimeType} from 'one.models/lib/recipes/DocumentRecipes/DocumentRecipes_1_1_0';
import StudyHelper from './StudyHelper';
import type {TaskCategories} from './StudyHelper';
import {OEvent} from 'one.models/lib/misc/OEvent';

/**
 * Total number of days of both studies Impact & Smiler.
 * Please take care, any changes on the visits side may affect this constant too.
 */
export const STUDY_DURATION = 29;

/**
 * Represents the common parts of the SMILER and IMPACT studies.
 */
export default class StudyWorkflow<T, U> {
    public onUpdated = new OEvent<() => void>();

    protected ecgModel: ECGModel;
    protected wbcModel: WbcDiffModel;
    protected documentModel: DocumentModel;
    protected questionnaireModel: QuestionnaireModel;
    protected channelManager: ChannelManager;
    protected audioExerciseModel: AudioExerciseModel;

    protected studyStartDate: Date;
    protected studyHelper: StudyHelper;
    protected currentStudyDay: number;
    protected daysUntilNextVisit: number;
    protected tasks: TaskCategories<T>;
    protected activeVisit: U | undefined;
    protected displayStudyEndedMessage: boolean;
    protected finishedTasks: T[];

    protected timer: ReturnType<typeof setTimeout> | undefined;

    protected questionnaireDisconnect: (() => void) | null = null;
    protected wbcDisconnect: (() => void) | null = null;
    protected ecgDisconnect: (() => void) | null = null;
    protected documentDisconnect: (() => void) | null = null;
    protected audioExerciseDisconnect: (() => void) | null = null;

    constructor(
        ecgModel: ECGModel,
        wbcModel: WbcDiffModel,
        documentModel: DocumentModel,
        questionnaireModel: QuestionnaireModel,
        audioExerciseModel: AudioExerciseModel,
        channelManager: ChannelManager
    ) {
        this.ecgModel = ecgModel;
        this.wbcModel = wbcModel;
        this.documentModel = documentModel;
        this.questionnaireModel = questionnaireModel;
        this.audioExerciseModel = audioExerciseModel;
        this.channelManager = channelManager;

        this.studyStartDate = new Date();
        this.studyHelper = new StudyHelper();
        this.currentStudyDay = 0;
        this.daysUntilNextVisit = 0;
        this.tasks = this.initializeTasks();
        this.displayStudyEndedMessage = false;
        this.finishedTasks = [];
    }

    /**
     * Shutdown module
     */
    public shutdown(): void {
        if (this.timer) {
            clearTimeout(this.timer);
        }

        if (this.questionnaireDisconnect) {
            this.questionnaireDisconnect();
        }

        if (this.wbcDisconnect) {
            this.wbcDisconnect();
        }

        if (this.ecgDisconnect) {
            this.ecgDisconnect();
        }

        if (this.documentDisconnect) {
            this.documentDisconnect();
        }

        if (this.audioExerciseDisconnect) {
            this.audioExerciseDisconnect();
        }
    }

    /**
     * Get the start study date in milliseconds.
     */
    public getStartStudy(): number {
        return dateFns.getTime(this.studyStartDate);
    }

    /**
     * Retuning the current study day of a study.
     */
    public getCurrentStudyDay(): number {
        return this.currentStudyDay;
    }

    /**
     * Returning the number of days until next visit will be active.
     */
    public getDaysUntilNextVisit(): number {
        return this.daysUntilNextVisit;
    }

    /**
     * Getting the tasks categories.
     */
    public getTasks(): TaskCategories<T> {
        return this.tasks;
    }

    /**
     * Returning the active visit of the study.
     */
    public getActiveVisit(): U | undefined {
        return this.activeVisit;
    }

    /**
     * Get the display study ended message flag.
     */
    public getDisplayStudyEndedMessage(): boolean {
        return this.displayStudyEndedMessage;
    }

    /**
     * Set the display study ended message flag.
     * @param value - the new value of the flag.
     */
    public setDisplayStudyEndedMessage(value: boolean): void {
        this.displayStudyEndedMessage = value;
    }

    /**
     * Get the finished tasks for a the active visit.
     */
    public getFinishedTasks(): T[] {
        return this.finishedTasks;
    }

    /**
     * Initialize the start study date.
     * This is calculated based on the first data that is added within the study.
     */
    protected async initializeStartStudyDate(): Promise<void> {
        const questionnaireResponses = await this.questionnaireModel.responses();
        const photos = await this.fetchPhotos();
        const ecgs = await this.ecgModel.retrieveAllWithoutData();
        const wbcs = await this.wbcModel.observations();
        const audioExercises = await this.audioExerciseModel.audioExercises();

        const objects = [...questionnaireResponses, ...photos, ...ecgs, ...wbcs, ...audioExercises];

        let currentDate = new Date();

        for (const object of objects) {
            if (dateFns.isBefore(object.creationTime, currentDate)) {
                currentDate = object.creationTime;
            }
        }

        this.studyStartDate = currentDate;
    }

    /**
     * Since there is no api which returns only the photos
     * we need to filter them to be sure that we have only photos.
     */
    protected async fetchPhotos(): Promise<ObjectData<DocumentInfo>[]> {
        const documents = await this.documentModel.documents();
        return documents.filter(
            document =>
                document.data.mimeType === AcceptedMimeType.PNG ||
                document.data.mimeType === AcceptedMimeType.JPEG
        );
    }

    /**
     * Just update the start study date and emit an event
     * when a new object which influence Smiler or Impact was added.
     */
    protected async handleOnStudyUpdate(): Promise<void> {
        await this.initializeStartStudyDate();
        this.onUpdated.emit();
    }

    // ########### private functions ############

    /**
     * Used to initialize the tasks.
     */
    private initializeTasks(): TaskCategories<T> {
        return {
            activeTasks: new Set<T>(),
            missingTasks: new Set<T>()
        };
    }
}
