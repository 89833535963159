import {useCallback, useEffect, useState} from 'react';
import type {ReactElement} from 'react';
import './Journal.css';
import '../../Primary.css';
import type JournalModel from 'one.models/lib/models/JournalModel';
import type {EventListEntry, EventType} from 'one.models/lib/models/JournalModel';
import {getCurrentEventInformation} from '../modelHelper/JournalHelper';
import {
    Button,
    CircularProgress,
    Divider,
    ListItem,
    ListItemIcon,
    ListItemText
} from '@material-ui/core';
import * as dateFns from 'date-fns';
import i18n from '../../i18n';
import Paper from '@material-ui/core/Paper';
import {useHistory} from 'react-router-dom';
import type {ObjectData} from 'one.models/lib/models/ChannelManager';
import MenuButton from '../menu/MenuButton';
import {formatDate, hideCircularProgress} from '../utils/Utils';
import JournalDialog from './JournalDialog';
import type {Electrocardiogram} from 'one.models/lib/recipes/ECGRecipes';
import type {QuestionnaireResponses} from 'one.models/lib/recipes/QuestionnaireRecipes/QuestionnaireResponseRecipes';
import type {SHA256Hash} from 'one.core/lib/util/type-checks';
import QuestionnaireView from '../questionnaire/QuestionnaireView';
import type QuestionnaireModel from 'one.models/lib/models/QuestionnaireModel';
import EntryView from './EntryView';
import ImageView from '../importDocument/ImageView';
import type DocumentModel from 'one.models/lib/models/DocumentModel';
import type WbcDiffModel from 'one.models/lib/models/WbcDiffModel';
import WbcEntryView from '../wbcForm/WbcEntryView';
import EcgView from '../ecg/EcgView';
import type ECGModel from 'one.models/lib/models/ECGModel';

/*
 type definition of the layout of elements of settings  menu
 */
export type EventTypes = {
    type: EventType;
    name: string;
    icon?: ReactElement;
};

/**
 * This component builds and returns the events list view.
 * @param props -View properties
 * @param props.journalModel
 * @param props.eventTypes
 * @param props.showFilterButtons
 * @param props.productType
 * @param props.questionnaireModel
 * @param props.documentModel
 * @param props.wbcModel
 * @param props.ecgModel
 * @returns - Entire event list view
 */
export default function Journal(props: {
    journalModel: JournalModel;
    questionnaireModel: QuestionnaireModel;
    documentModel: DocumentModel;
    wbcModel: WbcDiffModel;
    ecgModel: ECGModel;
    eventTypes: EventTypes[];
    showFilterButtons: boolean;
    productType: string;
}): ReactElement {
    const history = useHistory();
    const [clickedEvent, setClickedEvent] = useState<EventListEntry>();
    const [clickedEventType, setClickedEventType] = useState('');
    const [dialogState, setDialogState] = useState(false);

    const [filterType, setFilterType] = useState<EventType | undefined>();

    const [iteratorFetchedData, setIteratorFetchedData] = useState(false);

    const [eventsIterator] = useState(props.journalModel.retrieveEventsByDayIterator(5));
    const [currentEventsList, setCurrentEventsList] = useState<EventListEntry[]>([]);

    const [dialogContent, setDialogContent] = useState<ReactElement>(<></>);
    const [data, setData] = useState<EventListEntry>();
    const [openEntryView, setOpenEntryView] = useState(false);

    /**
     * Loads the events using the eventsIterator and it gets called whenever:
     * - new date arrives
     * - the user has reached the bottom of the page
     * - the journal list does not fits the whole screen
     */
    const loadEvents = useCallback(() => {
        eventsIterator
            .next()
            .then((event: IteratorResult<EventListEntry[]>) => {
                if (event.done) {
                    hideCircularProgress();
                }

                if (!event.done) {
                    setCurrentEventsList(eventList => {
                        event.value.forEach(value => {
                            insertSortedDescending(eventList, value);
                        });
                        return [...eventList];
                    });
                }

                setIteratorFetchedData(true);
            })
            .catch((ignored: Error) => {
                console.error('Error: could not fetch events with iterator.');
            });
    }, [eventsIterator]);

    useEffect(() => {
        /**
         * Function that tracks if the bottom of the screen has been reached to fetch more events
         */
        function trackScrolling(): void {
            const wrappedElement = document.getElementById('root');

            if (
                wrappedElement &&
                wrappedElement.getBoundingClientRect().bottom <= window.innerHeight + 1
            ) {
                loadEvents();
            }
        }
        document.addEventListener('scroll', trackScrolling);

        return () => {
            document.removeEventListener('scroll', trackScrolling);
        };
    });

    /**
     * Tracks if the journal list is big enough to fit the screen.
     */
    useEffect(() => {
        const height = document.getElementById('list-container-events');

        if (height && height.clientHeight < window.innerHeight) {
            loadEvents();
        }
    }, [loadEvents, currentEventsList]);

    /**
     * Attach the handler only when the iterator has fetched the first set of data. This is
     * because of the redirecting of saving a photo/document into the journal page, duplicating the item.
     */
    useEffect(() => {
        if (!iteratorFetchedData) {
            return;
        }

        /** Handler function that will add the new received item into the event list
         * @param {EventListEntry | undefined} event
         */
        function onNewEventListEntryReceived(event?: EventListEntry): void {
            if (event) {
                setCurrentEventsList(eventList => {
                    /** This can be optimised to a binary search, since the items in the arr are always in order. **/
                    const found = eventList.find(
                        (entry: EventListEntry) =>
                            entry.data.dataHash === event.data.dataHash &&
                            entry.data.creationTime.getTime() === event.data.creationTime.getTime()
                    );

                    if (found) {
                        return eventList;
                    } else {
                        insertSortedDescending(eventList, event);
                        return [...eventList];
                    }
                });
            }
        }

        const disconnect = props.journalModel.onUpdated(onNewEventListEntryReceived);

        return () => {
            disconnect();
        };
    }, [iteratorFetchedData, props.journalModel]);

    /**
     * Binary index search and item insert with Array.splice
     * @param {EventListEntry[]} arr
     * @param {EventListEntry} item
     */
    function insertSortedDescending(arr: EventListEntry[], item: EventListEntry): void {
        /**
         * @param {EventListEntry} a
         * @param {EventListEntry} b
         */
        function comparator(a: EventListEntry, b: EventListEntry): number {
            return a.data.creationTime < b.data.creationTime
                ? 1
                : a.data.creationTime > b.data.creationTime
                ? -1
                : 0;
        }

        /** get the index we need to insert the item at */
        let min = 0;
        let max = arr.length;
        let index = Math.floor((min + max) / 2);

        while (max > min) {
            if (comparator(item, arr[index]) < 0) {
                max = index;
            } else {
                min = index + 1;
            }
            index = Math.floor((min + max) / 2);
        }

        /** insert the item **/
        arr.splice(index, 0, item);
    }

    /**
     *  This method toggles the "selectedButton" class when a button is clicked
     * @param {HTMLElement}currentButton - The DOM element for the clicked button
     */
    function toggleSelectedClass(currentButton: HTMLElement): void {
        const selectedButton = document.getElementsByClassName('selectedButton')[0];
        selectedButton.classList.remove('selectedButton');
        currentButton.classList.add('selectedButton');
    }

    /**
     * This method builds the body in which events are listed. The events are grouped by day and each one of them contains the following components:
     * - the time when the event was created
     * - the specific icon for each type
     * - a brief description of the event
     *
     * @param givenEventsList
     * @param {EventTypes[]} eventTypes - The list of event types
     * @returns {ReactElement} - The body of events list
     */
    function buildJournal(
        givenEventsList: EventListEntry[],
        eventTypes: EventTypes[]
    ): ReactElement {
        const displayedEventsList = [];
        const eventsList = filterType
            ? givenEventsList.filter(event => event.type === filterType)
            : givenEventsList;

        if (eventsList.length) {
            let date = eventsList[eventsList.length - 1].data.creationTime;

            for (let i = 0; i < eventsList.length; i++) {
                const currentEventInfo = getCurrentEventInformation(eventsList[i], eventTypes);

                if (!dateFns.isSameDay(eventsList[i].data.creationTime, date) || i === 0) {
                    date = eventsList[i].data.creationTime;

                    // to not draw the divider for the first date
                    if (i !== 0) {
                        displayedEventsList.push(<Divider key={'divider_' + i.toString()} />);
                    }

                    displayedEventsList.push(
                        <div key={'time_' + i.toString()}>
                            <ListItem button key={date.toString()} className="journal-event-date">
                                <ListItemText>{formatDate(date)}</ListItemText>
                            </ListItem>
                        </div>
                    );
                }

                // TODO: Add a brief description for each event
                displayedEventsList.push(
                    <ListItem
                        button
                        className="journal-entry"
                        key={'event_' + i.toString()}
                        onClick={() => {
                            switch (true) {
                                case currentEventInfo.type ===
                                    i18n.t('common:eventTypes.QuestionnaireResponses'):
                                    if (
                                        (eventsList[i].data as ObjectData<QuestionnaireResponses>)
                                            .data.type === 'EQ5D3L'
                                    ) {
                                        setOpenEntryView(true);
                                        setData(eventsList[i]);
                                        setDialogContent(
                                            <QuestionnaireView
                                                questionnaireModel={props.questionnaireModel}
                                                questionnaireResponse={
                                                    eventsList[i]
                                                        .data as ObjectData<QuestionnaireResponses>
                                                }
                                            />
                                        );
                                    } else {
                                        history.push(
                                            'questionnaire/view?response=' +
                                                (
                                                    eventsList[i]
                                                        .data as ObjectData<QuestionnaireResponses>
                                                ).id
                                        );
                                    }
                                    break;
                                case currentEventInfo.type.includes(
                                    i18n.t('common:eventTypes.DiaryEntry')
                                ):
                                    history.push(`/diary/view/${eventsList[i].data.id}`);
                                    break;
                                case currentEventInfo.type.includes(
                                    i18n.t('common:eventTypes.WbcObservation')
                                ):
                                    setOpenEntryView(true);
                                    setData(eventsList[i]);
                                    setDialogContent(
                                        <WbcEntryView
                                            wbcModel={props.wbcModel}
                                            wbcId={eventsList[i].data.id}
                                        />
                                    );
                                    break;
                                case currentEventInfo.type.includes(
                                    i18n.t('common:eventTypes.bodyTemperature')
                                ):
                                    history.push(
                                        '/body-temperature/' +
                                            dateFns
                                                .getTime(eventsList[i].data.creationTime)
                                                .toString()
                                    );
                                    break;
                                case currentEventInfo.type.includes(
                                    i18n.t('common:eventTypes.documentInfo.image')
                                ): {
                                    setOpenEntryView(true);
                                    setData(eventsList[i]);
                                    setDialogContent(
                                        <ImageView
                                            documentModel={props.documentModel}
                                            imageId={eventsList[i].data.id}
                                        />
                                    );
                                    break;
                                }
                                case currentEventInfo.type.includes(
                                    i18n.t('common:eventTypes.documentInfo.document')
                                ): {
                                    history.push(`/view/pdf/${eventsList[i].data.id}`);
                                    break;
                                }
                                case currentEventInfo.type.includes(
                                    i18n.t('common:eventTypes.Electrocardiogram')
                                ): {
                                    setOpenEntryView(true);
                                    setData(eventsList[i]);
                                    setDialogContent(
                                        <EcgView
                                            ecgModel={props.ecgModel}
                                            electrocardiogramHash={
                                                eventsList[i].data
                                                    .dataHash as SHA256Hash<Electrocardiogram>
                                            }
                                        />
                                    );
                                    break;
                                }
                                case currentEventInfo.type === i18n.t('studies:impact.bodyScan'):
                                case currentEventInfo.type === i18n.t('studies:impact.yoga'):
                                case currentEventInfo.type === i18n.t('studies:impact.meditation'):
                                case currentEventInfo.type === i18n.t('studies:impact.meditative'):
                                    // no action needed if the entry is an audio exercise
                                    break;
                                default:
                                    setDialogState(!dialogState);
                                    setClickedEventType(currentEventInfo.type);
                                    setClickedEvent(eventsList[i]);
                                    break;
                            }
                        }}
                    >
                        <ListItemText className="eventTime">
                            {currentEventInfo.hour}
                            {':'}
                            {currentEventInfo.minutes}
                        </ListItemText>
                        <ListItemIcon>{currentEventInfo.icon}</ListItemIcon>
                        <ListItemText>{currentEventInfo.type}</ListItemText>
                    </ListItem>
                );
            }
        } else {
            displayedEventsList.push(
                <div key={'emptyJournal'}>{i18n.t('common:journal.emptyJournal')}</div>
            );
        }
        hideCircularProgress();
        return <>{displayedEventsList}</>;
    }

    return (
        <>
            <div className="circular-progress-container">
                <CircularProgress className="circular-progress" size={35} />
            </div>
            <div className="page-container hide">
                <div className="menu-button-header">
                    <MenuButton />
                    <h2 className="headline"> {i18n.t('common:menu.journal')}</h2>
                </div>
                {props.showFilterButtons && (
                    <div className="types-list paper-font-size">
                        <Button
                            variant="contained"
                            className="journal-button-type selectedButton"
                            onClick={e => {
                                setFilterType(undefined);
                                toggleSelectedClass(e.currentTarget);
                            }}
                        >
                            {i18n.t('common:eventTypes.all')}
                        </Button>
                        {props.eventTypes.map(({type, name, icon}, index) => (
                            <Button
                                variant="contained"
                                className="journal-button-type"
                                key={index}
                                onClick={e => {
                                    setFilterType(type);
                                    toggleSelectedClass(e.currentTarget);
                                }}
                            >
                                <div className="eventIcon">
                                    <div>{icon}</div>
                                    <div>{name}</div>
                                </div>
                            </Button>
                        ))}
                    </div>
                )}
                <Paper id="list-container-events" square elevation={3} className="page-content-box">
                    {buildJournal(currentEventsList, props.eventTypes)}
                </Paper>
                {dialogState && clickedEvent && (
                    <JournalDialog
                        dialogState={dialogState}
                        setDialogState={setDialogState}
                        event={clickedEvent}
                        type={clickedEventType}
                    />
                )}
            </div>
            {data && openEntryView && (
                <EntryView
                    isOpen={openEntryView}
                    closeEntryViewCallback={setOpenEntryView}
                    objectData={data}
                    content={dialogContent}
                />
            )}
        </>
    );
}
