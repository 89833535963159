import {useEffect} from 'react';
import type {ReactElement} from 'react';
import i18n from '../../i18n';
import {Icon} from '../icon/Icon';
import './AddToHomeScreen.css';
import Paper from '@material-ui/core/Paper';
import {useHistory} from 'react-router-dom';

/**
 * @returns
 */
export default function AddToHomeScreen(): ReactElement {
    const history = useHistory();

    useEffect(() => {
        // Detect the location/url change
        const unregisterHistoryListener = history.listen(() => {
            window.location.reload();
        });

        return () => {
            unregisterHistoryListener();
        };
    }, [history]);

    return (
        <div className="add-to-home-screen-container">
            <Paper square elevation={3} className="page-container">
                <div className="add-to-home-screen-header">
                    <Icon name="Info" />
                    <h2 className="headline">{i18n.t('addToHomeScreen:title')}</h2>
                </div>
                <div>
                    {i18n.t('addToHomeScreen:paragraph1')}
                    <span className="bold-text">{i18n.t('addToHomeScreen:paragraph1Bold')}</span>
                </div>
                <div className="italic-text">{i18n.t('addToHomeScreen:paragraph2')}</div>
                <div className="sth-steps-container">
                    <div className="left-step-wrapper">
                        <div className="bold-text">{i18n.t('addToHomeScreen:step1')}</div>
                        <div className="step1-description">
                            {i18n.t('addToHomeScreen:step1DescriptionPart1')}
                            <img
                                alt="logo"
                                className="homescreen-icon"
                                src={require('../homescreen_icon.svg')}
                            />
                            <span>{i18n.t('addToHomeScreen:step1DescriptionPart2')}</span>
                        </div>
                    </div>
                    <div className="step-wrapper">
                        <div className="bold-text">{i18n.t('addToHomeScreen:step2')}</div>
                        <div>
                            {i18n.t('addToHomeScreen:step2DescriptionPart1')}
                            <span className="bold-text">
                                {i18n.t('addToHomeScreen:addToHomeScreen')}
                            </span>
                            <span>{i18n.t('addToHomeScreen:step2DescriptionPart2')}</span>
                            <span className="bold-text">{i18n.t('addToHomeScreen:add')}</span>
                            <span>{i18n.t('addToHomeScreen:step2DescriptionPart3')}</span>
                        </div>
                    </div>
                </div>
                <div className="step3-title bold-text">{i18n.t('addToHomeScreen:step3')}</div>
                <div>{i18n.t('addToHomeScreen:step3Description')}</div>
                <img alt="logo" className="sth-image" src={require('../3phones.svg')} />
            </Paper>
        </div>
    );
}
