import type {SmilerVisitTask, SmilerVisitProperties} from '../../../model/studies/SmilerWorkflow';
import TimelineItem from '@material-ui/lab/TimelineItem/TimelineItem';
import TimelineContent from '@material-ui/lab/TimelineContent/TimelineContent';
import i18n from '../../../i18n';
import Timeline from '@material-ui/lab/Timeline/Timeline';
import type {ReactElement} from 'react';
import {TimelineOppositeContent} from '@material-ui/lab';
import * as dateFns from 'date-fns';
import Dashboard, {TASK_TYPES} from '../studyCommon/Dashboard';
import type DocumentModel from 'one.models/lib/models/DocumentModel';
import {buildTaskDoneHistory} from '../studyCommon/DashboardCommon';
import {SMILER_VISIT} from '../../../model/studies/StudyHelper';
import type {SmilerStudyVisit, TaskCategories} from '../../../model/studies/StudyHelper';
import type {Product} from '../../Ui';
import {PRODUCT_TYPE} from '../../Ui';
import StudyTimelineContent, {VISIT_STATE} from '../studyCommon/StudyTimelineContent';
import {SMILER_TASK} from '../../../model/studies/SmilerWorkflow';

/** The participant visits for Smiler study. */
export const SMILER_PHASES = [
    SMILER_VISIT.Day_0,
    SMILER_VISIT.Day_14,
    SMILER_VISIT.Day_28
] as const;

/**
 * Represents the Smiler study timeline which includes all the visits of a participant.
 * @param props - props of the view.
 * @param props.currentDayOfTheStudy - current study day.
 * @param props.currentVisit - active visit or undefined if the user it's between the visits.
 * @param props.visitsProperties - visit properties for each visit.
 * @param props.documentModel - the document model.
 * @param props.tasks - the tasks categories.
 * @param props.finishedTasks - finished tasks for the active visit.
 * @param props.productType - the product type because we need to display different things
 * depending on it.
 */
export default function SmilerTimeline(props: {
    currentDayOfTheStudy: number;
    currentVisit: SmilerStudyVisit | undefined;
    visitsProperties: SmilerVisitProperties;
    documentModel: DocumentModel;
    tasks: TaskCategories<SmilerVisitTask>;
    finishedTasks: SmilerVisitTask[];
    productType: Product;
}): ReactElement {
    return (
        <Timeline className="overview-timeline" align="left">
            {SMILER_PHASES.map((phaseIdentifier: SmilerStudyVisit, index: number) => (
                <div
                    key={index}
                    className={`${
                        props.finishedTasks.length !== 0 ||
                        props.tasks.missingTasks.size !== 0 ||
                        props.currentVisit !== phaseIdentifier ||
                        props.productType === PRODUCT_TYPE.Smiler_impact
                            ? 'visit'
                            : ''
                    }`}
                >
                    {props.productType !== PRODUCT_TYPE.Smiler_impact &&
                        props.currentVisit === phaseIdentifier && (
                            <>
                                <Dashboard
                                    documentModel={props.documentModel}
                                    tasks={[...props.tasks.activeTasks]}
                                    taskTypes={TASK_TYPES.active}
                                    visitDate={props.visitsProperties[props.currentVisit].visitDate}
                                />
                                <Dashboard
                                    documentModel={props.documentModel}
                                    tasks={[...props.tasks.missingTasks]}
                                    taskTypes={TASK_TYPES.missing}
                                />
                            </>
                        )}
                    <TimelineItem key={index} className="timeline-item">
                        {((props.currentVisit === phaseIdentifier &&
                            props.tasks.activeTasks.size === 0) ||
                            props.currentVisit !== phaseIdentifier ||
                            props.productType === PRODUCT_TYPE.Smiler_impact) && (
                            <TimelineOppositeContent className="visit-date">
                                {dateFns.format(
                                    props.visitsProperties[phaseIdentifier].visitDate,
                                    'dd/MM/yyyy'
                                )}
                            </TimelineOppositeContent>
                        )}
                        {props.productType === PRODUCT_TYPE.Smiler_impact ? (
                            <StudyTimelineContent
                                finishedTasks={props.finishedTasks}
                                studyTasks={[
                                    SMILER_TASK.Questionnaire,
                                    SMILER_TASK.Photo,
                                    SMILER_TASK.Wbc,
                                    SMILER_TASK.Ecg
                                ]}
                                visitState={
                                    // subtract one because the phaseIdentifier is a value
                                    // between [0;28] and the currentStudyDay is a value between
                                    // [1;29]
                                    phaseIdentifier === props.currentDayOfTheStudy - 1
                                        ? VISIT_STATE.active
                                        : phaseIdentifier < props.currentDayOfTheStudy
                                        ? VISIT_STATE.passed
                                        : VISIT_STATE.next
                                }
                            />
                        ) : (
                            <TimelineContent
                                className={`timeline-content ${
                                    phaseIdentifier < props.currentDayOfTheStudy ||
                                    props.currentVisit === phaseIdentifier
                                        ? 'blur'
                                        : ''
                                }`}
                            >
                                {props.finishedTasks.length >= 0 &&
                                props.tasks.missingTasks.size === 0 &&
                                props.currentVisit === phaseIdentifier ? (
                                    <div
                                        className={`${
                                            props.tasks.activeTasks.size === 0
                                                ? ''
                                                : 'milestone-information'
                                        }`}
                                    >
                                        {buildTaskDoneHistory([...props.finishedTasks])}
                                    </div>
                                ) : (
                                    i18n.t('studies:smiler.smilerVisit')
                                )}
                            </TimelineContent>
                        )}
                    </TimelineItem>
                </div>
            ))}
        </Timeline>
    );
}
