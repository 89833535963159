import type {Electrocardiogram} from 'one.models/lib/recipes/ECGRecipes';
import type {BloodGlucose} from 'one.models/lib/recipes/BloodGlucoseRecipes';
import {PRODUCT_TYPE} from '../Ui';
import type {Product} from '../Ui';
import {createTrackingPromise} from 'one.core/lib/util/promise';
import type {TrackingPromiseObj} from 'one.core/lib/util/promise';
import {isObject} from 'one.core/lib/util/type-checks-basic';
import {stringify} from 'one.core/lib/util/sorted-stringify';

// ------------------------------------ Interfaces ------------------------------------

interface Window {
    postECGs: (message: string) => void;
    postBloodGlucoseLevels: (message: string) => void;
    postIndexedDBSize: (message: string) => void;
    postQRConfiguration: (message: string) => void;
    finishedCapturing: (message: string) => void;
    scheduleNotificationsFinished: (message: string) => void;

    webkit: {
        messageHandlers: {
            log: {postMessage: (arg: string) => void};
            sendPDF: {postMessage: (arg: any) => void};
            getECGsAfterTS: {postMessage: (arg: any) => void};
            getBloodGlucose: {postMessage: (arg: any) => void};
            getIndexedDBSize: {postMessage: (arg: any) => void};
            scanQRConfiguration: {postMessage: (arg: any) => void};
            getQRConfiguration: {postMessage: (arg: any) => void};
            scheduleNotifications: {postMessage: (arg: any) => void};
        };
    };
    appInterface: {
        log: (arg: string) => void;
        sendPDF: (base64: string, fileName: string, mimeType?: string) => void;
        getECGsAfterTS: (arg: string) => void;
        getBloodGlucose: (arg: string) => void;
        getIndexedDBSize: (arg: string) => void;
        scanQRConfiguration: (arg: string) => void;
        getQRConfiguration: (arg: string) => void;
        scheduleNotifications: (notifications: string) => void;
    };
}

interface HandleWithAnyECG {
    handle: number;
    ecgs: Electrocardiogram[];
}

interface HandleWithGlucoseSample {
    handle: number;
    glucoseSamples: BloodGlucose[];
}

interface IndexedDBSize {
    size: number;
    handle: number;
    status: string;
}

interface Identity {
    email: string;
    secretEncryptionKey: string;
    secretSignKey: string;
    publicEncryptionKey: string;
    publicSignKey: string;
    productType: Product;
}

interface DataWithHandle {
    handle: number;
    data: any;
}

interface ScheduleNotificationsResponse {
    handle: number;
    notificationsScheduled: boolean;
}

// ------------------------------------ Classes ------------------------------------

/**
 * global window variable, mainly used and operated by {@link WindowAPI}
 */
declare const window: Window;

/**
 * Abstract class that defines the window's API
 */
export abstract class WindowAPI {
    /**
     * @public
     */
    public static LOG_LEVELS = {ERROR: 'Error', INFO: 'Info'};

    /**
     * The number is safe to be used as unique ID, because Number.MAX_SAFE_INTEGER will not be
     * reached. This should be safe for the future too, promiseID does not depend how fast the
     * CPUs are, but on the number of times we access iOS/Android specific data during the
     * runtime. Therefore the overflow risk is minimum (to nonexistent). The most intense
     * API usage at the moment is for the ECG synchronization. Even in this case, we don't have
     * more than 1 access/second, because the UI does the readings synchronously, so it awaits for
     * the previous response before doing the next API call.
     *
     * e.g.: It's hard to determine the worse case ever, but as an example, if we would have 1000
     * promise API endpoints usages per second (very unlikely), the number will overflow after
     * more than 280 000 years.
     * Number.MAX_SAFE_INTEGER = 9007199254740991, so:
     * 9007199254740991 / 1000 (accesses/sec) / 31556952 (seconds in a year) > 280 000 years.
     *
     * @protected
     */
    protected promiseID = 1;

    /**
     *
     * @protected
     */
    protected voidTPMap = new Map<number, TrackingPromiseObj<void>>();

    /**
     * @protected
     */
    protected ecgTPMap = new Map<number, TrackingPromiseObj<Electrocardiogram[]>>();

    /**
     * @protected
     */
    protected glucoseTPMap = new Map<number, TrackingPromiseObj<BloodGlucose[]>>();

    /**
     *
     * @protected
     */
    protected numberTPMap = new Map<number, TrackingPromiseObj<number>>();

    /**
     *
     * @protected
     */
    protected identityTPMap = new Map<number, TrackingPromiseObj<Identity>>();

    constructor() {
        this.exposeMethodsForNative();
    }
    /**
     * Sends a log to the webview
     * @param message
     * @param status
     */
    public abstract log(message: string, status: string): void;

    /**
     * Sends the data in base64 format, along with the fileName and the mimeType
     * @param base64 - The file content encoded as base64 string.
     * @param fileName - The name of the file.
     * @param mimeType - The mime type.
     */
    public abstract sendPDF(base64: string, fileName: string, mimeType?: string): void;

    /**
     * Fetches Electrocardiograms from the native side with startTimestamp greater than argument.
     * @param lastECGTS - The lower limit of the ECG startTimestamp.
     * @param numberOfSamples - The number of ECG samples to be fetched.
     */
    public abstract getECGsAfterTS(
        lastECGTS: number,
        numberOfSamples: number
    ): Promise<Electrocardiogram[]>;

    /**
     * Fetches Blood Glucose samples from the native side with startTimestamp greater than argument.
     * @param lastGlucoseTimestamp - The lower limit of the ECG startTimestamp.
     * @param numberOfSamples - The number of ECG samples to be fetched.
     */
    public abstract getBloodGlucoseLevels(
        lastGlucoseTimestamp: number,
        numberOfSamples: number
    ): Promise<BloodGlucose[]>;

    /**
     * Fetches the IndexedDB size in bytes.
     */
    public abstract getIndexedDBSize(): Promise<number>;

    /**
     * Opens the camera to scan the QR configuration.
     */
    public abstract scanQRConfiguration(): Promise<void>;

    /**
     * Fetches the QR configuration.
     */
    public abstract getQRConfiguration(): Promise<Identity>;

    /**
     * Schedule local push notifications. The promise is rejected if the notifications cannot be
     * scheduled.
     * NOTE: When this method is called, all the pending notifications are overwritten with the
     * ones given as a parameter.
     * @param notifications - the array of the notifications to be scheduled.
     */
    public abstract scheduleNotifications(
        notifications: {title: string; body: string; timestamp: number}[]
    ): Promise<void>;

    private exposeMethodsForNative(): void {
        window.postQRConfiguration = (message: string) => {
            let qrConfigurationJSON;

            try {
                const dataWithHandle = ensureDataWithHandle(JSON.parse(message));
                const identityTrackingPromise = this.identityTPMap.get(dataWithHandle.handle);

                if (identityTrackingPromise === undefined) {
                    return;
                }

                if (dataWithHandle.data === undefined) {
                    identityTrackingPromise.reject(new Error('Identity missing.'));
                    return;
                }

                qrConfigurationJSON = ensureQRConfiguration(dataWithHandle.data);
                identityTrackingPromise.resolve(qrConfigurationJSON);
            } catch (e) {
                this.log(
                    'Invalid qrConfiguration JSON: ' + (e as Error).message,
                    WindowAPI.LOG_LEVELS.ERROR
                );
            }
        };

        window.postIndexedDBSize = (message: string) => {
            let indexedDBSizeJSON;

            try {
                indexedDBSizeJSON = ensureIndexedDBSize(JSON.parse(message));

                const numberTrackingPromise = this.numberTPMap.get(indexedDBSizeJSON.handle);

                if (numberTrackingPromise === undefined) {
                    return;
                }

                if (indexedDBSizeJSON.size === -1) {
                    numberTrackingPromise.reject(
                        new Error(`IndexedDB size calculation failed: ${indexedDBSizeJSON.status}`)
                    );
                    return;
                }
                numberTrackingPromise.resolve(indexedDBSizeJSON.size);
            } catch (e) {
                this.log(
                    'Invalid indexedDBSize JSON. ' + (e as Error).message,
                    WindowAPI.LOG_LEVELS.ERROR
                );
            }
        };

        window.postBloodGlucoseLevels = (message: string) => {
            let glucoseSamplesWithHandle;

            try {
                glucoseSamplesWithHandle = ensureHandleWithGlucoseSample(JSON.parse(message));
            } catch (e) {
                this.log(
                    `Missing or invalid handle in Glucose Samples array. Error = ${
                        isObject(e) ? (e as Error).message : String(e)
                    }`,
                    WindowAPI.LOG_LEVELS.ERROR
                );

                return;
            }

            const handle = glucoseSamplesWithHandle.handle;
            const glucoseTrackingPromise = this.glucoseTPMap.get(glucoseSamplesWithHandle.handle);

            try {
                const bloodGlucoseReadings = ensureBloodGlucoseArray(
                    glucoseSamplesWithHandle.glucoseSamples
                );
                this.log(
                    'Received valid blood glucose for handle: ' + handle.toString(),
                    WindowAPI.LOG_LEVELS.INFO
                );

                if (glucoseTrackingPromise !== undefined) {
                    glucoseTrackingPromise.resolve(bloodGlucoseReadings);
                }
            } catch (e) {
                if (
                    isObject(e) &&
                    glucoseTrackingPromise !== undefined &&
                    e.type &&
                    e.type === 'bloodGlucoseCastError'
                ) {
                    glucoseTrackingPromise.reject(e.startTimestamp);
                }
                this.log(
                    `Invalid BloodGlucose array json. Error is = ${
                        isObject(e) ? (e as Error).message : String(e)
                    }`,
                    WindowAPI.LOG_LEVELS.ERROR
                );
            }
        };

        window.postECGs = (message: string) => {
            let ecgsWithHandle;

            try {
                ecgsWithHandle = ensureHandleWithAnyECG(JSON.parse(message));
            } catch {
                this.log('Missing or invalid handle in ECG array', WindowAPI.LOG_LEVELS.ERROR);
                return;
            }

            const handle = ecgsWithHandle.handle;
            const ecgTrackingPromise = this.ecgTPMap.get(ecgsWithHandle.handle);

            try {
                const ecgs = ensureElectrocardiogramArray(ecgsWithHandle.ecgs);
                this.log(
                    'Received valid ecgs for handle: ' + handle.toString(),
                    WindowAPI.LOG_LEVELS.INFO
                );

                if (ecgTrackingPromise !== undefined) {
                    ecgTrackingPromise.resolve(ecgs);
                }
            } catch (e) {
                if (ecgTrackingPromise !== undefined) {
                    ecgTrackingPromise.reject(e);
                }
                this.log('Invalid ECG array json', WindowAPI.LOG_LEVELS.ERROR);
            }
        };

        window.finishedCapturing = (message: string) => {
            try {
                const dataWithHandle = ensureDataWithHandle(JSON.parse(message));
                const voidTrackingPromise = this.voidTPMap.get(dataWithHandle.handle);

                if (voidTrackingPromise !== undefined) {
                    voidTrackingPromise.resolve();
                }
            } catch (e) {
                this.log(
                    'Invalid scanQRConfiguration JSON: ' + (e as Error).message,
                    WindowAPI.LOG_LEVELS.ERROR
                );
            }
        };

        window.scheduleNotificationsFinished = (message: string) => {
            try {
                const scheduleNotificationsResponse = ensureScheduleNotificationsResponse(
                    JSON.parse(message)
                );

                const voidTrackingPromise = this.voidTPMap.get(
                    scheduleNotificationsResponse.handle
                );

                if (voidTrackingPromise === undefined) {
                    return;
                }

                if (scheduleNotificationsResponse.notificationsScheduled) {
                    voidTrackingPromise.resolve();
                } else {
                    voidTrackingPromise.reject(new Error('Notifications could not be scheduled.'));
                }
            } catch (e) {
                this.log(
                    'Invalid notifications scheduling response JSON: ' + (e as Error).message,
                    WindowAPI.LOG_LEVELS.ERROR
                );
            }
        };
    }
}

/**
 * This class implements the abstract functions for the iOS webview
 * Extends {@link WindowAPI}
 *
 */
class IOSWindowAPI extends WindowAPI {
    public log(message: string, status: string): void {
        window.webkit.messageHandlers.log.postMessage(
            JSON.stringify({
                ts: new Date(),
                status: status,
                errorMessage: message
            })
        );
    }

    public sendPDF(base64: string, fileName: string): void {
        window.webkit.messageHandlers.sendPDF.postMessage(
            JSON.stringify({
                base64encoded: base64,
                filename: fileName
            })
        );
    }

    public getECGsAfterTS(
        lastECGTS: number,
        numberOfSamples: number
    ): Promise<Electrocardiogram[]> {
        const trackingPromise = createTrackingPromise<Electrocardiogram[]>();
        const handle = this.promiseID++;
        this.ecgTPMap.set(handle, trackingPromise);
        window.webkit.messageHandlers.getECGsAfterTS.postMessage(
            JSON.stringify({
                lastECGTS: lastECGTS,
                numberOfSamples: numberOfSamples,
                handle: handle
            })
        );
        return trackingPromise.promise;
    }

    public getBloodGlucoseLevels(
        lastGlucoseTimestamp: number,
        numberOfSamples: number
    ): Promise<BloodGlucose[]> {
        const trackingPromise = createTrackingPromise<BloodGlucose[]>();
        const handle = this.promiseID++;
        this.glucoseTPMap.set(handle, trackingPromise);
        window.webkit.messageHandlers.getBloodGlucose.postMessage(
            JSON.stringify({
                lastGlucoseTimestamp: lastGlucoseTimestamp,
                numberOfSamples: numberOfSamples,
                handle: handle
            })
        );
        return trackingPromise.promise;
    }

    public getIndexedDBSize(): Promise<number> {
        const trackingPromise = createTrackingPromise<number>();
        const handle = this.promiseID++;
        this.numberTPMap.set(handle, trackingPromise);

        window.webkit.messageHandlers.getIndexedDBSize.postMessage(
            JSON.stringify({
                handle: handle
            })
        );

        return trackingPromise.promise;
    }

    public getQRConfiguration(): Promise<Identity> {
        const trackingPromise = createTrackingPromise<Identity>();
        const handle = this.promiseID++;
        this.identityTPMap.set(handle, trackingPromise);

        window.webkit.messageHandlers.getQRConfiguration.postMessage(
            JSON.stringify({
                handle: handle
            })
        );

        return trackingPromise.promise;
    }

    public scanQRConfiguration(): Promise<void> {
        const trackingPromise = createTrackingPromise<void>();
        const handle = this.promiseID++;
        this.voidTPMap.set(handle, trackingPromise);

        window.webkit.messageHandlers.scanQRConfiguration.postMessage(
            JSON.stringify({
                handle: handle
            })
        );
        return trackingPromise.promise;
    }

    public scheduleNotifications(
        notifications: {title: string; body: string; timestamp: number}[]
    ): Promise<void> {
        const trackingPromise = createTrackingPromise<void>();
        const handle = this.promiseID++;
        this.voidTPMap.set(handle, trackingPromise);

        window.webkit.messageHandlers.scheduleNotifications.postMessage(
            JSON.stringify({
                notifications: notifications,
                handle: handle
            })
        );
        return trackingPromise.promise;
    }
}

/**
 * This class implements the abstract functions for the Android webview
 * Extends {@link WindowAPI}
 *
 */
class AndroidWindowAPI extends WindowAPI {
    log(message: string, status: string): void {
        window.appInterface.log(
            JSON.stringify({
                ts: new Date(),
                status: status,
                errorMessage: message
            })
        );
    }

    sendPDF(base64: string, fileName: string, mimeType?: string): void {
        window.appInterface.sendPDF(base64, fileName, mimeType);
    }

    getECGsAfterTS(lastECGTS: number, numberOfSamples: number): Promise<Electrocardiogram[]> {
        const trackingPromise = createTrackingPromise<Electrocardiogram[]>();
        const handle = this.promiseID++;
        this.ecgTPMap.set(handle, trackingPromise);

        window.appInterface.getECGsAfterTS(
            JSON.stringify({
                lastECGTS: lastECGTS,
                numberOfSamples: numberOfSamples,
                handle: handle
            })
        );
        return trackingPromise.promise;
    }

    getBloodGlucoseLevels(
        lastBloodGlucoseTS: number,
        numberOfSamples: number
    ): Promise<BloodGlucose[]> {
        const trackingPromise = createTrackingPromise<BloodGlucose[]>();
        const handle = this.promiseID++;
        this.glucoseTPMap.set(handle, trackingPromise);

        window.appInterface.getBloodGlucose(
            JSON.stringify({
                lastGlucoseTimestamp: lastBloodGlucoseTS,
                numberOfSamples: numberOfSamples,
                handle: handle
            })
        );
        return trackingPromise.promise;
    }

    getIndexedDBSize(): Promise<number> {
        const trackingPromise = createTrackingPromise<number>();
        const handle = this.promiseID++;
        this.numberTPMap.set(handle, trackingPromise);

        window.appInterface.getIndexedDBSize(
            JSON.stringify({
                handle: handle
            })
        );
        return trackingPromise.promise;
    }

    getQRConfiguration(): Promise<Identity> {
        const trackingPromise = createTrackingPromise<Identity>();
        const handle = this.promiseID++;
        this.identityTPMap.set(handle, trackingPromise);

        window.appInterface.getQRConfiguration(
            JSON.stringify({
                handle: handle
            })
        );
        return trackingPromise.promise;
    }

    scanQRConfiguration(): Promise<void> {
        const trackingPromise = createTrackingPromise<void>();
        const handle = this.promiseID++;
        this.voidTPMap.set(handle, trackingPromise);

        window.appInterface.scanQRConfiguration(
            JSON.stringify({
                handle: handle
            })
        );
        return trackingPromise.promise;
    }

    public scheduleNotifications(
        notifications: {title: string; body: string; timestamp: number}[]
    ): Promise<void> {
        const trackingPromise = createTrackingPromise<void>();
        const handle = this.promiseID++;
        this.voidTPMap.set(handle, trackingPromise);
        window.appInterface.scheduleNotifications(
            JSON.stringify({
                notifications: notifications,
                handle: handle
            })
        );
        return trackingPromise.promise;
    }
}

// ------------------------------------ Utilities ------------------------------------

/**
 * Check if the parameter is of type Electrocardiogram
 * @param arg
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function isECGObject(arg: any): arg is Electrocardiogram {
    if (!arg) {
        return false;
    }

    /* eslint-disable @typescript-eslint/no-unsafe-member-access */
    return (
        typeof arg.typeDescription === 'string' &&
        typeof arg.voltageMeasurements === 'number' &&
        typeof arg.startTimestamp === 'number' &&
        typeof arg.samplingFrequencyHz === 'number' &&
        typeof arg.endTimestamp === 'number' &&
        typeof arg.classification === 'string' &&
        typeof arg.symptoms === 'string'
    );
    /* eslint-enable @typescript-eslint/no-unsafe-member-access */
}

/**
 * Check if the parameter is of type Blood Glucose
 * @param arg
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function isBloodGlucoseObject(arg: any): arg is BloodGlucose {
    if (isObject(arg)) {
        /* eslint-disable @typescript-eslint/no-unsafe-member-access */
        return (
            typeof arg.value === 'number' &&
            typeof arg.startTimestamp === 'number' &&
            typeof arg.unit === 'string' &&
            typeof arg.endTimestamp === 'number'
        );
    }

    return false;
}

/**
 * Casts parameter to an Electrocardiogram array. If one of the array elements is invalid,
 * it will throw and error with the startTimestamp of that ECG.
 * @param electrocardiogramArrayJson
 */
function ensureElectrocardiogramArray(electrocardiogramArrayJson: any): Electrocardiogram[] {
    if (!electrocardiogramArrayJson) {
        throw new Error('Null json ECG array');
    }

    if (!Array.isArray(electrocardiogramArrayJson)) {
        throw new Error('JSON is not array');
    }

    for (const ecg of electrocardiogramArrayJson) {
        if (!isECGObject(ecg)) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            if (ecg.startTimestamp) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                throw createInvalidECGError(ecg.startTimestamp);
            }
        }
    }

    return electrocardiogramArrayJson as Electrocardiogram[];
}

/**
 * Casts parameter to an BloodGlucose array. If one of the array elements is invalid,
 * it will throw and error with the startTimestamp of that blood glucose sample.
 * @param bloodGlucoseArray
 */
function ensureBloodGlucoseArray(bloodGlucoseArray: any): BloodGlucose[] {
    if (bloodGlucoseArray === undefined) {
        throw new Error('Argument is undefined');
    }

    if (!Array.isArray(bloodGlucoseArray)) {
        throw new Error('Argument is not an array');
    }

    for (const glucoseReading of bloodGlucoseArray) {
        if (!isBloodGlucoseObject(glucoseReading) && glucoseReading.startTimestamp !== undefined) {
            throw createInvalidBloodGlucoseError(glucoseReading.startTimestamp);
        }
        glucoseReading.$type$ = 'BloodGlucose';
    }

    return bloodGlucoseArray as BloodGlucose[];
}

/**
 * Check if argument contains handler with 'number' type.
 * @param arg
 */
function isHandleWithAnyECG(arg: any): arg is HandleWithAnyECG {
    if (isObject(arg)) {
        return typeof arg.handle === 'number';
    }

    return false;
}

/**
 * Check if argument contains handler with 'number' type.
 * @param arg
 */
function isHandleWithGlucoseSample(arg: any): arg is HandleWithGlucoseSample {
    if (isObject(arg)) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        return typeof arg.handle === 'number';
    }

    return false;
}

/**
 * Cast argument to HandleWithAnyGlucoseSample.
 * @param arg
 */
function ensureHandleWithGlucoseSample(arg: any): HandleWithGlucoseSample {
    if (isHandleWithGlucoseSample(arg)) {
        return arg;
    }
    throw new Error('Invalid Glucose sample. Handle is not a number');
}

/**
 * Cast argument to HandleWithAnyECG.
 * @param arg
 */
function ensureHandleWithAnyECG(arg: any): HandleWithAnyECG {
    if (isHandleWithAnyECG(arg)) {
        return arg;
    } else {
        throw new Error('Invalid ECG. Handle is not a number');
    }
}

/**
 * Cast argument to IndexedDBSize
 * @param arg
 */
function ensureIndexedDBSize(arg: any): IndexedDBSize {
    if (!isObject(arg)) {
        throw new Error('Invalid IndexedDB argument');
    }

    /* eslint-disable @typescript-eslint/no-unsafe-member-access */
    if (typeof arg.handle !== 'number') {
        throw new Error('Invalid handle in IndexedDB');
    }

    if (typeof arg.size !== 'number') {
        throw new Error('Invalid size in IndexedDB');
    }

    if (typeof arg.status !== 'string') {
        throw new Error('Invalid status in IndexedDB');
    }
    /* eslint-enable @typescript-eslint/no-unsafe-member-access */

    return arg as IndexedDBSize;
}

/**
 * Cast argument to Identity.
 * @param arg
 */
function ensureQRConfiguration(arg: any): Identity {
    if (!isObject(arg)) {
        throw new Error('Invalid qrConfiguration Identity. Argument = ' + stringify(arg));
    }

    /* eslint-disable @typescript-eslint/no-unsafe-member-access */
    if (typeof arg.email !== 'string') {
        throw new Error('Invalid email in Identity structure. Email = ' + stringify(arg.email));
    }

    if (typeof arg.secretEncryptionKey !== 'string') {
        throw new Error(
            'Invalid secretEncryptionKey in Identity. SecretEncriptionKey = ' +
                stringify(arg.secretEncryptionKey)
        );
    }

    if (typeof arg.secretSignKey !== 'string') {
        throw new Error(
            'Invalid secretSignKey in Identity. SecretSignKey = ' + stringify(arg.secretSignKey)
        );
    }

    if (typeof arg.publicEncryptionKey !== 'string') {
        throw new Error(
            'Invalid publicEncryptionKey in Identity. PublicEncriptionKey = ' +
                stringify(arg.publicEncryptionKey)
        );
    }

    if (typeof arg.publicSignKey !== 'string') {
        throw new Error(
            'Invalid publicSignKey in Identity. PublicSignKey = ' + stringify(arg.publicSignKey)
        );
    }

    if (typeof arg.productType !== 'string') {
        throw new Error(
            'Invalid productType in Identity. ProductType = ' + stringify(arg.productType)
        );
    }

    if (
        arg.productType !== PRODUCT_TYPE.Smiler &&
        arg.productType !== PRODUCT_TYPE.Smiler_impact &&
        arg.productType !== PRODUCT_TYPE.Dev
    ) {
        throw new Error('Invalid productType in Identity json structure');
    }
    /* eslint-enable @typescript-eslint/no-unsafe-member-access */

    return arg as Identity;
}

function ensureDataWithHandle(arg: any): DataWithHandle {
    if (!isObject(arg)) {
        throw new Error('Invalid DataWithHandle');
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (typeof arg.handle !== 'number') {
        throw new Error('Invalid DataWithHandle: missing handle.');
    }

    return arg as DataWithHandle;
}

function createInvalidBloodGlucoseError(
    failingTimestamp: number
): Error & {name: 'InvalidBloodGlucoseError'; failingTS: number} {
    const err = new Error() as Error & {name: 'InvalidBloodGlucoseError'; failingTS: number};
    err.failingTS = failingTimestamp;
    err.name = 'InvalidBloodGlucoseError';
    return err;
}

function ensureScheduleNotificationsResponse(arg: any): ScheduleNotificationsResponse {
    if (!isObject(arg)) {
        throw new Error('Invalid DataWithHandle');
    }

    /* eslint-disable @typescript-eslint/no-unsafe-member-access */
    if (typeof arg.handle !== 'number') {
        throw new Error('Invalid ScheduleNotificationsResponse: missing handle.');
    }

    if (typeof arg.notificationsScheduled !== 'boolean') {
        throw new Error('Invalid ScheduleNotificationsResponse: missing notificationsScheduled.');
    }
    /* eslint-enable @typescript-eslint/no-unsafe-member-access */

    return arg as ScheduleNotificationsResponse;
}

function createInvalidECGError(
    failingTimestamp: number
): Error & {name: 'InvalidECGError'; failingTS: number} {
    const err = new Error() as Error & {name: 'InvalidECGError'; failingTS: number};
    err.failingTS = failingTimestamp;
    err.name = 'InvalidECGError';
    return err;
}

class StaticHelper {
    static api: WindowAPI | undefined = window.webkit
        ? new IOSWindowAPI()
        : window.appInterface
        ? new AndroidWindowAPI()
        : undefined;
}

export default StaticHelper.api;
