import type {ImpactStudyVisit} from '../../../model/studies/StudyHelper';
import {IMPACT_VISIT} from '../../../model/studies/StudyHelper';
import type {SmilerStudyVisit} from '../../../model/studies/StudyHelper';
import i18n from '../../../i18n';

/**
 * Used to get the right audio exercise name depending on the visit identifier.
 * @param visitIdentifier - the checked visit.
 */
export function getAudioExerciseName(visitIdentifier: ImpactStudyVisit): string {
    switch (visitIdentifier) {
        case IMPACT_VISIT.Day_0:
            return i18n.t('studies:impact.bodyScan');
        case IMPACT_VISIT.Day_7:
            return i18n.t('studies:impact.yoga');
        case IMPACT_VISIT.Day_14:
            return i18n.t('studies:impact.meditation');
        case IMPACT_VISIT.Day_21:
            return i18n.t('studies:impact.meditative');
    }
}

/**
 * Used to verify if a visit is of type of ImpactStudyVisit.
 * @param visitIdentifier - the visit which is checked.
 */
export function verifyImpactVisit(
    visitIdentifier: SmilerStudyVisit | ImpactStudyVisit
): ImpactStudyVisit | undefined {
    switch (visitIdentifier) {
        case IMPACT_VISIT.Day_0:
            return IMPACT_VISIT.Day_0;
        case IMPACT_VISIT.Day_7:
            return IMPACT_VISIT.Day_7;
        case IMPACT_VISIT.Day_14:
            return IMPACT_VISIT.Day_14;
        case IMPACT_VISIT.Day_21:
            return IMPACT_VISIT.Day_21;
        default:
            return undefined;
    }
}
