/*
This is a hack because adding one.smiler.replicant as a dependency will breack our build.
It runs out of memory when calling yarn build.
Remove it after the following ticket was resolved.
Ticket: https://refinio-corandus.myjetbrains.com/youtrack/issue/GLBL-180
 */

import {EventEmitter} from 'events';
import type {Recipe, Person} from 'one.core/lib/recipes';
import type {SHA256IdHash, SHA256Hash} from 'one.core/lib/util/type-checks';
import type {ChannelInfo} from 'one.models/lib/recipes/ChannelRecipes';

declare module '@OneObjectInterfaces' {
    export interface OneUnversionedObjectInterfaces {
        ReportAction: ReportAction;
    }

    export interface ReportAction {
        $type$: 'ReportAction';
        ownerIdHash: SHA256IdHash<Person>; // The channel owner to query
        wbcChannelInfoHash: SHA256Hash<ChannelInfo>;
        ecgChannelInfoHash: SHA256Hash<ChannelInfo>;
        /*
        Expected to be report_<patientIdHash>_<DD.MM.YYYY>.pdf
         */
        filename?: string;
    }
}

const Report: Recipe = {
    $type$: 'Recipe',
    name: 'ReportAction',
    rule: [
        {
            itemprop: 'ownerIdHash',
            valueType: 'string'
        },
        {
            itemprop: 'wbcChannelInfoHash',
            referenceToObj: new Set(['ChannelInfo'])
        },
        {
            itemprop: 'ecgChannelInfoHash',
            referenceToObj: new Set(['ChannelInfo'])
        },
        {
            itemprop: 'filename',
            valueType: 'string',
            optional: true
        }
    ]
};

export const ReportRecipes: Recipe[] = [Report];

export class ReportModel extends EventEmitter {
    public static readonly actionChannelId = 'ReportAction';
}
