import {useEffect, useState} from 'react';
import type {ReactElement} from 'react';
import {CircularProgress} from '@material-ui/core';
import MenuButton from '../menu/MenuButton';
import i18n from '../../i18n';
import {
    changeBackgroundColorToWhite,
    displayCircularProgress,
    hideCircularProgress,
    removeWhiteBackgroundColor,
    setTimeToEndOfTheDay,
    setTimeToStartOfTheDay
} from '../utils/Utils';
import GenericGraph from '../graphComponent/GenericGraph';
import type SmilerWorkflow from '../../model/studies/SmilerWorkflow';
import type WBCDataParser from '../../model/WBCDataParser';
import type {WBCData} from '../../model/WBCDataParser';
import {useSmilerStudyData} from '../modelHelper/StudyWorkflowHelper';
import './Graph.css';
import {SMILER_VISIT} from '../../model/studies/StudyHelper';

/**
 * This function is used to get the wbc properties in the right format for using them to build the graphs.
 * @param {WBCDataParser} wbcDataParser - the wbc data parser.
 * @returns {WBCData} an object containing arrays with data needed for the graph
 * for each property of the wbc data (currently there is only the leukocytes array).
 */
function useWbcData(wbcDataParser: WBCDataParser): WBCData | undefined {
    const [wbcData, setWBCData] = useState<WBCData | undefined>();

    useEffect(() => {
        function fetchWBCData(): void {
            wbcDataParser
                .getWbcProperties()
                .then(result => {
                    setWBCData(result);
                })
                .catch(e => console.error(e));
        }
        fetchWBCData();
        return wbcDataParser.onUpdated(fetchWBCData);
    }, [wbcDataParser]);

    return wbcData;
}

/**
 * This is the wbc graph view.
 * @param props
 * @param props.wbcDataParser
 * @param props.smilerWorkflow
 */
export default function Graph(props: {
    wbcDataParser: WBCDataParser;
    smilerWorkflow: SmilerWorkflow;
}): ReactElement {
    // getting the wbc data in the needed format for building the graphs
    const wbcData = useWbcData(props.wbcDataParser);

    // getting the visits dates -> are needed for the y axis
    const visitsProperties = useSmilerStudyData(props.smilerWorkflow).visitsProperties;

    // x axis properties for the leukocytes and neutrophile graph
    const xAxisProperty = {
        firstTick: setTimeToStartOfTheDay(visitsProperties[SMILER_VISIT.Day_0].visitDate).getTime(),
        lastTick: setTimeToEndOfTheDay(visitsProperties[SMILER_VISIT.Day_28].visitDate).getTime(),
        tickStep: SMILER_VISIT.Day_28 - SMILER_VISIT.Day_14
    };

    // y axis properties for the leukocytes and neutrophile graph
    const yAxisProperty = {
        firstTick: 0,
        lastTick: 15,
        tickLabelStep: 5,
        reference: {start: 5, end: 10}
    };

    useEffect(() => {
        if (wbcData === undefined) {
            displayCircularProgress();
        } else {
            hideCircularProgress();
        }
    }, [wbcData]);

    /**
     * Effect for changing the background color to white
     * when the component it's rendered and also to
     * remove it when the component is unmounted.
     */
    useEffect(() => {
        changeBackgroundColorToWhite();

        return () => {
            removeWhiteBackgroundColor();
        };
    }, []);

    return (
        <>
            <div className="circular-progress-container">
                <CircularProgress className="circular-progress" size={35} />
            </div>

            <div className="page-container hide">
                <div className="menu-button-header">
                    <MenuButton />
                    <h2 className="headline headline-color"> {i18n.t('common:menu.wbcGraph')}</h2>
                </div>
                {wbcData !== undefined && (
                    <>
                        <GenericGraph
                            graphTitle={i18n.t('wbcMeasurement:wbcGraph.leukocytes')}
                            xAxisProperty={xAxisProperty}
                            yAxisProperty={yAxisProperty}
                            data={wbcData.leukocytes}
                            measurementUnit={'(10^9/L)'}
                        />
                        <div className="last-graph">
                            <GenericGraph
                                graphTitle={i18n.t('wbcMeasurement:wbcGraph.neutrophile')}
                                xAxisProperty={xAxisProperty}
                                yAxisProperty={yAxisProperty}
                                data={wbcData.neutrophils}
                                measurementUnit={'(10^9/L)'}
                            />
                        </div>
                    </>
                )}
            </div>
        </>
    );
}
