import type {ReactElement} from 'react';
import './Questionnaire.css';
import i18n from '../../i18n';

/**
 * @returns {ReactElement} - introduction page of the EQ-5D-3L.
 */
export default function InformativePage(): ReactElement {
    return (
        <div>
            <p className="text-survey-welcome">{i18n.t('eq5:informativePage')}</p>
        </div>
    );
}
