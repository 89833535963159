import type BloodGlucoseModel from 'one.models/lib/models/BloodGlucoseModel';
import windowAPI, {WindowAPI} from './WebViewHelper';
import type {BloodGlucose} from 'one.models/lib/recipes/BloodGlucoseRecipes';

/**
 * The class offers the synchronization features for Blood Glucose data between the native part (iOS/Android)
 * and JS, through the WebView.
 */
export class BloodGlucoseDataSynchronizer {
    private static readonly TIMEOUT_PERIOD = 5 * 1000;

    private bloodGlucoseModel: BloodGlucoseModel;

    /**
     * Number of BloodGlucose samples to be fetched from native at a time.
     * @private
     */
    private numberOfSamples = 100;

    /**
     * Flag specifying if synchronization is already in progress.
     * @private
     */
    private isSyncing = false;

    private startSyncWithNativeCalled = false;

    private retryOnErrorCounter = 0;

    private retryTimeout: number | undefined;

    private bloodGlucoseWithTSfailed: number | undefined;

    private userStoppedSynchronization = false;

    private readonly syncMethod: () => void;

    public constructor(bgModel: BloodGlucoseModel) {
        this.bloodGlucoseModel = bgModel;

        this.syncMethod = () => {
            /**
             * 'void' is used because we don't want to wait for the promise
             */
            void this.syncRemainingBloodGlucoseReadings();
        };
    }

    public isBloodGlucoseSynchronizationAvailable(): boolean {
        return windowAPI !== undefined;
    }

    /**
     * Starts the synchronisation mechanism. Blood glucose samples will be fetched from the native side and posted
     * into the channel.
     */
    public startBloodGlucoseSynchronizationWithNative(): void {
        if (this.startSyncWithNativeCalled) {
            throw new Error('startBloodGlucoseSynchronizationWithNative has already been called');
        }

        if (windowAPI === undefined) {
            throw new Error('WindowAPI is not available');
        }

        this.userStoppedSynchronization = false;

        windowAPI.log(
            'startBloodGlucoseSynchronizationWithNative preparing to sync',
            WindowAPI.LOG_LEVELS.INFO
        );
        void this.syncRemainingBloodGlucoseReadings();

        document.addEventListener('newBloodGlucoseData', this.syncMethod);
    }

    /**
     * This will stop the Blood GlucoseDataSynchronizer listening for new Blood Glucose events.
     * NOTE: The current sync in progress will continue.
     */
    public stopBloodGlucoseSynchronizationWithNative(): void {
        if (windowAPI !== undefined) {
            windowAPI.log('stopBloodGlucoseSynchronizationWithNative', WindowAPI.LOG_LEVELS.INFO);
        }

        document.removeEventListener('newBloodGlucoseData', this.syncMethod);

        if (this.retryTimeout !== undefined) {
            window.clearTimeout(this.retryTimeout);
        }

        this.userStoppedSynchronization = true;
        this.startSyncWithNativeCalled = false;
        this.isSyncing = false;
    }

    // ---------------------------- Utilities ----------------------------

    private async syncRemainingBloodGlucoseReadings(): Promise<void> {
        try {
            if (windowAPI === undefined) {
                return;
            }

            if (this.isSyncing) {
                // ignore, because the current sync will include the latest Blood Glucose
                windowAPI.log(
                    'Ignored sync event. Synchronization already in progress.',
                    WindowAPI.LOG_LEVELS.INFO
                );
                return;
            }

            this.isSyncing = true;

            let lastBGStartTime = await this.bloodGlucoseModel.getLastBloodGlucoseTimestamp();
            windowAPI.log(
                `Requesting samples after timestamp ${lastBGStartTime}`,
                WindowAPI.LOG_LEVELS.INFO
            );

            if (this.retryOnErrorCounter === 2 && this.bloodGlucoseWithTSfailed) {
                // failed 3 times for one Blood Glucose, move forward
                lastBGStartTime = this.bloodGlucoseWithTSfailed;
                this.retryOnErrorCounter = 0;

                // the failing bloodGlucose was skipped, get 10 samples at once again
                this.numberOfSamples = 100;
            }

            let bloodGlucoseSamples: BloodGlucose[] = [];

            try {
                bloodGlucoseSamples = await windowAPI.getBloodGlucoseLevels(
                    lastBGStartTime,
                    this.numberOfSamples
                );
            } catch (e) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                if (e.name !== 'InvalidECGError') {
                    // if it's not our customized error (because of in invalid ecg), throw it further
                    throw e;
                }

                this.bloodGlucoseWithTSfailed = e.failingTS;
                this.retryOnErrorCounter++;
                this.retryTimeout = window.setTimeout(
                    () => this.syncRemainingBloodGlucoseReadings(),
                    BloodGlucoseDataSynchronizer.TIMEOUT_PERIOD
                );

                /**
                 * one of the fetched Blood Glucose sample is invalid, but maybe not the exactly following
                 * one, so start fetching one by one (when it reaches the invalid one, it
                 * will fail to fetch it 3 times, therefore only the invalid Blood Glucose will be
                 * skipped)
                 */
                this.numberOfSamples = 1;
            }

            if (this.bloodGlucoseWithTSfailed && lastBGStartTime > this.bloodGlucoseWithTSfailed) {
                this.numberOfSamples = 100;
            }

            for await (const sample of bloodGlucoseSamples) {
                if (this.userStoppedSynchronization) {
                    this.isSyncing = false;
                    break;
                }

                try {
                    await this.bloodGlucoseModel.postBloodGlucose(sample);
                    windowAPI.log('The blood glucose sample was saved', WindowAPI.LOG_LEVELS.INFO);
                    this.retryOnErrorCounter = 0;
                } catch (err) {
                    windowAPI.log(
                        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                        'The blood glucose sample could not be saved. Start timestamp is = ' +
                            sample.startTimestamp,
                        WindowAPI.LOG_LEVELS.ERROR
                    );
                    windowAPI.log(
                        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                        'error is = ' + err,
                        WindowAPI.LOG_LEVELS.ERROR
                    );

                    this.retryOnErrorCounter++;

                    if (this.retryOnErrorCounter === 2 && this.bloodGlucoseWithTSfailed) {
                        this.bloodGlucoseWithTSfailed = sample.startTimestamp;
                    }

                    this.retryTimeout = window.setTimeout(
                        () => this.syncRemainingBloodGlucoseReadings(),
                        BloodGlucoseDataSynchronizer.TIMEOUT_PERIOD
                    );
                }
            }

            if (bloodGlucoseSamples.length === this.numberOfSamples) {
                // Received same number of blood glucose samples as asked, check if more available
                this.isSyncing = false;
                await this.syncRemainingBloodGlucoseReadings();
            } else {
                this.isSyncing = false;
                windowAPI.log('Blood glucose data is up-to-date.', WindowAPI.LOG_LEVELS.INFO);
            }
        } catch (e) {
            console.error(e);
        }
    }
}
