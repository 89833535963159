import {useEffect, useState} from 'react';
import VersionedObjectsTable from './components/VersionedObjectsTableComponent';
import RecipesStable from 'one.models/lib/recipes/recipes-stable';
import RecipesExperimental from 'one.models/lib/recipes/recipes-experimental';
import {
    getAllVersionMapEntries,
    getObject,
    listAllIdHashes,
    onVersionedObj
} from 'one.core/lib/storage';
import type {VersionMapEntry} from 'one.core/lib/storage';
import type {AnyObject} from 'one.core/lib/util/object';
import type {OneVersionedObjectTypes, Recipe, Person} from 'one.core/lib/recipes';
import {CORE_RECIPES} from 'one.core/lib/recipes';
import type {SHA256IdHash} from 'one.core/lib/util/type-checks';
import MenuButton from '../menu/MenuButton';
import i18n from '../../i18n';
import {
    computeSizeOfObject,
    getPersonsWithWhoThisObjectIsSharedWIth
} from './utils/VersionedObjectsDetails';
import {getInstanceOwnerIdHash} from 'one.core/lib/instance';
import {ReportRecipes} from '../../one.smiler.replicant-dependencies/Minimal-ReportModel';
import type {ReactElement} from 'react';

export interface VersionedObjectDetails {
    id: SHA256IdHash;
    obj: OneVersionedObjectTypes;
    type: string;
    versionCount: number;
    lastVersion: string;
    idFields: string;
    sizeOfObject: number;
    createdBy: SHA256IdHash<Person> | undefined;
    sharedWith: SHA256IdHash<Person>[];
    // todo should be SHA256IdHash<Person>
    sharedBy: string;
}

function useAllVersionedObjects(onError: (err: Error) => void): VersionedObjectDetails[] {
    const [objectList, setObjectList] = useState<VersionedObjectDetails[]>([]);

    useEffect(() => {
        // Fetch versioned object list from one instance
        async function fetchIdList(): Promise<void> {
            // Get all known id hashes
            const allRecipes = [
                ...CORE_RECIPES,
                ...RecipesStable,
                ...RecipesExperimental,
                ...ReportRecipes
            ];
            const hashList = await listAllIdHashes();
            const versionMaps = await Promise.all(
                hashList.map((idHash: SHA256IdHash) => getAllVersionMapEntries(idHash))
            );
            const latestObjects = await Promise.all(
                versionMaps.map((mapEntry: VersionMapEntry[]) => getObject(mapEntry[0].hash))
            );
            const instanceOwner = getInstanceOwnerIdHash();

            // Build Versioned Object for each id hash
            const versionedObjects: VersionedObjectDetails[] = [];

            for (let i = 0; i < hashList.length; ++i) {
                let str = '';
                const foundRecipesRules = allRecipes.find(
                    (recipe: Recipe) => recipe.name === latestObjects[i].$type$
                )?.rule;

                if (foundRecipesRules !== undefined) {
                    for (const property of foundRecipesRules) {
                        if (property.isId !== undefined) {
                            str += `${property.itemprop.toString()} = "${
                                (latestObjects[i] as AnyObject)[property.itemprop]
                            }" \n `;
                        }
                    }
                }

                versionedObjects.push({
                    id: hashList[i],
                    obj: latestObjects[i],
                    type: latestObjects[i].$type$,
                    versionCount: versionMaps[i].length,
                    idFields: str,
                    lastVersion: new Date(versionMaps[i][0].timestamp).toLocaleString(),
                    sizeOfObject: 0,
                    createdBy: instanceOwner,
                    sharedWith: [],
                    sharedBy: '-'
                });
            }

            for await (const versionedObject of versionedObjects) {
                versionedObject.sharedWith = await getPersonsWithWhoThisObjectIsSharedWIth(
                    versionedObject.id
                );

                versionedObject.sizeOfObject = await computeSizeOfObject(versionedObject.id);
            }
            setObjectList(versionedObjects);
        }

        function fetchVersionedObjects(): void {
            fetchIdList().catch((error: Error) =>
                onError(new Error(`Failed to fetch ids list. Reason: ${error.message}.`))
            );
        }

        // Listen on new versioned objects
        onVersionedObj.addListener(fetchVersionedObjects);
        fetchVersionedObjects();

        return () => onVersionedObj.removeListener(fetchVersionedObjects);
    }, [onError]);
    return objectList;
}

/**
 * This component builds the versioned object list
 * @returns {ReactElement} - All the versioned object list
 */
export function OneBrowserVersionedObjectsScreen(): ReactElement {
    const [error, setError] = useState<Error>();
    const idHashes = useAllVersionedObjects(setError);

    return (
        <>
            <div className="page-container menu-button-header">
                <MenuButton />
                <h2 className="headline">{i18n.t('common:settings.oneBrowser')}</h2>
            </div>
            <div className="table-div">
                {error?.message ? <>Error happened: {error.toString()}</> : <></>}
                <VersionedObjectsTable data={idHashes} />
            </div>
        </>
    );
}
