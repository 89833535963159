import {Alert, AlertTitle} from '@material-ui/lab';
// If we let import React from 'react' the next error will appear:
// "This import is never used as a value and must use 'import type' because 'importsNotUsedAsValues' is set to 'error'."
// If we do import type the next error will appear:
// "Type import "React" is used by decorator metadata"
// So ts-ignore is legit.

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import React from 'react';
import i18n from '../../../i18n';
import Paper from '@material-ui/core/Paper/Paper';
import '../../../Primary.css';
import './StyledAlert.css';

/**
 * List of message types
 */
export const MESSAGE_TYPE = {
    error: 'error',
    warning: 'warning',
    info: 'info',
    success: 'success'
} as const;

/**
 * This component displays an alert box with a message, which can be an error, a warning, an info or a success message.
 *
 * @param props - Properties of this view:
 * @param props.infoMessage - The content of the info message
 * @param props.displayMessage - Flag that specifies whether or not to display the information message box
 * @param props.setDisplayMessage - Setter for displayMessage flag
 * @param props.messageType - The message types {@link MESSAGE_TYPE}
 * @returns {React.ReactElement}
 */
export function StyledAlert(props: {
    infoMessage: string;
    displayMessage: boolean;
    setDisplayMessage: (arg: boolean) => void;
    messageType: typeof MESSAGE_TYPE[keyof typeof MESSAGE_TYPE];
}): React.ReactElement {
    return (
        <>
            {props.displayMessage && (
                <Paper
                    square
                    elevation={3}
                    className={`info-message-container ${
                        props.messageType === MESSAGE_TYPE.error
                            ? 'error-color'
                            : props.messageType === MESSAGE_TYPE.warning
                            ? 'warning-color'
                            : props.messageType === MESSAGE_TYPE.success
                            ? 'success-color'
                            : ''
                    }`}
                >
                    <Alert
                        severity={props.messageType}
                        onClose={() => {
                            props.setDisplayMessage(false);
                        }}
                    >
                        <AlertTitle className="info-message-title">
                            {i18n.t(
                                `errors:title${
                                    props.messageType[0].toUpperCase() + props.messageType.substr(1)
                                }`
                            )}
                        </AlertTitle>
                        {i18n.t(`${props.infoMessage}`)}
                    </Alert>
                </Paper>
            )}
        </>
    );
}
