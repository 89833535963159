import {useEffect, useState} from 'react';
import type {ReactElement} from 'react';
import {Fab} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import './MenuButton.css';
import '../../Primary.css';
import Select from '@material-ui/core/Select/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {PRODUCT_TYPE} from '../Ui';
import type {Product} from '../Ui';
import windowAPI from '../modelHelper/WebViewHelper';

let clickCallback: () => void;
let shiftClickCallback: (typeOfProduct?: Product) => void;

/**
 * Renders the toolbar of the menu
 * @param props
 * @param props.isMenuOpen
 * @param props.isHidden
 * @param props.clickCallback
 * @param props.shiftClickCallback
 * @returns
 */
export default function MenuButton(props: {
    isMenuOpen?: boolean;
    isHidden?: boolean;
    clickCallback?: () => void;
    shiftClickCallback?: (typeOfProduct?: Product) => void;
}): ReactElement {
    let isPageFromFooter = false;

    const [isProductTypeDisplayed, setProductTypeDisplayed] = useState(false);

    if (props.clickCallback !== undefined) {
        clickCallback = props.clickCallback;
    }

    if (props.shiftClickCallback !== undefined) {
        shiftClickCallback = props.shiftClickCallback;
    }

    if (
        window.location.pathname === '/impressum' ||
        window.location.pathname === '/privacyPolicy'
    ) {
        isPageFromFooter = true;
    }

    function displayProductTypesList(): void {
        setProductTypeDisplayed(true);
    }

    function handleChange(typeOfProduct: Product): void {
        shiftClickCallback(typeOfProduct);
        setProductTypeDisplayed(false);
    }

    function useLongPress(
        callback = displayProductTypesList,
        ms = 500
    ): {
        onMouseDown: () => void;
        onMouseUp: () => void;
        onMouseLeave: () => void;
        onTouchStart: () => void;
        onTouchEnd: () => void;
    } {
        const [startLongPress, setStartLongPress] = useState(false);

        useEffect(() => {
            let timerId: ReturnType<typeof setTimeout>;

            if (startLongPress) {
                timerId = setTimeout(callback, ms);
            }

            return () => {
                clearTimeout(timerId);
            };
        }, [callback, ms, startLongPress]);

        // Disable long press product switch on the iOS native app
        const isNativeApp = windowAPI !== undefined;

        return {
            onMouseDown: () => setStartLongPress(!isNativeApp),
            onMouseUp: () => setStartLongPress(false),
            onMouseLeave: () => setStartLongPress(false),
            onTouchStart: () => setStartLongPress(!isNativeApp),
            onTouchEnd: () => setStartLongPress(false)
        };
    }

    const longPress = useLongPress(displayProductTypesList, 5000);

    return (
        <>
            {!props.isHidden && !props.isMenuOpen && !isPageFromFooter && (
                <div className="button-menu">
                    <Fab
                        size="small"
                        color="primary"
                        className="fab-wrapper"
                        onClick={event => {
                            if (event.shiftKey) {
                                shiftClickCallback();
                            } else {
                                clickCallback();
                            }
                        }}
                        {...longPress}
                    >
                        <MenuIcon />
                    </Fab>
                </div>
            )}
            {isProductTypeDisplayed && (
                <div className="button-menu">
                    <Select
                        disableUnderline
                        open={isProductTypeDisplayed}
                        defaultValue={PRODUCT_TYPE.Smiler}
                    >
                        {Object.values(PRODUCT_TYPE).map(key => (
                            <MenuItem
                                key={key}
                                className="select-item"
                                value={key}
                                onClick={() => handleChange(key)}
                            >
                                {key}
                            </MenuItem>
                        ))}
                    </Select>
                </div>
            )}
        </>
    );
}
