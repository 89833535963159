import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import Ui from './ui/Ui';
import Model from './model/Model';

import {createTheme, ThemeProvider} from '@material-ui/core/styles';
import {generateDynamicManifest} from './ui/utils/Utils';

const theme = createTheme({
    palette: {
        primary: {
            light: '#1DAAE1',
            main: '#2A5FA6',
            contrastText: '#ffffff'
        }
    },
    typography: {
        fontFamily: 'Arial, monospace'
    }
});

/**
 * Renders the main application.
 */
function renderApplicationUI(): void {
    // He have our own configuration script and it's not recognised by the window type
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const commServerUrl = window.Configs.COMMSERVER_URL as string;

    // Instantiate the models
    const model = new Model(commServerUrl);

    // Render the Ui
    ReactDOM.render(
        <ThemeProvider theme={theme}>
            <Ui model={model} settings={model.settings.getChild('ui')} />
        </ThemeProvider>,
        document.getElementById('root')
    );
}

void generateDynamicManifest();

// Just keep this unhandled, so when initialization fails we just get the unhandled rejection
// message that displays nice errors (at least for developers!)
void renderApplicationUI();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
