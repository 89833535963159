import type {ReactElement} from 'react';
import {
    Paper,
    DialogContent,
    List,
    ListItem,
    DialogTitle,
    Divider,
    Button
} from '@material-ui/core';
import {getInstanceOwnerIdHash} from 'one.core/lib/instance';
import type {ObjectData} from 'one.models/lib/models/ChannelManager';
import type JournalModel from 'one.models/lib/models/JournalModel';
import type {Person} from 'one.core/lib/recipes';
import type {SHA256IdHash} from 'one.core/lib/util/type-checks';
import type {DiaryEntry} from 'one.models/lib/recipes/DiaryRecipes';
import {useHistory, useParams} from 'react-router-dom';
import './ShareDetailsDialog.css';
import {Icon} from '../icon/Icon';
import i18n from '../../i18n';
import {useEffect, useState} from 'react';

type ShareDetails = {
    sharedWith: SHA256IdHash<Person>[];
    author: string;
};

function useShareDetails(journalModel: JournalModel, entryId: string): ShareDetails {
    const [responses, setResponses] = useState<ShareDetails>({sharedWith: [], author: ''});

    useEffect(() => {
        function fetchAccessDetails(): void {
            journalModel
                .retrieveAllEvents()
                .then(values => {
                    const sharedWith = values.find(
                        value => (value.data as ObjectData<DiaryEntry>).id === entryId
                    );

                    if (sharedWith) {
                        setResponses({
                            sharedWith: (sharedWith.data as ObjectData<DiaryEntry>).sharedWith,
                            author: (sharedWith.data as ObjectData<DiaryEntry>).author
                        });
                    }
                })
                .catch(err => {
                    console.error('Error: ', err);
                });
        }

        // TODO to be updated when JournalModel is also updated to the new EventMechanism (OEvent)
        journalModel.on('updated', fetchAccessDetails);
        fetchAccessDetails();

        return () => {
            journalModel.removeListener('updated', fetchAccessDetails);
        };
    }, [entryId, journalModel]);

    return responses;
}

/**
 * @param {{}}props
 * @param {boolean} props.sharedByWhom
 * @param {JournalModel} props.journalModel
 * @returns {ReactElement}
 */
export default function SharedDetailsDialog(props: {
    sharedByWhom: boolean;
    journalModel: JournalModel;
}): ReactElement {
    const [ownerId, setOwnerId] = useState<string | undefined>('');

    const {entryId} = useParams<{entryId: string}>();

    const personsWithWhomIsSharedList = useShareDetails(props.journalModel, entryId);

    const history = useHistory();

    useEffect(() => {
        // todo: replace call to core function with a getter from a smiler model
        setOwnerId(getInstanceOwnerIdHash());
    }, []);

    return (
        <Paper>
            <DialogTitle className="titleShareDetails">
                {props.sharedByWhom
                    ? i18n.t('common:shareDetails.sharedWith')
                    : i18n.t('common:shareDetails.ownedBy')}
                <Button
                    onClick={() => {
                        history.push('/journal');
                    }}
                >
                    <Icon name="Close" />
                </Button>
            </DialogTitle>
            <Divider />
            <DialogContent>
                {props.sharedByWhom ? (
                    <List>
                        {personsWithWhomIsSharedList.sharedWith.map((value, index) => {
                            if (value === ownerId) {
                                return (
                                    <ListItem key={'yourown'}>
                                        {i18n.t('common:shareDetails.sharedWithYourSelf')}
                                    </ListItem>
                                );
                            } else {
                                return <ListItem key={`person_${index}`}> {value} </ListItem>;
                            }
                        })}
                    </List>
                ) : (
                    <List>
                        <ListItem>
                            {personsWithWhomIsSharedList.author === ownerId ? (
                                <> {i18n.t('common:shareDetails.yourOwn')} </>
                            ) : (
                                <> {personsWithWhomIsSharedList.author} </>
                            )}
                        </ListItem>
                    </List>
                )}
            </DialogContent>
        </Paper>
    );
}
