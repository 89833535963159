import type {ReactElement} from 'react';
import type AudioExerciseModel from 'one.models/lib/models/AudioExerciseModel';
import type {AudioProperties} from './DashboardCommon';
import type {ImpactStudyVisit} from '../../../model/studies/StudyHelper';
import {IMPACT_VISIT} from '../../../model/studies/StudyHelper';
import type {SmilerStudyVisit} from '../../../model/studies/StudyHelper';
import audio_visit_1 from '../../../resources/audio/Bodyscan.m4a';
import i18n from '../../../i18n';
import audio_visit_2 from '../../../resources/audio/Yoga im Stehen.m4a';
import audio_visit_3 from '../../../resources/audio/Atemmeditation.m4a';
import audio_visit_4 from '../../../resources/audio/Meditatives Gehen.m4a';

/**
 * The audio player that is displayed in the popup when the user wants to listen an audio file.
 * @param props
 * @param props.audioExerciseModel - the audio exercise model which store an object whenever the play button is clicked.
 * @param props.audioFile - the audio file.
 * @param props.fileName - the name of the audio file.
 */
export default function AudioPlayer(props: {
    audioExerciseModel: AudioExerciseModel | undefined;
    audioFile: string;
    fileName: string;
}): ReactElement {
    if (props.audioExerciseModel === undefined) {
        return <></>;
    }

    return (
        <audio
            controls
            src={props.audioFile}
            onPlay={async () => {
                props.audioExerciseModel &&
                    (await props.audioExerciseModel.addAudioExercise(props.fileName, Date.now()));
            }}
            className="audio-player"
        />
    );
}

/**
 * Trigger specific action depending on what task was clicked.
 * @param closeEntryViewCallback
 * @param setContent
 * @param setTitle
 * @param audioExerciseModel
 * @param visit
 */
export function playAudioFile(
    closeEntryViewCallback: (value: boolean) => void,
    setContent: (content: ReactElement) => void,
    setTitle: (title: string) => void,
    audioExerciseModel: AudioExerciseModel,
    visit?: ImpactStudyVisit | undefined
): void {
    const audioFileProperties = loadAudioFile(visit);

    if (audioFileProperties.audio !== undefined) {
        closeEntryViewCallback(true);
        setTitle(audioFileProperties.title);
        setContent(
            <AudioPlayer
                audioExerciseModel={audioExerciseModel}
                audioFile={audioFileProperties.audioFile}
                fileName={audioFileProperties.fileName}
            />
        );
    }
}

/**
 * Building up the audio file properties that are needed when the user wants to listen au audio file.
 * @param currentVisit - depending on the current visit the audio file that is loaded differs.
 */
export function loadAudioFile(
    currentVisit: SmilerStudyVisit | ImpactStudyVisit | undefined
): AudioProperties {
    switch (currentVisit) {
        case IMPACT_VISIT.Day_0:
            return {
                fileName: 'Bodyscan',
                audio: new Audio(audio_visit_1),
                audioFile: audio_visit_1,
                title:
                    i18n.t('studies:stepAction.audio') +
                    ', ' +
                    i18n.t('studies:impact.title') +
                    ': ' +
                    i18n.t('studies:impact.bodyScan')
            };

        case IMPACT_VISIT.Day_7:
            return {
                fileName: 'Yoga im Stehen',
                audio: new Audio(audio_visit_2),
                audioFile: audio_visit_2,
                title:
                    i18n.t('studies:stepAction.audio') +
                    ', ' +
                    i18n.t('studies:impact.title') +
                    ': ' +
                    i18n.t('studies:impact.yoga')
            };

        case IMPACT_VISIT.Day_14:
            return {
                fileName: 'Atemmeditation',
                audio: new Audio(audio_visit_3),
                audioFile: audio_visit_3,
                title:
                    i18n.t('studies:stepAction.audio') +
                    ', ' +
                    i18n.t('studies:impact.title') +
                    ': ' +
                    i18n.t('studies:impact.meditation')
            };
        case IMPACT_VISIT.Day_21:
        default:
            return {
                fileName: 'Meditatives Gehen',
                audio: new Audio(audio_visit_4),
                audioFile: audio_visit_4,
                title:
                    i18n.t('studies:stepAction.audio') +
                    ', ' +
                    i18n.t('studies:impact.title') +
                    ': ' +
                    i18n.t('studies:impact.meditative')
            };
    }
}
