import type {ReactElement} from 'react';
import {Document, Page} from 'react-pdf';
import type {PDFDocumentProxy} from 'pdfjs-dist';
import {CircularProgress} from '@material-ui/core';
import {useWindowSize} from '../utils/Utils';
import './FileViewer.css';
import {useState} from 'react';

/**
 * @param props
 * @param props.pdfURL
 * @param props.onLoadError
 * @param props.onLoadSuccess
 */
export default function ViewPDFComponent(props: {
    pdfURL: string;
    onLoadError: (errorName: string) => void;
    onLoadSuccess?: () => void;
}): ReactElement {
    const [numberOfPages, setNumberOfPages] = useState<number | null>(null);
    const [width] = useWindowSize();

    /**
     *
     */
    function loadingCircle(): ReactElement {
        return (
            <div className="circular-progress-container">
                <CircularProgress className="circular-progress" size={35} />
            </div>
        );
    }

    return (
        <div className="page-content-box remove-padding pdf-container">
            <Document
                file={props.pdfURL}
                onLoadSuccess={(pdf: PDFDocumentProxy) => {
                    setNumberOfPages(pdf.numPages);

                    if (props.onLoadSuccess) {
                        props.onLoadSuccess();
                    }
                }}
                loading={loadingCircle}
                onLoadError={(err: Error) => {
                    props.onLoadError(err.name);
                }}
                error={''}
                className="document"
            >
                {Array.from(new Array(numberOfPages), (_, index) => (
                    <Page
                        key={`page_${index + 1}`}
                        pageNumber={index + 1}
                        loading={loadingCircle}
                        scale={width < 480 ? 0.5 : 1}
                    />
                ))}
            </Document>
        </div>
    );
}
