import windowAPI from './WebViewHelper';
import download from 'downloadjs';

/**
 * Downloads the given file as a parameter, taking into consideration if the app runs in a
 * webview or not.
 * @param file - file to be downloaded
 * @param filename -  the name of the file to be downloaded
 * @param contentType - the content type
 */
export async function downloadFile(
    file: Blob | Uint8Array | string,
    filename: string,
    contentType: string
): Promise<void> {
    if (windowAPI) {
        if (file instanceof Uint8Array) {
            windowAPI.sendPDF(arrayBufferToBase64(file), filename, contentType);
        } else if (file instanceof Blob) {
            const base = await blobToBase64(file);
            windowAPI.sendPDF(base, filename, contentType);
        } else {
            const decoded = decodeURIComponent(file);
            const base64Information = btoa(decoded);
            windowAPI.sendPDF(base64Information, filename, contentType);
        }
    } else {
        download(file, filename, contentType);
    }
}

/**
 *  Convert blob to base64 encoded string.
 * @param blob
 */
function blobToBase64(blob: Blob): Promise<string> {
    const reader = new FileReader();

    reader.onloadend = function () {
        return reader.result;
    };

    reader.readAsDataURL(blob);

    return new Promise(resolve => {
        reader.onloadend = () => {
            const base64 = reader.result as string;
            resolve(base64.substr(base64.indexOf(',') + 1));
        };
    });
}

/**
 * Convert Uint8Array to base64 encoded string
 * @param buffer
 */
function arrayBufferToBase64(buffer: Uint8Array): string {
    let binary = '';
    const bytes = new Uint8Array(buffer);

    const len = bytes.byteLength;

    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }

    return window.btoa(binary);
}
