import {useEffect, useState} from 'react';
import type OneInstanceModel from '../../model/OneInstanceModel';
import type {Authentication} from '../../model/OneInstanceModel';

/**
 * Provides the current authentication state from the OneIntsnaceModel
 *
 * @param oneInstanceModel - The one instance model
 * @returns - the current authentication state
 */
export function useAuthenticationState(
    oneInstanceModel: OneInstanceModel
): Authentication | undefined {
    const [authenticationState, setAuthenticationState] = useState<Authentication>();

    useEffect(() => {
        function fetchCurrentAuthenticationState(): void {
            setAuthenticationState(oneInstanceModel.authenticationState());
        }

        const disconnect = oneInstanceModel.onAuthStateChange(fetchCurrentAuthenticationState);
        fetchCurrentAuthenticationState();

        return disconnect;
    }, [oneInstanceModel]);

    return authenticationState;
}

/**
 * Provides the current registration state from the OneInstanceModel
 *
 * @param {OneInstanceModel} oneInstanceModel
 * @returns {boolean}
 */
export function useRegistrationState(oneInstanceModel: OneInstanceModel): boolean {
    const [registrationState, setRegistrationState] = useState(false);

    useEffect(() => {
        function fetchCurrentRegistrationState(): void {
            setRegistrationState(oneInstanceModel.registrationState());
        }

        const disconnect = oneInstanceModel.onRegistrationStateChange(
            fetchCurrentRegistrationState
        );
        fetchCurrentRegistrationState();

        return disconnect;
    }, [oneInstanceModel]);

    return registrationState;
}
