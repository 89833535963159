import type {EffectCallback} from 'react';
import React, {useCallback, useEffect, useState} from 'react';
import {useLocation, useHistory, useParams} from 'react-router-dom';
import type QuestionnaireModel from 'one.models/lib/models/QuestionnaireModel';
import type {Questionnaire, Question} from 'one.models/lib/models/QuestionnaireModel';
import type {Mark} from '@material-ui/core';
import {
    Button,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    List,
    Paper,
    Radio,
    RadioGroup,
    Slider,
    TextField
} from '@material-ui/core';
import i18n from '../../i18n';
import Autocomplete from '@material-ui/lab/Autocomplete';
import StringQuestion from './StringQuestion';
import {hideCircularProgress} from '../utils/Utils';

import type {SHA256IdHash} from 'one.core/lib/util/type-checks';
import {isHash} from 'one.core/lib/util/type-checks';
import type {ObjectData} from 'one.models/lib/models/ChannelManager';
import '../../Primary.css';
import './Questionnaire.css';
import type {AnyObject} from 'one.core/lib/util/object';
import type {Person} from 'one.core/lib/recipes';
import type {
    QuestionnaireResponse,
    QuestionnaireResponses
} from 'one.models/lib/recipes/QuestionnaireRecipes/QuestionnaireResponseRecipes';
import type {QuestionnaireResponseBuilderItem} from './QuestionnaireResponseBuilder';
import QuestionnaireResponseBuilder, {
    QuestionnaireResponseBuilderValidationErrorCode
} from './QuestionnaireResponseBuilder';
import {useForceRender} from '../modelHelper/useForceRender';
import {MESSAGE_TYPE, StyledAlert} from './components/StyledAlert';

const TRUE = 'true';
const FALSE = 'false';

/**
 * Compares if two arrays are equal by checking equality of the first level of items.
 *
 * @param a - first array
 * @param b - second array
 */
function arraysEqual(a: any[], b: any[]): boolean {
    return a.length === b.length && a.every((val, index) => val === b[index]);
}

/**
 * Gets an array and returns the same array.
 * The returned array will have the same instance over multiple renders, if the content
 * does not change.
 *
 * @param arr
 */
function useArray<T>(arr: T[]): T[] {
    const [retArray, setRetArray] = useState(arr);
    useEffect(() => {
        if (!arraysEqual(retArray, arr)) {
            // It is okay to set the state inside useEffect, because effects are executed after the render has finished
            setRetArray(arr);
        }
    }, [retArray, arr]);
    return retArray;
}

/**
 * Gets an array or an undefined and returns the same array or undefined.
 * The returned array will have the same instance over multiple renders, if the content does not change.
 * @param arr
 */
function useArrayUndefined<T>(arr: T[] | undefined): T[] | undefined {
    const [retArray, setRetArray] = useState(arr);

    useEffect(() => {
        if (!arr || !retArray) {
            if (arr !== retArray) {
                setRetArray(arr);
            }
        } else if (!arraysEqual(retArray, arr)) {
            // It is okay to set the state inside useEffect, because effects are
            // executed after the render has finished.
            setRetArray(arr);
        }
    }, [retArray, arr]);

    return retArray;
}

/**
 * Exactly as useQuestionnairesByName but it returns QuestionnaireResponseBuilder, instead
 * of the questionnaires.
 *
 * @param questionnaires
 * @param questionnaireResponses
 * @param updatedCallback
 */
function useQuestionnairesBuilders(
    questionnaires: Questionnaire[],
    questionnaireResponses: Map<string, QuestionnaireResponse>,
    updatedCallback: () => void
): Map<string, QuestionnaireResponseBuilder> {
    const [builders, setBuilders] = useState(new Map<string, QuestionnaireResponseBuilder>());

    useEffect(() => {
        // Build the questionnaire response builders
        const map = new Map<string, QuestionnaireResponseBuilder>();

        for (const questionnaire of questionnaires) {
            if (questionnaire.name) {
                map.set(
                    questionnaire.name,
                    new QuestionnaireResponseBuilder(
                        questionnaire,
                        questionnaireResponses.get(questionnaire.name)
                    )
                );
            }
        }
        setBuilders(map);

        // Register the events
        for (const value of map.values()) {
            value.on('updated', updatedCallback);
        }

        // Deregister event handlers
        return () => {
            for (const value of map.values()) {
                value.removeListener('updated', updatedCallback);
            }
        };
    }, [questionnaireResponses, questionnaires, updatedCallback]);

    return builders;
}

interface StateInterface {
    next(): void;
    prev(): void;
    set(state: string): void;
}

/**
 * This implementation manages states that can be stepped through or set.
 *
 * If the content of the states array changes, the state will be reset to the
 * initial state again.
 *
 * @param states
 * @param initialState
 */
function useStateList(states: string[], initialState: string): [string, StateInterface] {
    // Introduce a state for selecting questionnaires
    const [currentState, setState] = useState(initialState);
    const [statesInternal, setStatesInternal] = useState(states);

    function next(count: number = 1): void {
        const newIndex = states.findIndex(state => state === currentState) + count;

        if (states.length === 0) {
            set(initialState);
        } else if (newIndex < states.length) {
            set(states[newIndex]);
        } else {
            set(states[0]);
        }
    }

    function prev(count: number = 1): void {
        const newIndex = states.findIndex(state => state === currentState) - count;

        if (states.length === 0) {
            set(initialState);
        } else if (newIndex >= 0) {
            set(states[newIndex]);
        } else {
            set(states[states.length - 1]);
        }
    }

    function set(newState: string): void {
        setState(newState);
    }

    // This effect will reset the state to initial when the state array content changes.
    // Note this will cause a rerender.
    useEffect(() => {
        if (!arraysEqual(statesInternal, states) && initialState !== currentState) {
            // It is okay to set the state inside useEffect, because effects are
            // executed after the render has finished.
            setStatesInternal(states);
            setState(initialState);
        }
    }, [statesInternal, states, initialState, currentState]);

    // Return the state and the manipulating functions.
    return [
        currentState,
        {
            next,
            prev,
            set
        }
    ];
}

/**
 * Specifies how incomplete responses are handled.
 */
const INCOMPLETE_MODE = {
    disabled: 0,
    loadIncomplete: 1,
    startNew: 2
} as const;

/** The type definition based on the INCOMPLETE_MODE values. **/
type IncompleteModeType = typeof INCOMPLETE_MODE[keyof typeof INCOMPLETE_MODE];

/**
 * Load the questionnaires and the response objects.
 *
 * This function has the following modes:
 * 1) Load an old response by response hash -> This is usually used for view mode.
 * The questionnaires are loaded from the questionnaire urls that are stored in the
 * responses
 * -> responseId must be set to the hash of the response object to load
 * => questionnaireNames will be ignored
 * => language will be ignored
 * 2) Load questionnaires based on a parameter.
 * -> questionnaireNames must be a list of questionnaire names
 * -> (optional) language must be the selected language
 * -> responseId must be undefined - otherwise it will be mode 1)
 * 3) Load questionnaire based on a parameter but also keep in sync with an incomplete channel
 * -> questionnaireNames must be a list of questionnaire names
 * -> (optional) language must be the selected language
 * -> responseId must be undefined - otherwise it will be mode 1)
 * -> loadIncomplete must be true
 * @param questionnaireModel
 * @param questionnaireNames
 * @param language
 * @param responseId
 * @param incompleteMode
 * @param incompleteType
 */
async function loadQuestionnairesAndResponses(
    questionnaireModel: QuestionnaireModel,
    questionnaireNames: string[] | undefined,
    language: string | undefined,
    responseId: string | undefined,
    incompleteMode: IncompleteModeType,
    incompleteType: string
): Promise<[Map<string, Questionnaire>, Map<string, QuestionnaireResponse>, string]> {
    const questionnaireUrls: string[] = [];
    const responses: QuestionnaireResponse[] = [];
    let incompleteResponseHash: string = '';

    // Load The questionnaire URLs and possibly load the response
    if (responseId) {
        // Load the responses
        const responsesObject = await questionnaireModel.responsesById(responseId);

        // Get the questionnaire urls
        for (const response of responsesObject.data.response) {
            if (response.questionnaire) {
                questionnaireUrls.push(response.questionnaire);
                responses.push(response);
            }
        }
    } else if (questionnaireNames) {
        // Load the urls from the questionnaire model by name and language
        questionnaireUrls.push(
            ...(await Promise.all(
                questionnaireNames.map(name =>
                    questionnaireModel.questionnaireUrlByName(name, language)
                )
            ))
        );

        if (incompleteMode === INCOMPLETE_MODE.loadIncomplete) {
            const incompleteResponse = await questionnaireModel.incompleteResponse(incompleteType);

            if (incompleteResponse) {
                incompleteResponseHash = incompleteResponse.dataHash;
                responses.push(...incompleteResponse.data.response);
            }
        }

        if (incompleteMode === INCOMPLETE_MODE.startNew) {
            await questionnaireModel.markIncompleteResponseAsComplete(incompleteType);
        }
    }

    // Load the questionnaires
    const questionnaires = await Promise.all(
        questionnaireUrls.map(url => questionnaireModel.questionnaireByUrl(url))
    );

    // Transform everything to maps
    const questionnaireMap = new Map<string, Questionnaire>();
    const responseMap = new Map<string, QuestionnaireResponse>();

    for (const questionnaire of questionnaires) {
        if (!questionnaire.name) {
            continue;
        }

        // Set the questionnaire in the questionnaire map
        questionnaireMap.set(questionnaire.name, questionnaire);

        // Set the response in the response map
        const response = responses.find(r => r.questionnaire === questionnaire.url);

        if (response) {
            responseMap.set(questionnaire.name, response);
        }
    }

    return [questionnaireMap, responseMap, incompleteResponseHash];
}

/**
 * This function loads questionnaires based on either the passed questionnaire names and language, or
 * based on the response. The questionnaireNames take precedence.
 * @param questionnaireModel
 * @param questionnaireNames
 * @param language
 * @param responseId
 * @param incompleteMode
 * @param setError
 */
function useQuestionnairesAndResponses(
    questionnaireModel: QuestionnaireModel,
    questionnaireNames: string[] | undefined,
    language: string | undefined,
    responseId: string | undefined,
    incompleteMode: IncompleteModeType,
    setError: (err: string) => void
): [Map<string, Questionnaire>, Map<string, QuestionnaireResponse>] {
    const [ret, setRet] = useState<
        [Map<string, Questionnaire>, Map<string, QuestionnaireResponse>]
    >([new Map<string, Questionnaire>(), new Map<string, QuestionnaireResponse>()]);

    // Make questionnaire names only change when the content changes.
    const stableQuestionnaireNames = useArrayUndefined(questionnaireNames);

    // Load everything
    useEffect((): ReturnType<EffectCallback> => {
        const incompleteType = stableQuestionnaireNames ? stableQuestionnaireNames.join(',') : '';
        let incompleteResponseHash: string = '';

        function fetch(): void {
            loadQuestionnairesAndResponses(
                questionnaireModel,
                stableQuestionnaireNames,
                language,
                responseId,
                incompleteMode,
                incompleteType
            )
                .then(qAndR => {
                    const [qMap, rMap, iHash] = qAndR;
                    incompleteResponseHash = iHash;
                    setRet([qMap, rMap]);
                })
                .catch((err: string) => setError(err));
        }

        function fetchForIncomplete(): void {
            questionnaireModel
                .incompleteResponse(incompleteType)
                .then((response: ObjectData<QuestionnaireResponses> | null) => {
                    // Fetch new data if
                    // - one of the old/new response is null
                    // - both are not null and the data hashes differ
                    if (incompleteResponseHash === '' && response !== null) {
                        fetch();
                    } else if (incompleteResponseHash !== '' && response === null) {
                        fetch();
                    } else if (incompleteResponseHash !== '' && response !== null) {
                        if (incompleteResponseHash !== response.dataHash) {
                            fetch();
                        }
                    }
                })
                .catch((err: string) => setError(err));
        }

        fetch();

        // If in any incomplete mode, listen for new incomplete responses
        // How do we know that a new incomplete response of the requested type
        // has happened? We would need to compare them before doing the fetch!
        if (incompleteMode !== INCOMPLETE_MODE.disabled) {
            // Fetch the questionnaires
            questionnaireModel.on('updatedIncomplete', fetchForIncomplete);

            return () => {
                questionnaireModel.removeListener('updatedIncomplete', fetchForIncomplete);
            };
        }
    }, [
        questionnaireModel,
        stableQuestionnaireNames,
        language,
        responseId,
        setError,
        incompleteMode
    ]);

    return ret;
}

/**
 * This component builds and returns the questionnaire view.
 *
 * @param props - Properties of this view:
 * @param props.questionnaireModel - the Questionnaire model
 * @param props.skipQuestionnaire - specifies whether a questionnaire should be skipped based on a given answer
 * @param props.skipQuestionnaire.nextQuestionnaire - specifies the next questionnaire, that needs to be displayed
 * @param props.skipQuestionnaire.previousQuestionnaire - specifies the previous questionnaire
 * @param props.skipQuestionnaire.decisiveAnswer - specifies the required answer for skipping
 * @param props.questionnaireName - specifies the questionnaire name
 * @param props.language - specifies the selected language.
 * @param props.action - specifies how the questionnaire will be displayed, the view mode can be: new, edit or view.
 * @param props.responseId - specifies the response id, which is used to complete a questionnaire in the view mode.
 * @param props.redirectAfterSubmit - specifies the function that needs to be called after the submit button is clicked.
 * @param props.testResponses - is an object whose property keys are the question ids and whose property values are the given answers and the correct answers.
 * It is used in the view mode and if the given answer is the same as the correct one, then the question and answers will be colored with green, otherwise they will be red.
 * @param props.menuButton
 * @returns {React.ReactElement}
 */
export default function QuestionnaireView(props: {
    questionnaireModel: QuestionnaireModel;
    // TODO: The settings part (used for autoscroll configurations, showing / hiding info boxes) was commented, because it was considered not so important at the moment.
    //  But in the future we have to decide if we want that part to be in one.ui or is it a specific thing for a project.
    // settings: PropertyTree;
    skipQuestionnaire?: {
        nextQuestionnaire: string;
        previousQuestionnaire: string;
        decisiveAnswer: string;
    };
    questionnaireName?: string;
    language?: string;
    action?: string;
    responseId?: string;
    redirectAfterSubmit?: () => void;
    testResponses?: Record<string, {givenAnswer: string | undefined; correctAnswer: string}>;
    menuButton?: React.ReactElement;
}): React.ReactElement {
    const {action}: {action: string} = useParams();
    let viewMode = true;
    let incompleteMode: IncompleteModeType = INCOMPLETE_MODE.disabled;

    // Evaluate the action and set variables accordingly
    if (action === 'edit' || props.action === 'edit') {
        incompleteMode = INCOMPLETE_MODE.loadIncomplete;
        viewMode = false;
    } else if (action === 'new' || props.action === 'new') {
        incompleteMode = INCOMPLETE_MODE.startNew;
        viewMode = false;
    }

    // Setup a state to select the current questionnaire
    const [error, setError] = useState('');
    const [displayError, setDisplayError] = React.useState(false);

    // Extract the parameters from the URL
    const history = useHistory();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const paramResponse = props.responseId ? props.responseId : queryParams.get('response');
    const paramLanguage = props.language ? props.language : queryParams.get('language');
    const paramQuestionnaire = props.questionnaireName
        ? props.questionnaireName
        : queryParams.get('questionnaires');
    const showPrefixStr = queryParams.get('showPrefix');
    const paramChannelOwner = queryParams.get('channelOwner');
    const showPrefix = showPrefixStr && showPrefixStr === TRUE;
    const forceRender = useForceRender();

    // Parse the channel owner
    const channelOwner = isHash<Person>(paramChannelOwner)
        ? (paramChannelOwner as SHA256IdHash<Person>)
        : undefined;

    // Build the questionnaire response builders
    const [questionnairesMap, responsesMap] = useQuestionnairesAndResponses(
        props.questionnaireModel,
        paramQuestionnaire ? paramQuestionnaire.split(',') : undefined,
        paramLanguage ? paramLanguage : undefined,
        paramResponse ? paramResponse : undefined,
        incompleteMode,
        setError
    );

    // Extract the names of the questionnaires and the questionnaires and build the builders
    const questionnaireNames: string[] = useArray([...questionnairesMap.keys()]);
    const questionnaires: Questionnaire[] = useArray([...questionnairesMap.values()]);
    const builders = useQuestionnairesBuilders(questionnaires, responsesMap, forceRender);

    // Introduce a state for selecting questionnaires
    const [questionnaireName, qStateApi] = useStateList(
        questionnaireNames,
        questionnaireNames.length > 0 ? questionnaireNames[0] : ''
    );

    // Auto scroll flag from application settings
    const autoScroll = TRUE;
    // TODO: --for Settings: const [autoScroll] = useSettings(props.settings, 'autoScroll', 'true');

    // We need something that keep a reference for each question
    const refs = {} as AnyObject;

    // Setting that specifies if the progress of the questionnaire is saved or not when the component is closed
    const saveQuestionnaireProgress = TRUE;
    // TODO: --for Settings: const [saveQuestionnaireProgress] = useSettings(props.settings,'saveQuestionnaireProgress','true');

    // Setting that specifies if the info boxes needs to be displayed or not
    const hideInfoBox = FALSE;
    // TODO: --for Settings: const [hideInfoBox, setHideInfoBox] = useSettings(props.settings, 'hideInfoBox', 'false');

    // State for displaying the information box for saving the progress of a questionnaire
    const [savingIncompleteQuestionnaireInformation, setSavingIncompleteQuestionnaireInformation] =
        useState(saveQuestionnaireProgress === TRUE);

    // When the info box is closed, the state is stored as a setting
    useEffect(() => {
        if (!savingIncompleteQuestionnaireInformation) {
            // TODO: --for Settings: setHideInfoBox('true').catch(err => setError(err.toString()));
        }
    }, [saveQuestionnaireProgress, savingIncompleteQuestionnaireInformation]);

    // Variables for currently selected questionnaire
    const builder = builders.get(questionnaireName);
    const [questionnaireValidationFailed, setQuestionnaireValidationFailed] = useState(false);
    const paperRef = React.createRef<HTMLDivElement>();

    // Calculate the paging numbers (starts from 1)
    let currentPage = questionnaireNames.findIndex(name => name === questionnaireName) + 1;
    let totalPages = questionnaireNames.length;

    if (currentPage < 1) {
        currentPage = 1;
    }

    // TODO: This is project specific, so we should remove it when we have a generic way for this
    let skipIQuestionnaire = false;

    {
        if (props.skipQuestionnaire) {
            const previousQuestionnaireIndex = questionnaireNames.findIndex(
                name => name === props.skipQuestionnaire?.previousQuestionnaire
            );
            const nextQuestionnaireIndex = questionnaireNames.findIndex(
                name => name === props.skipQuestionnaire?.nextQuestionnaire
            );

            if (
                previousQuestionnaireIndex >= 0 &&
                nextQuestionnaireIndex === previousQuestionnaireIndex + 1
            ) {
                const previousQuestionnaireBuilder = builders.get(
                    props.skipQuestionnaire.previousQuestionnaire
                );

                if (previousQuestionnaireBuilder) {
                    const previousQuestionnaireAnswers = previousQuestionnaireBuilder.getAnswers(
                        props.skipQuestionnaire.decisiveAnswer
                    );

                    // If the infYNU answer is not 'Yes' (Infected), then we don't count the skipped questionnaire
                    // This means that we have to
                    if (
                        previousQuestionnaireAnswers.length === 0 ||
                        previousQuestionnaireAnswers[0].valueCoding === undefined ||
                        previousQuestionnaireAnswers[0].valueCoding.code !== '1'
                    ) {
                        --totalPages;
                        skipIQuestionnaire = true;

                        if (currentPage > nextQuestionnaireIndex) {
                            --currentPage;
                        }
                    }
                }
            }
        }
    }

    useEffect(() => {
        setError('');
    }, [builders]);

    // Clear the error if a new questionnaire is selected
    useEffect(() => {
        // Set the correct initial state based on the status of the loaded questionnaires
        //
        // The current behavior is to skip to page 1 if an incomplete questionnaire was
        // loaded that has a page filled out with incomplete answers in a previous index.
        if (incompleteMode !== INCOMPLETE_MODE.disabled) {
            let index = 0;

            for (const builderValue of builders.values()) {
                ++index;

                if (index < currentPage && builderValue.status() === 'in-progress') {
                    history.push('/');
                    break;
                }
            }
        }

        // Forward from "edit" to "new"
        if (action === 'new' && props.action !== 'new') {
            const editPath =
                history.location.pathname.slice(0, -3) + 'edit' + history.location.search;
            history.push(editPath);
        }
    }, [
        builders,
        incompleteMode,
        qStateApi,
        questionnaireNames,
        action,
        props.action,
        currentPage,
        history
    ]);

    /**
     * Submits the incomplete state of the questionnaire to the incomplete channel.
     */
    const submitIncomplete = useCallback(
        async (completed: boolean): Promise<void> => {
            const incompleteType = questionnaireNames.join(',');

            if (completed) {
                await props.questionnaireModel.markIncompleteResponseAsComplete(incompleteType);
            } else {
                const responses = [];

                // If no questionnaires are loaded, then do not write incomplete entries.
                if (incompleteType === '') {
                    return;
                }

                // Build the responses array
                for (const [currentBuilderName, currentBuilder] of builders) {
                    if (
                        skipIQuestionnaire &&
                        currentBuilderName === props.skipQuestionnaire?.nextQuestionnaire
                    ) {
                        continue;
                    }
                    responses.push(currentBuilder.buildResponse());
                }

                // Store the responses array
                // On success go to the home screen
                // On failure set the error state
                await props.questionnaireModel.postIncompleteResponseCollection(
                    responses,
                    incompleteType
                );
            }
        },
        [
            builders,
            props.questionnaireModel,
            questionnaireNames,
            skipIQuestionnaire,
            props.skipQuestionnaire
        ]
    );

    // Incomplete handling - on each question answered
    // We deactivated this, because we only want to save incomplete ones on submit for now
    // because of the 'too fast click' issue that results in lost answers.
    /* useEffect(() => {
        const currBuilder = builder;

        **
         * Creates an incomplete entry with the new state
         *
        function handler() {
            submitIncomplete(false);
        }

        if (currBuilder) {
            currBuilder.on('updated', handler);

            return () => {
                currBuilder.removeListener('updated', handler);
            };
        }
    }, [builder, submitIncomplete]);*/

    /**
     * Submit function that is called when the submit button is pressed.
     *
     * This function will check the validity of the current questionnaire.
     * If not valid, it will set the error state accordingly.
     * If valid it will jump to the next questionnaire or store the
     * questionnaire if the last questionnaire was reached.
     */
    function submit(): void {
        async function submitInternal(): Promise<void> {
            if (!builder) {
                return;
            }

            // Check if questionnaire is valid => step to next or store
            if (builder.validate()) {
                builder.setStatus('completed');

                // Check if it is the last questionnaire
                if (currentPage === totalPages) {
                    const responses = [];
                    const responsesTypes = [];

                    // Build the responses array
                    for (const [currentBuilderName, currentBuilder] of builders) {
                        if (
                            skipIQuestionnaire &&
                            currentBuilderName === props.skipQuestionnaire?.nextQuestionnaire
                        ) {
                            continue;
                        }
                        responses.push(currentBuilder.buildResponse());
                        responsesTypes.push(currentBuilderName);
                    }

                    await submitIncomplete(true);

                    // Store the responses array
                    // On success go to the home screen
                    // On failure set the error state
                    await props.questionnaireModel.postResponseCollection(
                        responses,
                        undefined,
                        responsesTypes.join(','),
                        channelOwner
                    );

                    if (props.redirectAfterSubmit) {
                        props.redirectAfterSubmit();
                    } else {
                        history.push('/');
                    }
                } else {
                    // Scroll to the top of the questionnaire of the next questionnaire
                    scrollPaperToTop();

                    await submitIncomplete(false);

                    // Jump to the next questionnaire in line
                    setError('');
                    qStateApi.next();
                    setQuestionnaireValidationFailed(false);
                }
            } else {
                if (
                    builder.validationErrors.find(
                        validationError =>
                            validationError.code ===
                            QuestionnaireResponseBuilderValidationErrorCode.requiredNotPresent
                    )
                ) {
                    setError('errors:default.questionnaire.answerAllQuestions');
                    setDisplayError(true);
                }
                setFinishedQuestion(builder.validationErrors[0].linkId);
                setQuestionnaireValidationFailed(true);
            }
        }

        submitInternal().catch((e: string) => {
            setError(e);
            setDisplayError(true);
        });
    }

    /**
     * Change the scroll by centering the question given as parameter.
     * If the parameter is undefined it means that there is no other question that needs to be centered and nothing will happen.
     *
     * @param linkId - the question id or undefined
     */
    function setFinishedQuestion(linkId: string | undefined): void {
        if (linkId) {
            scrollToCenter(linkId);
        }
    }

    /**
     * Used to iterate over a questionnaire and build the questionnaire html structure.
     *
     * @returns {React.ReactElement} - the questionnaire body
     */
    function buildQuestionnaire(): React.ReactElement {
        const questionnaire = [];

        const currentQuestionnaire = questionnaires[0];

        let i = 0;

        if (currentQuestionnaire) {
            if (builder !== undefined) {
                for (const questionContainer of builder.questionIterator({
                    hideDisabledValues: true
                })) {
                    // creating a ref for the question
                    refs[questionContainer.question.linkId] = React.createRef();

                    i++;

                    questionnaire.push(mapQuestion(questionContainer, undefined, i === 1));

                    if (questionContainer.subItems) {
                        questionnaire.push(buildSubQuestions(questionContainer));
                    }
                }
                hideCircularProgress();
            }
        }

        return <>{questionnaire}</>;
    }

    /**
     * Used to build up the subQuestions of a group parent question.
     *
     * @param  questionResponseBuilderItem - The question response builder
     * @returns The subQuestions body
     */
    function buildSubQuestions(
        questionResponseBuilderItem: QuestionnaireResponseBuilderItem
    ): React.ReactElement {
        const subQuestions = [];

        if (questionResponseBuilderItem.subItems === undefined) {
            return <></>;
        }

        let openChoiceQuestions: QuestionnaireResponseBuilderItem[] = [];

        for (const subItem of questionResponseBuilderItem.subItems) {
            // creating a ref for the question
            refs[subItem.question.linkId] = React.createRef();

            subQuestions.push(mapQuestion(subItem, true));

            if (subItem.subItems) {
                subQuestions.push(buildSubQuestions(subItem));
            }

            if (subItem.question.type === 'open-choice') {
                openChoiceQuestions.push(subItem);
                continue;
            }

            if (openChoiceQuestions.length > 0) {
                subQuestions.push(
                    buildOpenChoiceSubgroup(questionResponseBuilderItem, openChoiceQuestions)
                );
                openChoiceQuestions = [];
            }
        }

        if (openChoiceQuestions.length > 0) {
            subQuestions.push(
                buildOpenChoiceSubgroup(questionResponseBuilderItem, openChoiceQuestions)
            );
        }

        return (
            <div key={'buildSubQuestions' + questionResponseBuilderItem.question.linkId}>
                {subQuestions}
            </div>
        );
    }

    /**
     * This is used to threat a special case for open-choice questions within a group.
     * Because they are seen as answers for a group question, we need to manage them together,
     * so we are building a container with all open-choice questions.
     *
     * @param parentQuestionResponseBuilder
     * @param openChoiceArray
     * @returns The body of the open choice subgroup
     */
    function buildOpenChoiceSubgroup(
        parentQuestionResponseBuilder: QuestionnaireResponseBuilderItem,
        openChoiceArray: QuestionnaireResponseBuilderItem[]
    ): React.ReactElement {
        return (
            <div
                className={`qtn-answers-container qtn-open-choice-container ${
                    parentQuestionResponseBuilder.enabled ? '' : 'qtn-disabled-question'
                }`}
                key={parentQuestionResponseBuilder.question.linkId}
            >
                {openChoiceArray.map(openChoiceSubQuestion => (
                    <div key={openChoiceSubQuestion.question.linkId}>
                        {buildAnswers(openChoiceSubQuestion, true)}
                    </div>
                ))}
            </div>
        );
    }

    /**
     * This function scrolls to the question, given as a parameter, if the autoScroll is selected.
     *
     * @param linkId - the question linkId
     */
    function scrollToCenter(linkId: string): void {
        // eslint-disable-next-line no-restricted-syntax,@typescript-eslint/no-unsafe-member-access
        if (autoScroll === TRUE && linkId in refs && refs[linkId].current !== null) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
            refs[linkId].current.scrollIntoView({
                behavior: 'auto',
                block: 'center'
            });
        }
    }

    /**
     * Builds the body of the question.
     *
     * @param question - the content of the question
     * @param enabled - flag that specifies if the question should be enabled or not
     * @param validationFailed - flag that specifies if the validation of the answer failed
     * @returns The body of the question
     */
    function buildQuestion(
        question: Question,
        enabled: boolean,
        validationFailed: boolean
    ): React.ReactElement {
        let questionColorClass = '';

        if (
            props.testResponses &&
            props.testResponses[question.linkId] &&
            props.testResponses[question.linkId].correctAnswer !==
                props.testResponses[question.linkId].givenAnswer
        ) {
            questionColorClass = 'qtn-wrong-question';
        }

        return (
            <>
                {question.text && (
                    <div
                        className={`${question.text === '' ? '' : 'qtn-question-text'}  ${
                            enabled ? '' : 'qtn-disabled-question'
                        } ${
                            validationFailed && questionnaireValidationFailed
                                ? 'qtn-wrong-answer-error'
                                : ''
                        }
                ${question.type === 'display' ? 'qtn-display-question' : ''} ${
                            question.type === 'group' ? 'qtn-group-question' : ''
                        }
                ${questionColorClass}`}
                    >
                        {showPrefix && question.prefix} {question.text}
                    </div>
                )}
            </>
        );
    }

    /**
     * Build the answers to an integer question
     *
     * @param start - the starting value
     * @param end - the ending value
     * @param order - the order of the answers
     * @returns The array of the answers
     */
    function buildAnswersForQuestionOfTypeInteger(
        start: number,
        end: number,
        order?: string
    ): string[] {
        // since answers are used only in html elements,
        // with string type will be easier to work with them
        const answers: string[] = [];

        // building the answers in ascending order
        for (let i = start; i <= end; i++) {
            answers.push(i.toString());
        }

        if (order && order === 'descending') {
            // return them in descending order
            return answers.reverse();
        }

        return answers;
    }

    /**
     * Used for building the marks for slider question type.
     *
     * @param question - for each answer of the question will be created a mark.
     * @returns The marks that will be displayed.
     */
    function buildMarks(question: Question): Mark[] {
        const marks: {value: number; label: string}[] = [];

        if (question.answerOption) {
            for (const answer of question.answerOption) {
                if (answer.valueCoding && !isNaN(Number(answer.valueCoding.code))) {
                    marks.push({
                        value: Number(answer.valueCoding.code),
                        label: answer.valueCoding.display || ''
                    });
                }
            }
        }

        return marks;
    }

    /**
     * Used to set properly the answer of an integer question.
     *
     * @param questionResponseBuilderItem - The questionnaire response builder
     * @param answers - the list of the answers
     * @param newAnswer - the wanted answer
     */
    function onIntegerQuestionAnswerChanged(
        questionResponseBuilderItem: QuestionnaireResponseBuilderItem,
        answers: string[],
        newAnswer: string
    ): void {
        const foundAnswer = answers.find(answer => answer === newAnswer);

        questionResponseBuilderItem.setAnswer(
            foundAnswer
                ? [
                      {
                          valueInteger: foundAnswer
                      }
                  ]
                : []
        );
    }

    /**
     * Used to build the answers body of the question given as a parameter.
     *
     * @param questionResponseBuilderItem - The questionnaire response builder
     * @param isFromGroup - Used to apply different style for question type open-choice
     * @returns The body of answers
     */
    function buildAnswers(
        questionResponseBuilderItem: QuestionnaireResponseBuilderItem,
        isFromGroup?: boolean
    ): React.ReactElement {
        const component = [];

        switch (questionResponseBuilderItem.question.type) {
            case 'group':
            case 'display': {
                break;
            }
            case 'choice': {
                const answers = questionResponseBuilderItem.question.answerOption
                    ? questionResponseBuilderItem.question.answerOption
                    : [];

                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                const currentReference: string = refs[questionResponseBuilderItem.question.linkId];
                component.push(
                    <div
                        ref={currentReference}
                        key={questionResponseBuilderItem.question.linkId}
                        className={`qtn-answers-container ${
                            questionResponseBuilderItem.enabled ? '' : 'qtn-disabled-question'
                        } `}
                    >
                        {answers.map((answer, idx) => {
                            const displayedAnswer = answer.valueCoding
                                ? answer.valueCoding.display
                                : '';

                            const value =
                                questionResponseBuilderItem.value[0] &&
                                questionResponseBuilderItem.value[0].valueCoding
                                    ? questionResponseBuilderItem.value[0].valueCoding.display
                                    : '';
                            const currentAnswerText = answer.valueCoding
                                ? answer.valueCoding.display
                                : '';
                            let answerColorClass = '';

                            if (
                                viewMode &&
                                props.testResponses &&
                                props.testResponses[questionResponseBuilderItem.question.linkId]
                            ) {
                                if (
                                    props.testResponses[questionResponseBuilderItem.question.linkId]
                                        .correctAnswer === idx.toString()
                                ) {
                                    answerColorClass = 'qtn-correct-answer';
                                } else if (value === currentAnswerText) {
                                    answerColorClass = 'qtn-wrong-answer';
                                }
                            }

                            return (
                                <div
                                    key={
                                        questionResponseBuilderItem.question.linkId + idx.toString()
                                    }
                                    className="qtn-choice-answer"
                                >
                                    <RadioGroup
                                        key={idx}
                                        value={value}
                                        onChange={() => {
                                            questionResponseBuilderItem.setAnswer([answer]);
                                            setFinishedQuestion(
                                                questionResponseBuilderItem.nextEnabledItem()
                                            );
                                        }}
                                    >
                                        <FormControlLabel
                                            className={`qtn-answers-text ${answerColorClass}`}
                                            value={displayedAnswer}
                                            control={<Radio />}
                                            label={displayedAnswer}
                                        />
                                    </RadioGroup>
                                </div>
                            );
                        })}
                    </div>
                );
                break;
            }
            case 'open-choice': {
                const value =
                    questionResponseBuilderItem.value[0] &&
                    questionResponseBuilderItem.value[0].valueCoding
                        ? questionResponseBuilderItem.value[0].valueCoding.display
                        : '';

                component.push(
                    <FormControlLabel
                        key={questionResponseBuilderItem.question.linkId}
                        className={`qtn-answers-text ${
                            isFromGroup
                                ? 'qtn-open-choice-answer-min'
                                : 'qtn-open-choice-answer-max'
                        } ${
                            !questionResponseBuilderItem.enabled && !isFromGroup
                                ? 'qtn-disabled-question'
                                : ''
                        }`}
                        value={value}
                        onChange={() => {
                            if (value) {
                                questionResponseBuilderItem.setAnswer([]);
                            } else if (questionResponseBuilderItem.question.answerOption) {
                                questionResponseBuilderItem.setAnswer(
                                    questionResponseBuilderItem.question.answerOption
                                );
                            }
                        }}
                        control={<Checkbox />}
                        label={
                            questionResponseBuilderItem.question.answerOption &&
                            questionResponseBuilderItem.question.answerOption[0] &&
                            questionResponseBuilderItem.question.answerOption[0].valueCoding
                                ? questionResponseBuilderItem.question.answerOption[0].valueCoding
                                      .display
                                : ''
                        }
                        checked={
                            questionResponseBuilderItem.question.answerOption &&
                            questionResponseBuilderItem.question.answerOption[0] &&
                            questionResponseBuilderItem.question.answerOption[0].valueCoding &&
                            questionResponseBuilderItem.question.answerOption[0].valueCoding
                                .display === value
                        }
                    />
                );
                break;
            }
            case 'string':
            case 'date': {
                const value = questionResponseBuilderItem.value[0]
                    ? questionResponseBuilderItem.value[0].valueString
                        ? questionResponseBuilderItem.value[0].valueString
                        : questionResponseBuilderItem.value[0].valueDate
                        ? questionResponseBuilderItem.value[0].valueDate
                        : ''
                    : '';

                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                const currentRef: string = refs[questionResponseBuilderItem.question.linkId];
                component.push(
                    <div
                        ref={currentRef}
                        key={questionResponseBuilderItem.question.linkId}
                        className={`qtn-answers-container ${
                            questionResponseBuilderItem.enabled ? '' : 'qtn-disabled-question'
                        }`}
                    >
                        <StringQuestion
                            currentValue={value}
                            question={questionResponseBuilderItem.question}
                            setQuestionAnswer={questionResponseBuilderItem.setAnswer}
                            setFinishedQuestion={setFinishedQuestion}
                            viewMode={viewMode}
                        />
                    </div>
                );
                break;
            }
            case 'integer': {
                let answers: string[] = [];

                if (
                    questionResponseBuilderItem.question.answerRestriction !== undefined &&
                    questionResponseBuilderItem.question.answerRestriction.minValue &&
                    questionResponseBuilderItem.question.answerRestriction.minValue.valueInteger &&
                    questionResponseBuilderItem.question.answerRestriction.maxValue &&
                    questionResponseBuilderItem.question.answerRestriction.maxValue.valueInteger
                ) {
                    const maxInclusive =
                        questionResponseBuilderItem.question.answerRestriction.maxInclusive ===
                            undefined ||
                        questionResponseBuilderItem.question.answerRestriction.maxInclusive;
                    const minInclusive =
                        questionResponseBuilderItem.question.answerRestriction.minInclusive ===
                            undefined ||
                        questionResponseBuilderItem.question.answerRestriction.minInclusive;

                    let maxValue = Number(
                        questionResponseBuilderItem.question.answerRestriction.maxValue.valueInteger
                    );
                    let minValue = Number(
                        questionResponseBuilderItem.question.answerRestriction.minValue.valueInteger
                    );

                    // Remove the edges of the answer interval if needed
                    if (maxValue > minValue) {
                        if (!maxInclusive) {
                            maxValue--;
                        }

                        if (!minInclusive) {
                            minValue++;
                        }
                    } else {
                        if (!maxInclusive) {
                            maxValue++;
                        }

                        if (!minInclusive) {
                            minValue--;
                        }
                    }

                    // that's because for some questions we want the answers to be ordered descending
                    if (minValue > maxValue) {
                        // build answers in descending order
                        answers = buildAnswersForQuestionOfTypeInteger(
                            maxValue,
                            minValue,
                            'descending'
                        );
                    } else {
                        // build answers in ascending order
                        answers = buildAnswersForQuestionOfTypeInteger(
                            minValue,
                            maxValue,
                            'ascending'
                        );
                    }
                }

                answers.push('');

                const value =
                    questionResponseBuilderItem.value[0] &&
                    questionResponseBuilderItem.value[0].valueInteger
                        ? questionResponseBuilderItem.value[0].valueInteger
                        : null;
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                const currentRef: string = refs[questionResponseBuilderItem.question.linkId];

                component.push(
                    <div
                        ref={currentRef}
                        key={questionResponseBuilderItem.question.linkId}
                        className={`qtn-answers-container ${
                            questionResponseBuilderItem.enabled ? '' : 'qtn-disabled-question'
                        }`}
                    >
                        <div className="qtn-autocomplete-answer">
                            <Autocomplete
                                options={answers}
                                getOptionLabel={answer => answer}
                                value={value}
                                onInputChange={(_, newValue: string) => {
                                    if (newValue === '' && questionResponseBuilderItem.enabled) {
                                        questionResponseBuilderItem.setAnswer([]);
                                    }
                                }}
                                onChange={(_, newValue: string | null) => {
                                    if (newValue !== null) {
                                        questionResponseBuilderItem.setAnswer([
                                            {
                                                valueInteger: newValue
                                            }
                                        ]);
                                        setFinishedQuestion(
                                            questionResponseBuilderItem.nextEnabledItem()
                                        );
                                    }
                                }}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        value={value}
                                        variant="outlined"
                                        onKeyDown={(
                                            event: React.KeyboardEvent<HTMLInputElement>
                                        ) => {
                                            if (event.key === 'Enter') {
                                                const enteredAnswer = (
                                                    event.target as HTMLInputElement
                                                ).value;

                                                onIntegerQuestionAnswerChanged(
                                                    questionResponseBuilderItem,
                                                    answers,
                                                    enteredAnswer
                                                );
                                                setFinishedQuestion(
                                                    questionResponseBuilderItem.nextEnabledItem()
                                                );
                                                (event.target as HTMLInputElement).blur();
                                            }
                                        }}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            onIntegerQuestionAnswerChanged(
                                                questionResponseBuilderItem,
                                                answers,
                                                event.target.value
                                            );
                                        }}
                                        onBlur={() => {
                                            setFinishedQuestion(
                                                questionResponseBuilderItem.nextEnabledItem()
                                            );
                                        }}
                                    />
                                )}
                            />
                        </div>
                    </div>
                );
                break;
            }
            case 'slider': {
                const marks = buildMarks(questionResponseBuilderItem.question);

                const value =
                    questionResponseBuilderItem.value[0] &&
                    questionResponseBuilderItem.value[0].valueCoding
                        ? Number(questionResponseBuilderItem.value[0].valueCoding.code)
                        : -1;
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                const currentRef: string = refs[questionResponseBuilderItem.question.linkId];

                component.push(
                    <div
                        ref={currentRef}
                        key={questionResponseBuilderItem.question.linkId}
                        className={`qtn-answers-container ${
                            questionResponseBuilderItem.enabled ? '' : 'qtn-disabled-question'
                        }`}
                    >
                        {viewMode ? (
                            <Slider
                                value={value}
                                aria-labelledby="discrete-slider-always"
                                step={1}
                                marks={marks}
                                min={marks[0].value}
                                max={marks[marks.length - 1].value}
                            />
                        ) : (
                            <Slider
                                className={`qtn-slider-answer ${
                                    value === -1 ? 'qtn-hide-slider-bullet' : ''
                                }`}
                                value={value}
                                onChangeCommitted={(_, val: number | number[]) => {
                                    if (questionResponseBuilderItem.question.answerOption) {
                                        for (const answer of questionResponseBuilderItem.question
                                            .answerOption) {
                                            if (
                                                answer.valueCoding &&
                                                !isNaN(Number(answer.valueCoding.code)) &&
                                                Number(answer.valueCoding.code) === val
                                            ) {
                                                setFinishedQuestion(
                                                    questionResponseBuilderItem.nextEnabledItem()
                                                );
                                                questionResponseBuilderItem.setAnswer([answer]);
                                            }
                                        }
                                    }
                                }}
                                aria-labelledby="discrete-slider-always"
                                step={1}
                                marks={marks}
                                min={marks[0].value}
                                max={marks[marks.length - 1].value}
                            />
                        )}
                    </div>
                );
                break;
            }
            default: {
                component.push(<div>Wrong type of question</div>);
                break;
            }
        }

        return <>{component}</>;
    }

    /**
     * Used to build the question body, this means text of the question and the possible answers
     *
     * @param questionResponseBuilderItem - The questionnaire response builder
     * @param isFromGroup - Flag that specifies if the question is contained in a group
     * @param isFirstQuestion - Flag that specifies if the question is first
     * @returns The body of the question
     */
    function mapQuestion(
        questionResponseBuilderItem: QuestionnaireResponseBuilderItem,
        isFromGroup?: boolean,
        isFirstQuestion?: boolean
    ): React.ReactElement {
        return (
            <List
                className={`qtn-question-container ${
                    isFromGroup || isFirstQuestion ? 'qtn-question-no-top-border' : ''
                }`}
                key={`mapQuestions${questionResponseBuilderItem.question.linkId}`}
            >
                {questionResponseBuilderItem.question.type === 'open-choice'
                    ? ''
                    : buildQuestion(
                          questionResponseBuilderItem.question,
                          questionResponseBuilderItem.enabled,
                          questionResponseBuilderItem.validationFailed
                      )}
                {buildAnswers(questionResponseBuilderItem, isFromGroup)}
            </List>
        );
    }

    /**
     * Used to scroll the paper which contains the questionnaire
     * to the top while navigating through a set of questionnaires.
     */
    function scrollPaperToTop(): void {
        if (paperRef !== null && paperRef.current !== null) {
            paperRef.current.scrollTo(0, 0);
        }
    }

    return (
        <>
            <div className="circular-progress-container">
                <CircularProgress className="circular-progress" size={35} />
            </div>
            <div className="page-container qtn-container hide">
                {error && (
                    <StyledAlert
                        infoMessage={error}
                        displayMessage={displayError}
                        setDisplayMessage={setDisplayError}
                        messageType={MESSAGE_TYPE.error}
                    />
                )}
                {!viewMode && hideInfoBox === FALSE && (
                    <StyledAlert
                        infoMessage={'errors:questionnaire.savingQuestionnaireProgress'}
                        displayMessage={savingIncompleteQuestionnaireInformation}
                        setDisplayMessage={setSavingIncompleteQuestionnaireInformation}
                        messageType={MESSAGE_TYPE.info}
                    />
                )}
                <div className="menu-button-header">
                    {props.menuButton !== undefined && props.menuButton}
                    <h2 className="headline">
                        {questionnaireNames.length
                            ? i18n.t(`common:menu.${questionnaireNames.toString()}Questionnaire`)
                            : i18n.t('common:questionnaire')}
                    </h2>
                    <div className="qtn-progress-indicator">
                        {currentPage}/{totalPages}
                    </div>
                </div>
                <Paper
                    square
                    elevation={3}
                    className="page-content-box qtn-content-box"
                    ref={paperRef}
                >
                    <div className={viewMode ? 'qtn-view-mode' : 'qtn-edit-mode'}>
                        {buildQuestionnaire()}
                    </div>
                </Paper>
                {viewMode ? (
                    totalPages !== currentPage && (
                        <div className="qtn-buttons-container">
                            <div>
                                <Button
                                    color="primary"
                                    disabled={currentPage === 1}
                                    variant="contained"
                                    className="button"
                                    onClick={() => {
                                        qStateApi.prev();
                                        scrollPaperToTop();
                                    }}
                                >
                                    {i18n.t('common:buttons.previous')}
                                </Button>
                            </div>
                            <div>
                                <Button
                                    color="primary"
                                    disabled={currentPage === totalPages}
                                    variant="contained"
                                    className="button button-margin-left"
                                    onClick={() => {
                                        qStateApi.next();
                                        scrollPaperToTop();
                                    }}
                                >
                                    {i18n.t('common:buttons.next')}
                                </Button>
                            </div>
                        </div>
                    )
                ) : (
                    <div className="qtn-buttons-container">
                        <Button
                            color="primary"
                            variant="contained"
                            className="button"
                            onClick={submit}
                        >
                            {i18n.t('common:buttons.submit')}
                        </Button>
                    </div>
                )}
            </div>
        </>
    );
}
