import type {ReactElement} from 'react';
import {CircularProgress} from '@material-ui/core';
import './Loading.css';

/**
 *
 * Displays Login page
 * @returns
 */
export default function Loading(): ReactElement {
    return <CircularProgress className={'spinner'} />;
}
