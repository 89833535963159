import type {ReactElement} from 'react';
import '../studyCommon/StudyOverview.css';
import '../../../Primary.css';
import i18n from '../../../i18n';
import MenuButton from '../../menu/MenuButton';
import {
    changeBackgroundColorToWhite,
    hideCircularProgress,
    removeWhiteBackgroundColor
} from '../../utils/Utils';
import CurrentDate from '../../studies/studyCommon/CurrentDate';
import {CircularProgress} from '@material-ui/core';
import {useImpactStudyData} from '../../modelHelper/StudyWorkflowHelper';
import ImpactTimeline from './ImpactTimeline';
import type ImpactWorkflow from '../../../model/studies/ImpactWorkflow';
import {STUDY_DURATION} from '../../../model/studies/StudyWorkflow';
import type AudioExerciseModel from 'one.models/lib/models/AudioExerciseModel';
import * as dateFns from 'date-fns';
import {IMPACT_VISIT} from '../../../model/studies/StudyHelper';
import EndOfStudyMessage from '../studyCommon/EndOfStudyMessage';
import StudyProgress from '../studyCommon/StudyProgress';
import type {Product} from '../../Ui';
import {PRODUCT_TYPE} from '../../Ui';
import StudyHeadline from '../studyCommon/StudyHeadline';
import {useEffect} from 'react';

/**
 * This function builds and returns the impact study overview.
 * @param props - properties of this view.
 * @param props.impactWorkflow
 * @param props.audioExerciseModel
 * @param props.productType
 * @returns - the impact study overview.
 */
export default function ImpactOverview(props: {
    impactWorkflow: ImpactWorkflow;
    audioExerciseModel: AudioExerciseModel;
    productType: Product;
}): ReactElement {
    const {
        // current study day of the impact study -> used to keep the study progress up to date
        currentStudyDay,
        // current visit of the participant or undefined for the impact study -> used to display the active visit for the participant
        currentVisit,
        // getting the details for each visit for impact study -> used to highlight active tasks and blur inactive tasks
        visitsProperties,
        // getting the amount of days until the next visit becomes available for impact study
        daysUntilNextVisit,
        // getting the tasks categories
        tasks,
        // getting finished tasks
        finishedTasks
    } = useImpactStudyData(props.impactWorkflow);

    useEffect(() => {
        changeBackgroundColorToWhite();
        hideCircularProgress();

        return () => {
            removeWhiteBackgroundColor();
        };
    }, []);

    return (
        <>
            <div className="circular-progress-container">
                <CircularProgress className="circular-progress" size={35} />
            </div>

            <div className="page-container hide home-screen">
                <div className="menu-button-header">
                    <MenuButton />
                    <h2 className="headline"> {i18n.t('common:menu.impact')}</h2>
                </div>
                <CurrentDate />
                {daysUntilNextVisit !== -1 &&
                    (currentVisit !== IMPACT_VISIT.Day_21 ||
                        visitsProperties[currentVisit].tasksCompleted !==
                            visitsProperties[currentVisit].totalTasks) && (
                        <StudyProgress
                            currentStudyDay={currentStudyDay}
                            studyDuration={STUDY_DURATION}
                        />
                    )}
                <p className="next-visit">
                    {daysUntilNextVisit === -1 ||
                    (currentVisit === IMPACT_VISIT.Day_21 &&
                        visitsProperties[currentVisit].tasksCompleted ===
                            visitsProperties[currentVisit].totalTasks) ? (
                        <i>
                            {i18n.t('studies:endOfStudy1')}
                            <br />
                            {i18n.t('studies:endOfStudy2')}
                        </i>
                    ) : currentVisit === undefined ||
                      !dateFns.isSameDay(visitsProperties[currentVisit].visitDate, new Date()) ? (
                        daysUntilNextVisit !== -1 && (
                            <i>
                                {i18n.t('studies:impact.taskInactive')}
                                {daysUntilNextVisit}
                                {i18n.t('studies:days')}
                            </i>
                        )
                    ) : (
                        <i>
                            {props.productType === PRODUCT_TYPE.Smiler_impact ? (
                                <StudyHeadline
                                    completedTasks={visitsProperties[currentVisit].tasksCompleted}
                                    totalTasks={visitsProperties[currentVisit].totalTasks}
                                />
                            ) : (
                                i18n.t('studies:impact.taskActive')
                            )}
                        </i>
                    )}
                </p>
                <ImpactTimeline
                    currentDayOfTheStudy={currentStudyDay}
                    currentVisit={currentVisit}
                    visitsProperties={visitsProperties}
                    audioExerciseModel={props.audioExerciseModel}
                    tasks={tasks}
                    finishedTasks={finishedTasks}
                    productType={props.productType}
                />
                {(daysUntilNextVisit === -1 ||
                    (currentVisit === IMPACT_VISIT.Day_21 &&
                        visitsProperties[currentVisit].tasksCompleted ===
                            visitsProperties[currentVisit].totalTasks)) && <EndOfStudyMessage />}
            </div>
        </>
    );
}
