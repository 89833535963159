import type {ReactElement} from 'react';
import type {EventListEntry} from 'one.models/lib/models/JournalModel';
import './JournalDialog.css';
import '../../Primary.css';
import {Button, Dialog, DialogContent, DialogTitle, Divider} from '@material-ui/core';
import * as dateFns from 'date-fns';
import Paper from '@material-ui/core/Paper';
import Close from '@material-ui/icons/Close';

/**
 * This component builds and returns the dialog view with the event information
 * @param {{}} props
 * @param {boolean} props.dialogState
 * @param {Function} props.setDialogState
 * @param {EventListEntry} props.event
 * @param {string} props.type
 * @returns {ReactElement} - Entire event list view
 */
export default function JournalDialog(props: {
    dialogState: boolean;
    setDialogState: (value: boolean) => void;
    event: EventListEntry;
    type: string;
}): ReactElement {
    const hour = dateFns.format(props.event.data.creationTime, 'HH');
    const minutes = dateFns.format(props.event.data.creationTime, 'mm');
    const month =
        props.event.data.creationTime.getMonth() > 8
            ? props.event.data.creationTime.getMonth() + 1
            : `0${props.event.data.creationTime.getMonth() + 1}`;
    const day =
        props.event.data.creationTime.getDate() > 9
            ? props.event.data.creationTime.getDate()
            : `0${props.event.data.creationTime.getDate()}`;

    return (
        <>
            <Paper square elevation={3}>
                <Dialog
                    open={props.dialogState}
                    onClose={() => {
                        props.setDialogState(false);
                    }}
                    className="jrnl-dialog"
                >
                    <DialogTitle className="jrnl-dialog-title">
                        <div className="jrnl-event-type">{props.type}</div>
                        <Button
                            className="jrnl-dialog-close-button"
                            onClick={() => {
                                props.setDialogState(false);
                            }}
                        >
                            <Close />
                        </Button>
                    </DialogTitle>
                    <Divider />
                    <DialogContent className="jrnl-dialog-content">
                        <div className="jrnl-event-date">
                            <div>
                                {month}
                                {'/'}
                                {day}
                                {'/'}
                                {props.event.data.creationTime.getFullYear()}
                            </div>
                            <div>
                                {hour}
                                {':'}
                                {minutes}
                            </div>
                        </div>
                        <div>
                            <pre>{JSON.stringify(props.event.data, null, 2)}</pre>
                        </div>
                    </DialogContent>
                </Dialog>
            </Paper>
        </>
    );
}
