import type {ReactElement} from 'react';
import {CircularProgress} from '@material-ui/core';
import i18n from '../../i18n';
import MenuButton from '../menu/MenuButton';
import type DocumentModel from 'one.models/lib/models/DocumentModel';
import type {DocumentInfo} from 'one.models/lib/models/DocumentModel';
import {useParams} from 'react-router-dom';
import './FileViewer.css';
import {pdfjs} from 'react-pdf';
import InfoMessage, {MESSAGE_TYPE} from '../errors/InfoMessage';
import type {InformationType} from '../errors/InfoMessage';
import {displayCircularProgress, formatDate, hideCircularProgress} from '../utils/Utils';
import ViewPDFComponent from './ViewPDFComponent';
import ViewPictureComponent from './ViewPictureComponent';
import type {ObjectData} from 'one.models/lib/models/ChannelManager';
import {ACCEPTED_FILE_TYPES} from './ImportFile';
import type {AcceptedFile} from './ImportFile';
import {useEffect, useState} from 'react';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

/**
 * Getting the pdf document from ONE by its id.
 * @param documentId - the id of the pdf document.
 * @param documentModel - the pdf document model.
 * @param setPdfURLCb - setter callback function for the pdf url.
 * @param setDateCb - setter callback function for the date when the pdf document was uploaded.
 * @param setFileNameCb
 * @param setErrorMessageCb - setter callback function for the error that is thrown if the document is not found.
 * @param setErrorMessageTypeCb
 */
function loadDocument(
    documentId: string,
    documentModel: DocumentModel,
    setPdfURLCb: (value: string) => void,
    setDateCb: (value: Date | undefined) => void,
    setFileNameCb: (value: string) => void,
    setErrorMessageCb: (value: string) => void,
    setErrorMessageTypeCb: (value: InformationType) => void
): void {
    displayCircularProgress();
    documentModel
        .getDocumentById(documentId)
        .then(async (value: ObjectData<DocumentInfo>) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-call
            const arrayBuffer: ArrayBuffer = await documentModel.blobHashToArrayBuffer(value.data);
            const array = new Uint8Array(arrayBuffer);
            const blob = new Blob([array]);
            setPdfURLCb(window.URL.createObjectURL(blob));
            setDateCb(value.creationTime);
            setFileNameCb(value.data.documentName);
            hideCircularProgress();
        })
        .catch(error => {
            setErrorMessageTypeCb(MESSAGE_TYPE.Error);
            setErrorMessageCb(error);
        });
}

/**
 * This is the view which is rendered when the user wants to visualize an uploaded pdf or picture from the journal.
 *
 * @param {{ }} props - properties of the view.
 * @param {DocumentModel} props.documentModel - the document model.
 */
export default function FileViewer(props: {documentModel: DocumentModel}): ReactElement {
    const [documentURL, setDocumentURL] = useState('');
    const [documentDate, setDocumentDate] = useState<Date>();
    const [documentName, setDocumentName] = useState('');

    /** Error handling **/
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState<InformationType>(MESSAGE_TYPE.Error);

    /** URL related **/
    const {documentId, type} = useParams<{documentId: string; type: AcceptedFile}>();

    useEffect(() => {
        setDocumentURL('');
        setDocumentName('');
        setDocumentDate(undefined);

        if (documentId) {
            loadDocument(
                documentId,
                props.documentModel,
                setDocumentURL,
                setDocumentDate,
                setDocumentName,
                setMessage,
                setMessageType
            );
        }
    }, [documentId, props.documentModel, type]);

    /**
     *
     */
    function renderFileViewer(): ReactElement {
        switch (type) {
            case ACCEPTED_FILE_TYPES.Pdf: {
                return <ViewPDFComponent pdfURL={documentURL} onLoadError={onLoadFailed} />;
            }
            case ACCEPTED_FILE_TYPES.Pic: {
                return <ViewPictureComponent pictureURL={documentURL} onLoadError={onLoadFailed} />;
            }
            default: {
                return renderWrongTypeUI();
            }
        }
    }

    /**
     *
     */
    function renderWrongTypeUI(): ReactElement {
        setMessage(i18n.t('errors:importDocument.corruptedURL'));

        return (
            <InfoMessage
                errorMessage={message}
                setDisplayMessage={setMessage}
                messageType={MESSAGE_TYPE.Error}
            />
        );
    }

    /**
     * This function set the error state to true if the uploaded file is not a pdf file.
     *
     * @param {string} errorName - the error name.
     */
    function onLoadFailed(errorName: string): void {
        if (errorName === 'InvalidPDFException' || errorName === 'CorruptedImageFormat') {
            setMessageType(MESSAGE_TYPE.Error);
            setMessage('errors:importDocument.corruptedFile');
        }
    }

    return (
        <div className="page-container document-component">
            <InfoMessage
                errorMessage={message}
                setDisplayMessage={setMessage}
                messageType={messageType}
            />
            <div className={`circular-progress-container ${documentURL ? '' : 'hide'}`}>
                <CircularProgress className="circular-progress" size={35} />
            </div>
            <>
                {documentURL && (
                    <>
                        <div className="menu-button-header">
                            <MenuButton />
                            <h2 className="headline title-padding">{i18n.t('common:document')}</h2>
                        </div>

                        {documentDate && (
                            <>
                                <div className="title-element title-padding">
                                    {`${documentName}`}{' '}
                                </div>
                                <div className="title-element title-padding">
                                    {formatDate(documentDate)}{' '}
                                </div>
                            </>
                        )}
                        {renderFileViewer()}
                    </>
                )}
            </>
            <div />
        </div>
    );
}
