import {Paper} from '@material-ui/core';
import ReactMarkdown from 'react-markdown';
import type {ReactElement} from 'react';
import {hideCircularProgress} from '../utils/Utils';
import {useEffect, useState} from 'react';

/**
 * The markdown paper view component.
 * @param props
 * @param props.filePath - the filePath of the markdown file that will be displayed.
 * @returns
 */
export default function MarkdownPaperView(props: {filePath: string}): ReactElement {
    const [markdownContent, setMarkdownContent] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        if (props.filePath) {
            // eslint-disable-next-line @typescript-eslint/no-var-requires,@typescript-eslint/no-unsafe-assignment
            const markdownFullFilePath = require('../../' + props.filePath);

            fetch(markdownFullFilePath.default)
                .then(res => res.text())
                .then(content => {
                    setMarkdownContent(content);
                    hideCircularProgress();
                })
                .catch(error => {
                    setErrorMessage(error);
                    hideCircularProgress();
                });
        }
    }, [props.filePath]);

    return (
        <Paper square elevation={3} className="page-content-box paper-font-size">
            {errorMessage ? (
                errorMessage
            ) : (
                <ReactMarkdown className="markdown-content">{markdownContent}</ReactMarkdown>
            )}
        </Paper>
    );
}
