import type {PropertyTree} from 'one.models/lib/models/SettingsModel';
import {useEffect, useState} from 'react';

/**
 * @param settings
 * @param key
 * @param defaultValue
 * @returns
 */
export function useSettings(
    settings: PropertyTree,
    key: string,
    defaultValue: string
): [string, (value: string) => Promise<void>] {
    const [value, setValue] = useState<string>(
        settings.hasValue(key) ? settings.getValue(key) : defaultValue
    );

    useEffect(() => {
        function fetchSettings(): void {
            if (settings.hasValue(key)) {
                setValue(settings.getValue(key));
            } else {
                setValue(defaultValue);
                settings.setValue(key, defaultValue).catch(error => {
                    console.error(`Error ${error}`);
                });
            }
        }

        const disconnect = settings.onSettingChange(fetchSettings);
        fetchSettings();

        return disconnect;
    }, [defaultValue, key, settings]);

    return [
        value,
        (tmpValue: string) => {
            return settings.setValue(key, tmpValue);
        }
    ];
}
