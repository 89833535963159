import {OEvent} from 'one.models/lib/misc/OEvent';
import type ConnectionsModel from 'one.models/lib/models/ConnectionsModel';
import type QuestionnaireModel from 'one.models/lib/models/QuestionnaireModel';
import type ECGModel from 'one.models/lib/models/ECGModel';
import type WbcDiffModel from 'one.models/lib/models/WbcDiffModel';
import type DocumentModel from 'one.models/lib/models/DocumentModel';

/**
 * This model represents a HACK for the data syncing
 * mechanism between the fresh created instance and its replicant.
 */
export default class DataSyncManager {
    public onSyncDone = new OEvent<() => void>();

    private connectionModel: ConnectionsModel;
    private questionnaireModel: QuestionnaireModel;
    private wbcModel: WbcDiffModel;
    private ecgModel: ECGModel;
    private documentModel: DocumentModel;

    private connectionsModelDisconnect: (() => void) | null = null;
    private wbcModelDisconnect: (() => void) | null = null;
    private ecgModelDisconnect: (() => void) | null = null;
    private documentModelDisconnect: (() => void) | null = null;
    private questionnaireModelDisconnect: (() => void) | null = null;

    private timer: ReturnType<typeof setTimeout> | undefined;
    private isSyncDone: boolean;

    constructor(
        connectionModel: ConnectionsModel,
        questionnaireModel: QuestionnaireModel,
        wbcModel: WbcDiffModel,
        ecgModel: ECGModel,
        documentModel: DocumentModel
    ) {
        this.connectionModel = connectionModel;
        this.questionnaireModel = questionnaireModel;
        this.wbcModel = wbcModel;
        this.ecgModel = ecgModel;
        this.documentModel = documentModel;
        this.isSyncDone = false;
    }

    /**
     * Used to init data synchronization when the instance isn't created via random instance.
     */
    public init(): void {
        this.connectionsModelDisconnect =
            localStorage.getItem('isRandomInstance') === 'true'
                ? null
                : this.connectionModel.onChumStart(this.handleOnUpdated.bind(this));
    }
    /**
     * Shutdown module
     */
    public shutdown(): void {
        if (this.connectionsModelDisconnect !== null) {
            this.connectionsModelDisconnect();
        }

        if (this.questionnaireModelDisconnect !== null) {
            this.questionnaireModelDisconnect();
        }

        if (this.documentModelDisconnect !== null) {
            this.documentModelDisconnect();
        }

        if (this.wbcModelDisconnect !== null) {
            this.wbcModelDisconnect();
        }

        if (this.ecgModelDisconnect !== null) {
            this.ecgModelDisconnect();
        }

        if (this.timer) {
            clearTimeout(this.timer);
        }
    }

    /**
     * Getter function for the isSyncDone property;
     */
    public getSyncDone(): boolean {
        return this.isSyncDone;
    }

    /**
     * The logic behind the function:
     *  1. Set an initial timeout after the chum with the replicant starts.
     *  2. If objects are received through the channels then the timer is reinitialized.
     *  3. When no more objects are received from the replicant then an event is emitted
     *  that means the synchronization is over.
     */
    private detectSynchronizationFinish(): void {
        if (!this.timer) {
            this.timer = setTimeout(() => {
                this.isSyncDone = true;
                this.onSyncDone.emit();
            }, 5000);
        }

        if (this.questionnaireModelDisconnect === null) {
            this.questionnaireModelDisconnect = this.questionnaireModel.onUpdated(
                this.reinitializeTimer.bind(this)
            );
        }

        if (this.documentModelDisconnect === null) {
            this.documentModelDisconnect = this.documentModel.onUpdated(
                this.reinitializeTimer.bind(this)
            );
        }

        if (this.wbcModelDisconnect === null) {
            this.wbcModelDisconnect = this.wbcModel.onUpdated(this.reinitializeTimer.bind(this));
        }

        if (this.ecgModelDisconnect === null) {
            this.ecgModelDisconnect = this.ecgModel.onUpdated(this.reinitializeTimer.bind(this));
        }
    }

    /**
     * Emit an event when no more objects are
     * received through the channels from the replicant.
     */
    private handleOnUpdated(): void {
        this.detectSynchronizationFinish();
    }

    /**
     * Function to reinitialize the timer when a new object it's received.
     */
    private reinitializeTimer(): void {
        if (this.timer) {
            clearTimeout(this.timer);
        }

        this.timer = setTimeout(() => {
            this.isSyncDone = true;
            this.onSyncDone.emit();
        }, 3000);
    }
}
