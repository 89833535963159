import type {ReactElement} from 'react';
import * as dateFns from 'date-fns';
import './News.css';
import ReactMarkdown from 'react-markdown';
import type NewsModel from 'one.models/lib/models/NewsModel';
import type {News} from 'one.models/lib/models/NewsModel';
import i18n from '../../i18n';
import '../../Primary.css';
import {Alert} from '@material-ui/lab';
import Paper from '@material-ui/core/Paper';
import type {ObjectData} from 'one.models/lib/models/ChannelManager';
import {CircularProgress} from '@material-ui/core';
import MenuButton from '../menu/MenuButton';
import {hideCircularProgress} from '../utils/Utils';
import {useEffect, useState} from 'react';

/**
 *
 * @param newsModel
 * @param channelId
 * @returns
 */
export function useNewsFromAChannelId(newsModel: NewsModel, channelId: string): ObjectData<News>[] {
    const [responses, setResponses] = useState<ObjectData<News>[]>([]);
    useEffect(() => {
        function fetchNews(): void {
            newsModel
                .entries(channelId)
                .then(value => {
                    setResponses(value);
                })
                .catch(err => {
                    console.error(err);
                });
        }

        const disconnect = newsModel.onNewsEvent(fetchNews);

        fetchNews();

        return disconnect;
    }, [channelId, newsModel]);

    return responses;
}

/**
 * This component build and return the news view.
 * @param {{}} props - Properties of this view.
 * @param {NewsModel} props.newsModel
 * @returns {ReactElement} the news reader view.
 */
export default function NewsReader(props: {newsModel: NewsModel}): ReactElement {
    const news = useNewsFromAChannelId(props.newsModel, 'newsChannel').reverse();

    useEffect(() => {
        hideCircularProgress();
    }, [news]);

    return (
        <>
            <div className="circular-progress-container">
                <CircularProgress className="circularProgress" size={35} />
            </div>
            <div className="page-container hide">
                <div className="scrollable-div">
                    <div className="menu-button-header">
                        <MenuButton />
                        <h2 className="headline"> {i18n.t('common:menu.news')}</h2>
                    </div>
                    {news.length === 0 ? (
                        <Paper square elevation={3} className="page-content-box margin-box">
                            <Alert severity="info" className="patient-info" key={'alert'}>
                                {i18n.t('news:noNews')}
                            </Alert>
                        </Paper>
                    ) : (
                        <>
                            {news.map((post, index) => {
                                return (
                                    <Paper
                                        key={index}
                                        square
                                        elevation={3}
                                        className="page-content-box margin-box"
                                    >
                                        <ReactMarkdown className="paper-font-size">
                                            {post.data.content}
                                        </ReactMarkdown>
                                        <div className="date-style paper-font-size">
                                            {dateFns.format(
                                                post.creationTime,
                                                'MMMM dd yyyy HH:mm'
                                            )}
                                        </div>
                                    </Paper>
                                );
                            })}
                        </>
                    )}
                </div>
            </div>
        </>
    );
}
