import {MenuItem, Select} from '@material-ui/core';
import type {ReactElement} from 'react';
import {useState} from 'react';
import type {ChangeEvent} from 'react';
import DayCalendarView from './DayCalendarView';
import type JournalModel from 'one.models/lib/models/JournalModel';
import WeekCalendarView from './WeekCalendarView';
import MonthCalendarView from './MonthCalendarView';
import type {EventTypes} from '../journal/Journal';
import '../../Primary.css';
import i18n from '../../i18n';
import MenuButton from '../menu/MenuButton';

/**
 * This component is returning the entire view when option "Events Calendar" is selected from the application menu.
 * @param props - Properties of this view.
 * @param props.journalModel
 * @param props.eventTypes
 * @returns  - calendar component.
 */
export default function CalendarViewMenu(props: {
    journalModel: JournalModel;
    eventTypes: EventTypes[];
}): ReactElement {
    const viewType = [
        i18n.t('common:date.month'),
        i18n.t('common:date.week'),
        i18n.t('common:date.day')
    ];
    const [view, setView] = useState(viewType[0]);

    /**
     *  Setting the view type based on selected option from the drop down list menu.
     * @param {ChangeEvent<HTMLInputElement>} event - the emitted event by selecting an option from the drop down list.
     */
    function handleChange(event: ChangeEvent<HTMLInputElement>): void {
        setView(event.target.value);
    }

    /**
     * This function is returning the corresponding calendar view accordingly to the calendarFormat parameter.
     * @param {string} calendarFormat - a string which represent the view type (month/week/day).
     * @returns {ReactElement} - calendar component.
     */
    function getCalendarByFormat(calendarFormat: string): ReactElement {
        switch (calendarFormat) {
            case i18n.t('common:date.day'):
                return (
                    <DayCalendarView
                        journalModel={props.journalModel}
                        eventTypes={props.eventTypes}
                    />
                );
            case i18n.t('common:date.week'):
                return (
                    <WeekCalendarView
                        journalModel={props.journalModel}
                        eventTypes={props.eventTypes}
                    />
                );
            case i18n.t('common:date.month'):
                return (
                    <MonthCalendarView
                        journalModel={props.journalModel}
                        eventTypes={props.eventTypes}
                    />
                );
            default:
                return (
                    <MonthCalendarView
                        journalModel={props.journalModel}
                        eventTypes={props.eventTypes}
                    />
                );
        }
    }

    return (
        <div className="page-container">
            <div className="menu-button-header">
                <MenuButton />
                <h2 className="headline">{i18n.t('common:menu.calendar')}</h2>
            </div>
            <div className="content_calendar">
                <form>
                    <Select
                        className="view-select"
                        //  Because of the hooks methods that are used, the event gets wrongly recognised as MouseEvent
                        //  which wouldn't have the properties it actually has (e.g value).
                        onChange={e => handleChange(e as ChangeEvent<HTMLInputElement>)}
                        defaultValue={i18n.t('common:date.month')}
                    >
                        {viewType.map((option, index) => (
                            <MenuItem key={index} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </Select>
                </form>
                {getCalendarByFormat(view)}
            </div>
        </div>
    );
}
