import type {ReactElement} from 'react';
import '../studyCommon/StudyOverview.css';
import '../../../Primary.css';
import i18n from '../../../i18n';
import MenuButton from '../../menu/MenuButton';
import {changeBackgroundColorToWhite, removeWhiteBackgroundColor} from '../../utils/Utils';
import CurrentDate from '../studyCommon/CurrentDate';
import type SmilerWorkflow from '../../../model/studies/SmilerWorkflow';
import type DocumentModel from 'one.models/lib/models/DocumentModel';
import SmilerTimeline from './SmilerTimeline';
import {useSmilerStudyData} from '../../modelHelper/StudyWorkflowHelper';
import type DataSyncManager from '../../../model/DataSyncManager';
import {STUDY_DURATION} from '../../../model/studies/StudyWorkflow';
import * as dateFns from 'date-fns';
import EndOfStudyMessage from '../studyCommon/EndOfStudyMessage';
import {SMILER_VISIT} from '../../../model/studies/StudyHelper';
import {PRODUCT_TYPE} from '../../Ui';
import type {Product} from '../../Ui';
import StudyProgress from '../studyCommon/StudyProgress';
import StudyHeadline from '../studyCommon/StudyHeadline';
import {useEffect} from 'react';

/**
 * This function builds and returns the home screen stepper.
 * @param props - properties of this view.
 * @param props.smilerWorkflow
 * @param props.documentModel
 * @param props.dataSyncManager
 * @param props.productType
 * @returns - the home page view.
 */
export default function SmilerOverview(props: {
    smilerWorkflow: SmilerWorkflow;
    documentModel: DocumentModel;
    dataSyncManager: DataSyncManager;
    productType: Product;
}): ReactElement {
    const {
        // current study day of the smiler study -> used to keep the study progress up to date
        currentStudyDay,
        // current visit of the participant or undefined for the smiler study -> used to display the active visit for the participant
        currentVisit,
        // getting the amount of days until the next visit becomes available for smiler study
        daysUntilNextVisit,
        // getting the details for each visit for smiler study -> used to highlight active tasks and blur inactive tasks
        visitsProperties,
        // getting the tasks categories
        tasks,
        // getting finished tasks
        finishedTasks
    } = useSmilerStudyData(props.smilerWorkflow);

    useEffect(() => {
        changeBackgroundColorToWhite();

        return () => {
            removeWhiteBackgroundColor();
        };
    }, [props.smilerWorkflow]);

    return (
        <>
            <div className="page-container home-screen">
                <div className="menu-button-header">
                    <MenuButton />
                    <h2 className="headline">
                        {props.productType === PRODUCT_TYPE.Smiler_impact
                            ? i18n.t('common:menu.smiler')
                            : i18n.t('common:menu.overview')}
                    </h2>
                </div>
                <CurrentDate />
                {daysUntilNextVisit !== -1 &&
                    (currentVisit !== SMILER_VISIT.Day_28 ||
                        visitsProperties[currentVisit].tasksCompleted !==
                            visitsProperties[currentVisit].totalTasks) && (
                        <StudyProgress
                            currentStudyDay={currentStudyDay}
                            studyDuration={STUDY_DURATION}
                        />
                    )}
                <p className="next-visit">
                    {daysUntilNextVisit === -1 ||
                    (currentVisit === SMILER_VISIT.Day_28 &&
                        visitsProperties[currentVisit].tasksCompleted ===
                            visitsProperties[currentVisit].totalTasks) ? (
                        <i>
                            {i18n.t('studies:endOfStudy1')}
                            <br />
                            {i18n.t('studies:endOfStudy2')}
                        </i>
                    ) : currentVisit === undefined ||
                      !dateFns.isSameDay(visitsProperties[currentVisit].visitDate, new Date()) ? (
                        daysUntilNextVisit !== -1 && (
                            <i>
                                {i18n.t('studies:smiler.taskInactive')}
                                {daysUntilNextVisit}
                                {i18n.t('studies:days')}
                            </i>
                        )
                    ) : (
                        <i>
                            {props.productType === PRODUCT_TYPE.Smiler_impact ? (
                                <StudyHeadline
                                    totalTasks={visitsProperties[currentVisit].totalTasks}
                                    completedTasks={visitsProperties[currentVisit].tasksCompleted}
                                />
                            ) : (
                                <>
                                    {i18n.t('studies:smiler.visitProgress')}
                                    {visitsProperties[currentVisit].tasksCompleted}/
                                    {visitsProperties[currentVisit].totalTasks}
                                </>
                            )}
                        </i>
                    )}
                </p>
                <SmilerTimeline
                    currentDayOfTheStudy={currentStudyDay}
                    currentVisit={currentVisit}
                    visitsProperties={visitsProperties}
                    documentModel={props.documentModel}
                    tasks={tasks}
                    finishedTasks={finishedTasks}
                    productType={props.productType}
                />
                {(daysUntilNextVisit === -1 ||
                    (currentVisit === SMILER_VISIT.Day_28 &&
                        visitsProperties[currentVisit].tasksCompleted ===
                            visitsProperties[currentVisit].totalTasks)) && <EndOfStudyMessage />}
            </div>
        </>
    );
}
