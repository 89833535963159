import type {ReactElement} from 'react';
import {
    Button,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    List,
    ListItem,
    TextField
} from '@material-ui/core';
import type WbcDiffModel from 'one.models/lib/models/WbcDiffModel';
import {useHistory} from 'react-router-dom';
import i18n from '../../i18n';
import '../../Primary.css';
import InfoMessage, {MESSAGE_TYPE} from '../errors/InfoMessage';
import {displayCircularProgress, hideCircularProgress} from '../utils/Utils';
import type {WbcObservation} from 'one.models/lib/recipes/WbcDiffRecipes';
import MenuButton from '../menu/MenuButton';
import Paper from '@material-ui/core/Paper';
import './AddWBCMeasurement.css';
import {useState} from 'react';

/**
 * HemoCue form for the patients to fill in
 * @param props
 * @param props.wbcModel
 * @returns
 */
export default function AddWBCMeasurement(props: {wbcModel: WbcDiffModel}): ReactElement {
    const history = useHistory();
    const unit = '10^9/L';

    const [wbcCount, setWbcCount] = useState('');
    const [wbcCountUnit, setWbcCountUnit] = useState(unit);

    const [neuCount, setNeuCount] = useState('');
    const [neuCountUnit, setNeuCountUnit] = useState(unit);
    const [neuCountUnsafe, setNeuCountUnsafe] = useState(false);

    const [lymCount, setLymCount] = useState('');
    const [lymCountUnit, setLymCountUnit] = useState(unit);
    const [lymCountUnsafe, setLymCountUnsafe] = useState(false);

    const [monCount, setMonCount] = useState('');
    const [monCountUnit, setMonCountUnit] = useState(unit);
    const [monCountUnsafe, setMonCountUnitUnsafe] = useState(false);

    const [eosCount, setEosCount] = useState('');
    const [eosCountUnit, setEosCountUnit] = useState(unit);
    const [eosCountUnsafe, setEosCountUnsafe] = useState(false);

    const [basCount, setBasCount] = useState('');
    const [basCountUnit, setBasCountUnit] = useState(unit);
    const [basCountUnsafe, setBasCountUnsafe] = useState(false);

    const [errorState, setErrorState] = useState('');

    const wbcObservation: WbcObservation = {
        $type$: 'WbcObservation',
        acquisitionTime: new Date().toUTCString(),
        Leukocytes: {value: wbcCount, unit: wbcCountUnit},
        Neutrophils: neuCount
            ? {value: neuCount, unit: neuCountUnit, unsafe: neuCountUnsafe}
            : undefined,
        Lymphocytes: lymCount
            ? {value: lymCount, unit: lymCountUnit, unsafe: lymCountUnsafe}
            : undefined,
        Monocytes: monCount
            ? {value: monCount, unit: monCountUnit, unsafe: monCountUnsafe}
            : undefined,
        Eosinophils: eosCount
            ? {value: eosCount, unit: eosCountUnit, unsafe: eosCountUnsafe}
            : undefined,
        Basophils: basCount
            ? {value: basCount, unit: basCountUnit, unsafe: basCountUnsafe}
            : undefined
    };

    /**
     * Save the WbcObservation to ONE
     */
    function saveWbcObservation(): void {
        displayCircularProgress();
        props.wbcModel
            .postObservation(wbcObservation)
            .then(() => {
                hideCircularProgress();
                history.push('/journal');
            })
            .catch(() => {
                hideCircularProgress();
                setErrorState('errors:wbc.missingLeukocytes');
            });
    }

    return (
        <>
            <div className="circular-progress-container hide">
                <CircularProgress className="circular-progress" size={35} />
            </div>
            <div className={'page-container'}>
                <InfoMessage
                    errorMessage={errorState}
                    setDisplayMessage={setErrorState}
                    messageType={MESSAGE_TYPE.Error}
                />
                <div className="menu-button-header">
                    <MenuButton />
                    <h2 className="headline"> {i18n.t('wbcMeasurement:wbcForm')}</h2>
                </div>

                <Paper square elevation={3} className="page-content-box wbc-paper">
                    <List className="wbc-list">
                        <> {i18n.t('wbcMeasurement:wbcFormTextfields.wbcTitle')}</>
                        <ListItem className="no-checkbox">
                            <TextField
                                id="wbc-value"
                                label={i18n.t('wbcMeasurement:wbcFormTextfields.countValue')}
                                variant="outlined"
                                onChange={e => setWbcCount(e.target.value)}
                            />
                            <TextField
                                id="owbc-unit"
                                label={i18n.t('wbcMeasurement:wbcFormTextfields.countUnit')}
                                variant="outlined"
                                value={wbcCountUnit}
                                onChange={e => setWbcCountUnit(e.target.value)}
                            />
                        </ListItem>
                        <> {i18n.t('wbcMeasurement:wbcFormTextfields.neuTitle')}</>
                        <ListItem>
                            <TextField
                                id="neu-value"
                                label={i18n.t('wbcMeasurement:wbcFormTextfields.countValue')}
                                variant="outlined"
                                onChange={e => setNeuCount(e.target.value)}
                            />
                            <TextField
                                id="neu-unit"
                                label={i18n.t('wbcMeasurement:wbcFormTextfields.countUnit')}
                                variant="outlined"
                                value={neuCountUnit}
                                onChange={e => setNeuCountUnit(e.target.value)}
                            />
                            <Checkbox
                                color="primary"
                                inputProps={{'aria-label': 'secondary checkbox'}}
                                onChange={e => setNeuCountUnsafe(e.target.checked)}
                            />
                        </ListItem>
                        <> {i18n.t('wbcMeasurement:wbcFormTextfields.lymTitle')}</>
                        <ListItem>
                            <TextField
                                id="lym-value"
                                label={i18n.t('wbcMeasurement:wbcFormTextfields.countValue')}
                                variant="outlined"
                                onChange={e => setLymCount(e.target.value)}
                            />
                            <TextField
                                id="lym-unit"
                                label={i18n.t('wbcMeasurement:wbcFormTextfields.countUnit')}
                                variant="outlined"
                                value={lymCountUnit}
                                onChange={e => setLymCountUnit(e.target.value)}
                            />
                            <Checkbox
                                color="primary"
                                inputProps={{'aria-label': 'secondary checkbox'}}
                                onChange={e => setLymCountUnsafe(e.target.checked)}
                            />
                        </ListItem>
                        <> {i18n.t('wbcMeasurement:wbcFormTextfields.monTitle')}</>
                        <ListItem>
                            <TextField
                                id="mon-value"
                                label={i18n.t('wbcMeasurement:wbcFormTextfields.countValue')}
                                variant="outlined"
                                onChange={e => setMonCount(e.target.value)}
                            />
                            <TextField
                                id="mon-unit"
                                label={i18n.t('wbcMeasurement:wbcFormTextfields.countUnit')}
                                variant="outlined"
                                value={monCountUnit}
                                onChange={e => setMonCountUnit(e.target.value)}
                            />
                            <Checkbox
                                color="primary"
                                inputProps={{'aria-label': 'secondary checkbox'}}
                                onChange={e => setMonCountUnitUnsafe(e.target.checked)}
                            />
                        </ListItem>
                        <> {i18n.t('wbcMeasurement:wbcFormTextfields.eosTitle')}</>
                        <ListItem>
                            <TextField
                                id="eos-value"
                                label={i18n.t('wbcMeasurement:wbcFormTextfields.countValue')}
                                variant="outlined"
                                onChange={e => setEosCount(e.target.value)}
                            />
                            <TextField
                                id="eos-unit"
                                label={i18n.t('wbcMeasurement:wbcFormTextfields.countUnit')}
                                variant="outlined"
                                value={eosCountUnit}
                                onChange={e => setEosCountUnit(e.target.value)}
                            />
                            <Checkbox
                                color="primary"
                                inputProps={{'aria-label': 'secondary checkbox'}}
                                onChange={e => setEosCountUnsafe(e.target.checked)}
                            />
                        </ListItem>
                        <> {i18n.t('wbcMeasurement:wbcFormTextfields.basTitle')}</>
                        <ListItem>
                            <TextField
                                id="bas-value"
                                label={i18n.t('wbcMeasurement:wbcFormTextfields.countValue')}
                                variant="outlined"
                                onChange={e => setBasCount(e.target.value)}
                            />
                            <TextField
                                id="bas-unit"
                                label={i18n.t('wbcMeasurement:wbcFormTextfields.countUnit')}
                                variant="outlined"
                                value={basCountUnit}
                                onChange={e => setBasCountUnit(e.target.value)}
                            />
                            <Checkbox
                                color="primary"
                                inputProps={{'aria-label': 'secondary checkbox'}}
                                onChange={e => setBasCountUnsafe(e.target.checked)}
                            />
                        </ListItem>
                    </List>
                    <FormControlLabel
                        value="end"
                        control={<Checkbox color="primary" />}
                        label={i18n.t('wbcMeasurement:unsafeValues')}
                        labelPlacement="end"
                    />
                </Paper>
                <div className="buttons-container">
                    <Button
                        onClick={() => saveWbcObservation()}
                        color="secondary"
                        variant="contained"
                        autoFocus
                    >
                        {i18n.t('common:buttons.submit')}
                    </Button>
                </div>
            </div>
        </>
    );
}
