import type {ReactElement} from 'react';
import {List, ListItem, ListItemIcon, ListItemText} from '@material-ui/core';
import type ConnectionsModel from 'one.models/lib/models/ConnectionsModel';
import {Link} from 'react-router-dom';
import {Icon} from '../icon/Icon';
import './CloudConnections.css';
import '../../Primary.css';
import i18n from '../../i18n';
import Paper from '@material-ui/core/Paper';
import CloudConnections from './CloudConnections';
import MenuButton from '../menu/MenuButton';
import {usePersonalConnections} from '../modelHelper/ConnectionsHelper';

/**
 * @param {{}} props
 * @param {ConnectionsModel} props.connectionsModel
 * @returns {ReactElement}
 */
export default function PersonalCloud(props: {connectionsModel: ConnectionsModel}): ReactElement {
    const connectionsMap = usePersonalConnections(props.connectionsModel);

    return (
        <>
            <div className="page-container personal-devices-container">
                <div className="menu-button-header">
                    <MenuButton />
                    <h2 className="headline">
                        {i18n.t('connections:personalDevices.personalDevicesTitle')}
                    </h2>
                </div>
                <Paper square elevation={3} className="page-content-box">
                    <List className="PersonalDevicesList">
                        <Link className="app-menu-entry" to={'/invites/personalCloud'}>
                            <ListItem key="inviteButton" button>
                                <ListItemIcon>
                                    <Icon name="Devices" />
                                </ListItemIcon>
                                <ListItemText>
                                    {i18n.t('connections:personalDevices.addNewDevice')}
                                </ListItemText>
                            </ListItem>
                        </Link>
                    </List>
                </Paper>
                <CloudConnections
                    connections={connectionsMap}
                    connectionsModel={props.connectionsModel}
                    personalCloud={true}
                />
            </div>
        </>
    );
}
