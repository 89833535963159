import type {ReactElement} from 'react';
import {isIOS, isTablet, isMobile} from 'react-device-detect';
import {Snackbar, Dialog, Button} from '@material-ui/core';
import {Alert, AlertTitle} from '@material-ui/lab';
import i18n from '../../i18n';
import {Icon} from '../icon/Icon';
import './AddToHomeScreen.css';
import {isStandalone} from '../utils/Utils';
import {useEffect, useState} from 'react';

/**
 * @returns
 */
export default function AddToHomeScreenDialog(): ReactElement {
    const [showDialog, setShowDialog] = useState(false);
    const [isIPad, setIsIPad] = useState(false);

    useEffect(() => {
        if (isIOS && isMobile && !isStandalone()) {
            // Checks if the popup notification should be displayed for iPad or not:
            if (isTablet) {
                setIsIPad(true);
            }
            setShowDialog(true);
        }
    }, []);

    return (
        <Dialog open={showDialog}>
            <Snackbar
                open={showDialog}
                anchorOrigin={{vertical: isIPad ? 'top' : 'bottom', horizontal: 'center'}}
            >
                <div>
                    {isIPad ? (
                        <div className="arrow-up-icon">
                            <Icon name="ArrowUp" />
                            <br />
                        </div>
                    ) : (
                        <div />
                    )}
                    <Alert severity="info">
                        <AlertTitle>
                            <b>{i18n.t('common:addToHomeScreen.title')}</b>
                        </AlertTitle>
                    </Alert>
                    {i18n.t('common:addToHomeScreen.details')}
                    <b>{i18n.t('common:addToHomeScreen.optionName')}</b>
                    <br />
                    <Button
                        className="close-button"
                        color="primary"
                        size="small"
                        onClick={() => setShowDialog(false)}
                    >
                        {i18n.t('common:settings.cancel')}
                    </Button>
                    {isIPad ? (
                        <div />
                    ) : (
                        <div className="arrow-down-icon">
                            <Icon name="ArrowDown" />
                        </div>
                    )}
                </div>
            </Snackbar>
        </Dialog>
    );
}
