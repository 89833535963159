import {OEvent} from 'one.models/lib/misc/OEvent';
import type WbcDiffModel from 'one.models/lib/models/WbcDiffModel';

export type GraphData = {
    value: number;
    timestamp: number;
};

export type WBCData = {
    leukocytes: GraphData[];
    neutrophils: GraphData[];
};

/**
 * This model represents data parser for the wbc measurements.
 * For now it takes the leukocytes and the creation timestamp for each, from a wbc.
 */
export default class WBCDataParser {
    public onUpdated = new OEvent<() => void>();

    private wbcModel: WbcDiffModel;
    private wbcParsedData: WBCData;

    // When the user accesses for the first time the view that renders the wbc data, the call that
    // get the observations from the model should be made. In this way we make sure that we have
    // the wbc data initialized with the right values from one. In order to proceed this call
    // only once, we need this flag because afterwards the event listener will update the data
    // automatically.
    private isDataInitialized: boolean = false;

    private wbcDisconnect: (() => void) | null = null;

    constructor(wbcModel: WbcDiffModel) {
        this.wbcModel = wbcModel;
        this.wbcParsedData = WBCDataParser.resetWBCProps();
    }

    /**
     * Initialize the current instance.
     */
    public init(): void {
        this.wbcDisconnect = this.wbcModel.onUpdated(this.handleOnUpdated.bind(this));
    }

    /**
     * Shutdown module
     */
    public shutdown(): void {
        if (this.wbcDisconnect) {
            this.wbcDisconnect();
        }
    }

    /**
     * Used to extract each leukocyte value and each neutrophile value from the wbc data and its creation time.
     */
    public async extractWbcProperties(): Promise<void> {
        const leukocytes: GraphData[] = [];
        const neutrophils: GraphData[] = [];

        const wbcs = await this.wbcModel.observations();
        wbcs.map(wbc => {
            const timestamp = Date.parse(wbc.data.acquisitionTime);
            leukocytes.push({
                value: parseFloat(wbc.data.Leukocytes.value),
                timestamp: timestamp
            });

            if (wbc.data.Neutrophils) {
                neutrophils.push({
                    value: parseFloat(wbc.data.Neutrophils.value),
                    timestamp: timestamp
                });
            }

            return [leukocytes, neutrophils];
        });

        this.wbcParsedData = {
            leukocytes: leukocytes,
            neutrophils: neutrophils
        };

        // If the "extractWbcProperties" call is made then the wbcData is initialized/updated =>
        // update the flag state.
        this.isDataInitialized = true;
    }

    /**
     * Get the leukocytes array.
     */
    public async getWbcProperties(): Promise<WBCData> {
        // If the flag is false then we should extract the observations and parse the data to be
        // ready to be viewed.
        if (!this.isDataInitialized) {
            await this.extractWbcProperties();
        }

        return this.wbcParsedData;
    }

    /**
     * Update the leukocytes and trigger an 'updated' event
     * when the wbc model triggers an 'updated' event.
     */
    private async handleOnUpdated(): Promise<void> {
        await this.extractWbcProperties();
        this.onUpdated.emit();
    }

    /**
     * Used to reset the wbc data values when the class instance is created.
     */
    private static resetWBCProps(): WBCData {
        return {
            leukocytes: [],
            neutrophils: []
        };
    }
}
