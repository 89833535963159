import {useState} from 'react';
import type {ReactElement} from 'react';
import type QuestionnaireModel from 'one.models/lib/models/QuestionnaireModel';
import type {QuestionnaireResponses} from 'one.models/lib/recipes/QuestionnaireRecipes/QuestionnaireResponseRecipes';
import {useForceRender} from '../modelHelper/useForceRender';
import {
    useEQ5D3LQuestionnaireAndResponse,
    useQuestionnairesBuilder
} from '../modelHelper/QuestionnaireModelHelper';
import type {QuestionnaireResponseBuilderItem} from './QuestionnaireResponseBuilder';
import './QuestionnaireView.css';
import type {ObjectData} from 'one.models/lib/models/ChannelManager';

/**
 * The questionnaire popup view.
 * @param props
 * @param props.questionnaireModel - questionnaire model.
 * @param props.questionnaireResponse - data that will be displayed in the popup.
 */
export default function QuestionnaireView(props: {
    questionnaireModel: QuestionnaireModel;
    questionnaireResponse: ObjectData<QuestionnaireResponses>;
}): ReactElement {
    /** the questionnaire type **/
    const questionnaireType = 'EQ5D3L';

    const [error, setError] = useState('');

    const forceRender = useForceRender();
    const [questionnaireMap, responsesMap] = useEQ5D3LQuestionnaireAndResponse(
        props.questionnaireModel,
        setError,
        questionnaireType,
        undefined,
        props.questionnaireResponse.id ? props.questionnaireResponse.id : undefined
    );
    const questionnaire = questionnaireMap.get(questionnaireType);
    const builderMap = useQuestionnairesBuilder(responsesMap, forceRender, questionnaire);
    const builder = builderMap.get(questionnaireType);

    /**
     * Used to build and display the answers of the questionnaire.
     * @param questionnaireResponseBuilderItem - getting the answer for a question.
     */
    function buildAnswer(
        questionnaireResponseBuilderItem: QuestionnaireResponseBuilderItem
    ): ReactElement {
        switch (questionnaireResponseBuilderItem.question.type) {
            case 'choice':
                return (
                    <p key={questionnaireResponseBuilderItem.question.linkId}>
                        {questionnaireResponseBuilderItem.value[0] &&
                            questionnaireResponseBuilderItem.value[0].valueCoding &&
                            questionnaireResponseBuilderItem.value[0].valueCoding.display}
                    </p>
                );
            case 'integer':
                return (
                    <p key={questionnaireResponseBuilderItem.question.linkId}>
                        {questionnaireResponseBuilderItem.value[0] &&
                            questionnaireResponseBuilderItem.value[0].valueInteger &&
                            Number(questionnaireResponseBuilderItem.value[0].valueInteger)}
                    </p>
                );
        }

        return <></>;
    }

    /**
     * Build the questionnaire, question-answer pairs.
     */
    function buildQuestionnaire(): ReactElement {
        if (builder === undefined) {
            return <></>;
        }

        const questions = [];
        let index = 0;

        for (const questionIterator of builder.questionIterator()) {
            index++;
            questions.push(
                <div key={index} className="question answer">
                    {index}. {questionIterator.question.text}
                    {buildAnswer(questionIterator)}
                </div>
            );
        }

        return <>{questions}</>;
    }

    return <>{error ? error : buildQuestionnaire()}</>;
}
