import type {ReactElement} from 'react';
import type JournalModel from 'one.models/lib/models/JournalModel';
import type {EventListEntry} from 'one.models/lib/models/JournalModel';
import {EventType} from 'one.models/lib/models/JournalModel';
import type {EventTypes} from '../journal/Journal';
import {Help} from 'mdi-material-ui';
import * as dateFns from 'date-fns';
import i18n from '../../i18n';
import type {DocumentInfo} from 'one.models/lib/models/DocumentModel';
import type {ObjectData} from 'one.models/src/models/ChannelManager';
import type {AudioExercise} from 'one.models/lib/recipes/AudioExerciseRecipes';
import {useEffect, useState} from 'react';

/**
 * This function provides a event list response based on the eventList model.
 * @param journalModel - The event list model.
 * @returns - an array with event list responses.
 */
export function useEventList(journalModel: JournalModel): EventListEntry[] {
    const [responses, setResponses] = useState<EventListEntry[]>([]);

    useEffect(() => {
        /**
         * Get all the events
         */
        function fetchEvents(): void {
            journalModel
                .retrieveAllEvents()
                .then((value: EventListEntry[]) => {
                    setResponses(value.reverse()); // TODO: sort order should be passed as sorting
                    // flag to events() call for efficiency reasons
                })
                .catch(err => {
                    // TODO: This should be replaced by something the user actually sees
                    console.error(`Error fetching data: ${err}`);
                });
        }

        // TODO to be updated when JournalModel is updated to the new EventMechanism (OEvent)
        journalModel.on('updated', fetchEvents);
        fetchEvents();

        return () => {
            journalModel.removeListener('updated', fetchEvents);
        };
    }, [journalModel]);

    return responses;
}

/**
 * This function provides a event list response based on the eventList model.
 * @param event - The event list model.
 * @param listOfEventTypes - array with event list responses.
 * @returns  - needed information
 */
export function getCurrentEventInformation(
    event: EventListEntry,
    listOfEventTypes: EventTypes[]
): {
    hour: string;
    minutes: string;
    icon?: ReactElement;
    type: string;
} {
    const currentEventType = listOfEventTypes.find(element => element.type === event.type);
    let type: string;

    switch (event.type) {
        case EventType.DocumentInfo: {
            const castedEventData = event.data as ObjectData<DocumentInfo>;

            switch (castedEventData.data.mimeType) {
                case 'application/pdf':
                    type = i18n.t('common:eventTypes.documentInfo.pdf');
                    break;
                case 'image/jpeg':
                case 'image/png':
                    type = i18n.t('common:eventTypes.documentInfo.image');
                    break;
                default:
                    type = i18n.t('common:eventTypes.documentInfo.document');
                    break;
            }
            break;
        }
        case EventType.ECGEvent: {
            // We must check for the ECGEvent because the retrieve function passed in
            // the journalModel retrieves undefined for the data field because of
            // performance issues.
            type = i18n.t('common:eventTypes.Electrocardiogram');
            break;
        }
        case EventType.AudioExercise: {
            const castedEventData = event.data as ObjectData<AudioExercise>;
            type = castedEventData.data.name;
            break;
        }
        default: {
            type = i18n.t(`common:eventTypes.${event.data.data.$type$}`);
            break;
        }
    }

    if (!currentEventType) {
        type = i18n.t('common:eventTypes.unknownEvent');
    }

    return {
        hour: dateFns.format(event.data.creationTime, 'HH'),
        minutes: dateFns.format(event.data.creationTime, 'mm'),
        icon: currentEventType ? currentEventType.icon : <Help />,
        type: type
    };
}
