import {useState} from 'react';
import type {ReactElement} from 'react';
import {useHistory} from 'react-router-dom';
import ImportFile, {
    ACCEPTED_FILE_TYPES,
    IMPORT_FILE_CLASSNAME
} from '../../importDocument/ImportFile';
import i18n from '../../../i18n';
import List from '@material-ui/core/List';
import Button from '@material-ui/core/Button';
import PopupBox, {POPUP_BOX_TYPE} from '../../popupBox/PopupBox';
import type DocumentModel from 'one.models/lib/models/DocumentModel';
import {SMILER_TASK} from '../../../model/studies/SmilerWorkflow';
import type {SmilerVisitTask} from '../../../model/studies/SmilerWorkflow';
import * as dateFns from 'date-fns';
import {IMPACT_TASK} from '../../../model/studies/ImpactWorkflow';
import type {ImpactVisitTask} from '../../../model/studies/ImpactWorkflow';
import {playAudioFile} from './AudioPlayer';
import type AudioExerciseModel from 'one.models/lib/models/AudioExerciseModel';
import {useEntryViewContext} from '../../journal/EntryView';
import type {SmilerStudyVisit, ImpactStudyVisit} from '../../../model/studies/StudyHelper';
import {verifyImpactVisit} from './CommonFunctionalities';

/** The types of tasks. */
export const TASK_TYPES = {
    active: 'active',
    optional: 'optional',
    missing: 'missing'
} as const;

/** The type definition based on the TASK_TYPES value. */
export type TaskTypes = typeof TASK_TYPES[keyof typeof TASK_TYPES];

/**
 * Represents the smiler study dashboard.
 * @param props
 * @param props.documentModel - the document model.
 * @param props.tasks - the tasks separated in three sets by categories: active, optional and missing.
 * @param props.taskTypes - for which tasks category the dashboard is build.
 * @param props.visitDate - the date of the visit.
 * @param props.currentVisit
 * @param props.audioExerciseModel
 */
export default function Dashboard(props: {
    documentModel?: DocumentModel;
    tasks: (SmilerVisitTask | ImpactVisitTask)[];
    taskTypes: TaskTypes;
    visitDate?: Date;
    currentVisit?: SmilerStudyVisit | ImpactStudyVisit;
    audioExerciseModel?: AudioExerciseModel;
}): ReactElement {
    // if one of the ecg or wbc are clicked then the popup with the information need to be displayed
    // this state handle this part
    const [clickedTask, setClickedTask] = useState('');

    // the context for the entry view that is displayed with the audio player
    const {closeEntryViewCallback, setContent, setTitle} = useEntryViewContext();

    const history = useHistory();

    /**
     * Trigger specific action depending on what task was clicked.
     * @param task - specify the action that will happens.
     */
    function handleTaskClickEvent(task: SmilerVisitTask | ImpactVisitTask): void {
        switch (task) {
            case SMILER_TASK.Questionnaire:
                history.push('/answerQuestionnaire/new?language=de');
                break;
            case SMILER_TASK.Ecg:
            case SMILER_TASK.Wbc:
                setClickedTask(i18n.t(`studies:smiler.task.${task}`));
                break;
            case IMPACT_TASK.AudioExercise:
                if (props.audioExerciseModel !== undefined) {
                    playAudioFile(
                        closeEntryViewCallback,
                        setContent,
                        setTitle,
                        props.audioExerciseModel,
                        props.currentVisit === undefined
                            ? undefined
                            : verifyImpactVisit(props.currentVisit)
                    );
                }
                break;
        }
    }

    return (
        <>
            {props.tasks.length > 0 && (
                <List className={`dashboard ${props.taskTypes}`}>
                    <div
                        className={`${
                            props.taskTypes === TASK_TYPES.optional
                                ? 'optional-tasks-headline'
                                : props.taskTypes === TASK_TYPES.missing
                                ? 'missing-tasks-headline'
                                : 'visit-date'
                        }`}
                    >
                        {props.taskTypes === TASK_TYPES.optional
                            ? ''
                            : props.taskTypes === TASK_TYPES.missing
                            ? i18n.t('studies:missingTasks')
                            : props.taskTypes === TASK_TYPES.active && (
                                  <>
                                      {props.visitDate &&
                                          dateFns.format(props.visitDate, 'dd/MM/yyyy')}
                                      <div className="active-tasks-headline">
                                          {i18n.t('studies:openTasks')}
                                      </div>
                                  </>
                              )}
                    </div>
                    {props.tasks.map(action => (
                        <div key={action}>
                            {props.documentModel && action === SMILER_TASK.Photo ? (
                                <div key={action} className="dashboard-button">
                                    <ImportFile
                                        key={action}
                                        documentModel={props.documentModel}
                                        type={ACCEPTED_FILE_TYPES.Pic}
                                        className={
                                            props.taskTypes === TASK_TYPES.optional
                                                ? IMPORT_FILE_CLASSNAME.DashboardInactiveButton
                                                : props.taskTypes === TASK_TYPES.active
                                                ? IMPORT_FILE_CLASSNAME.DashboardActiveButton
                                                : IMPORT_FILE_CLASSNAME.DashboardMissingButton
                                        }
                                        text={
                                            i18n.t(`studies:stepAction.${action}`) +
                                            ' ' +
                                            i18n.t('studies:smiler.add')
                                        }
                                    />
                                </div>
                            ) : (
                                <Button
                                    color="default"
                                    variant="contained"
                                    className={`${props.taskTypes}-task-button`}
                                    onClick={() => handleTaskClickEvent(action)}
                                >
                                    {i18n.t(`studies:stepAction.${action}`)}
                                    {action === IMPACT_TASK.AudioExercise
                                        ? i18n.t('studies:impact.listen')
                                        : i18n.t('studies:smiler.add')}
                                </Button>
                            )}
                        </div>
                    ))}
                </List>
            )}
            <PopupBox
                setPopupText={setClickedTask}
                popupText={clickedTask}
                popupType={POPUP_BOX_TYPE.Info}
            />
        </>
    );
}
