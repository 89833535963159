import type {ReactElement} from 'react';
import './OneBrowser.css';
import {Route, Switch, useRouteMatch} from 'react-router-dom';
import {OneBrowserVersionedObjectsScreen} from './OneBrowserVersionedObjectsScreen';
import {OneBrowserDetailsScreen} from './OneBrowserDetailsScreen';

/**
 * This component builds and returns the events list view.
 * @returns {ReactElement} - Entire event list view
 */
export default function OneBrowser(): ReactElement {
    const match = useRouteMatch();

    return (
        <div>
            <Switch>
                <Route exact path={`${match.url}/versioned-object`}>
                    <OneBrowserVersionedObjectsScreen />
                </Route>
                <Route exact path={`${match.url}/versioned-object/:objectId?`}>
                    <OneBrowserDetailsScreen />
                </Route>
            </Switch>
        </div>
    );
}
