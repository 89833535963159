import * as dateFns from 'date-fns';
import i18n from '../../i18n';
import {useCallback, useRef, useState} from 'react';

/**
 * Display the loading wheel in the page.
 *
 * The loading wheel should always have the circular-progress-container class
 * and the page content should have the page-container class (or for registration
 * pages the registration-stepper-wrapper class is also considered).
 *
 * When the loading wheel is displayed the page content is hidden.
 */
export function displayCircularProgress(): void {
    const pageContainer = document.getElementsByClassName('page-container')[0];

    if (pageContainer) {
        pageContainer.classList.add('hide');
    }

    if (pageContainer) {
        const circularProgressBar = document.getElementsByClassName(
            'circular-progress-container'
        )[0];

        if (circularProgressBar && circularProgressBar.classList.contains('hide')) {
            circularProgressBar.classList.remove('hide');
        }
    }
}

/**
 * Hide the loading wheel.
 *
 * The loading wheel should always have the circular-progress-container class
 * and the page content should have the page-container class (or for registration
 * pages the registration-stepper-wrapper class is also considered).
 *
 * When the page content is displayed the loading wheel is hidden.
 */
export function hideCircularProgress(): void {
    const circularProgressBar = document.getElementsByClassName('circular-progress-container')[0];

    if (circularProgressBar) {
        circularProgressBar.classList.add('hide');

        const pageContainer = document.getElementsByClassName('page-container')[0];

        if (pageContainer && pageContainer.classList.contains('hide')) {
            pageContainer.classList.remove('hide');
        }
    }
}

/**
 * This returns a function that can be used to force a rerender.
 *
 * This is sometimes required, when registering for an update of a complex data structure
 * where only the content changes, but not the address, so updating a state would not trigger
 * a re-render.
 */
export function useForceRender(): () => void {
    const [, setRedrawState] = useState(false);
    const stateRef = useRef(true);

    return useCallback(() => {
        setRedrawState(stateRef.current);
        stateRef.current = !stateRef.current;
    }, [setRedrawState]);
}

/**
 * Global internationalized formatter for a date.
 * @param date - the date that is formatted.
 * @returns The formatted date.
 */
export function formatDate(date: Date | number | undefined): string {
    if (date) {
        return (
            dateFns.format(date, 'dd') +
            ' ' +
            i18n.t(`common:default.months.${dateFns.format(date, 'MMMM')}`) +
            ' ' +
            dateFns.format(date, 'yyyy')
        );
    }

    return '';
}
