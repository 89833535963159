import type {ReactElement} from 'react';
import i18n from '../../../i18n';

/**
 * Common component for studies which displays the progress of the study based on the current
 * study day and the study duration. (e.g. day 10 out of 30).
 * @param props
 * @param props.currentStudyDay - represents the current day of the study in which the user is.
 * @param props.studyDuration - how long the study takes place (represented in days).
 */
export default function StudyProgress(props: {
    currentStudyDay: number;
    studyDuration: number;
}): ReactElement {
    return (
        <div className="study-progress">
            <i>
                {i18n.t('studies:studyProgress.day')} {props.currentStudyDay}
                {i18n.t('studies:studyProgress.from')} {props.studyDuration}
            </i>
        </div>
    );
}
