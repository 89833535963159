import {Alert, AlertTitle} from '@material-ui/lab';
import type {ReactElement} from 'react';
import i18n from '../../i18n';
import Paper from '@material-ui/core/Paper/Paper';

export const MESSAGE_TYPE = {
    Error: 'error',
    Warning: 'warning',
    Info: 'info',
    Success: 'success'
} as const;

/** The type definition based on the MESSAGE_TYPE value. **/
export type InformationType = typeof MESSAGE_TYPE[keyof typeof MESSAGE_TYPE];

/**
 * @param props
 * @param props.errorMessage
 * @param props.setDisplayMessage
 * @param props.messageType
 * @returns
 */
export default function InfoMessage(props: {
    errorMessage: string;
    setDisplayMessage: (value: string) => void;
    messageType: InformationType;
}): ReactElement {
    return (
        <>
            {props.errorMessage !== '' && (
                <Paper
                    square
                    elevation={3}
                    className={`stick-message-top message-font-size ${
                        props.messageType === MESSAGE_TYPE.Error
                            ? 'error-color'
                            : props.messageType === MESSAGE_TYPE.Warning
                            ? 'warning-color'
                            : props.messageType === MESSAGE_TYPE.Success
                            ? 'success-color'
                            : ''
                    }`}
                >
                    <Alert
                        severity={props.messageType}
                        onClose={() => {
                            props.setDisplayMessage('');
                        }}
                    >
                        <AlertTitle className="message-title">
                            {i18n.t(
                                `errors:title${
                                    props.messageType[0].toUpperCase() + props.messageType.substr(1)
                                }`
                            )}
                        </AlertTitle>
                        {i18n.t(`${props.errorMessage}`)}
                    </Alert>
                </Paper>
            )}
        </>
    );
}
