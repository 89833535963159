import type {Questionnaire} from 'one.models/lib/models/QuestionnaireModel';
import {QuestionnaireEQ5D3L} from './QuestionnaireEQ5D3L';

/** Needed for users that has questionnaires
 *  saved with the old questionnaire url -> 'http://uk-erlangen.de/freeda/questionnaire/EQ5D3L_de'
 **/
export const QuestionnaireEQ5D3L_old: Questionnaire = {
    resourceType: 'Questionnaire',
    language: 'de',
    url: 'http://uk-erlangen.de/freeda/questionnaire/EQ5D3L_de',
    name: 'EQ5D3L',
    status: 'active',
    item: [...QuestionnaireEQ5D3L.item]
};
