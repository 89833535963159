import type ConnectionsModel from 'one.models/lib/models/ConnectionsModel';
import type {ConnectionInfo} from 'one.models/lib/misc/CommunicationModule';
import type {SHA256IdHash} from 'one.core/lib/util/type-checks';
import type {Person} from 'one.core/lib/recipes';
import type AccessModel from 'one.models/lib/models/AccessModel';
import {CONNECTIONS_TYPE} from '../cloudConnections/Connections';
import type {Connection} from '../cloudConnections/Connections';
import i18n from '../../i18n';
import {downloadFile} from './DownloadHelper';
import {useEffect, useState} from 'react';

/* TODO this part adding the optional parameters (setPanels, panels) is a temporary solution for release
 *  they are used to set the panels in the first case when the connections list is rendered for the panels
 *  that contain the connections to be opened when going to connections page. They are used in the
 *  2 functions (useOtherConnections and usePersonal) ,set as optional parameters
 */

/**
 * Identify all personal cloud connections and return the connections information.
 *
 * @param connectionsModel
 * @param panels
 * @param setPanels
 * @returns
 */
export function usePersonalConnections(
    connectionsModel: ConnectionsModel,
    panels?: Connection[],
    setPanels?: (value: Connection[]) => void
): Map<SHA256IdHash<Person>, ConnectionInfo[]> {
    const [personsToConnectionsMap, setPersonsToConnectionsMap] = useState<
        Map<SHA256IdHash<Person>, ConnectionInfo[]>
    >(new Map());

    useEffect(() => {
        function fetchConnections(): void {
            const cloudConnections = connectionsModel
                .connectionsInfo()
                .filter((connection: ConnectionInfo) => connection.isInternetOfMe);
            setPersonsToConnectionsMap(createPersonToConnectionsMap(cloudConnections));

            // if there is a cloudConnection in the list set the panel too to be opened
            if (cloudConnections.length > 0) {
                if (panels && !panels.includes(CONNECTIONS_TYPE.PersonalCloud)) {
                    panels.push(CONNECTIONS_TYPE.PersonalCloud);

                    if (setPanels) {
                        setPanels(panels);
                    }
                }
            }
        }

        const disconnect = connectionsModel.onConnectionsChange(fetchConnections);
        fetchConnections();

        return disconnect;
    }, [connectionsModel]);

    return personsToConnectionsMap;
}

/**
 * Identify all partner connections which are included in a specific access group and return the connections information.
 * @param {ConnectionsModel} connectionsModel
 * @param {AccessModel} accessModel
 * @param {string} groupName
 * @param {(value: string) => void} setErrorCallback
 * @param {Connection[]} panels
 * @param {(value: Connection[]) => void} setPanels
 * @returns {ConnectionInfo[]}
 */
export function useOtherConnections(
    connectionsModel: ConnectionsModel,
    accessModel: AccessModel,
    groupName: string,
    setErrorCallback: (value: string) => void,
    panels?: Connection[],
    setPanels?: (value: Connection[]) => void
): Map<SHA256IdHash<Person>, ConnectionInfo[]> {
    const [personsToConnectionsMap, setPersonsToConnectionsMap] = useState<
        Map<SHA256IdHash<Person>, ConnectionInfo[]>
    >(new Map());

    useEffect(() => {
        async function fetchConnections(): Promise<void> {
            try {
                // check if the group exists before accessing it's members
                await accessModel.getAccessGroupByName(groupName);

                const availablePartnerConnections = await accessModel.getAccessGroupPersons(
                    groupName
                );

                const partnerConnections = connectionsModel
                    .connectionsInfo()
                    .filter(
                        (connection: ConnectionInfo) =>
                            !connection.isInternetOfMe &&
                            availablePartnerConnections.includes(connection.targetPersonId)
                    );
                setPersonsToConnectionsMap(createPersonToConnectionsMap(partnerConnections));

                // if a partner connection is received check group and set specific panel to open
                if (
                    partnerConnections.length > 0 &&
                    panels &&
                    !panels.includes(CONNECTIONS_TYPE.ClinicServer)
                ) {
                    panels.push(CONNECTIONS_TYPE.ClinicServer);

                    if (setPanels) {
                        setPanels([...panels]);
                    }
                }
            } catch (error) {
                // the groups_updated event is emitted also when the groups are created
                // and because the application is not yet initialised or the group that
                // we want to access is not created yet we expect to receive an error
                // when trying to access the group
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                if (error.name !== 'FileNotFoundError' || error.name !== 'Error') {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    setErrorCallback(error.name);
                }
            }
        }

        const disconnectConnectionsChange = connectionsModel.onConnectionsChange(fetchConnections);
        const disconnectGroupsUpdated = accessModel.onGroupsUpdated(fetchConnections);
        fetchConnections().catch((err: Error) => {
            setErrorCallback(err.name);
        });

        return () => {
            disconnectConnectionsChange();
            disconnectGroupsUpdated();
        };
    }, [connectionsModel, accessModel, groupName, setErrorCallback]);

    return personsToConnectionsMap;
}

/**
 * Identify all partner connections and return the connections information.
 *
 * @param {ConnectionsModel} connectionsModel
 * @returns {Map<SHA256IdHash<Person>, ConnectionInfo[]>}
 */
export function usePartnerConnections(
    connectionsModel: ConnectionsModel
): Map<SHA256IdHash<Person>, ConnectionInfo[]> {
    const [personsToConnectionsMap, setPersonsToConnectionsMap] = useState<
        Map<SHA256IdHash<Person>, ConnectionInfo[]>
    >(new Map());

    useEffect(() => {
        function fetchConnections(): void {
            const partnerConnections = connectionsModel
                .connectionsInfo()
                .filter((connection: ConnectionInfo) => !connection.isInternetOfMe);

            setPersonsToConnectionsMap(createPersonToConnectionsMap(partnerConnections));
        }

        const disconnect = connectionsModel.onConnectionsChange(fetchConnections);
        fetchConnections();

        return disconnect;
    }, [connectionsModel]);

    return personsToConnectionsMap;
}

/**
 * @param {ConnectionInfo[]} connections
 * @returns {Map<SHA256IdHash<Person>, ConnectionInfo[]>}
 */
export function createPersonToConnectionsMap(
    connections: ConnectionInfo[]
): Map<SHA256IdHash<Person>, ConnectionInfo[]> {
    const connectionsMap = new Map<SHA256IdHash<Person>, ConnectionInfo[]>();

    for (const conn of connections) {
        if (connectionsMap.has(conn.targetPersonId)) {
            const connectionsOfPerson: ConnectionInfo[] | undefined = connectionsMap.get(
                conn.targetPersonId
            );

            if (connectionsOfPerson !== undefined) {
                connectionsOfPerson.push(conn);
            }

            connectionsMap.set(
                conn.targetPersonId,
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/ban-ts-comment
                // @ts-ignore
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                connectionsOfPerson
            );
        } else {
            connectionsMap.set(conn.targetPersonId, [conn]);
        }
    }

    return connectionsMap;
}

/**
 * Choose the correct URL for the connection invite.
 *
 * @returns {string}
 */
export function urlBase(): string {
    const {hostname, protocol, port} = window.location;

    if (port === '') {
        return `${protocol}//${hostname}`;
    }

    return `${protocol}//${hostname}:${port}`;
}

/**
 * Create the URL for the invitation.
 *
 * @param {string} code
 * @returns {string}
 */
export function acceptUrl(code: string): string {
    return `${urlBase()}/invites/personalCloud/?invited=true/#${code}`;
}

/**
 * The generated pairing information will be transmitted in the QR-code and
 * will be used for verifying the identity of the receiver instance.
 *
 * @param {ConnectionsModel} connectionsModel
 * @param {boolean} takeOver
 * @returns {Promise<void>}
 */
export async function downloadPairingInformationFile(
    connectionsModel: ConnectionsModel,
    takeOver: boolean
): Promise<void> {
    const currentInvitationDuration = connectionsModel.authTokenExpirationDuration;
    // make the invitation valid for 24 hours
    connectionsModel.authTokenExpirationDuration = 60000 * 60 * 24;

    const pairingInformation = await connectionsModel.generatePairingInformation(takeOver);
    // restore invitation availability
    connectionsModel.authTokenExpirationDuration = currentInvitationDuration;

    const encryptedInformationString = JSON.stringify(pairingInformation);
    const filename = i18n.t('connections:rpiFileTitle') + '.json';
    const contentType = 'application/json;charset=utf-8;';

    await downloadFile(encryptedInformationString, filename, contentType);
}
