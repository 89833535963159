import * as dateFns from 'date-fns';
import i18n from '../../i18n';
import {useEffect, useLayoutEffect, useRef, useState} from 'react';

/**
 * Check if the content is larger than the window size.
 *
 * This functions calculates the window height without the footer and
 * checks if the height of the element received as parameter is larger
 * than the computed content height.
 *
 * @param element
 * @returns
 */
export function contentAreaLargerThanDisplayArea(element: HTMLElement): boolean {
    const pageHeightWithoutFooter =
        window.innerHeight - document.getElementsByTagName('footer')[0].clientHeight;

    if (document.getElementsByClassName('register-stepper-container')[0] === undefined) {
        return (
            pageHeightWithoutFooter < element.children[0].scrollHeight ||
            element.children[0].clientHeight < element.children[0].scrollHeight
        );
    } else {
        if (
            document.getElementsByClassName('register-stepper-buttons-container')[0] ===
                undefined ||
            document.getElementsByClassName('registration-stepper')[0] === undefined
        ) {
            return false;
        }

        return (
            pageHeightWithoutFooter -
                document.getElementsByClassName('register-stepper-buttons-container')[0]
                    .clientHeight -
                document.getElementsByClassName('registration-stepper')[0].clientHeight <
            element.children[0].scrollHeight
        );
    }
}

/**
 * Add css class for scrollbar for the parent of the element received as argument.
 *
 * @param {HTMLElement} element
 */
export function addScrollbar(element: HTMLElement): void {
    if (element.parentElement) {
        element.parentElement.classList.add('is-scrollable');
    }
}

/**
 * Remove css class for scrollbar for the parent of the element received as argument.
 *
 * @param {HTMLElement} element
 */
export function removeScrollbar(element: HTMLElement): void {
    if (element.parentElement) {
        element.parentElement.classList.remove('is-scrollable');
    }
}

/**
 * Check if an standalone application is used
 *
 * @returns {boolean}
 */
export function isStandalone(): boolean {
    // ts-ignore needed because window.navigator.standalone is available only for safari
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const isInStandalone = window.navigator.standalone;

    return !!isInStandalone;
}

/**
 * Creates the dynamic manifest, setting the start_url at current window location. (Used for STH)
 *
 * @returns {void}
 */
export function generateDynamicManifest(): void {
    const dynamicManifest = {
        name: 'smiler',
        short_name: 'smiler',
        scope: window.location.origin,
        start_url:
            window.location.origin +
            window.location.pathname +
            window.location.search +
            window.location.hash,
        display: 'standalone',
        theme_color: '#000000',
        background_color: '#ffffff',
        icons: [
            {
                src: window.location.origin + '/smiler_one.png',
                type: 'image/png',
                sizes: '769x768'
            },
            {
                src: window.location.origin + '/smiler_one.png',
                type: 'image/png',
                sizes: '192x192'
            },
            {
                src: window.location.origin + '/smiler_one.png',
                type: 'image/png',
                sizes: '64x64 32x32 24x24 16x16'
            }
        ]
    };
    const stringManifest = JSON.stringify(dynamicManifest);
    const blob = new Blob([stringManifest], {type: 'application/json'});
    const manifestURL = URL.createObjectURL(blob);
    const htmlTag = document.querySelector('#dynamic-manifest');

    if (htmlTag) {
        htmlTag.setAttribute('href', manifestURL);
    }
}

/**
 * Display the loading wheel in the page.
 *
 * The loading wheel should always have the circular-progress-container class
 * and the page content should have the page-container class.
 *
 * When the loading wheel is displayed the page content is hidden.
 */
export function displayCircularProgress(): void {
    const pageContainer = document.getElementsByClassName('page-container')[0];

    if (pageContainer) {
        pageContainer.classList.add('hide');
    }

    if (pageContainer) {
        const circularProgressBar = document.getElementsByClassName(
            'circular-progress-container'
        )[0];

        if (circularProgressBar && circularProgressBar.classList.contains('hide')) {
            circularProgressBar.classList.remove('hide');
        }
    }
}

/**
 * Hide the loading wheel.
 *
 * The loading wheel should always have the circular-progress-container class
 * and the page content should have the page-container class.
 *
 * When the page content is displayed the loading wheel is hidden.
 */
export function hideCircularProgress(): void {
    const circularProgressBar = document.getElementsByClassName('circular-progress-container')[0];

    if (circularProgressBar) {
        circularProgressBar.classList.add('hide');

        const pageContainer = document.getElementsByClassName('page-container')[0];

        if (pageContainer && pageContainer.classList.contains('hide')) {
            pageContainer.classList.remove('hide');
        }
    }
}

/**
 *  Listen for window resize event and check if the scrollbar is needed
 *  after resize or it the scrollbar should be removed after resize.
 *
 *  @param {HTMLElement} element
 */
export function updateScrollbarAtWindowResize(element: HTMLElement): void {
    window.onresize = () => {
        if (contentAreaLargerThanDisplayArea(element)) {
            addScrollbar(element);
        } else {
            removeScrollbar(element);
        }
    };
}

/**
 * Global internationalized formatter for a date.
 * @param {Date} date - the date that is formatted.
 * @param {boolean} includeTime - flag for adding the time to the formatted date.
 * @returns {string} - formatted date.
 */
export function formatDate(date: Date | number | undefined, includeTime?: boolean): string {
    if (date) {
        return (
            dateFns.format(date, 'dd') +
            ' ' +
            i18n.t(`common:months.${dateFns.format(date, 'MMMM')}`) +
            ' ' +
            dateFns.format(date, 'yyyy') +
            `${includeTime ? ' - ' + dateFns.format(date, 'HH:mm:ss') : ''}`
        );
    }

    return '';
}

/**
 * Custom hook for getting the size of the window.
 * @returns {number[]} - width and height of the window.
 */
export function useWindowSize(): number[] {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize(): void {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

/**
 * Used to change the color of the background to white.
 */
export function changeBackgroundColorToWhite(): void {
    const rootHTMLElement = document.getElementById('root');

    if (rootHTMLElement) {
        rootHTMLElement.classList.add('white-background');
    }
}

/**
 * Used to remove the white background.
 */
export function removeWhiteBackgroundColor(): void {
    const rootHTMLElement = document.getElementById('root');

    if (rootHTMLElement) {
        rootHTMLElement.classList.remove('white-background');
    }
}

/**
 * Set the time of a date to the end of the day time.
 * @param date
 */
export function setTimeToEndOfTheDay(date: Date): Date {
    return dateFns.setHours(dateFns.setMinutes(dateFns.setSeconds(new Date(date), 59), 59), 23);
}

/**
 * Set the time of a date to the start of the daytime.
 * @param date
 */
export function setTimeToStartOfTheDay(date: Date): Date {
    return dateFns.setHours(dateFns.setMinutes(dateFns.setSeconds(new Date(date), 0), 0), 0);
}

/**
 * Custom hook for getting the previous value of a state.
 * @param value - the value of a state.
 */
export function usePrevious<T>(value: T): T | undefined {
    const ref = useRef<T>();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}
