import type InstancesModel from 'one.models/lib/models/InstancesModel';
import type {SHA256IdHash} from 'one.core/lib/util/type-checks';
import type {Instance} from 'one.core/lib/recipes';
import i18n from '../../i18n';
import {useEffect, useState} from 'react';

/**
 * Extract the current anonymous instance id.
 *
 * @param instancesModel
 * @param setErrorCallback
 * @returns
 */
export function useCurrentAnonInstanceId(
    instancesModel: InstancesModel,
    setErrorCallback: (value: string) => void
): {
    mainInstanceId: SHA256IdHash<Instance> | undefined;
    anonymousInstanceId: SHA256IdHash<Instance> | undefined;
} {
    const [anonymousInstanceId, setAnonymousInstanceId] = useState<SHA256IdHash<Instance>>();
    const [mainInstanceId, setMainInstanceId] = useState<SHA256IdHash<Instance>>();

    useEffect(() => {
        instancesModel
            .localInstancesInfo()
            .then(infos => {
                if (infos.length !== 2) {
                    setErrorCallback(i18n.t('errors:unknownError'));
                }

                infos.forEach(instanceInfo => {
                    if (instanceInfo.isMain) {
                        setMainInstanceId(instanceInfo.instanceId);
                    } else {
                        setAnonymousInstanceId(instanceInfo.instanceId);
                    }
                });
            })
            .catch(err => setErrorCallback(err));
    });

    return {
        mainInstanceId: mainInstanceId,
        anonymousInstanceId: anonymousInstanceId
    };
}
