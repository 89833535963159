import i18n from '../../i18n';
import type ConsentFileModel from 'one.models/lib/models/ConsentFileModel';
import type RecoveryModel from 'one.models/lib/models/RecoveryModel';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFontsX from 'pdfmake/build/vfs_fonts';
import {downloadFile} from '../modelHelper/DownloadHelper';

/** Use pdf make library default fonts. */
pdfMake.vfs = pdfFontsX.pdfMake.vfs;

/**
 * Generate the recovery file which will contain the user id, the recovery key,
 * the url for recovering the current instance and the qr code which contains the
 * recovery url.
 *
 * The user needs to scan the qr code and type a new password and the recovery key
 * and the instance with the same email will be created.
 * After the creation the user has to connect again to it's partner in order to
 * recover questionnaires and consent file data.
 *
 * @param {ConsentFileModel} consentFileModel
 * @param {RecoveryModel} recoveryModel
 */
export async function downloadRecoveryFile(
    consentFileModel: ConsentFileModel,
    recoveryModel: RecoveryModel
): Promise<void> {
    const {hostname, protocol, port} = window.location;
    /** Load the recovery details which will be printed in the recovery file **/
    const recoveryInformation = await recoveryModel.extractEncryptedPersonInformation();
    const recoveryNonce = recoveryInformation.recoveryNonce;
    const recoveryKey = recoveryInformation.recoveryKey;
    const encryptedPersonKeys = recoveryInformation.encryptedPersonInformation;
    /** Generate the recovery url **/
    const link = `${protocol}//${hostname}:${port}/recovery/#${recoveryNonce}#${encryptedPersonKeys}`;
    /** Extract the person id **/
    const personIdHash = consentFileModel.getOwnerId();

    if (!personIdHash) {
        throw new Error(i18n.t('errors:recovery.emailNotFound'));
    }

    const pdfTitle = `${i18n.t('common:infos.pdfTitle')}-${personIdHash}.pdf`;
    const personId = `${personIdHash}`;

    /** Generate the recovery pdf file structure **/
    const docDefinition = {
        styles: {
            header: {
                fontSize: 14,
                bold: true,
                lineHeight: 2
            },
            body: {
                fontSize: 12,
                lineHeight: 1.5
            },
            inputs: {
                bold: true,
                italics: true,
                lineHeight: 1.5,
                fontSize: 12
            },
            footer: {
                fontSize: 12,
                italics: true,
                margin: 10
            }
        },
        content: [
            {text: i18n.t('recovery:title'), style: 'header'},
            {
                text: [
                    {text: i18n.t('recovery:personId'), style: 'body'},
                    {text: personId, style: 'inputs'}
                ]
            },
            {
                text: [
                    {text: i18n.t('recovery:recoveryKey'), style: 'body'},
                    {text: recoveryKey, style: 'inputs'}
                ]
            },
            {
                text: [
                    {text: i18n.t('recovery:link'), style: 'body'},
                    {text: i18n.t('recovery:clickHere'), link: link, style: 'inputs'}
                ]
            },
            {text: i18n.t('recovery:qrCode'), style: 'body'},
            {qr: link, fit: 300, alignment: 'center'},
            {text: '\n\n'},
            {text: i18n.t('recovery:text1'), style: 'body'},
            {text: '\n\n'},
            {text: i18n.t('recovery:text2'), style: 'header'},
            {text: i18n.t('recovery:text3'), style: 'body'},
            {text: '\n\n'},
            {text: i18n.t('recovery:text4'), style: 'header'},
            {text: i18n.t('recovery:text5'), style: 'body'}
        ],
        footer: {text: i18n.t('recovery:footer'), style: 'footer'}
    };
    /** Generate the recovery pdf file and download it **/
    // ts-ignore is required because we are using alignment property
    // for th qr code and typescript can not recognise it
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    pdfMake.createPdf(docDefinition).getBlob(async (res: Blob) => {
        await downloadFile(res, pdfTitle, 'application/pdf');
    });
}
